import React from 'react'
import { IconProps } from '../../../common/types'

const SettingsSection: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12.5C8.61929 12.5 7.5 11.3807 7.5 10C7.5 8.61929 8.61929 7.5 10 7.5C11.3807 7.5 12.5 8.61929 12.5 10C12.5 11.3807 11.3807 12.5 10 12.5Z"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.6665 9.26657C1.6665 8.39991 2.37484 7.68324 3.24984 7.68324C4.75817 7.68324 5.37484 6.61657 4.6165 5.30824C4.18317 4.55824 4.4415 3.58324 5.19984 3.14991L6.6415 2.32491C7.29984 1.93324 8.14984 2.16657 8.5415 2.82491L8.63317 2.98324C9.38317 4.29157 10.6165 4.29157 11.3748 2.98324L11.4665 2.82491C11.8582 2.16657 12.7082 1.93324 13.3665 2.32491L14.8082 3.14991C15.5665 3.58324 15.8248 4.55824 15.3915 5.30824C14.6332 6.61657 15.2498 7.68324 16.7582 7.68324C17.6248 7.68324 18.3415 8.39157 18.3415 9.26657V10.7332C18.3415 11.5999 17.6332 12.3166 16.7582 12.3166C15.2498 12.3166 14.6332 13.3832 15.3915 14.6916C15.8248 15.4499 15.5665 16.4166 14.8082 16.8499L13.3665 17.6749C12.7082 18.0666 11.8582 17.8332 11.4665 17.1749L11.3748 17.0166C10.6248 15.7082 9.3915 15.7082 8.63317 17.0166L8.5415 17.1749C8.14984 17.8332 7.29984 18.0666 6.6415 17.6749L5.19984 16.8499C4.4415 16.4166 4.18317 15.4416 4.6165 14.6916C5.37484 13.3832 4.75817 12.3166 3.24984 12.3166C2.37484 12.3166 1.6665 11.5999 1.6665 10.7332V9.26657Z"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SettingsSection
