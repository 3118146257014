import React from 'react'
import { IconProps } from '../../../common/types'

const TileCartIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M3.34766 2.5H5.01432L5.34766 4.16667M6.68099 10.8333H15.0143L18.3477 4.16667H5.34766M6.68099 10.8333L5.34766 4.16667M6.68099 10.8333L4.77025 12.7441C4.24527 13.269 4.61708 14.1667 5.3595 14.1667H15.0143M15.0143 14.1667C14.0938 14.1667 13.3477 14.9129 13.3477 15.8333C13.3477 16.7538 14.0938 17.5 15.0143 17.5C15.9348 17.5 16.681 16.7538 16.681 15.8333C16.681 14.9129 15.9348 14.1667 15.0143 14.1667ZM8.34766 15.8333C8.34766 16.7538 7.60146 17.5 6.68099 17.5C5.76051 17.5 5.01432 16.7538 5.01432 15.8333C5.01432 14.9129 5.76051 14.1667 6.68099 14.1667C7.60146 14.1667 8.34766 14.9129 8.34766 15.8333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TileCartIcon
