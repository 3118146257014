import React, { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { handleError } from '../../../../../../utilities/commonUtilities'
import { editRole, getVendorPermissions } from '../api'
import RoleDetailPermissions from './Permission'

const VendorRolePermissions: React.FC = () => {
  const [perm, setPerm] = useState<any>([])

  const params = useParams()
  const { setActiveTab, handleCallBack, roleData, permissions, isFetching } =
    useOutletContext<{
      setActiveTab: (id: string) => void
      handleCallBack: () => void
      permissions: any[]
      isFetching: boolean
      roleData: any
    }>()

  useEffect(
    () => {
      setActiveTab('SYSTEM_SETTINGS_VENDOR_ROLE_PERMISIIONS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!isFetching) {
      getAllPermissions()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching])
  const handleUpdate = (data: any) =>
    editRole({
      input: { ...roleData, permissions: data?.permissions },
      id: params.id as string,
      id2: params.id2 as string,
    }).then((res) => res)

  const getAllPermissions = () => {
    getVendorPermissions()
      .then((res) => {
        // const sorted_data = res.sort((a: any, b: any) => {
        //   return a.sort - b.sort
        // })
        const sorted_data = res
        setPerm(
          sorted_data?.map((parent: any) => ({
            ...parent,
            checked: permissions?.some((itm: any) => itm.acl === parent.id),
          }))
        )
      })
      .catch((err: any) => {
        handleError(err)
      })
  }
  const handleRefresh = () => {
    getAllPermissions()
    handleCallBack?.()
  }

  return (
    <RoleDetailPermissions
      permissions={perm || []}
      handleRefresh={handleRefresh}
      handleUpdate={handleUpdate}
    />
  )
}

export default VendorRolePermissions
