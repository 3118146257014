import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../components/app/formBuilder/index'
import CustomDrawer from '../../../../../components/common/drawer'
import { useCreateVendorBankAccount } from '../api'
import { AddBankAccountSchema, bankAccountSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  parentParams?: { id?: string; name?: string }
  handleCallback?: () => void
}

export default function AddBankAccount({
  isDrawerOpen,
  handleClose,
  // subSection,
  handleCallback,
  parentParams,
}: Props) {
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text'
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
  })

  const getAccountType = () => {
    const data = [
      { id: 'current', name: 'Current' },
      { id: 'savings', name: 'Savings' },
    ]
    return data
  }

  const formBuilderProps = [
    textField('display_name', 'Display Name', 'Enter Display Name', false),
    textField('account_name', 'Name In Account', 'Enter Name In Account', true),
    {
      name: 'account_type_name',
      label: 'Account Type',
      required: true,
      getData: getAccountType,
      async: true,
      id: 'account_type',
      descId: 'id',
      desc: 'name',
      type: 'auto_complete',
      spacing: 4,
      placeholder: 'Enter Account Type',
    },
    textField('account_number', 'Account Number', 'Enter Account Number', true),
    textField('bank_name', 'Bank Name', 'Enter Bank Name', true),
    textField('branch_name', 'Branch Name', 'Enter Branch Name', true),
    textField('ifsc_code', 'IFSC Code', 'Enter IFSC Code', true),
  ]

  const methods = useForm<AddBankAccountSchema>({
    resolver: zodResolver(bankAccountSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const handleSubmission = () => {
    methods.reset({
      display_name: '',
      account_name: '',
      account_number: '',
      account_type_name: '',
      bank_name: '',
      branch_name: '',
      ifsc_code: '',
    })
    handleCallback?.()
    handleClearAndClose()
  }
  const { mutate, data, isLoading } =
    useCreateVendorBankAccount(handleSubmission)
  const onSubmit = (form_data: AddBankAccountSchema) => {
    setTimeout(() => {
      mutate({
        input: { ...form_data },
        id: parentParams?.id,
      })
    }, 500)
  }

  useEffect(() => {
    handleCallback?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({
      display_name: '',
      account_name: '',
      account_number: '',
      account_type_name: '',
      bank_name: '',
      branch_name: '',
      ifsc_code: '',
    })
    handleClose()
  }

  return (
    <>
      <CustomDrawer
        className="formDrawer"
        open={isDrawerOpen}
        handleClose={() => handleClearAndClose()}
        handleSubmit={handleSubmit((data) => onSubmit(data))}
        title="Add Bank Account"
        actionLoader={isLoading}
      >
        <div className="flex flex-col gap-4">
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        </div>
      </CustomDrawer>
    </>
  )
}
