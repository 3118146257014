import { getNestedProperty } from '../../../utilities/parsers'
import Icons from '../../common/icons/index'

const defaultColumnProps1 = {
  sortable: false,
  resizable: true,
  isVisible: true,
}
export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell =
    (key: string | string[], type?: string) => (row: any) => {
      switch (type) {
        case 'join':
          return {
            cell: `${getNestedProperty(row, key[0]) ?? ''} ${
              getNestedProperty(row, key[1]) ?? ''
            }`,
          }

        case 'custom':
          return {
            cell: (
              <div className="flex gap-1 items-center">
                {getNestedProperty(row, key[0]) && (
                  <Icons
                    name="phone"
                    toolTip={getNestedProperty(row, key[0])}
                  />
                )}
                {getNestedProperty(row, key[1]) && (
                  <Icons
                    name="email"
                    toolTip={getNestedProperty(row, key[1])}
                  />
                )}
              </div>
            ),
          }
        case 'priority':
          return {
            cell: (
              <>
                <span
                  style={{
                    color: row?.task_priority?.colour_code,
                  }}
                >
                  {getNestedProperty(row, key as string)}
                </span>
              </>
            ),
          }
        case 'loop':
          return {
            cell: (
              <>
                {row?.assignees?.slice(0, 1).map((item: any, index: number) => (
                  <span key={item.id}>
                    {item.name}
                    {row?.assignees.length - 1 !== index && ', '}
                  </span>
                ))}
                {row?.assignees?.length > 1 && (
                  <span className=" p-1 bg-bgBadgeColor rounded-xl px-2 text-white ml-1">
                    +{row.assignees.length - 1}
                  </span>
                )}
              </>
            ),
          }

        default:
          return {
            cell: getNestedProperty(row, key as string),
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
      }
    }

  const column = [
    {
      title: 'Task Category',
      field: 'event_date',
      renderCell: createRenderCell('category.name'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Task Details',
      field: 'task',
      rowClick: (row: any) => onViewAction(row),
      ...defaultColumnProps1,
      link: true,
    },

    {
      title: 'Task Asignee',
      field: 'datetime_created',
      renderCell: createRenderCell('assign_to.name'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Task Reminder',
      field: 'reminder_date',
      type: 'dateTime',
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Task Status',
      field: '',
      renderCell: createRenderCell('status.name'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Task Remark',
      field: '',
      renderCell: createRenderCell('task_status_remark'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Task Priority',
      field: '',
      renderCell: createRenderCell('task_priority.display_name', 'priority'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Created By',
      field: 'datetime_created',
      renderCell: createRenderCell('created_by.name'),
      customCell: true,
      ...defaultColumnProps1,
    },

    {
      title: 'Created On',
      field: 'datetime_created',
      type: 'date',
      customCell: true,
      ...defaultColumnProps1,
    },
  ]
  return column
}
