import { z } from 'zod'

import noLeadingSpaces from '../../../utilities/noLeadingSpaces'

export const sendInvoiceSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required.' })
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  // .optional()
  // .nullable(),
})

export type SendInvoiceSchema = z.infer<typeof sendInvoiceSchema>
