import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import FileAttachments from '../../../../components/app/attachments'
import { blockActionsbyStatus } from '../../../../configs/permissionGate'
import { checkDetailPermission } from '../../permission'

const QuoteFiles = () => {
  const { setActiveTab, handleParentCallBack, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack: () => void
    detailsData: any
  }>()
  const [detailData, setDetailData] = useState(detailsData)
  useEffect(
    () => {
      setActiveTab('QUOTE_DETAILS_FILES')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setDetailData(detailsData)
  }, [detailsData])
  return (
    <FileAttachments
      model_name="Quote"
      handleCallback={handleParentCallBack}
      disabled={
        blockActionsbyStatus(detailData?.order_status?.code) ||
        !detailsData?.is_quote
      }
      checkModulePermission={checkDetailPermission}
    />
  )
}

export default QuoteFiles
