import { z } from 'zod'

import noLeadingSpaces from '../../../utilities/noLeadingSpaces'

export const addTeamSchema = z.object({
  name: z
    .string({ invalid_type_error: 'Category Name is required.' })
    .min(1, { message: 'Category Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
})

export type AddTeamSchema = z.infer<typeof addTeamSchema>
