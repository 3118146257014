import React from 'react'
import { IconProps } from '../../../common/types'

const TextFieldCancel: React.FC<IconProps> = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.769231 10L0 9.23077L4.23077 5L0 0.769231L0.769231 0L5 4.23077L9.23077 0L10 0.769231L5.76923 5L10 9.23077L9.23077 10L5 5.76923L0.769231 10Z"
        fill="#E55353"
      />
    </svg>
  )
}

export default TextFieldCancel
