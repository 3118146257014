import moment from 'moment'
import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = () => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat

    if (isCustom === 'amount') {
      const propertyValue = getNestedProperty(row, key)
      const numbers =
        typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

      return {
        cell: format(numbers),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else if (isCustom === 'date') {
      return {
        cell: getNestedProperty(row, key)
          ? moment(getNestedProperty(row, key)).format('DD-MM-YYYY')
          : '',
      }
    } else {
      return {
        cell: getNestedProperty(row, key),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    {
      title: 'Date',
      field: 'datetime_created',
      renderCell: createRenderCell('datetime_created', 'date'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Created By',
      renderCell: createRenderCell('created_by.name'),
      field: 'name',
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Activity',
      colWidth: 1000,
      field: 'text',
      ...defaultColumnProps3,
    },
  ]

  return column
}
