import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../components/app/formBuilder/index'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { getPointOfSupply, useEditVendor } from '../../api'
import {
  ACCEPTED_IMAGE_TYPES,
  basicInformationSchema,
  basicSchema,
} from '../../create/schema'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
}

const BasicInformation = (props: Props) => {
  const { data, id, handleRefresh, handleEditButton, editFlag, hasPermission } =
    props

  const [isEditing, setIsEditing] = useState(false)

  const getPlaceOfSupply = async (value: any) => {
    const { data } = await getPointOfSupply({
      search: value ?? '',
      type: 'dropdown',
    })
    return data?.map((item: any) => ({
      ...item,
      place_of_supply_name: `${item.name} (${item.code})`,
    }))
  }

  const onInit = () => {
    return {
      vendor_name: data?.vendor_name ?? '',
      vendor_display_name: data?.vendor_display_name ?? '',
      email: data?.email ?? '',
      phone: data?.phone ?? '',
      website: data?.website?.length > 0 ? data?.website : null,
      gst_no: data?.gst_no?.length > 0 ? data?.gst_no : null,
      pan_no: data?.pan_no?.length > 0 ? data?.pan_no : null,
      cin: data?.cin?.length > 0 ? data?.cin : null,
      place_of_supply_id: data?.place_of_supply?.id ?? null,
      place_of_supply_name: data?.place_of_supply?.name ?? null,
      remark: data.remark ?? '',
      logo: data.logo ?? '',
      status: data.status ? 'True' : 'False',
    }
  }
  const methods = useForm<basicInformationSchema>({
    resolver: zodResolver(basicSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    value,
    ...(required ? { required: true } : {}),
  })
  console.log('form', methods.formState.errors)
  const formBuilderProps = [
    {
      ...textField(
        'vendor_name',
        'Name',
        'Enter name',
        data?.vendor_name,
        true
      ),
    },
    {
      ...textField(
        'vendor_display_name',
        'Display name',
        'Enter display name',
        data?.vendor_display_name,
        true
      ),
    },
    {
      ...textField('email', 'Email', 'Enter email', data?.email, true),
      type: 'email',
    },
    textField('phone', 'Phone', 'Enter Phone', data?.phone, true),
    textField(
      'website',
      'Website',
      'https://www.website.com',
      data?.website,
      true
    ),
    textField('gst_no', 'GST No', 'Enter GST No', data?.gst_no, false),
    {
      ...textField('pan_no', 'PAN No', 'Enter PAN No', data?.pan_no, false),
      toCapitalize: true,
    },
    textField('cin', 'CIN', 'Enter CIN', data?.cin, false),
    {
      name: 'place_of_supply_name',
      label: 'Place of Supply',
      required: false,
      getData: getPlaceOfSupply,
      async: false,
      id: 'place_of_supply_id',
      descId: 'id',
      initialLoad: true,
      desc: 'place_of_supply_name',
      type: 'auto_complete',
      placeholder: 'Enter Place of Supply ',
      value: data?.place_of_supply?.name
        ? `${data?.place_of_supply?.name} (${data?.place_of_supply?.code})`
        : '--',
    },
    {
      name: 'logo',
      required: false,
      id: 'logo',
      descId: 'id',
      type: 'file_upload',
      link: data?.logo,
      value: data?.logo ? 'logo' : '',
      label: 'Logo',
      acceptedFiles: ['PNG, JPG, JPEG'],
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      accept: 'image/*',
    },
    {
      ...textField('remark', 'Remarks', 'Enter Remarks', data?.remark, false),
      type: 'textarea',
    },
    {
      ...textField('status', 'Status', 'Enter Status', data?.status, false),
      type: 'radio_group',
      group_data: [
        { id: 'status', value: 'True', label: 'Active' },
        { id: 'status2', value: 'False', label: 'Inactive' },
      ],
    },
  ]

  if (isEditing) {
  }

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const { mutate, isLoading } = useEditVendor(handleSubmission)
  const onSubmit = (data: any) => {
    const formData = new FormData()
    Object.keys(data)?.forEach((item: string) => {
      if (item === 'logo') {
        if (typeof data?.logo !== 'string' && data.logo !== undefined) {
          formData.append('logo', data?.logo)
        }
      } else {
        formData.append(item, data[item] ?? '')
      }
    })
    formData.append('type', 'basic_info')
    mutate({
      input: formData,
      id,
    })
  }

  const { handleSubmit } = methods

  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={isEditing} />
          </FormProvider>
        ) : (
          formBuilderProps.map((basicInformation: any) => (
            <div key={data?.id} className=" col-span-2 md:col-span-1">
              <FormFieldView
                value={
                  basicInformation.type === 'radio_group'
                    ? basicInformation.value
                      ? 'Active'
                      : 'Inactive'
                    : basicInformation.value
                }
                required={basicInformation?.required}
                label={basicInformation.label}
                type={basicInformation?.type}
                link={basicInformation?.link}
                image={basicInformation.link ? true : false}
              />
            </div>
          ))
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="location-pin" />
          <h4 className=" font-bold text-primaryText">Basic Information</h4>
        </div>
        <div className="iconBlack cursor-pointer">
          {hasPermission && (
            <div className="iconBlack cursor-pointer">
              {isEditing ? (
                <div className="flex gap-2">
                  <div>
                    <Button
                      onClick={handleCancel}
                      size="xs"
                      label="Cancel"
                      outlined={true}
                    />
                  </div>
                  <div>
                    <Button
                      size="xs"
                      label="Save"
                      outlined={false}
                      isLoading={isLoading}
                      onClick={handleSubmit((data) => onSubmit(data))}
                    />
                  </div>
                </div>
              ) : !editFlag ? (
                <Icons onClick={handleEditClick} name="edit" />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">{renderFields()}</div>
    </div>
  )
}

export default BasicInformation
