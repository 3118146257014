import Icons from '../../../../components/common/icons'
import { getNestedProperty } from '../../../../utilities/parsers'

const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell =
    (key: string | string[], type?: string) => (row: any) => {
      switch (type) {
        case 'is_primary':
          return {
            cell: <>{row?.is_primary ? 'Yes' : 'No'}</>,
          }
        case 'icon':
          return {
            cell: (
              <div className="flex gap-1 items-center">
                {row?.is_primary && <Icons name="favourite-colored" />}{' '}
                {row?.account_name}
              </div>
            ),
          }
        default:
          return {
            cell: getNestedProperty(row, key as string),
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
      }
    }

  const column = [
    {
      title: 'Name In Account',
      ...defaultColumnProps,
      field: '',
      renderCell: createRenderCell('account_name', 'icon'),
      customCell: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'account_name'),
      colWidth: 250,
    },
    {
      title: 'Display Name',
      ...defaultColumnProps,
      field: 'display_name',
      colWidth: 250,
    },
    {
      title: 'Account Type',
      ...defaultColumnProps,
      field: 'account_type.label',
      colWidth: 150,
    },
    {
      title: 'Account Number',
      ...defaultColumnProps,
      field: 'account_number',
      colWidth: 150,
    },
    {
      title: 'Bank Name',
      ...defaultColumnProps,
      field: 'bank_name',
    },
    {
      title: 'Branch Name',
      ...defaultColumnProps,
      field: 'branch_name',
    },
    {
      title: 'IFSC Code',
      ...defaultColumnProps,
      field: 'ifsc_code',
      colWidth: 150,
    },
    {
      title: 'Primary',
      ...defaultColumnProps,
      field: '',
      renderCell: createRenderCell('is_primary', 'is_primary'),
      customCell: true,
      colWidth: 100,
    },
  ]

  return column
}
