import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../components/common'
import { useSnackbarManager } from '../../../components/common/snackbar'
import config from '../../../config'
import { ForgetResetPassword, verifyResetPassword } from '../api'
import { ResetPasswordSchema, resetSchema } from '../schema'

const fixedInputClass =
  'rounded appearance-none relative block w-full px-3 py-2 border border-formBorder placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-gray-300 focus:z-10 sm:text-sm'

export default function ResetPassword() {
  const params = useParams()
  const { enqueueSnackbar } = useSnackbarManager()
  const navigate = useNavigate()
  const [linkValid, setLinkValid] = useState(false)
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<ResetPasswordSchema>({
    mode: 'onChange',
    resolver: zodResolver(resetSchema),
  })
  const handleValidateToken = () => {
    const data = { token: params?.token }
    verifyResetPassword(data)
      .then(() => {
        setLinkValid(true)
      })
      .catch((error) => {
        console.error('Error:', error)
        navigate('/dashboard')
      })
  }
  useEffect(() => {
    if (params?.token) {
      handleValidateToken()
    } else {
      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])
  const handleBack = () => {
    navigate('/login')
  }
  const handleData = (data: ResetPasswordSchema, e: any) => {
    e.preventDefault()
    const details = {
      token: params?.token,
      password: data.password,
      confirm_password: data.confirm_password,
    }
    ForgetResetPassword(details)
      .then((res) => {
        enqueueSnackbar(res.message, { variant: 'success' })
        navigate('/login')
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm_password: false,
  })

  return (
    <>
      {linkValid && (
        <form onSubmit={(e) => handleSubmit(handleData)(e)} noValidate>
          <div className="min-h-full h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-slatedark">
            <div className="max-w-md w-full space-y-8 bg-bgWhite p-8 rounded-lg shadow-xl">
              <div className="mb-6">
                <div className="flex justify-center">
                  <img
                    src={config.PROJECT_LOGO}
                    alt="bizpole"
                    className="h-12"
                  />
                </div>
                <h2 className="mt-8 text-center text-3xl font-bold text-gray-900">
                  Reset Password
                </h2>
              </div>
              <div className="mt-4 space-y-6">
                <div className="my-4">
                  <label
                    className="text-sm mb-2 block text-primaryText"
                    htmlFor={'password'}
                  >
                    Password
                  </label>
                  <div className="relative flex  items-center">
                    <div className="w-full">
                      <input
                        id={'password'}
                        type={showPassword.password ? 'text' : 'password'}
                        required={true}
                        className={fixedInputClass}
                        placeholder={'Enter Password'}
                        {...register('password')}
                      />
                    </div>
                    {/* <button type="button" onClick={togglePasswordVisibility}> */}
                    <div
                      className="absolute right-1 cursor-pointer z-50"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          password: !showPassword.password,
                        })
                      }
                    >
                      {showPassword.password ? (
                        <AiFillEye color="primary" />
                      ) : (
                        <AiFillEyeInvisible color="primary" />
                      )}
                    </div>

                    {/* {showPassword ? (
                        <Icons name="eye" />
                      ) : (
                        <Icons name="eye-close" />
                      )} */}
                    {/* </button> */}
                  </div>
                  {errors.password && (
                    <div className="text-xs text-red-500">
                      {errors?.password?.message as string}
                    </div>
                  )}
                </div>
                <div className="my-4">
                  <label
                    className="text-sm mb-2 block text-primaryText"
                    htmlFor={'confirm_password'}
                  >
                    Confirm Password
                  </label>
                  <div className="relative flex  items-center">
                    <div className="w-full">
                      <input
                        id={'confirm_password'}
                        type={
                          showPassword.confirm_password ? 'text' : 'password'
                        }
                        required={true}
                        className={fixedInputClass}
                        placeholder={'Confirm Password'}
                        {...register('confirm_password')}
                      />
                    </div>
                    <div
                      className="absolute right-1 cursor-pointer z-50"
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirm_password: !showPassword.confirm_password,
                        })
                      }
                    >
                      {showPassword.confirm_password ? (
                        <AiFillEye color="primary" />
                      ) : (
                        <AiFillEyeInvisible color="primary" />
                      )}
                    </div>
                  </div>
                  {errors.confirm_password && (
                    <div className="text-xs text-red-500">
                      {errors?.confirm_password?.message as string}
                    </div>
                  )}
                </div>
                <div className="my-4 flex gap-3">
                  <>
                    <Button
                      label="Cancel"
                      className="w-full"
                      outlined={true}
                      primary={false}
                      onClick={handleBack}
                    />

                    <Button label="Submit" className="w-full" type="submit" />
                  </>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}
