export default function Maintenance() {
  return (
    <div>
      <div
        style={{
          padding: '40px 0 20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '120px',
        }}
      >
        <img src={'/images/logo.png'} alt="Logo" />
      </div>
      <div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 'calc(100vh - 120px)',
            flexDirection: 'column',
          }}
        >
          <h4
            className="titleLarge"
            style={{
              color: '#000',
              maxWidth: '752px',
              textAlign: 'center',
              margin: '0 auto 16px',
            }}
          >
            This site is currently down for maintenance
          </h4>
          <p className="textSmall">
            We apologise for any inconveniences caused
          </p>
          <p className="textSmall">We’ve almost done.</p>
          <div className="imgSet" style={{ paddingTop: '36px' }}>
            <img
              src={'/images/under-maintenance.png'}
              alt="Under Maintenance"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
