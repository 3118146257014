import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../../../components/app/alertBox/infoBox'
import { Button, DialogModal } from '../../../../../../components/common'
import Icons from '../../../../../../components/common/icons/index'
import { useSnackbarManager } from '../../../../../../components/common/snackbar'
import { router_config } from '../../../../../../configs/route.config'
import {
  getErrorMessage,
  getNestedProperty,
} from '../../../../../../utilities/parsers'
import { checkDetailPermission, checkPermission } from '../../../../permission'
import { changeEmployeeTeamStatus } from '../api'
import AddTeam from './addTeam'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
}
const TeamInfo = (props: Props) => {
  const { data, handleRefresh } = props
  const navigate = useNavigate()
  const [isTeamOpen, setIsTeamOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<any>()
  const [changeStatus, changeStatusOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])
  const params = useParams()

  const handleAction = () => {
    setIsTeamOpen(true)
  }
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
  }>()

  useEffect(
    () => {
      setActiveTab('CONTACT_DETAILS_ACCOUNTS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const createRenderCell =
    (key: string | string[], type?: string) => (row: any) => {
      switch (type) {
        case 'join':
          return {
            cell: `${getNestedProperty(row, key[0]) ?? ''} ${
              getNestedProperty(row, key[1]) ?? ''
            }`,
          }
        case 'status':
          return {
            cell: <div>{row.status ? 'Active' : 'Inactive'}</div>,
          }
        default:
          return {
            cell: getNestedProperty(row, key as string),
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
      }
    }

  const column = [
    {
      title: 'Name',
      field: 'team.name',
      sortable: false,
      isVisible: true,
      resizable: true,
      link: true,
      renderCell: createRenderCell('team.name'),
      getPath: (row: any) => onViewAction(row),
    },
  ]
  const onViewAction = (r: any) => {
    if (r?.id) {
      return `${router_config.SYSTEM_SETTINGS_VENDORS.path}/${params.id}/${router_config.SYSTEM_SETTINGS_VENDOR_TEAM.path}/${r.team.id}/basic-info`
    } else {
      return ''
    }
  }
  const handleChangeStatus = () => {
    changeEmployeeTeamStatus(
      params.id,
      activeRow.team.id,
      activeRow.id as string,
      activeRow.is_active
    )
      .then(() => {
        enqueueSnackbar('Updated successfully', {
          variant: 'success',
        })
        handleChangeStatusClose()
        handleRefresh()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.errors[0]?.message[0] ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const handleChangeStatusClose = () => {
    changeStatusOpen(false)
    setActiveRow(null)
  }
  const handleChangeStatusOpen = (row: any) => {
    setActiveRow(row)

    changeStatusOpen(true)
  }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          isLoading={isLoading}
          columns={column}
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                onClick={handleAction}
                label={'Add New'}
                hidden={!checkDetailPermission('add', 'team')}
                className="se"
                outlined
              />
            </div>
          }
          actionProps={[
            {
              title: 'View',
              action: (rowData) => navigate(onViewAction(rowData)),
              icon: <Icons name="eye" />,
              hidden: !checkPermission('view'),
              toolTip: 'View',
            },
            {
              title: 'Activate',
              action: (rowData) => handleChangeStatusOpen(rowData),
              icon: <Icons name="activate-icon" />,
              toolTip: 'Activate',
              hide: (rowData) => rowData?.is_active,
            },
            {
              title: 'Deactivate',
              action: (rowData) => handleChangeStatusOpen(rowData),
              icon: <Icons name="deactivate-icon" />,
              toolTip: 'Deactivate',
              hide: (rowData) => !rowData?.is_active,
            },
          ]}
          asyncSearch
          columnToggle
        />
        <AddTeam
          isDrawerOpen={isTeamOpen}
          paramsId={params?.id}
          handleClose={() => setIsTeamOpen(false)}
          handleCallback={handleRefresh}
        />
        <DialogModal
          isOpen={changeStatus}
          onClose={() => handleChangeStatusClose()}
          title={'Are you sure?'}
          onSubmit={() => handleChangeStatus()}
          secondaryAction={() => handleChangeStatusClose()}
          secondaryActionLabel="No, Cancel"
          actionLabel="Yes, I am"
          body={
            <InfoBox
              content={
                data?.is_active
                  ? 'Do you want to deactivate this user?'
                  : 'Do you want to activate this user?'
              }
            />
          }
        />
      </div>
    </>
  )
}

export default TeamInfo
