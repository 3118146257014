import { z } from 'zod'

const MAX_FILE_SIZE = 5000000

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]
const assigneeSchema = z.object({
  id: z.string().nonempty({ message: 'Id is required and cannot be empty.' }),
  name: z
    .string()
    .nonempty({ message: 'Name is required and cannot be empty.' }),
})
export const attachmentsSchema = z.object({
  event_type_id: z
    .string()
    .refine((val) => val !== '', { message: 'Event Type is required' }),
  event_type_name: z
    .string()
    .refine((val) => val !== '', { message: 'Event Type is required' }),
  event_date: z
    .union([z.date(), z.string(), z.null()])
    .refine((val) => val !== null, { message: 'Event Date is required' }),
  event_time: z
    .union([z.date(), z.string(), z.null()])
    .refine((val) => val !== null, { message: 'Event Time is required' }),
  remarks: z
    .string()
    .refine((val) => val !== '', { message: 'Remarks  is required' }),

  attachment_name: z.string().optional().nullable(),
  attachment: z
    .union([z.instanceof(File), z.any()])
    .refine((file) => !(file instanceof File) || file.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb',
    })
    .refine(
      (file) =>
        !(file instanceof File) || ACCEPTED_IMAGE_TYPES.includes(file.type),
      {
        message: 'File type not allowed',
      }
    )
    .nullable(),

  assignees: z.array(assigneeSchema).refine((data) => data.length > 0, {
    message: 'Event Participant(s) Required',
  }),
})

export type AttachmentsSchema = z.infer<typeof attachmentsSchema>
