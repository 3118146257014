import { useState } from 'react'

import { exportDocument } from '../../../apis/common.apis'
import { DialogModal, TextField } from '../../common'
import Icons from '../../common/icons'
import { useSnackbarManager } from '../../common/snackbar'

type Props = {
  isOpen: boolean
  handleClose: () => void
  pageParams?: any
  slug: string
}

export default function ExportComponent({
  isOpen,
  handleClose,
  pageParams,
  slug,
}: Props) {
  const [name, setName] = useState('')

  const { enqueueSnackbar } = useSnackbarManager()
  const handleSubmit = () => {
    exportDocument({ ...pageParams, file_name: name }, slug)
      .then((res) => res.data)
      .then((res) => {
        handleClose()
        enqueueSnackbar(res.message, { variant: 'success' })
        setName('')
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' })
        setName('')
      })
  }
  return (
    <DialogModal
      isOpen={isOpen}
      onClose={handleClose}
      title="Export Options"
      subTitle="Customise your exported file name"
      secondaryAction={handleClose}
      secondaryActionLabel="Cancel"
      onSubmit={handleSubmit}
      actionLabel="Export"
      actionLoader={false}
      body={
        <div className="flex flex-col gap-4">
          <div className="w-full flex flex-col gap-2">
            <TextField
              id="1"
              name="export_name"
              label="Export Name (Optional)"
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter Export Name"
              disabled={false}
              required={false}
            />
            <p className=" text-xs leading-4 text-secondary font-semibold">
              This is the name given to the exported .csv file
            </p>
          </div>
          <div className="p-4 bg-cardWrapperBg rounded-lg flex items-start gap-2">
            <div className="w-5 min-w-[20px]">
              <Icons name="exclamation-circle" />
            </div>
            <div className="flex flex-col gap-3">
              <p className=" text-primaryText text-xxs leading-4">
                Your request is being processed and can be tracked in Exports
                section. You can download it once its ready.
              </p>
              <p className=" text-primaryText text-xxs leading-4">
                Exports can be downloaded from Menu Exports page once its
                generated
              </p>
            </div>
          </div>
        </div>
      }
    />
  )
}
