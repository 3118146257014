import React from 'react'
import { IconProps } from '../../../common/types'

const LogoutIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3333 10.6667L14 8M14 8L11.3333 5.33334M14 8L4.66667 8M8.66667 10.6667V11.3333C8.66667 12.4379 7.77124 13.3333 6.66667 13.3333H4C2.89543 13.3333 2 12.4379 2 11.3333V4.66667C2 3.5621 2.89543 2.66667 4 2.66667H6.66667C7.77124 2.66667 8.66667 3.5621 8.66667 4.66667V5.33334"
        stroke="#C90909"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LogoutIcon
