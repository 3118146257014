import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../components/app/formBuilder/index'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { useEditVendor } from '../../api'
import { contactInformationSchema, contactSchema } from '../../create/schema'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
}

const ContactInformation = (props: Props) => {
  const { data, id, handleRefresh, handleEditButton, editFlag, hasPermission } =
    props

  const [isEditing, setIsEditing] = useState(false)

  const onInit = () => {
    return {
      contact_first_name: data?.contact_first_name ?? '',
      contact_last_name: data?.contact_last_name ?? '',
      contact_email: data?.contact_email ?? '',
      contact_phone: data?.contact_phone ?? '',
    }
  }
  const methods = useForm<contactInformationSchema>({
    resolver: zodResolver(contactSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    value,
    ...(required ? { required: true } : {}),
  })

  const formBuilderProps = [
    textField(
      'contact_first_name',
      'First Name',
      'Enter First Name',
      data?.contact_first_name,
      false
    ),
    textField(
      'contact_last_name',
      'Last Name',
      'Enter Last Name',
      data?.contact_last_name,
      false
    ),
    {
      ...textField(
        'contact_email',
        'Email',
        'Enter Email',
        data?.contact_email,
        false
      ),
      type: 'email',
    },
    textField(
      'contact_phone',
      'Phone',
      'Enter Phone',
      data?.contact_phone,
      false
    ),
  ]

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const { mutate, isLoading } = useEditVendor(handleSubmission)
  const onSubmit = (data: any) => {
    const formData = new FormData()
    Object.keys(data)?.forEach((item: string) => {
      formData.append(item, data[item] ?? '')
    })
    formData.append('type', 'contact_info')
    mutate({
      input: formData,
      id,
    })
  }

  const { handleSubmit } = methods

  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={isEditing} />
          </FormProvider>
        ) : (
          formBuilderProps.map((ContactInformation) => (
            <div key={data?.id} className=" col-span-2 md:col-span-1">
              <FormFieldView
                value={ContactInformation.value}
                required={ContactInformation?.required}
                label={ContactInformation.label}
                type={ContactInformation?.type}
              />
            </div>
          ))
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="location-pin" />
          <h4 className=" font-bold text-primaryText">Contact Information</h4>
        </div>
        <div className="iconBlack cursor-pointer">
          {hasPermission && (
            <div className="iconBlack cursor-pointer">
              {isEditing ? (
                <div className="flex gap-2">
                  <div>
                    <Button
                      onClick={handleCancel}
                      size="xs"
                      label="Cancel"
                      outlined={true}
                    />
                  </div>
                  <div>
                    <Button
                      size="xs"
                      label="Save"
                      isLoading={isLoading}
                      outlined={false}
                      onClick={handleSubmit((data) => onSubmit(data))}
                    />
                  </div>
                </div>
              ) : !editFlag ? (
                <Icons onClick={handleEditClick} name="edit" />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">{renderFields()}</div>
    </div>
  )
}

export default ContactInformation
