import moment from 'moment'

import Icons from '../../../../components/common/icons/index'
import { getNestedProperty } from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({ onViewAction, statusClassGen }: any) => {
  const createRenderCell = (key: string, custom?: string) => (row: any) => {
    switch (custom) {
      case 'status':
        return {
          cell: (
            <div className="text-capitalize">
              <span
                className={`statusLabel  ${
                  row.status?.name
                    ? statusClassGen(row?.status?.name)
                    : 'Active'
                }`}
              >
                {row?.status?.name}
              </span>
            </div>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      case 'icons':
        return {
          cell: (
            <div className="flex gap-1 items-center">
              {row?.customer.primary_company_id &&
                row?.customer.primary_company_id === row.company.id && (
                  <Icons name="favourite-colored" />
                )}
              <>{`${getNestedProperty(row, key) ?? ''}`}</>
            </div>
          ),
        }
      case 'created':
        return {
          cell: (
            <>
              {row?.company?.datetime_created
                ? moment(row?.company?.datetime_created).format('DD-MM-YYYY')
                : '--'}
            </>
          ),
        }

      default:
        return {
          cell: getNestedProperty(row, key),
          toolTip: getNestedProperty(row, key) ?? '',
        }
    }
  }

  const column = [
    {
      title: 'Account ID',
      field: 'company_code',
      ...defaultColumnProps3,
      fixed: true,
      renderCell: createRenderCell('company.company_code'),
      customCell: true,
      getPath: (row: any) => onViewAction(row),
      link: true,
    },
    {
      title: 'Created Date',
      field: 'datetime_created',
      ...defaultColumnProps3,
      renderCell: createRenderCell('company.datetime_created', 'created'),
      customCell: true,
    },
    {
      title: 'Account Name',
      renderCell: createRenderCell('company.business_name', 'icons'),
      field: 'business_name',
      ...defaultColumnProps3,
      customCell: true,
      link: true,
      getPath: (row: any) => onViewAction(row),
    },
    {
      title: 'Account CRE',
      renderCell: createRenderCell('company.account_cre'),
      field: 'account_cre',
      ...defaultColumnProps3,
      customCell: true,
    },
    {
      title: 'Mobile 1',
      renderCell: createRenderCell('company.mobile'),
      field: 'mobile',
      ...defaultColumnProps3,
      customCell: true,
    },
    {
      title: 'Email 1',
      renderCell: createRenderCell('company.email'),
      field: 'email',
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Website',
      field: 'customer_referral_id',
      ...defaultColumnProps3,
      renderCell: createRenderCell('company.website'),
      customCell: true,
    },
    {
      title: 'Constitution Category',
      field: 'account_count',
      ...defaultColumnProps3,
      renderCell: createRenderCell(
        'company.constitution.constitution_category_display_name'
      ),
      customCell: true,
    },
    {
      title: 'Sector',
      field: 'active_orders',
      ...defaultColumnProps3,
      renderCell: createRenderCell(
        'company.sector.business_sector_display_name'
      ),
      customCell: true,
    },
    {
      title: 'Address 1: State',
      renderCell: createRenderCell('company.address_info.state'),
      field: 'state',
      ...defaultColumnProps3,
      customCell: true,
    },
    {
      title: 'Address 1: City',
      renderCell: createRenderCell('company.address_info.city'),
      field: 'city',
      ...defaultColumnProps3,
      customCell: true,
    },
  ]

  return column
}
