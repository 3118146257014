import { useMutation } from '@tanstack/react-query'

import { updateData } from '../../../../apis/api.helpers'
import { getErrorMessage } from '../../../../utilities/parsers'
import { useSnackbarManager } from '../../../common/snackbar'

export const addPayment = (data: { input: any; id: string; url: string }) => {
  return updateData(`${data.url}/${data?.id}`, data?.input)
}
export const useAddPayment = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(addPayment, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Payment Updated successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error.message), {
        variant: 'error',
      })
    },
  })
}
