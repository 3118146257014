import moment from 'moment'
// import { Button } from '../../common'
import Icons from '../../common/icons/index'
import { MODULES } from '../../../configs/scopes.config'
type Props = {
  reply?: boolean
  handleClick?: (data: any) => void
  data?: any
  disabled?: boolean
  parentName?: string
  handleReply?: (data: any) => void
  checkModulePermission?: (key: string, sub: string) => boolean
}

export default function CommentContainer({
  // reply,
  data,
  parentName,
  // disabled = false,
  // handleReply,
  handleClick,
  checkModulePermission,
}: Props) {
  return (
    <div className="flex items-center justify-between gap-2 group">
      <div className="flex items-start justify-start flex-1 gap-2">
        <div className="w-5 h-5 min-w-[20px] rounded-full overflow-hidden flex items-center justify-center bg-[#EDF1FD] text-[10px] leading-4 font-medium text-primary dark:text-white">
          {data?.created_by?.name?.split(' ')[0]?.[0] || ''}
          {data?.created_by?.name?.split(' ')[1]?.[0] || ''}
        </div>
        <div className="flex flex-col items-start">
          <p className=" text-sm text-primaryText dark:text-white font-bold mb-0.5">
            {data?.created_by?.name}
          </p>
          <div className="flex items-center gap-2 mb-1">
            <span className="text-xxs leading-4 font-bold text-linkBlue">
              {`@${parentName}`}
            </span>
            <span className="text-xxs leading-4 dark:text-white text-secondary">
              {data?.datetime_created
                ? moment(data?.datetime_created).format('DD-MM-YYYY')
                : '--'}
            </span>
            {/* <span className="text-xxs leading-4  text-secondary">
              12 : 20AM
            </span> */}
          </div>
          <p className="text-xxs leading-4 mb-2 dark:text-white text-secondary">
            {data?.comment}
          </p>
          {/* {reply && (
            <Button
              className="btn-secondary dark:text-white"
              outlined={true}
              disabled={disabled}
              hidden={!checkModulePermission?.('reply', MODULES.notecomment)}
              onClick={() => handleReply?.(data)}
              size="xs"
              icon="reply"
              label="Reply"
            />
          )} */}
        </div>
      </div>
      <div className="flex items-center justify-center">
        {checkModulePermission?.('change', MODULES.notecomment) && (
          <div
            onClick={() => handleClick?.(data)}
            className=" cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity group-hover:transition-opacity"
          >
            <Icons name="edit" className="iconWidthSm iconGrey" />
          </div>
        )}
      </div>
    </div>
  )
}
