import { z } from 'zod'

import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

export const roleSchema = z.object({
  name: z
    .string({ invalid_type_error: 'Role Name is required.' })
    .min(1, { message: 'Role Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
})

export type RoleSchema = z.infer<typeof roleSchema>
