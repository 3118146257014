import { z } from 'zod'

export const allocatedSchema = z.object({
  service_name: z.string().optional().nullable(),
  service_id: z.string().optional().nullable(),
  order_item_id: z.string().optional().nullable(),
  service_total: z.union([z.number(), z.string()]).optional().nullable(),
  allocated_amount: z.union([z.number(), z.string()]).optional().nullable(),
  allocation_amount: z
    .number({ invalid_type_error: 'Required' })
    .min(0, 'Allocation amount must be non-negative'),
  allocation_note: z.string().optional().nullable(),
  remaining_amount: z.string().optional().nullable(),
  allocated_by: z.string().optional().nullable(),
  allocated_on: z.string().optional().nullable(),
})

export type AllocatedSchema = z.infer<typeof allocatedSchema>
