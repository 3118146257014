import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import apiUrl from '../../../../apis/api.url'
import InfoBox from '../../../../components/app/alertBox/infoBox'
import ManagePayment from '../../../../components/app/payment/manage'
import ShareReceipt from '../../../../components/app/payment/receipt/shareReceipt'
import { DialogModal, TextArea } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons/index'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { blockActionsbyStatus } from '../../../../configs/permissionGate'
import { router_config } from '../../../../configs/route.config'
import { MODULES } from '../../../../configs/scopes.config'
import { useAuthStore } from '../../../../store/authStore'
import { downloadFromServer } from '../../../../utilities/commonUtilities'
import { verifiyPayments } from '../../../payments/api'
import { deletePayment, sendPaymentLink, useOrderPayment } from '../../api'
import {
  checkDetailPermission,
  checkPaymentDetailPermission,
} from '../../permission'
import Create from './create/index'
import { getColumns } from './paymentColumns'
import SendLink from './sendLink/index'

const ContactProfiles = () => {
  const { enqueueSnackbar } = useSnackbarManager()
  const { setActiveTab, handleParentCallBack, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack: () => void
    detailsData: any
  }>()

  const [isPaymentOpen, setIsPaymentOpen] = useState(false)
  const [detailData, setDetailData] = useState<any>()
  const [isPaymentLinkOpen, setIsPaymentLinkOpen] = useState(false)
  const [columns, setColumns] = useState<any>([])
  const params = useParams()
  const [openManage, setOpenManage] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [item, setItem] = useState<string | null>(null)
  const [openEdit, setOpenEdit] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState<string | null>(null)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const [showPaymentNotifyModal, setShowPaymentNotifyModal] = useState(false)
  const [remark, setRemark] = useState('')
  const [shareReceipt, setshareReceipt] = useState(false)
  const onViewAction = (r: any, from: string) => {
    if (from === 'account') {
      return `${router_config.ACCOUNTS.path}/${r?.account?.id}/summary`
    } else if (from == 'order') {
      return `${router_config.SERVICE_AND_ORDERS_BY_ORDERS.path}/${r.order?.id}/summary`
    } else if (from == 'transactionId') {
      setSelectedPayment(r?.id)
      setOpenEdit(false)
      setOpenManage(true)
    } else {
      handlePaymentRecieptOpen(r)
    }
  }
  useEffect(() => {
    setDetailData(detailsData)
  }, [detailsData])
  const handleAction = () => {
    setIsPaymentOpen(true)
  }
  useEffect(() => {
    if (params.trans_id) {
      setSelectedPayment(params.trans_id)
      setOpenEdit(false)
      setOpenManage(true)
    }
  }, [params.trans_id])
  const handlePaymentLinkAction = () => {
    setIsPaymentLinkOpen(true)
  }

  const handlePaymentLinkNotifyAction = (row: any) => {
    setItem(row.id)
    setShowPaymentNotifyModal(true)
  }

  const handleClose = () => {
    setIsPaymentOpen(false)
  }

  const handlePaymentLinkClose = (flag?: boolean) => {
    setIsPaymentLinkOpen(false)
    if (flag) {
      refetch()
    }
  }

  const handleResetColums = () => {
    setColumns(() => getColumns({ onViewAction: onViewAction }))
  }

  const handlePaymentRecieptOpen = (res: any) => {
    if (res.id) {
      window.open(`/order/payment/${res.id}/receipt`, '_blank')
    }
  }

  const handleCallback = () => {
    refetch()
    handleParentCallBack?.()
  }
  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const handleEdit = (row: any) => {
    setOpenEdit(true)
    setSelectedPayment(row?.id)
    setOpenManage(true)
  }
  const handleCloseMange = (flag?: boolean) => {
    setOpenManage(false)
    setOpenEdit(false)
    setSelectedPayment(null)
    if (flag) {
      refetch()
    }
  }
  useEffect(() => {
    handleResetColums()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleDeleteModal = (rowData: any) => {
    setItem(rowData.id)
    setDeleteModal(true)
  }
  const handleDeleteModalClose = () => {
    setItem(null)
    setDeleteModal(false)
  }
  const handleDelete = () => {
    deletePayment(item as string)
      .then((res) => {
        enqueueSnackbar(res.message ? res.message : 'Deleted successfully', {
          variant: 'success',
        })
        handleCallback()
        setDeleteModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.response?.data?.error?.message, {
          variant: 'error',
        })
      })
  }

  const handlePaymentNotify = () => {
    console.log('handlePaymentNotify')
    sendPaymentLink(item as string)
      .then((res) => {
        enqueueSnackbar(
          res.message ? res.message : 'Payment link sent successfully',
          {
            variant: 'success',
          }
        )
        handlePaymentNotifyModalClose()
        refetch()
      })
      .catch((err) => {
        enqueueSnackbar(err.response?.data?.error?.message, {
          variant: 'error',
        })
      })
  }
  const { data, isFetching, refetch } = useOrderPayment(params?.id ?? '')

  const handleVerificationModal = (rowData: any) => {
    setItem(rowData.id)
    setConfirmationModal(true)
  }
  const handleVerificationModalClose = () => {
    setItem(null)
    setConfirmationModal(false)
  }

  const handlePaymentNotifyModalClose = () => {
    setItem(null)
    setShowPaymentNotifyModal(false)
  }

  const handleVerify = (from: string) => {
    verifiyPayments({
      id: item ?? '',
      input: {
        verification_status_type: from == 'verify' ? 'verify' : 'reject',
        remark: remark,
      },
    })
      .then((res) => {
        enqueueSnackbar(
          res.message
            ? res.message
            : from == 'verify'
              ? 'Verified successfully'
              : 'Rejected successfully',
          {
            variant: 'success',
          }
        )
        refetch()
        handleParentCallBack()
        setConfirmationModal(false)
        handleCallback()
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data?.error?.message, {
          variant: 'error',
        })
      })
  }
  const handleView = (row: any) => {
    if (row?.receipt) {
      downloadFromServer(row.receipt, row.display_file_name)
    }
  }
  const handleShare = (row: any) => {
    setSelectedPayment(row?.id)
    setshareReceipt(true)
  }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          isLoading={isFetching}
          columns={columns}
          columnToggle
          handleResetColumns={() => handleResetColums()}
          actionProps={[
            {
              icon: <Icons name="payment-receipt" />,
              action: (row: any) => handlePaymentRecieptOpen(row),
              title: 'View Receipt',
              toolTip: 'View Receipt',
              hidden: !checkDetailPermission('view', MODULES.receipt),

              hide: (row: any) =>
                row?.payment_verification_status?.code === 'not_verified' ||
                row?.payment_verification_status?.code === 'rejected' ||
                (blockActionsbyStatus(detailData?.order_status?.code, [
                  // 'cancelled',
                  // 'order_cancelled', //View Receipt fro order cancelled
                  'rejected',
                ]) &&
                  detailData?.is_locked),
            },
            {
              icon: <Icons name="edit" />,
              action: (row: any) => handleEdit(row),
              title: 'Edit',
              toolTip: 'Edit',
              hide: (row: any) =>
                (row.payment_verification_status &&
                  row.payment_verification_status?.code !== 'not_verified') ||
                (blockActionsbyStatus(detailData?.order_status?.code, [
                  'cancelled',
                  'order_cancelled',
                  'rejected',
                  'order_completed_payment_done',
                ]) &&
                  detailData?.is_locked),
              hidden:
                !checkDetailPermission('change', MODULES.payment) ||
                (blockActionsbyStatus(detailData?.order_status?.code, [
                  'cancelled',
                  'order_cancelled',
                  'order_completed_payment_done',
                  'rejected',
                ]) &&
                  detailData?.is_locked),
            },
            {
              icon: <Icons name="delete" />,
              action: (row: any) => handleDeleteModal(row),
              title: 'Delete',

              toolTip: 'Delete',
              hide: (row: any) =>
                row?.payment_verification_status?.code !== 'not_verified' ||
                (blockActionsbyStatus(detailData?.order_status?.code, [
                  'cancelled',
                  'order_cancelled',
                  'rejected',
                  'order_completed_payment_pending',
                  'order_completed_payment_done',
                ]) &&
                  detailData?.is_locked),
              hidden:
                !useAuthStore.getState().userData?.is_admin ||
                !checkDetailPermission('delete', MODULES.payment) ||
                (blockActionsbyStatus(detailData?.order_status?.code, [
                  'cancelled',
                  'order_cancelled',
                  'rejected',
                  'order_completed_payment_pending',
                  'order_completed_payment_done',
                ]) &&
                  detailData?.is_locked),
            },
            {
              icon: <Icons name="share" />,
              action: (row: any) => handleShare(row),
              title: 'Share',
              toolTip: 'Share',
              hidden:
                !checkDetailPermission('share', MODULES.receipt) ||
                (blockActionsbyStatus(detailData?.order_status?.code, [
                  'cancelled',
                  'rejected',
                  'order_cancelled',
                ]) &&
                  detailData?.is_locked),
              hide: (row: any) =>
                row.payment_status_label === 'Pending' ||
                row?.payment_verification_status?.code === 'not_verified',
            },
            {
              icon: <Icons name="share" />,
              action: (row: any) => handlePaymentLinkNotifyAction(row),
              title: 'Share Payment Link',
              toolTip: 'Share Payment Link',
              hidden:
                !checkPaymentDetailPermission('share', 'link') ||
                (blockActionsbyStatus(detailData?.order_status?.code, [
                  'cancelled',
                  'rejected',
                  'order_cancelled',
                ]) &&
                  detailData?.is_locked),
              hide: (row: any) =>
                row?.payment_status_label !== 'Pending' && !row?.payment_link,
            },
            {
              icon: <Icons name="download" />,
              action: (row: any) => handleView(row),
              title: 'Download',
              toolTip: 'Download',
              hidden:
                !checkDetailPermission('download', MODULES.receipt) ||
                (blockActionsbyStatus(detailData?.order_status?.code, [
                  'cancelled',
                  'order_cancelled',
                  'rejected',
                ]) &&
                  detailData?.is_locked),
              hide: (row: any) =>
                row.payment_status_label === 'Pending' ||
                row?.payment_verification_status?.code === 'not_verified',
            },
            {
              icon: <Icons name="payment-verify" />,
              action: (row: any) => handleVerificationModal(row),
              title: 'Payment Verification',
              toolTip: 'Payment Verification',
              hidden:
                !checkDetailPermission('verify', MODULES.payment) ||
                (blockActionsbyStatus(detailData?.order_status?.code, [
                  'cancelled',
                  'order_cancelled',
                  'rejected',
                ]) &&
                  detailData?.is_locked),
              hide: (row: any) =>
                row.payment_verification_status &&
                row.payment_verification_status.code === 'not_verified' &&
                row.can_verify_payment == true
                  ? false
                  : true ||
                    (blockActionsbyStatus(detailData?.order_status?.code, [
                      'cancelled',
                      'order_cancelled',
                      'rejected',
                    ]) &&
                      detailData?.is_locked),
            },
          ]}
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                onClick={handleAction}
                label={'Record Payment'}
                className="se"
                disabled={
                  blockActionsbyStatus(detailData?.order_status?.code, [
                    'cancelled',
                    'order_cancelled',
                    'rejected',
                    'order_completed_payment_done',
                  ]) && detailData?.is_locked
                }
                icon="plus"
                hidden={!checkDetailPermission('add', MODULES.payment)}
                outlined
              />
              <Button
                onClick={handlePaymentLinkAction}
                label={'Send Payment Link'}
                hidden={!checkDetailPermission('link', MODULES.payment)}
                className="se"
                disabled={
                  blockActionsbyStatus(detailData?.order_status?.code, [
                    'cancelled',
                    'order_cancelled',
                    'rejected',
                    'order_completed_payment_done',
                  ]) && detailData?.is_locked
                }
                icon="plus"
              />
            </div>
          }
        />
        <Create
          isDrawerOpen={isPaymentOpen}
          parentParams={{ id: params?.id }}
          handleClose={handleClose}
          handleCallback={() => handleCallback()}
        />
        <SendLink
          isDrawerOpen={isPaymentLinkOpen}
          parentParams={{ id: params?.id }}
          handleClose={handlePaymentLinkClose}
          handleCallback={() => handleCallback()}
        />

        <ManagePayment
          isDrawerOpen={openManage}
          handleClose={handleCloseMange}
          edit={openEdit}
          handleCallback={() => handleCallback()}
          id={selectedPayment as string}
          url={apiUrl.PAYMENT}
        />
        <DialogModal
          isOpen={deleteModal}
          onClose={() => handleDeleteModalClose()}
          title={'Are you sure?'}
          onSubmit={() => handleDelete()}
          secondaryAction={() => handleDeleteModalClose()}
          secondaryActionLabel="No, Cancel"
          actionLabel="Yes, I am"
          body={
            <InfoBox
              content={
                'Deleting this item is an irreversible action. Are you sure you want to proceed with the deletion?'
              }
            />
          }
        />
        <DialogModal
          isOpen={showPaymentNotifyModal}
          onClose={() => handlePaymentNotifyModalClose()}
          title={'Are you sure?'}
          onSubmit={() => handlePaymentNotify()}
          secondaryAction={() => handlePaymentNotifyModalClose()}
          secondaryActionLabel="No, Cancel"
          actionLabel="Yes, I am"
          body={
            <InfoBox
              content={
                'Payment link will be sent to the customer. Are you sure you want to proceed?'
              }
            />
          }
        />
        <ShareReceipt
          isDrawerOpen={shareReceipt}
          handleClose={() => setshareReceipt(false)}
          id={selectedPayment as string}
        />
        <DialogModal
          isOpen={confirmationModal}
          onClose={() => handleVerificationModalClose()}
          title={'Verify Payment Entry'}
          onSubmit={() => handleVerify('verify')}
          secondaryAction={() => handleVerify('reject')}
          secondaryActionLabel="No, Reject"
          actionLabel="Yes, Verify"
          body={
            <InfoBox
              content={
                <div>
                  <div className="mb-5">
                    Confirm a manual payment entry post bank/account
                    reconciliation. Please be aware that this action is
                    irreversible, and once verified, the payment entry cannot be
                    edited or deleted.
                  </div>
                  <TextArea
                    id="1"
                    name="reason"
                    label="Verification Remarks"
                    onChange={(e) => setRemark(e.target.value)}
                    placeholder="Enter Verification Remarks"
                    required={false}
                  />
                </div>
              }
            />
          }
        />
      </div>
    </>
  )
}

export default ContactProfiles
