import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import InfoBox from '../../../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../../../components/common'
import Button from '../../../../../../components/common/buttons/Button'
import Icons from '../../../../../../components/common/icons/index'
import { useSnackbarManager } from '../../../../../../components/common/snackbar'
import { router_config } from '../../../../../../configs/route.config'
import { MODULES } from '../../../../../../configs/scopes.config'
import { getNestedProperty } from '../../../../../../utilities/parsers'
import { checkDetailPermission } from '../../../../permission'
import { activateTeamMembers, deactivateTeamMembers } from '../api'
import AddMember from './addMember'

type Props = {
  data: any
  id: string
  id2: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
}
const TeamInfo = (props: Props) => {
  const { data, handleRefresh, id, id2 } = props
  const [isTeamOpen, setIsTeamOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<any>()
  const [changeStatusOpen, setStatusOpen] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])
  const params = useParams()

  const handleAction = () => {
    setIsTeamOpen(true)
  }
  const getColumns = () => {
    const createRenderCell = (key: string) => (row: any) => {
      return {
        cell: getNestedProperty(row, key),
        toolTip: getNestedProperty(row, key),
      }
    }

    return [
      {
        title: 'Member Name',
        field: 'member.name',
        sortable: false,
        isVisible: true,
        renderCell: createRenderCell('member.name'),
        customCell: true,
        link: true,
        getPath: (r: any) =>
          `${router_config.SYSTEM_SETTINGS_VENDORS.path}/${params.id}/${router_config.SYSTEM_SETTINGS_VENDOR_EMPLOYEES.path}/${r.member.id}/basic-info`,
        resizable: true,
      },
    ]
  }
  // const column = [
  //   {
  //     title: 'Member Name',
  //     field: 'name.name',
  //     sortable: false,
  //     isVisible: true,
  //     link: true,
  //     getPath: (r: any) =>
  //       `${router_config.SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES.path}/${r.id}/basic-info`,
  //     resizable: true,
  //   },
  // ]

  const manageStatus = () =>
    activeRow?.is_active
      ? deactivateTeamMembers(params.id, params.id2, activeRow?.id)
      : activateTeamMembers(params.id, params.id2, activeRow?.id)

  const handleStatusChange = () => {
    manageStatus()
      .then((res) => {
        enqueueSnackbar(
          res.message ? res.message : 'Status updated successfully',
          {
            variant: 'success',
          }
        )

        setStatusOpen(false)
        setActiveRow(null)
        handleRefresh()
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }
  const handleStatusChangeClose = () => {
    setStatusOpen(false)
    setActiveRow(null)
  }
  const handleStatusChangeOpen = (row: any) => {
    setActiveRow(row)
    setStatusOpen(true)
  }
  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          isLoading={isLoading}
          columns={getColumns()}
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                onClick={handleAction}
                label={'Add New'}
                hidden={
                  !checkDetailPermission('add', MODULES.employeeteam_member)
                }
                className="se"
                outlined
              />
            </div>
          }
          actionProps={[
            {
              title: 'Activate',
              action: (rowData) => handleStatusChangeOpen(rowData),
              icon: <Icons name="activate-icon" />,
              toolTip: 'Activate',
              hide: (rowData) => rowData?.is_active,
            },
            {
              title: 'Deactivate',
              action: (rowData) => handleStatusChangeOpen(rowData),
              icon: <Icons name="deactivate-icon" />,
              toolTip: 'Deactivate',
              hide: (rowData) => !rowData?.is_active,
            },
          ]}
          asyncSearch
          columnToggle
        />
        <AddMember
          isDrawerOpen={isTeamOpen}
          id={id}
          id2={id2}
          handleClose={() => setIsTeamOpen(false)}
          handleCallback={handleRefresh}
        />
        <DialogModal
          isOpen={changeStatusOpen}
          onClose={() => handleStatusChangeClose()}
          title={'Are you sure?'}
          onSubmit={() => handleStatusChange()}
          secondaryAction={() => handleStatusChangeClose()}
          secondaryActionLabel="No, Cancel"
          actionLabel="Yes, I am"
          body={
            <InfoBox
              content={`Are you sure you want to ${
                activeRow?.is_active ? 'Deactivate' : 'Activate'
              }?`}
            />
          }
        />
      </div>
    </>
  )
}

export default TeamInfo
