import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import FileAttachments from '../../../../components/app/attachments'
import { getServiceDetails } from '../../api'
import { checkDetailPermission } from '../../permission'

const ServiceFiles = () => {
  const { setActiveTab, disabled, handleParentCallBack } = useOutletContext<{
    setActiveTab: (id: string) => void
    disabled: boolean
    handleParentCallBack: () => void
  }>()
  const params = useParams()
  const [data, setData] = useState<any>()

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_FILES')
      getData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const getData = async () => {
    const { data } = await getServiceDetails(params.id)
    setData({
      order_number: data.order?.order_number,
      order_id: data.order?.id,
      order_service: data?.service_name,
      order_service_id: data?.service_id,
    })
  }

  return (
    <FileAttachments
      model_name="OrderItem"
      disabled={disabled}
      checkModulePermission={checkDetailPermission}
      handleCallback={handleParentCallBack}
      fromData={data}
    />
  )
}

export default ServiceFiles
