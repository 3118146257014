import React from 'react'
import { IconProps } from '../../../common/types'

const Restore: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id="body_1"
      width="16"
      height="16"
    >
      <g transform="matrix(0.33333334 0 0 0.33333334 0 0)">
        <path d="M0 0L48 0L48 48L0 48L0 0z" stroke="none" fill="none" />
        <path
          d="M25.99 6C 16.04 6 8 14.06 8 24L8 24L2 24L9.79 31.79L9.93 32.08L18 24L12 24C 12 16.27 18.27 10 26 10C 33.73 10 40 16.27 40 24C 40 31.73 33.73 38 26 38C 22.130001 38 18.64 36.42 16.11 33.89L16.11 33.89L13.280001 36.72C 16.53 39.98 21.02 42 25.99 42C 35.94 42 44 33.94 44 24C 44 14.060001 35.94 6 25.99 6zM24 16L24 26L32.56 31.08L34 28.65L27 24.5L27 16L24 16z"
          stroke="none"
          fill="#000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default Restore
