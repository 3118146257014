import React from 'react'
import { IconProps } from '../../../common/types'

const SubMenuListIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 12V9.08333H1V11H2.91667V12H0ZM9.08333 12V11H11V9.08333H12V12H9.08333ZM6 11C4.61111 11 3.43056 10.5139 2.45833 9.54167C1.48611 8.56944 1 7.38889 1 6C1 4.61111 1.48611 3.43056 2.45833 2.45833C3.43056 1.48611 4.61111 1 6 1C7.38889 1 8.56944 1.48611 9.54167 2.45833C10.5139 3.43056 11 4.61111 11 6C11 7.38889 10.5139 8.56944 9.54167 9.54167C8.56944 10.5139 7.38889 11 6 11ZM6 10C7.10667 10 8.05 9.61 8.83 8.83C9.61 8.05 10 7.10667 10 6C10 4.89333 9.61 3.95 8.83 3.17C8.05 2.39 7.10667 2 6 2C4.89333 2 3.95 2.39 3.17 3.17C2.39 3.95 2 4.89333 2 6C2 7.10667 2.39 8.05 3.17 8.83C3.95 9.61 4.89333 10 6 10ZM0 2.91667V0H2.91667V1H1V2.91667H0ZM11 2.91667V1H9.08333V0H12V2.91667H11Z"
        fill="#AAACB0"
      />
    </svg>
  )
}

export default SubMenuListIcon
