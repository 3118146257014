import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { getErrorMessage } from '../../../utilities/parsers'
import { addSupplierCategories, updateSupplierCategoriess } from '../api'
import { addTeamSchema, AddTeamSchema } from '../create/schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleRefresh?: () => void
  subSection?: boolean
  data?: any
  edit?: boolean
  paramsId?: string
  handleCallback?: () => void
}

export default function CreateEmployee({
  isDrawerOpen,
  handleClose,
  handleCallback,

  data,
  edit,
  handleRefresh,
}: Props) {
  const { enqueueSnackbar } = useSnackbarManager()
  const [isLoading, setIsLoading] = useState(false)
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
  })

  const formBuilderProps = [textField('name', 'Name', 'Enter Name', true)]

  const methods = useForm<AddTeamSchema>({
    resolver: zodResolver(addTeamSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  useEffect(() => {
    if (edit && isDrawerOpen) {
      methods.reset({ ...methods.watch(), ...data })
    }
  }, [edit, isDrawerOpen, data])
  console.log(data)
  const manageApis = (d: any, id?: string) =>
    !id ? addSupplierCategories(d) : updateSupplierCategoriess(id as string, d)
  const onSubmit = (d: any) => {
    setIsLoading(true)
    manageApis(
      {
        name: d.name,
      },
      data?.id
    )
      .then(() => {
        enqueueSnackbar(
          data?.id
            ? 'Supplier Category updated successfully '
            : 'Supplier Category created successfully',
          {
            variant: 'success',
          }
        )

        handleClearAndClose()
        handleRefresh?.()
        handleCallback?.()
        setIsLoading(false)
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
        setIsLoading(false)
      })
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }

  return (
    <>
      <CustomDrawer
        className="formDrawer"
        actionLoader={isLoading}
        open={isDrawerOpen}
        handleClose={() => handleClearAndClose()}
        handleSubmit={handleSubmit((data) => onSubmit(data))}
        title={edit ? 'Edit Category' : 'Create New Category'}
      >
        <div className="flex flex-col gap-4">
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        </div>
      </CustomDrawer>
    </>
  )
}
