import { checkPermissionAny } from '../../configs/permissionGate'
import { MODULES } from '../../configs/scopes.config'

const module = MODULES.order
const paymentModule = MODULES.payment
export const checkPermission = (slug: string) => {
  const permissionSlug = `${slug}_${module}`

  return checkPermissionAny(permissionSlug)
}
export const checkDetailPermission = (slug: string, subModule?: string) => {
  const permissionSlug = `${slug}_${module}_${subModule}`

  return checkPermissionAny(permissionSlug)
}

export const checkPaymentDetailPermission = (
  slug: string,
  subModule?: string
) => {
  const permissionSlug = `${slug}_${paymentModule}_${subModule}`

  return checkPermissionAny(permissionSlug)
}
