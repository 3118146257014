import React from 'react'
import { IconProps } from '../../../common/types'

const QuoteIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M8.5 10.25H16.5M8.5 13.75H14.5M20.5 16.96C20.5 18.48 20.36 19.56 20 20.33C20 20.34 19.99 20.36 19.98 20.37C19.76 20.65 19.47 20.79 19.13 20.79C18.6 20.79 17.96 20.44 17.27 19.7C16.45 18.82 15.19 18.89 14.47 19.85L13.46 21.19C13.06 21.73 12.53 22 12 22C11.47 22 10.94 21.73 10.54 21.19L9.52002 19.84C8.81002 18.89 7.55999 18.82 6.73999 19.69L6.72998 19.7C5.59998 20.91 4.60002 21.09 4.02002 20.37C4.01002 20.36 4 20.34 4 20.33C3.64 19.56 3.5 18.48 3.5 16.96V7.04C3.5 5.52 3.64 4.44 4 3.67C4 3.66 4.00002 3.65 4.02002 3.64C4.59002 2.91 5.59998 3.09 6.72998 4.3L6.73999 4.31C7.55999 5.18 8.81002 5.11 9.52002 4.16L10.54 2.81C10.94 2.27 11.47 2 12 2C12.53 2 13.06 2.27 13.46 2.81L14.47 4.15C15.19 5.11 16.45 5.18 17.27 4.3C17.96 3.56 18.6 3.21 19.13 3.21C19.47 3.21 19.76 3.36 19.98 3.64C20 3.65 20 3.66 20 3.67C20.36 4.44 20.5 5.52 20.5 7.04V16.96Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default QuoteIcon
