import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import FileAttachments from '../../../../components/app/attachments'
import { checkDetailPermission } from '../../permission'

export default function LeadAttachments() {
  const { setActiveTab, disabled } = useOutletContext<{
    setActiveTab: (id: string) => void
    disabled: boolean
  }>()

  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  useEffect(() => {
    setActiveTab('LEAD_FILES_AND_ATTACHMENTS')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  return (
    <FileAttachments
      model_name="Lead"
      disabled={isDisabled}
      checkModulePermission={checkDetailPermission}
    />
  )
}
