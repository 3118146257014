import { useEffect, useState } from 'react'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
}
const Settings = (props: Props) => {
  // const [isEditing, setIsEditing] = useState(false)
  const { data } = props
  const [detailData, setDetailData] = useState<any>([])
  const formBuilderProps = [
    {
      label: 'Stakeholder',
      value: data?.stakeholder?.stakeholder_name,
      required: true,
      spacing: 6,
    },
    {
      label: 'Visible to Customer',
      value: data?.show_for_customer ? 'Yes' : 'No',
      required: false,
      spacing: 6,
    },
    {
      label: 'Requires Client Confirmation',
      value: data?.requires_client_confirmation ? 'Yes' : 'No',
      required: true,
      spacing: 6,
    },
    {
      label: 'Requires Stakeholder Confirmation',
      value: data?.requires_stakeholder_confirmation ? 'Yes' : 'No',
      required: true,
      spacing: 6,
    },
    {
      label: 'Requires Client Approval for Completion',
      value: data?.requires_client_approval ? 'Yes' : 'No',
      required: true,
      spacing: 6,
    },
    {
      label: 'Requires Stakeholder Approval for Completion',
      value: data?.requires_stakeholder_approval ? 'Yes' : 'No',
      required: true,
      spacing: 6,
    },
  ]
  useEffect(() => {
    setDetailData(formBuilderProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const renderFields = () => {
    return (
      <>
        {
          detailData.map((basicInformation: any) => (
            <div
              key={data?.id}
              className={`col-span-12 md:col-span-${
                basicInformation.spacing || 12
              }`}
            >
              <FormFieldView
                value={basicInformation.value}
                type={basicInformation?.type}
                required={basicInformation?.required}
                label={basicInformation.label}
              />
            </div>
          ))
          // )
        }
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="settings-secion" />
          <h4 className=" font-bold text-primaryText">Activity Settings</h4>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
    </div>
  )
}

export default Settings
