import { z } from 'zod'
import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

export const paymentSchema = z.object({
  amount: z.number({
    required_error: 'Amount is required',
    invalid_type_error: 'Amount is required',
  }),
  payment_method: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  payment_method_name: z.string().optional().nullable(),
  message: z.string().optional().nullable(),
  internal_notes: z.string().optional().nullable(),
  is_advance: z.string().optional().nullable(),
})

export type PaymentSchema = z.infer<typeof paymentSchema>
