import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}
interface Params {
  [key: string]: string | number | boolean
}
const fetchOrderData = async (input: any) => {
  const { type, ...rest } = input
  const url = buildUrlWithParams(apiUrl.QUOTE_LIST_URL, {
    ...rest,

    ...(type === 'lead' ? { created_from: 'lead' } : {}),
    ...(type === 'deal' ? { created_from: 'deal' } : {}),
    ...(type === 'prospect' ? { created_from: 'prospect' } : {}),
    ...(type === 'direct' ? { created_from: 'direct' } : {}),
  })
  const response = await getData(url)
  return response?.data
}

export const useByOrder = ({
  input,
  type,
}: {
  input: any
  type?: string | null
}) => {
  return useQuery(['quote_list', input, type], () => fetchOrderData(input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
export const deleteQuote = (orderId?: any) => {
  return postData(`${apiUrl.QUOTE_NOTE_LIST_URL}cancel`, orderId)
}
export const deleteQuoteItem = (quoteId?: any) => {
  return deleteData(`${apiUrl.QUOTE_NOTE_LIST_URL}${quoteId}/delete`)
}

export const deleteQuoteService = (quoteId?: string, itemid?: string) => {
  return deleteData(`${apiUrl.QUOTE_LIST_URL}/${quoteId}/remove-item/${itemid}`)
}

export const { getOrderStatusList, getServiceCategoryList, getAllServiceList } =
  {
    getOrderStatusList: (params: Params) =>
      getData(buildUrlWithParams(apiUrl.QUOTE_STATUS, params)),
    getServiceCategoryList: (params: Params) =>
      getData(buildUrlWithParams(apiUrl.SERVICE_CATEGORY, params)),
    getAllServiceList: (params: Params) =>
      getData(buildUrlWithParams(apiUrl.ALL_SERVICE, params)),
  }

export const getOrderDetails = (id: any) => {
  return getData(`${apiUrl.QUOTE_NOTE_LIST_URL}${id}/details`)
}

export const getOrderInvoice = (id: any) => {
  return getData(`${apiUrl.QUOTE_LIST_URL}/${id}/quote-document`)
}

export const getOrderInvoiceList = (id: any) => {
  return getData(`${apiUrl.INVOICE_LIST_URL}?order_id=${id}`)
}

export const getQuoteInvoice = (id: any) => {
  return getData(`${apiUrl.QUOTE_LIST_URL}/${id}/invoices`)
}

export const generateInvoice = (id: string, data: any) => {
  return postData(`${apiUrl.QUOTE_LIST_URL}/${id}/invoices`, data)
}
export const getQuoteStatusList = (data?: any) => {
  return getData(buildUrlWithParams(apiUrl.QUOTE_STATUS, data))
}
export const updateQuoteStatus = (id?: string, data?: any) => {
  return postData(`${apiUrl.QUOTE_NOTE_LIST_URL}${id}/change-status`, data)
}
export const quoteConvertionValidation = (id?: string) => {
  return getData(`${apiUrl.QUOTE_NOTE_LIST_URL}${id}/validate-quote`)
}
export const quoteConvertion = (id?: string) => {
  return postData(`${apiUrl.QUOTE_NOTE_LIST_URL}${id}/convert-quote`, {})
}
export const deleteInvoice = (id: string, reason: string) => {
  return deleteData(`invoices?id=${id}&cancellation_reason=${reason}`)
}

const getOrderServiceDetails = async (id: string, input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.QUOTE_LIST_URL}/${id}/items`, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useOrderServiceDetails = (id: string, input: any) => {
  return useQuery(
    ['quote_service_list'],
    () => getOrderServiceDetails(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

export const serviceAllocated = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.SERVICE_ALLOCATED}`, data?.input)
}

export const useServiceAllocated = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(serviceAllocated, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Service Allocated successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.errors ??
            error.response.data.error.message ??
            error.response.data.errors[0].message[0]
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const addService = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.QUOTE_LIST_URL}/${data?.id}/items`, data?.input)
}

export const useAddService = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(addService, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Service Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.errors[0].message[0] ?? error.response.data.errors
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const getLeadSource = (data: any) => {
  return getData(`${apiUrl.LEAD_SOURCE}${parseQueryParams(data)}`)
}

export const getContacts = (id: any, data?: any) => {
  return getData(
    `${apiUrl.CONTACT_LIST_URL}account/${id}/contacts/${parseQueryParams(data)}`
  )
}

const orderPaymentFetchData = async (id: string, input?: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.QUOTE_LIST_URL}/${id}/payments`, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useOrderPayment = (id: string, input?: QueryParams) => {
  return useQuery(
    ['quote-payment', id, input],
    () => orderPaymentFetchData(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

export const addPayment = (data: { input: any; id: string }) => {
  return postData(`${apiUrl.QUOTE_LIST_URL}/${data?.id}/payments`, data?.input)
}

export const useAddPayment = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(addPayment, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Payment Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.errors[0].message[0] ?? error.response.data.errors
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const editOrder = (data: { input: any; id: string }) => {
  return updateData(
    `${apiUrl.QUOTE_NOTE_LIST_URL}${data?.id}/update`,
    data?.input
  )
}
export const useEditQuote = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editOrder, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Quote Edited successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const add_orders = (val: any) =>
  postData(`${apiUrl.QUOTE_LIST_URL}/create-order`, val)

export const addQuote = (input: any) =>
  postData(`${apiUrl.QUOTE_LIST_URL}`, input)

export const allocateQuote = (id: string, val: any) => {
  return postData(`${apiUrl.QUOTE_NOTE_LIST_URL}${id}/allocate-cre`, val)
}

const getQuoteDocumentList = async (id: string) => {
  const url = buildUrlWithParams(
    `${apiUrl.QUOTE_LIST_URL}/${id}/quote-document`,
    {}
  )
  const response = await getData(url)
  return response?.data
}

export const useGetQuoteDocumentList = (id: string) => {
  return useQuery(['quote_document_list', id], () => getQuoteDocumentList(id), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
export const getQuoteStatus = (data?: any) => {
  return getData(
    buildUrlWithParams(
      apiUrl.QUOTE_NOTE_LIST_URL + 'quote-document/status',
      data
    )
  )
}

export const fetchQuoteStatus = (params: Params) => {
  return getData(buildUrlWithParams(apiUrl.ORDER_STATUS, params))
}
export const quoteStatusChange = (id: string, data: any) => {
  return postData(
    `${apiUrl.QUOTE_LIST_URL}/quote-document/${id}/change-status`,
    data
  )
}

export const quoteCancel = (id: string) => {
  return postData(
    `${apiUrl.QUOTE_LIST_URL}/quote-document/${id}/cancel-quote-document`,
    {}
  )
}

export const generateQuoteDocument = (id: string) => {
  return postData(
    `${apiUrl.QUOTE_NOTE_LIST_URL}${id}/create-quote-document`,
    {}
  )
}

export const sendDocument = (id: string, data: any) => {
  return postData(
    `${apiUrl.QUOTE_LIST_URL}/quote-document/${id}/send-quote-document`,
    data
  )
}

const getQuoteActivityLogList = async (id: string, params: Params) => {
  const url = buildUrlWithParams(`${apiUrl.ORDER_LIST_URL}${id}/logs`, params)
  const response = await getData(url)
  return response?.data?.results
}

export const useGetQuoteActivityLogList = (id: string, input: any) => {
  return useQuery(
    ['quote_activity_logs_list', id],
    () => getQuoteActivityLogList(id, input),
    {
      refetchOnWindowFocus: true,
      //staleTime: 50000,
    }
  )
}
