import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { AdvanceFilter, StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'

const defaultParams: any = []

export const useCREPerformanceFilterStore =
  create<CREPerformanceFilterstoreType>()(
    persist(
      (set) => ({
        pageParams: defaultPageParams,
        advanceFilter: {
          filterParams: defaultParams,
          isDetailed: true,
        },
        selectedRows: [],
        setAdvanceFilter: (data) => set(() => ({ advanceFilter: data })),
        setPageParams: (data) => set(() => ({ pageParams: data })),
        clearAdvanceFilter: () =>
          set(() => ({
            advanceFilter: {
              filterParams: defaultParams?.map((item: any) => ({
                ...item,
                isChecked: false,
              })),
              isDetailed: true,
            },
          })),
        setSelectedRows: (data) => set(() => ({ selectedRows: data })),
        clearSelectedRows: () => set({ selectedRows: [] }),
        clearCREPerformanceFilter: (key) =>
          set((state) => ({
            pageParams: key
              ? { ...defaultPageParams, search: state?.pageParams?.search }
              : defaultPageParams,
          })),
      }),
      { name: 'fit__cre_perf_store' }
    )
  )

type CREPerformanceFilterstoreType = {
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]
  advanceFilter: AdvanceFilter
  setAdvanceFilter: (data: AdvanceFilter) => void
  setSelectedRows: (data: (number | string)[]) => void
  setPageParams: (data: StoreFilterParams) => void
  clearCREPerformanceFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
