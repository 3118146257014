import moment from 'moment'

import { useEffect, useState } from 'react'
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'

import { TabItemProps } from '../../../common/types'

import { TabContainer } from '../../../components/common'
import DetailHeader from '../../../components/common/DetailTiles'

import { router_config } from '../../../configs/route.config'
import { useAppStore } from '../../../store/appStore'
import { getLeadDetails } from '../api'

const UnallottedLeadDetails = () => {
  const navigate = useNavigate()
  const [data, setData] = useState<any>()

  const { setIsLoading } = useAppStore()

  const params = useParams()

  const tabData = [
    {
      label: router_config.UNALLOTTED_LEAD_DETAILS_PROFILE.label,
      id: 'UNALLOTTED_LEAD_DETAILS_PROFILE',
    },
  ]
  const [activeTab, setActiveTab] = useState<string>(
    'UNALLOTTED_LEAD_DETAILS_PROFILE'
  )

  const haandleTabChange = (selected: TabItemProps) => {
    navigate(
      generatePath(router_config[selected.id]?.path as string, {
        id: params.id,
      })
    )
  }

  const getData = async () => {
    setIsLoading(true)
    const detail = await getLeadDetails(params.id)
    setData(detail)
    setIsLoading(false)
  }

  const contextData = {
    setActiveTab: (id: string) => setActiveTab(id),
    edit: false,
    detailData: data,
    disabled: true,
    handleParentCallback: getData,
  }

  const tiledata = [
    {
      id: 1,
      tiles: [
        {
          icon: 'email',
          value: data?.email,
          id: 1,
        },
        {
          icon: 'phone',
          value: data?.phone,
          id: 2,
        },
      ],
    },
    {
      id: 2,
      tiles: [
        {
          label: 'Primary CRE',
          value: data?.primary_cre?.name,
          // getData: getEmployee,
          id: 1,
          placeholder: 'Assign a User',
        },
        {
          label: 'Probability ',
          value: data?.lead_additional_data?.probability
            ? `${data?.lead_additional_data?.probability} %`
            : '--',
          id: 2,
        },
        {
          label: 'Next Follow Up  ',
          value: data?.next_followup_date
            ? moment(data?.next_followup_date).format('DD-MM-YYYY')
            : '--',
          id: 3,
        },
      ],
    },
  ]
  const chipdata = [
    { id: 1, status: 'Normal' },
    { id: 2, status: 'Normal' },
    { id: 3, status: 'Normal' },
  ]
  const basicData = {
    title: `${data?.name} (${data?.lead_number ?? '--'})`,
    subtitle: `${data?.business_name ?? '--'}`,
    image: data?.profile_image,
  }

  useEffect(() => {
    if (params.id) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  return (
    <div>
      <div>
        <div>
          <DetailHeader
            key={data?.primary_cre?.name}
            tiledata={tiledata}
            chipdata={chipdata}
            data={basicData}
            disabled={true}
            // setUpdateCREId={handleSetUpdate}
          />
          {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
        </div>
        {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      </div>

      <div className="flex gap-4">
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          onClick={haandleTabChange}
        >
          <Outlet context={contextData} />
        </TabContainer>
      </div>
    </div>
  )
}

export default UnallottedLeadDetails
