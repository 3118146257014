import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../components/app/formBuilder/index'
import CustomDrawer from '../../../../../components/common/drawer'
import { useAuthStore } from '../../../../../store/authStore'
import { useMasterData } from '../../../../../store/masterDataStore'
import { getFranchiseePaymentGateway, useAddPayment } from '../../../api'
import { paymentSchema, PaymentSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: (f: boolean) => void
  parentParams?: { id?: string; name?: string }
  handleCallback?: () => void
}

export default function SendLink({
  isDrawerOpen,
  handleClose,
  parentParams,
  handleCallback,
}: Props) {
  const { masterData } = useMasterData()
  const { franchisee, franchiseeDefaultGateway, setFranchiseeDefaultGateway } =
    useAuthStore()

  useEffect(() => {
    if (franchisee.id && isDrawerOpen) {
      getFranchiseePaymentGateway(franchisee.id).then((res) => {
        setFranchiseeDefaultGateway({
          ...res?.data?.default_payment_gateway,
        })
      })
    }
  }, [franchisee.id, isDrawerOpen])
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    spacing = 4,
    type = 'text'
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    spacing: spacing,
  })

  const formBuilderProps = [
    {
      ...textField('amount', 'Amount', 'Amount', true, 8, 'number'),
    },
    {
      name: 'payment_method_name',
      label: 'Payment Method',
      required: false,
      data: masterData?.payment_methods,
      async: false,
      id: 'payment_method',
      paginationEnabled: false,
      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter Payment Method',
      disabled: true,
    },
    {
      ...textField('message', 'Message to Customer', 'Enter Message', false),
      type: 'textarea',
      disabled: false,
    },
    {
      ...textField(
        'internal_notes',
        'Internal Notes',
        'Enter internal notes',
        false
      ),
      type: 'textarea',
      disabled: false,
    },
  ]
  const methods = useForm<PaymentSchema>({
    resolver: zodResolver(paymentSchema),
    mode: 'onChange',
    defaultValues: {
      payment_method_name: franchiseeDefaultGateway?.display_name,
      payment_method: franchiseeDefaultGateway?.id,
      is_advance: 'false',
    },
    reValidateMode: 'onChange',
  })

  const handleSubmission = () => {
    methods.reset()
    handleClearAndClose()
  }
  const { mutate, data, isLoading } = useAddPayment(handleSubmission)
  const onSubmit = (data: any) => {
    const formData = new FormData()

    Object.keys(data)?.forEach((item: string) => {
      if (item === 'is_advance') {
        formData.append('is_advance', data.is_advance ?? false)
      } else {
        formData.append(item, data[item] ?? '')
      }
    })

    formData.append('payment_type', 'link')
    mutate({
      input: formData,
      id: parentParams?.id,
    })
  }

  useEffect(() => {
    handleCallback?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { handleSubmit } = methods

  const handleClearAndClose = (flag = false) => {
    methods.reset({})
    handleClose(flag)
  }
  return (
    <>
      {
        <CustomDrawer
          className="formDrawer"
          open={isDrawerOpen}
          handleClose={() => handleClearAndClose()}
          actionLoader={isLoading}
          handleSubmit={handleSubmit((data) => onSubmit(data))}
          title="Send Payment Link"
        >
          <div className="flex flex-col gap-4">
            <FormProvider {...methods}>
              <FormBuilder data={formBuilderProps} edit={true} />
              <div className={`customRadioButton w-auto`}>
                <label className={`labels label-text`}>Advance Payment</label>
                <div className="customRadio-field relative flex items-center gap-4">
                  <div className="flex item-center gap-1 my-2 cursor-pointer">
                    <input
                      type="radio"
                      value="true"
                      id="is_advance"
                      {...methods.register('is_advance')}
                    />
                    <label htmlFor="is_advance" className="cursor-pointer">
                      Yes
                    </label>
                  </div>
                  <div className="flex item-center gap-1 my-2 cursor-pointer">
                    <input
                      type="radio"
                      value="false"
                      id="is_advance2"
                      {...methods.register('is_advance')}
                    />
                    <label htmlFor="is_advance2" className="cursor-pointer">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </FormProvider>
          </div>
        </CustomDrawer>
      }
    </>
  )
}
