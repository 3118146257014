import React from 'react'

import { TextAreaProps } from '../../../common/types'

const TextArea: React.FC<TextAreaProps> = ({
  name,
  id,
  label,
  rows = 5,
  disabled = false,
  fullwidth = true,
  placeholder,
  register,
  value,
  // value = '',
  edited = false,
  fieldEdit = false,
  required = false,
  autoComplete = false,
  autoFocus = false,
  onChange,
  errors,
}) => {
  const getErrors = (err: any) => {
    let errMsg = ''
    if (err.message) {
      errMsg = err?.message
    }
    return errMsg
  }
  const generateClassName = (from: string) => {
    let className = ' '
    switch (from) {
      case 'input':
        className = ` w-full input-area resize-none ${
          fieldEdit ? 'pr-[25px] ' : 'pr-input '
        }`
        if (errors && errors[name]) {
          className += ' textfield-error'
        } else {
          if (edited) {
            className += ' textfield-success '
          } else {
            if (fieldEdit) {
              className += ' textfield-editable'
            } else {
              className += ' textfield'
            }
          }
        }
        break
      case 'adorement':
        className += '  absolute right-[0px] bottom-[10px] adorement mr-[1px]'
        break
      default:
        break
    }

    return className
  }
  return (
    <div className={fullwidth ? 'w-full' : 'w-auto'}>
      {label && (
        <div className="mb-[4px] ">
          <label className={`labels label-text}`}>
            {label}
            {required ? <span className="text-error"> *</span> : <></>}
          </label>
        </div>
      )}
      <div className="relative flex flex-col justify-end">
        <textarea
          id={id}
          disabled={disabled}
          data-testid="textarea"
          {...register?.(name, { required })}
          placeholder={placeholder || label}
          rows={rows}
          value={value}
          autoComplete={autoComplete ? 'on' : 'off'}
          autoFocus={autoFocus}
          className={generateClassName('input')}
          onChange={onChange}
        />
      </div>
      {errors && errors[name] && (
        <div className="text-error text-error-label mt-[1px]">
          {getErrors(errors[name])}
        </div>
      )}
    </div>
  )
}

export default TextArea
