import { getNestedProperty } from '../../../../utilities/parsers'

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell =
    (key: string, customData = '') =>
    (row: any) => {
      switch (customData) {
        case 'status':
          return {
            cell: (
              <div className="text-capitalize">
                {row?.is_active ? 'Active' : 'Inactive'}
              </div>
            ),
            toolTip: getNestedProperty(row, key),
          }

        default:
          return {
            cell: getNestedProperty(row, key),
            toolTip: getNestedProperty(row, key),
          }
      }
    }
  const column = [
    {
      title: 'Team Name',
      field: 'name',
      sortable: false,
      resizable: true,
      isVisible: true,
      // colWidth: window.innerWidth - 350,
      link: true,
      getPath: (row: any) => onViewAction(row),
    },
    {
      title: 'Status',
      field: 'status',
      renderCell: createRenderCell('status', 'status'),
      sortable: false,
      resizable: true,
      isVisible: true,
      customCell: true,
    },
  ]
  return column
}
