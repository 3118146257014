import { z } from 'zod'

export const attachmentsSchema = z.object({
  linked_entity: z.string().optional(),
  task: z.string().refine((val) => val !== '', { message: 'Task Required' }),
  category_Name: z
    .string()
    .refine((val) => val !== '', { message: 'Category Required' }),
  category_id: z
    .string()
    .refine((val) => val !== '', { message: 'Category Required' }),
  task_priority_name: z
    .string()
    .refine((val) => val !== '', { message: 'Priority Required' }),
  task_priority_id: z
    .string()
    .refine((val) => val !== '', { message: 'Priority Required' }),
  due_date: z
    .union([z.date(), z.string(), z.null()])
    .refine((val) => val !== null, { message: 'Due Date is required' }),

  reminder_date: z
    .union([z.date(), z.string(), z.null()])
    .refine((val) => val !== null, {
      message: 'Reminder date is required',
    }),

  reminder_time: z
    .union([z.date(), z.string(), z.null()])
    .refine((val) => val !== null, {
      message: 'Reminder Time is required',
    }),
  status_id: z
    .string()
    .refine((val) => val !== '', { message: 'Status Required' }),
  status_name: z
    .string()
    .refine((val) => val !== '', { message: 'Status Required' }),
  assign_to_id: z
    .string()
    .refine((val) => val !== '', { message: 'Assignee Required' }),
  assign_to_name: z
    .string()
    .refine((val) => val !== '', { message: 'Assignee Required' }),
  remark: z.string().optional().nullable(),
})

export type AttachmentsSchema = z.infer<typeof attachmentsSchema>
