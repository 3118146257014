import React from 'react'
import { IconProps } from '../../../common/types'

const ForwardArrow: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6552 7.27143C10.6552 7.17835 10.6397 7.09302 10.6087 7.01546C10.5776 6.93789 10.5233 6.86032 10.4458 6.78275L5.83823 2.17521C5.71412 2.0511 5.54735 1.99293 5.33792 2.00068C5.12849 2.00844 4.96171 2.07437 4.83761 2.19848C4.68247 2.35362 4.61654 2.52039 4.63981 2.6988C4.66308 2.8772 4.73677 3.03622 4.86088 3.17584L8.95647 7.27143L4.86088 11.367C4.73677 11.4911 4.66696 11.6579 4.65144 11.8673C4.63593 12.0768 4.70574 12.2435 4.86088 12.3676C4.98498 12.5228 5.14788 12.5887 5.34955 12.5654C5.55123 12.5422 5.72188 12.4685 5.8615 12.3444L10.4458 7.76011C10.5233 7.68254 10.5776 7.60497 10.6087 7.52741C10.6397 7.44984 10.6552 7.36451 10.6552 7.27143Z"
        fill="white"
      />
    </svg>
  )
}

export default ForwardArrow
