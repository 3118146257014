import { z } from 'zod'

import noLeadingSpaces from '../../../utilities/noLeadingSpaces'

export const formSchema = z.object({
  name: z
    .string({ invalid_type_error: 'Name is required.' })
    .min(1, { message: 'Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  address_1: z
    .string()
    .refine((value) => value.length < 149, {
      message: 'Maximum character reached.',
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  address_2: z
    .string()
    .refine((value) => value.length < 149, {
      message: 'Maximum character reached.',
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  city: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  state: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  state_name: z.string().nullable().optional(),

  pin_code: z
    .string()
    .refine((value) => value === '' || /^[1-9][0-9]{5}$/.test(value), {
      message: 'Pincode must be a 6-digit number.',
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  country: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  country_name: z.string().nullable().optional(),
  gst_no: z
    .string()
    .refine(
      (value) =>
        value === '' ||
        /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([A-Z0-9]){1}$/.test(
          value
        ),
      {
        message: 'Invalid GST number format.',
      }
    )
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),

  category: z
    .string({ invalid_type_error: 'Category is required.' })
    .refine((val) => val !== '', {
      message: 'Category is required',
    }),
  category_name: z
    .string({ invalid_type_error: 'Category is required.' })
    .refine((val) => val !== '', {
      message: 'Category is required',
    }),

  contact_email: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .nullable()
    .optional(),
  contact_phone: z
    .string()
    .refine((value) => value === '' || /^(\+?\d{10,12})$/.test(value), {
      message: 'Invalid mobile number.',
    })
    .nullable()
    .optional(),
})

export type FranchiseeSchema = z.infer<typeof formSchema>
