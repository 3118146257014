import { z } from 'zod'

import noLeadingSpaces from '../../../utilities/noLeadingSpaces'

export const accountSchema = z.object({
  // Basic Information
  business_name: z
    .string()
    .min(1, { message: 'Business Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  constitution: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  constitution_category_name: z.string().optional().nullable(),

  sector: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  business_sector_name: z.string().optional().nullable(),

  industry: z.string().optional().nullable(),
  sectorName: z.string().optional().nullable(),

  pan_no: z
    .string()
    .refine((value) => value === '' || /^[A-Z]{5}\d{4}[A-Z]{1}$/.test(value), {
      message: 'Invalid PAN number format.',
    })
    .refine((value) => value === '' || noLeadingSpaces(value), {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),

  gst_no: z
    .string()
    .refine(
      (value) =>
        value === '' ||
        /^([0-9]){2}([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}([0-9]){1}([a-zA-Z]){1}([A-Z0-9]){1}$/.test(
          value
        ),
      {
        message: 'Invalid GST number format.',
      }
    )
    .refine((value) => value === '' || noLeadingSpaces(value), {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),

  cin: z
    .string()
    .refine(
      (value) =>
        value === '' ||
        /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/.test(
          value
        ),
      {
        message: 'Invalid CIN number format.',
      }
    )
    .refine((value) => value === '' || noLeadingSpaces(value), {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),

  business_industry_name: z.string().optional().nullable(),
  customer_id: z.string().optional().nullable(),
  contact_name: z.string().optional().nullable(),
  sub_sector: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  sub_sector_name: z.string().optional().nullable(),

  lead_source: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  lead_source_name: z.string().optional().nullable(),

  business_tier: z
    .string()
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  business_tier_name: z.string().optional().nullable(),

  // Contact Information
  mobile: z
    .string()
    .refine((value) => value === '' || /^(\+?\d{10,12})$/.test(value), {
      message: 'Invalid mobile number.',
    })
    .optional()
    .nullable(),
  email: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  website: z
    .string()
    .refine(
      (value) =>
        value === '' ||
        /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/.test(
          value
        ),
      {
        message: 'Invalid Website.',
      }
    )
    .optional()
    .nullable(),
  mobile_2: z
    .string()
    .optional()
    .nullable()
    .refine(
      (value) => {
        if (value === null || value === undefined || value === '') return true

        return /^(\+?\d{10,12})$/.test(value)
      },
      {
        message: 'Invalid mobile number.',
      }
    ),
  email_2: z
    .string()
    .optional()
    .nullable()
    .refine(
      (value) =>
        value === '' ||
        value === null ||
        value === undefined ||
        z.string().email().safeParse(value).success,
      {
        message: 'Invalid email format.',
      }
    ),
  // Address Information
  id: z.string().optional().nullable(),
  address_type: z.string().optional().nullable(),
  address_type_name: z.string().optional().nullable(),
  address_1: z.string().optional().nullable(),
  address_2: z.string().optional().nullable(),
  country: z
    .string({ invalid_type_error: 'Country is required.' })
    .min(1, 'Country is required.')
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  countryName: z.string().nullable().optional(),
  state: z
    .string({ invalid_type_error: 'State is required.' })
    .min(1, 'State is required.')
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  stateName: z.string().nullable().optional(),
  city: z
    .string({ invalid_type_error: 'City is required.' })
    .min(1, 'City is required.')
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),

  pin_code: z.string().optional().nullable(),
  phone_number: z
    .string()
    .refine((value) => /^(\+?\d{10,12})$/.test(value), {
      message: 'Invalid mobile number.',
    })
    .optional()
    .nullable(),
  description: z.string().optional().nullable(),
  is_billing_address: z.boolean().optional().nullable(),
})

export const createSchema = accountSchema.omit({
  country: true,
  countryName: true,
})

export const basicInformationSchema = accountSchema.omit({
  state: true,
  city: true,
  country: true,
  stateName: true,
  countryName: true,
})

export const addressInformationSchema = accountSchema.omit({
  business_name: true,
})

export const contactInformationSchema = accountSchema.omit({
  business_name: true,
  state: true,
  country: true,
  countryName: true,
  city: true,
})

export type CreateSchema = z.infer<typeof createSchema>

export type BasicInformationSchema = z.infer<typeof basicInformationSchema>

export type AddressInformationSchema = z.infer<typeof addressInformationSchema>

export type ContactInformationSchema = z.infer<typeof contactInformationSchema>
