import React from 'react'
import { IconProps } from '../../../common/types'

const FavouriteColored: React.FC<IconProps> = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.87257 8.61086L9.58587 8.3791L8.87257 8.61087L9.72823 11.2443C9.73317 11.2595 9.73322 11.2674 9.73319 11.2694C9.73316 11.2715 9.73288 11.2728 9.73236 11.2742C9.73096 11.2781 9.72509 11.2893 9.71007 11.3002C9.69504 11.3111 9.68259 11.3132 9.67844 11.3134C9.67692 11.3134 9.67566 11.3133 9.67359 11.3127C9.67176 11.3121 9.66423 11.3096 9.65129 11.3002L7.41115 9.67265C6.8679 9.27795 6.13227 9.27795 5.58902 9.67265L3.34888 11.3002L3.78972 11.907L3.34888 11.3002C3.33594 11.3096 3.32841 11.3121 3.32658 11.3127C3.32451 11.3133 3.32325 11.3134 3.32173 11.3134C3.31758 11.3132 3.30513 11.3111 3.2901 11.3002C3.27508 11.2893 3.26921 11.2781 3.26781 11.2742C3.26729 11.2728 3.26701 11.2715 3.26698 11.2694C3.26695 11.2674 3.267 11.2595 3.27194 11.2443L4.1276 8.61087C4.3351 7.97223 4.10778 7.27261 3.56452 6.87791L1.32439 5.25036L0.883551 5.85712L1.32439 5.25036C1.31144 5.24095 1.30675 5.23457 1.30565 5.233C1.3044 5.23123 1.3039 5.23007 1.30348 5.22861C1.30231 5.22462 1.30049 5.21212 1.30623 5.19446C1.31197 5.17679 1.32079 5.16776 1.32408 5.16521C1.32528 5.16428 1.32636 5.16364 1.32841 5.16293C1.33023 5.16231 1.33778 5.15991 1.35378 5.15991H4.12274C4.79424 5.15991 5.38937 4.72752 5.59688 4.08889L4.88358 3.85712L5.59688 4.08889L6.45253 1.45545C6.45748 1.44023 6.4621 1.43379 6.46325 1.43226C6.46455 1.43052 6.4655 1.42969 6.46676 1.42884C6.47019 1.4265 6.48151 1.4209 6.50008 1.4209C6.51866 1.4209 6.52998 1.4265 6.53341 1.42884C6.53467 1.42969 6.53562 1.43052 6.53692 1.43226C6.53807 1.43379 6.54269 1.44023 6.54764 1.45545L7.40329 4.08889C7.6108 4.72752 8.20593 5.15991 8.87743 5.15991H11.6464C11.6624 5.15991 11.6699 5.16231 11.6718 5.16293C11.6738 5.16364 11.6749 5.16428 11.6761 5.16521C11.6794 5.16776 11.6882 5.17679 11.6939 5.19446C11.6997 5.21212 11.6979 5.22462 11.6967 5.22861C11.6963 5.23007 11.6958 5.23123 11.6945 5.233C11.6934 5.23457 11.6887 5.24095 11.6758 5.25036L9.43564 6.87791C8.89239 7.27261 8.66507 7.97223 8.87257 8.61086Z"
        fill="#E76458"
        stroke="#E76458"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default FavouriteColored
