import React from 'react'
import { IconProps } from '../../../common/types'

const SaveIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M14.5905 13.3333V15C14.5905 15.9205 13.8443 16.6667 12.9238 16.6667H4.59049C3.67002 16.6667 2.92383 15.9205 2.92383 15V9.16667C2.92383 8.24619 3.67002 7.5 4.59049 7.5H6.25716M8.75716 4.16667H7.92383C7.00335 4.16667 6.25716 4.91286 6.25716 5.83333V11.6667C6.25716 12.5871 7.00335 13.3333 7.92383 13.3333H16.2572C17.1776 13.3333 17.9238 12.5871 17.9238 11.6667V5.83333C17.9238 4.91286 17.1776 4.16667 16.2572 4.16667H15.4238M14.5905 7.5L12.0905 10M12.0905 10L9.59049 7.5M12.0905 10L12.0905 2.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SaveIcon
