import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = () => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat

    if (isCustom === 'amount') {
      const propertyValue = getNestedProperty(row, key)
      const numbers =
        typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

      return {
        cell: format(numbers),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else {
      return {
        cell: getNestedProperty(row, key),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    {
      title: 'Service Name',
      field: 'service_name',
      ...defaultColumnProps3,
      fixed: true,
      link: false,
      renderCell: createRenderCell('service.service_name'),
      customCell: true,
    },
    {
      title: 'Service Value',
      field: 'amount',
      renderCell: createRenderCell('amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Prof Fees',
      field: 'professional_fee',
      renderCell: createRenderCell('professional_fee', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Vendor Fees',
      field: 'vendor_fee',
      renderCell: createRenderCell('vendor_fee', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Govt Fees',
      field: 'govt_fee',
      renderCell: createRenderCell('govt_fee', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'GST',
      field: 'gst_percent',
      renderCell: createRenderCell('gst_amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Discount',
      field: 'discount_percent',
      renderCell: createRenderCell('discount_amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
  ]

  return column
}
