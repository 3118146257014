import React, { useState } from 'react'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { Link } from 'react-router-dom'

import Icons from '../components/common/icons'
import { router_config, RouterMenuProps } from '../configs/route.config'
import { useAppStore } from '../store/appStore'
import { useClearFilter } from '../store/filterSore/clearStore'
import { checkPermissionAny } from '../configs/permissionGate'
const generateArray = (m: {
  [key: string]: RouterMenuProps
}): RouterMenuProps[] => {
  return Object.keys(m).map((k) => {
    const obj = m[k]
    return { ...obj, slug: k }
  })
}

const SideNav = () => {
  const { activeRouteSlug, menuOpened, setMenuOpened } = useAppStore()

  const [sideMenu, setSideMenu] = useState<RouterMenuProps[]>(
    generateArray(router_config)
  )
  const handleClear = useClearFilter()
  const handleMangeExpansion = (e: any, menu: any) => {
    e.stopPropagation()
    setMenuOpened(true)
    setSideMenu(
      sideMenu.map((item) => {
        return item.id === menu.id
          ? { ...item, isExpanded: !item.isExpanded }
          : item
      })
    )
  }

  const generateClassNames = (l: number): string => {
    const mod = l % 5
    switch (mod) {
      case 4:
        return menuOpened ? 'pl-5' : 'pl-0'
      case 3:
        return menuOpened ? 'pl-4' : 'pl-0'
      case 2:
        return menuOpened ? 'pl-3' : 'pl-0'
      case 1:
        return menuOpened ? 'pl-2' : 'pl-0'
      default:
        return menuOpened ? 'pl-2' : 'pl-0'
    }
  }
  const handleClearFilters = () => {
    handleClear()
  }
  const hasMenuPermission = (item: any) => {
    if (item.publicPermission) {
      return true
    }
    return checkPermissionAny(`manage_${item.key}`)
  }
  const renderFuncs = (mitem: RouterMenuProps, level: number): any => {
    return (
      <>
        {sideMenu.map((item) => {
          return (
            <>
              <React.Fragment key={item.label}>
                {item.parent_id === mitem.id &&
                  item.isSidebarMenu &&
                  hasMenuPermission(item) && (
                    <>
                      {item?.hasChild ? (
                        <li>
                          <div
                            title={item?.label}
                            key={level}
                            onClick={(e) => {
                              handleMangeExpansion(e, item)
                            }}
                            className={` flex items-center  ${
                              menuOpened
                                ? 'justify-start px-2 py-1'
                                : 'justify-start p-1'
                            } text-sm  gap-2 font-medium hover:bg-secondary rounded-md  text-icontextColor`}
                          >
                            {item?.icon ? (
                              <div
                                className={` transition-all ${
                                  item?.isExpanded ? 'dropIconActive' : ''
                                }`}
                              >
                                <Icons
                                  className="menuIconWidth"
                                  name={`${item?.icon}`}
                                />
                              </div>
                            ) : (
                              <span
                                className={` iconBox ${
                                  item?.isExpanded ? 'iconBox-active' : ''
                                }`}
                              >
                                {item?.label[0]}
                              </span>
                            )}
                            <h2
                              style={{
                                transitionDelay: `${level + 3}00ms`,
                              }}
                              className={`transition-all overflow-hidden text-ellipsis  ${
                                menuOpened ? 'flex' : 'hidden'
                              } ${item?.isExpanded ? 'text-primary' : ''}`}
                            >
                              {item?.label}
                            </h2>
                            <div
                              className={`absolute right-0 px-[5px]  ${
                                menuOpened ? 'flex' : 'hidden'
                              } cursor-pointer`}
                            >
                              {item?.isExpanded ? (
                                <BsChevronUp
                                  className={`transition-all ${
                                    item?.isExpanded ? 'text-primary' : ''
                                  }`}
                                  size={16}
                                />
                              ) : (
                                <BsChevronDown
                                  className={`transition-all ${
                                    item?.isExpanded ? 'text-primary' : ''
                                  }`}
                                  size={16}
                                />
                              )}
                            </div>
                          </div>
                          <>
                            {item.isExpanded && (
                              <ol
                                className={`${generateClassNames(level)} ${
                                  menuOpened ? '' : '!pl-0'
                                } rounded-lg flex flex-col gap-1 mt-1`}
                              >
                                {renderFuncs(item, level + 1)}
                              </ol>
                            )}
                          </>
                        </li>
                      ) : (
                        <>
                          <li>
                            <Link
                              title={item?.label}
                              to={item?.pathOverride ?? (item?.path as string)}
                              onClick={() => handleClearFilters()}
                              className={` flex items-center  ${
                                menuOpened
                                  ? 'justify-start px-2 py-1'
                                  : 'justify-center p-1'
                              } text-sm  gap-2 font-medium  rounded-md  text-icontextColor ${
                                (activeRouteSlug &&
                                  activeRouteSlug === item?.slug) ||
                                item?.slugOptions?.some(
                                  (s) => s === activeRouteSlug
                                )
                                  ? 'bg-primary hover:bg-primary'
                                  : 'hover:bg-secondary'
                              } `}
                            >
                              {item?.icon ? (
                                <div
                                  className={`${
                                    (activeRouteSlug &&
                                      activeRouteSlug === item.slug) ||
                                    item?.slugOptions?.some(
                                      (s) => s === activeRouteSlug
                                    )
                                      ? 'iconActive'
                                      : ''
                                  }`}
                                >
                                  <Icons
                                    className="menuIconWidth"
                                    name={`${item?.icon}`}
                                  />
                                </div>
                              ) : (
                                <span
                                  className={` iconBox ${
                                    (activeRouteSlug &&
                                      activeRouteSlug === item.slug) ||
                                    item?.slugOptions?.some(
                                      (s) => s === activeRouteSlug
                                    )
                                      ? 'iconBox_bg-active'
                                      : ''
                                  }`}
                                >
                                  {item?.label[0]}
                                </span>
                              )}
                              <h2
                                className={`overflow-hidden text-ellipsis   ${
                                  menuOpened ? 'flex' : 'hidden'
                                }`}
                              >
                                {item?.label}
                              </h2>
                            </Link>
                          </li>
                        </>
                      )}
                    </>
                  )}
              </React.Fragment>
            </>
          )
        })}
      </>
    )
  }
  const toggeleSideBar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()

    setMenuOpened(!menuOpened)
  }

  return (
    <section className="flex bg-black relative">
      <div
        className={`group min-h-full px-4 py-3 flex gap-5 items-start flex-col ${
          menuOpened ? ' w-[268px]' : 'w-16  '
        } duration-500 text-gray-100 transition-all `}
      >
        <div
          className={`flex items-center w-full h-[52px] ${
            menuOpened ? 'justify-start' : 'justify-center '
          }`}
        >
          <img
            alt="logo"
            src={menuOpened ? '/logo_light.png' : '/logo_short_light.png'}
          />
        </div>
        <div
          className="absolute cursor-pointer bg-white -right-3 top-[50px] w-6 h-6 rounded-[42px] border border-[#D6D8DC] z-50 flex items-center justify-center"
          onClick={(e) => toggeleSideBar(e)}
        >
          <Icons
            className={!menuOpened ? 'rotate-180' : ''}
            name={'sidebar-arrow'}
          />
        </div>
        <ol
          className={`flex flex-col gap-2 relative overflow-y-auto whitespace-nowrap overflow-ellipsis customScroll w-full ${
            menuOpened ? '' : 'items-start justify-start '
          }`}
        >
          {sideMenu.map((menu_item, i) => {
            return (
              <React.Fragment key={i}>
                {menu_item.isSidebarMenu &&
                  !menu_item?.parent_id &&
                  hasMenuPermission(menu_item) && (
                    <>
                      {menu_item?.hasChild ? (
                        <li className="">
                          <div
                            title={menu_item?.label}
                            onClick={(e) => {
                              handleMangeExpansion(e, menu_item)
                            }}
                            className={`  flex items-center mb-1  ${
                              menuOpened
                                ? 'justify-start px-2 py-1'
                                : 'justify-center p-1'
                            } text-sm  gap-2 font-medium  hover:bg-secondary rounded-md transition-all text-icontextColor`}
                          >
                            {menu_item?.icon ? (
                              <div
                                className={`transition-all ${
                                  menu_item?.isExpanded ? 'dropIconActive' : ''
                                }`}
                              >
                                <Icons
                                  className="menuIconWidth iconWhite"
                                  name={`${menu_item?.icon}`}
                                />
                              </div>
                            ) : (
                              <span
                                className={` iconBox ${
                                  menu_item?.isExpanded ? 'iconBox-active' : ''
                                }`}
                              >
                                {menu_item?.label[0]}
                              </span>
                            )}
                            <h2
                              className={`transition-all overflow-hidden text-ellipsis 
                     flex items-center  ${menuOpened ? 'flex' : 'hidden'} ${
                       menu_item?.isExpanded ? 'text-primary' : ''
                     }`}
                            >
                              {menu_item?.label}
                            </h2>
                            <div
                              className={`absolute right-0 px-[5px]  cursor-pointer  ${
                                menuOpened ? 'flex' : 'hidden'
                              }`}
                            >
                              {menu_item?.isExpanded ? (
                                <BsChevronUp
                                  className={`transition-all ${
                                    menu_item?.isExpanded ? 'text-primary' : ''
                                  }`}
                                  size={16}
                                />
                              ) : (
                                <BsChevronDown
                                  className={`transition-all ${
                                    menu_item?.isExpanded ? 'text-primary' : ''
                                  }`}
                                  size={16}
                                />
                              )}
                            </div>
                          </div>

                          {menu_item.isExpanded && (
                            <ol
                              className={` flex flex-col gap-2 ${
                                menuOpened
                                  ? 'pl-1'
                                  : 'pl-0 items-center justify-center  w-fit'
                              }`}
                            >
                              {renderFuncs(menu_item, 1)}
                            </ol>
                          )}
                        </li>
                      ) : (
                        <li key={i}>
                          <Link
                            title={menu_item?.label}
                            onClick={() => handleClearFilters()}
                            to={
                              menu_item?.pathOverride ??
                              (menu_item?.path as string)
                            }
                            className={` h-7  flex items-center  ${
                              menuOpened
                                ? 'justify-start px-2 py-1'
                                : 'justify-center p-1 w-7'
                            } text-sm  gap-2 font-medium rounded-md  text-icontextColor ${
                              (activeRouteSlug &&
                                activeRouteSlug === menu_item.slug) ||
                              menu_item?.slugOptions?.some(
                                (s) => s === activeRouteSlug
                              )
                                ? 'bg-primary hover:bg-primary'
                                : 'hover:bg-secondary'
                            }`}
                          >
                            {menu_item?.icon && (
                              <div
                                className={`${
                                  (activeRouteSlug &&
                                    activeRouteSlug === menu_item.slug) ||
                                  menu_item?.slugOptions?.some(
                                    (s) => s === activeRouteSlug
                                  )
                                    ? 'iconActive'
                                    : ''
                                }`}
                              >
                                <Icons
                                  className="menuIconWidth iconWhite"
                                  name={`${menu_item?.icon}`}
                                />
                              </div>
                            )}
                            <h2
                              style={{
                                transitionDelay: `${i + 3}00ms`,
                              }}
                              className={`overflow-hidden text-ellipsis ${
                                menuOpened ? 'flex' : 'hidden'
                              }`}
                            >
                              {menu_item?.label}
                            </h2>
                          </Link>
                        </li>
                      )}

                      {menu_item.hasDivider && (
                        <div className="border-t border-secondary w-full my-1" />
                      )}
                    </>
                  )}
              </React.Fragment>
            )
          })}
        </ol>
      </div>
    </section>
  )
}

export default SideNav
