import React from 'react'
import { IconProps } from '../../../common/types'

const ReviewRequest: React.FC<IconProps> = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1766 8.17509V5.3351C14.1766 2.64844 13.5499 1.9751 11.0299 1.9751H5.98993C3.46993 1.9751 2.84326 2.64844 2.84326 5.3351V12.8418C2.84326 14.6151 3.8166 15.0351 4.9966 13.7684L5.00326 13.7618C5.54992 13.1818 6.38325 13.2284 6.85659 13.8618L7.52993 14.7618"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5767 14.6417C11.3985 14.6417 10.4434 13.6865 10.4434 12.5083C10.4434 11.3301 11.3985 10.375 12.5767 10.375C13.7549 10.375 14.71 11.3301 14.71 12.5083C14.71 13.6865 13.7549 14.6417 12.5767 14.6417Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.11 15.0417L14.4434 14.375"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.77686 5.0415H11.1102"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.44336 7.7085H10.4434"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ReviewRequest
