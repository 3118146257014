import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import { router_config } from '../../../../configs/route.config'
import { useAccountFilterStore } from '../../../../store/filterSore/accountStore'
import { listAccountDuplicate } from '../../api'
import { checkPermission } from '../../permission'
import { getColumns } from './columns'

export default function AccountPossibleDuplicates() {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
  }>()
  const params = useParams()
  const [data, setData] = useState<any>()
  const [isLoading, setIsLoading] = useState(false)

  const [pageParams, setPageParams] = useState({
    page_size: 30,
    page: 1,
  })
  const navigate = useNavigate()
  const { setSelectedRows } = useAccountFilterStore()

  const handleGetDuplicates = async () => {
    setIsLoading(true)
    const { data } = await listAccountDuplicate(params?.id, pageParams)
    setData(data)
    setIsLoading(false)
  }
  useEffect(() => {
    setActiveTab('ACCOUNT_POSSIBLE_DUPLICATES')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  const onViewAction = (r: any, from: string) => {
    if (from == 'primaryContact') {
      return `${router_config.CONTACTS.path}/${r.primary_contact?.id}/summary`
    } else {
      return `${router_config.ACCOUNTS.path}/${r.id}/summary`
    }
  }
  useEffect(() => {
    handleGetDuplicates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, pageParams])

  const handleOnSlect = (rows: any) => {
    setSelectedRows(rows)
  }
  const handleMerge = () => {
    navigate(`${router_config.ACCOUNTS.path}/${params.id}/merge`)
  }

  return (
    <div>
      <QbsTable
        columns={getColumns({ onViewAction: onViewAction })}
        data={data?.results}
        pagination
        toolbar
        selection
        onSelect={handleOnSlect}
        sortColumn=""
        isLoading={isLoading}
        sortType="asc"
        paginationProps={{
          onPagination: onChangePage,
          total: data?.count,
          currentPage: pageParams?.page,
          rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
          onRowsPerPage: onChangeRowsPerPage,
          dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
            ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
            : [10, 20, 30, 50],
        }}
        selectedRowActions={[
          {
            actionTitle: 'Merge',
            action: () => handleMerge(),
            hidden: !checkPermission('merge'),

            customHide: '>2',
          },
        ]}
      />
    </div>
  )
}
