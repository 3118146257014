import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router'

import FormBuilder from '../../../../../../components/app/formBuilder/index'
import { Button } from '../../../../../../components/common'
import Icons from '../../../../../../components/common/icons'
import FormFieldView from '../../../../../../components/common/inputs/FormFieldView'
import { roleSchema, RoleSchema } from '../../create/schema'
import { useEditRole } from '../api'

type Props = {
  data: any
  id: string
  id2: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
}

const BasicInformation = (props: Props) => {
  const {
    data,
    id,
    id2,
    handleRefresh,
    handleEditButton,
    editFlag,
    hasPermission,
  } = props
  const location = useLocation()
  const navigate = useNavigate()
  const [isEditing, setIsEditing] = useState(false)

  const onInit = () => {
    return {
      name: data?.name ?? '',
    }
  }
  useEffect(() => {
    if (location?.state?.edit && !editFlag) {
      setIsEditing(location?.state?.edit || false)
      window.history.replaceState({}, document.title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.edit])

  const methods = useForm<RoleSchema>({
    resolver: zodResolver(roleSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    value,
    ...(required ? { required: true } : {}),
  })

  const formBuilderProps = [
    textField('name', 'Role Name', 'Enter Role name', data?.name, true),
  ]

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    navigate(location.pathname)
    setIsEditing(!isEditing)
  }
  const { mutate, isLoading } = useEditRole(handleSubmission)
  const generateSubmissionParams = (ex: any) => {
    const per: any = []
    ex?.permissions?.forEach((item: any) => {
      per.push(item.acl)
    })
    return per
  }
  const onSubmit = (d: RoleSchema) => {
    mutate({
      input: {
        ...data,
        ...d,
        permissions: generateSubmissionParams(data),
      },
      id,
      id2,
    })
  }

  const { handleSubmit } = methods

  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={isEditing} />
          </FormProvider>
        ) : (
          formBuilderProps.map((addressInformation) => (
            <div key={data?.id} className=" col-span-2 md:col-span-1">
              <FormFieldView
                value={addressInformation.value}
                required={addressInformation?.required}
                label={addressInformation.label}
                type={addressInformation?.type}
              />
            </div>
          ))
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText">Basic Information</h4>
        </div>
        <div className="iconBlack cursor-pointer">
          {hasPermission && (
            <div className="iconBlack cursor-pointer">
              {isEditing ? (
                <div className="flex gap-2">
                  <div>
                    <Button
                      onClick={handleCancel}
                      size="xs"
                      label="Cancel"
                      outlined={true}
                    />
                  </div>
                  <div>
                    <Button
                      size="xs"
                      label="Save"
                      outlined={false}
                      isLoading={isLoading}
                      onClick={handleSubmit((data) => onSubmit(data))}
                    />
                  </div>
                </div>
              ) : !editFlag ? (
                <Icons onClick={handleEditClick} name="edit" />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-4">
        {renderFields()}
      </div>
    </div>
  )
}

export default BasicInformation
