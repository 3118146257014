import moment from 'moment'
import { Link } from 'react-router-dom'

import { router_config } from '../../../../configs/route.config'
import { getNestedProperty } from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell =
    (key: string, custom_data?: string) => (row: any) => {
      if (custom_data == 'deliverables') {
        return {
          cell: (
            <>
              {row?.deliverables
                ?.slice(0, 3)
                ?.map((item: any, index: number) => (
                  <span key={item.id}>
                    <Link
                      to={`${router_config.CONTACTS.path}/${item.id}/summary`}
                    >
                      {item?.service_checklist_deliverable?.display_name}
                    </Link>
                    {index < 2 &&
                      row?.deliverables.length > 1 &&
                      row?.deliverables.length - 1 !== index &&
                      ', '}
                  </span>
                ))}
              {row?.deliverables?.length > 3 && (
                <span className=" p-1 bg-bgBadgeColor rounded-xl px-2 text-white ml-1">
                  +{row.deliverables.length - 3}
                </span>
              )}
            </>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else if (custom_data == 'collectables') {
        return {
          cell: (
            <div>
              {row?.collectables
                ?.slice(0, 3)
                ?.map((item: any, index: number) => (
                  <span key={item.id}>
                    <Link
                      to={`${router_config.CONTACTS.path}/${item.id}/summary`}
                    >
                      {item?.service_checklist_collectable?.display_name}
                    </Link>
                    {index < 2 &&
                      row?.collectables.length > 1 &&
                      row?.collectables.length - 1 !== index &&
                      ', '}
                  </span>
                ))}
              {row?.collectables?.length > 3 && (
                <span className=" p-1 bg-bgBadgeColor rounded-xl px-2 text-white ml-1">
                  +{row.collectables.length - 3}
                </span>
              )}
            </div>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else if (custom_data == 'boolean') {
        return {
          cell: <>{row?.show_for_customer ? 'Yes' : 'No'}</>,
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else if (custom_data == 'confirmation') {
        return {
          cell: <>{row[key] ? 'Yes' : 'No'}</>,
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else if (custom_data == 'date') {
        return {
          cell: row?.assigned_at
            ? moment(row?.assigned_at).format('DD-MM-YYYY')
            : '',
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else if (custom_data == 'followup') {
        const followup_date = moment(row?.expected_completion_date)
        const today = moment(new Date())
        const formattedDate = moment(row?.expected_completion_date).format(
          'DD-MM-yyyy'
        )
        return {
          cell: (
            <>
              {today.isAfter(followup_date, 'day') ? (
                <span className="text-red selected-item text-sm inline-block">
                  {row?.expected_completion_date
                    ? formattedDate
                    : row?.expected_completion_date}
                </span>
              ) : row?.expected_completion_date ? (
                formattedDate
              ) : (
                row?.expected_completion_date
              )}
            </>
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      } else {
        return {
          cell: getNestedProperty(row, key),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      }
    }

  const column = [
    {
      title: 'Activity ID',
      field: 'task_number',
      ...defaultColumnProps3,
      link: true,
      getPath: (row: any) => onViewAction(row, 'taskName'),
      fixed: true,
    },
    {
      title: 'Activity Name',
      field: 'display_name',
      ...defaultColumnProps3,
      link: true,
      getPath: (row: any) => onViewAction(row, 'taskName'),
    },
    {
      title: 'Stakeholder',
      field: 'stakeholder_name',
      renderCell: createRenderCell('stakeholder.stakeholder_name'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Customer Visibility',
      field: 'show_for_customer',
      renderCell: createRenderCell('task.show_for_customer', 'boolean'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Client Confirmation',
      field: 'requires_client_confirmation',
      renderCell: createRenderCell(
        'requires_client_confirmation',
        'confirmation'
      ),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Stakeholder Confirmation',
      field: 'requires_stakeholder_confirmation',
      renderCell: createRenderCell(
        'requires_stakeholder_confirmation',
        'confirmation'
      ),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Client Approval for Confirmation',
      field: 'requires_client_approval',
      renderCell: createRenderCell('requires_client_approval', 'confirmation'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Stakeholder Approval for Confirmation',
      field: 'requires_stakeholder_approval',
      renderCell: createRenderCell(
        'requires_stakeholder_approval',
        'confirmation'
      ),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Deliverables',
      field: 'deliverables',

      renderCell: createRenderCell('deliverables', 'deliverables'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Collectables',
      field: 'collectables',
      renderCell: createRenderCell('collectables', 'collectables'),
      customCell: true,
      ...defaultColumnProps3,
    },

    {
      title: 'Activity Assignee',
      field: 'assignee.name',
      ...defaultColumnProps3,
    },
    {
      title: 'Assigned on',
      field: 'assigned_at',
      renderCell: createRenderCell('assigned_at', 'date'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Activity Status',
      field: 'name',
      renderCell: createRenderCell('status.name'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Pending With',
      field: 'pending_with',
      ...defaultColumnProps3,
    },
    {
      title: 'Pending For',
      field: 'pending_for',
      ...defaultColumnProps3,
    },
    {
      title: 'Target Date',
      field: 'expected_completion_date',
      ...defaultColumnProps3,
      renderCell: createRenderCell('expected_completion_date', 'followup'),
      customCell: true,
      type: 'date',
    },
    {
      title: 'Completed on',
      field: 'actual_completion_date',
      ...defaultColumnProps3,
    },
    {
      title: 'Remarks',
      field: 'remarks',
      ...defaultColumnProps3,
    },
    {
      title: 'Notes',
      field: 'notes_count',
      link: true,
      getPath: (row: any) => onViewAction(row, 'notes'),
      ...defaultColumnProps3,
    },
  ]

  return column
}
