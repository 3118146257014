import { getNestedProperty } from '../../utilities/parsers'

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell = (row: any, key: string) => {
    return {
      cell: getNestedProperty(row, key),
      toolTip: getNestedProperty(row, key),
    }
  }

  const columnProps = {
    sortable: false,
    customCell: true,
    isVisible: true,
    resizable: true,
  }
  return [
    {
      title: 'Name',
      field: 'name',
      sortable: false,
      isVisible: true,
      resizable: true,
      fixed: true,
      link: true,
      rowClick: (row: any) => onViewAction(row),
    },
    {
      title: 'Supplier Code',
      field: 'unique_id',
      ...columnProps,
    },
    {
      title: 'Category',
      field: 'category.name',
      renderCell: (row: any) => createRenderCell(row, 'category.name'),
      ...columnProps,
    },
    {
      title: 'Email',
      field: 'contact_email',

      ...columnProps,
    },
    {
      title: 'Phone',
      field: 'contact_phone',

      ...columnProps,
    },
    {
      title: 'Country',
      field: 'address.country',
      renderCell: (row: any) => createRenderCell(row, 'address.country'),
      ...columnProps,
    },
    {
      title: 'State',
      field: 'address.state',
      renderCell: (row: any) => createRenderCell(row, 'address.state'),
      ...columnProps,
    },
    {
      title: 'City',
      field: 'address.city',
      renderCell: (row: any) => createRenderCell(row, 'address.city'),
      ...columnProps,
    },
    {
      title: 'Address Line 1',
      field: 'address.address_1',
      renderCell: (row: any) => createRenderCell(row, 'address.address_1'),
      ...columnProps,
    },
    {
      title: 'Address Line 2',
      field: 'address.address_2',
      renderCell: (row: any) => createRenderCell(row, 'address.address_2'),
      ...columnProps,
    },
    {
      title: 'Pincode',
      field: 'address.pin_code',
      renderCell: (row: any) => createRenderCell(row, 'address.pin_code'),
      ...columnProps,
    },
    {
      title: 'GST No.',
      field: 'gst_no',
      ...columnProps,
    },
  ]
}
