import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import CommonNotes from '../../../../components/app/notes'
import apiUrl from '../../../../apis/api.url'
import { checkDetailPermission } from '../../permission'

const ServiceNotes = () => {
  const { setActiveTab, disabled } = useOutletContext<{
    setActiveTab: (id: string) => void
    disabled: boolean
  }>()

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_NOTES')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <CommonNotes
      moduleUrl={apiUrl.SERVICE_DETAIL_NOTES_URL}
      apisectionUrl="service-notes"
      disabled={disabled}
      checkModulePermission={checkDetailPermission}
    />
  )
}

export default ServiceNotes
