import React from 'react'

import { IconProps } from '../../../common/types'

const RaiseRequest: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8.66683 12.2868L5.70015 14.2601C5.26015 14.5535 4.66683 14.2402 4.66683 13.7068V12.2868C2.66683 12.2868 1.3335 10.9535 1.3335 8.95349V4.95345C1.3335 2.95345 2.66683 1.62012 4.66683 1.62012H11.3335C13.3335 1.62012 14.6668 2.95345 14.6668 4.95345V8.95349C14.6668 10.9535 13.3335 12.2868 11.3335 12.2868H8.66683Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.0002 7.57324V7.43327C8.0002 6.97993 8.28022 6.73992 8.56022 6.54659C8.83355 6.35992 9.10685 6.11993 9.10685 5.67993C9.10685 5.0666 8.61354 4.57324 8.0002 4.57324C7.38687 4.57324 6.89355 5.0666 6.89355 5.67993"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99684 9.16683H8.00284"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RaiseRequest
