import React from 'react'
import { IconProps } from '../../../common/types'

const MyApprovalsIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M9.5 18.4296C9.5 18.7496 9.46 19.0596 9.38 19.3596H11.5L15.95 22.3297C16.61 22.7697 17.5 22.2896 17.5 21.4896V19.3596C19 19.3596 20.25 18.8596 21.12 17.9896C22 17.1096 22.5 15.8596 22.5 14.3596V8.35962C22.5 5.35962 20.5 3.35962 17.5 3.35962H7.5C4.5 3.35962 2.5 5.35962 2.5 8.35962V14.3596C2.5 14.8096 2.54 15.2396 2.63 15.6396C3.36 14.8896 4.38 14.4296 5.5 14.4296M9.5 18.4296C9.5 17.4096 9.11 16.4696 8.47 15.7596C7.75 14.9396 6.68 14.4296 5.5 14.4296M9.5 18.4296C9.5 16.2196 7.71 14.4296 5.5 14.4296M9.5 18.4296C9.5 19.1796 9.29001 19.8897 8.92001 20.4897C8.71001 20.8497 8.44 21.1697 8.13 21.4297C7.43 22.0597 6.51 22.4297 5.5 22.4297C4.04 22.4297 2.76999 21.6497 2.07999 20.4897C1.70999 19.8897 1.5 19.1797 1.5 18.4297C1.5 17.1697 2.08 16.0397 3 15.3097C3.69 14.7597 4.56 14.4296 5.5 14.4296M3.94 18.4297L4.92999 19.4197L7.06 17.4497M9 11.4297H16"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default MyApprovalsIcon
