import React from 'react'
import { IconProps } from '../../../common/types'

const PaymentApprovalsIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M2.5 10.9297H22.5M12.0483 21.4295H6.93829C3.38829 21.4295 2.48828 20.5495 2.48828 17.0395V8.81946C2.48828 5.63946 3.22831 4.61946 6.01831 4.45946C6.29831 4.44946 6.60829 4.43945 6.93829 4.43945H18.0483C21.5983 4.43945 22.4983 5.31945 22.4983 8.82945V13.2395M6.5 16.9297H10.5M16.9414 18.9292L17.9314 19.9192L20.0614 17.9492M21.92 20.9897C21.23 22.1497 19.96 22.9297 18.5 22.9297C17.04 22.9297 15.77 22.1497 15.08 20.9897C14.71 20.3897 14.5 19.6797 14.5 18.9297C14.5 16.7197 16.29 14.9297 18.5 14.9297C20.71 14.9297 22.5 16.7197 22.5 18.9297C22.5 19.6797 22.29 20.3897 21.92 20.9897Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PaymentApprovalsIcon
