import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { AdvanceFilter, StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'

const defaultParams = [
  { title: 'Sub sector', slug: 'sub_sector', isChecked: false },
  { title: 'Lead Source', slug: 'lead_source', isChecked: false },
  { title: 'State', slug: 'state', isChecked: false },
  { title: 'CRE', slug: 'cre', isChecked: false },
  {
    title: 'Primary Contact',
    slug: 'primary_contact',
    isChecked: false,
  },
]
export const useAccountFilterStore = create<AccountFilterstoreType>()(
  persist(
    (set) => ({
      pageParams: defaultPageParams,
      advanceFilter: {
        filterParams: defaultParams,
        isDetailed: true,
      },
      selectedRows: [],

      setAdvanceFilter: (data) => set(() => ({ advanceFilter: data })),
      setPageParams: (data) => set(() => ({ pageParams: data })),
      clearAdvanceFilter: () =>
        set(() => ({
          advanceFilter: {
            filterParams: defaultParams,
            isDetailed: true,
          },
        })),
      setSelectedRows: (data) => set(() => ({ selectedRows: data })),
      clearSelectedRows: () => set({ selectedRows: [] }),
      clearAccountFilter: () =>
        set(() => ({
          pageParams: defaultPageParams,
        })),
    }),
    { name: 'fit__associated_acc_store' }
  )
)

type AccountFilterstoreType = {
  advanceFilter: AdvanceFilter
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]

  setAdvanceFilter: (data: AdvanceFilter) => void
  setSelectedRows: (data: (number | string)[]) => void
  setPageParams: (data: StoreFilterParams) => void
  clearAccountFilter: () => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
