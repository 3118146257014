import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  postData,
  updateData,
} from '../../../../../apis/api.helpers'
import apiUrl from '../../../../../apis/api.url'
import { QueryParams } from '../../../../../common/types'
import { useSnackbarManager } from '../../../../../components/common/snackbar'
import {
  getErrorMessage,
  parseQueryParams,
} from '../../../../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

export const createRole = (input: any) => {
  return postData(apiUrl.ROLE_LIST_URL, input)
}

export const useCreateRole = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createRole, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Role Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
export const getVendorPermissions = () => getData(apiUrl.VENDOR_PERMISSIONS)
const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.ROLE_LIST_URL, {
    ...input,
  })
  const response = await getData(url)

  return response
}

export const useRole = (input: QueryParams) => {
  return useQuery(['employee', input], () => fetchData(input), {
    refetchOnWindowFocus: false,
  })
}

export const deleteRole = (id: any) => {
  return deleteData(`${apiUrl.ROLE_LIST_URL}${id}`)
}

export const getRoleDetails = (id: any, id2: any) => {
  return getData(`${apiUrl.VENDORS}${id}/group/${id2}`)
}
export const deleteVendorRole = (id: any, id2: any) => {
  return deleteData(`${apiUrl.VENDORS}${id}/group/${id2}`)
}

export const editRole = (data: { input: any; id: string; id2: string }) => {
  return updateData(
    `${apiUrl.VENDORS}${data?.id}/group/${data?.id2}/`,
    data?.input
  )
}

export const useEditRole = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editRole, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const getRoles = (input: any) => {
  return getData(`${apiUrl.ROLE_LIST_URL}${parseQueryParams(input)}`)
}
