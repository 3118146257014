import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useAuthStore = create<AuthStoreType>()(
  persist(
    (set) => ({
      authenticated: undefined,
      impersonating: undefined,
      token: undefined,
      actualeUser: {},
      isMember: false,
      userData: {},
      updateKey: 1,
      permissionData: [],
      franchiseesList: [],
      roleData: {},
      franchisee: '',
      franchiseeDefaultGateway: {},
      setAuthenticated: (is_authenticated) =>
        set(() => ({ authenticated: is_authenticated })),

      setImpersonating: (is_impersonating) =>
        set(() => ({ impersonating: is_impersonating })),

      setIsMember: (data) => set(() => ({ isMember: data })),
      setActualUser: (data) => set(() => ({ actualeUser: data })),

      setFranchisee: (id) => set(() => ({ franchisee: id })),
      setFranchiseesList: (data) => set(() => ({ franchiseesList: data })),
      setFranchiseeDefaultGateway: (data) =>
        set(() => ({ franchiseeDefaultGateway: data })),
      setToken: (data) => set(() => ({ token: data })),
      setUpdateKey: (data) => set(() => ({ updateKey: data })),
      setUserData: (data) => set(() => ({ userData: data })),
      setRoleData: (data) => set(() => ({ roleData: data })),
      setPermissionData: (data) => set(() => ({ permissionData: data })),
      clearAuthenticated: () =>
        set(() => ({
          authenticated: undefined,
          impersonating: undefined,
          token: undefined,
          actualeUser: {},
          updateKey: 1,
          isMember: false,
          userData: {},
          permissionData: [],
          franchiseesList: [],
          roleData: {},
          franchisee: '',
          franchiseeDefaultGateway: {},
        })),
    }),
    { name: 'authenticated' }
  )
)

type AuthStoreType = {
  franchisee: any
  updateKey: number
  setFranchisee: (id: string) => void
  isMember: boolean
  setIsMember: (value: boolean) => void
  authenticated: boolean | undefined
  token: string | undefined
  franchiseesList: any[]
  setAuthenticated: (authenticated: boolean | undefined) => void
  setUpdateKey: (data: number) => void
  impersonating: boolean | undefined
  setImpersonating: (impersonating: boolean | undefined) => void
  clearAuthenticated: () => void
  setFranchiseesList: (data: any[]) => void
  userData: UserDataProps
  roleData: RoleDataProps
  permissionData: PermissionDataProps[]
  setUserData: (data: UserDataProps) => void
  setActualUser: (data: UserDataProps) => void
  actualeUser: UserDataProps
  setToken: (data: string) => void
  setRoleData: (data: RoleDataProps) => void
  setPermissionData: (data: PermissionDataProps[]) => void
  franchiseeDefaultGateway?: {
    id?: string
    display_name?: string
  }
  setFranchiseeDefaultGateway: (data: any) => void
}

export interface UserDataProps {
  id?: string | null
  name?: string | null
  is_admin?: string | null | boolean
  is_operations_head?: string | null
  is_team_lead?: string | null
  email?: string | null
  email_2?: string | null
  first_name?: string | null
  last_name?: string | null
  mobile?: string | null
  mobile_2?: string | null
  username?: string | null
  franchisee?: {
    default_payment_gateway?: {
      id?: string
      display_name?: string
    }
    franchisee_display_name: string
    franchisee_name: string
    id: string
  }
}

export interface RoleDataProps {
  id?: string | null
  name?: string | null
}

export interface PermissionDataProps {
  acl?: string
  codename?: string
  id?: number
}
