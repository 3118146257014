import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { checkDetailPermission } from '../../permission'

import EventComponent from '../../../../components/app/eventlogs/index'

export default function ContactEventLogs() {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
  }>()

  useEffect(() => {
    setActiveTab('CONTACT_DETAILS_EVENT_LOGS')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <EventComponent
      model_name="Customer"
      checkModulePermission={checkDetailPermission}
    />
  )
}
