import { z } from 'zod'

import noLeadingSpaces from '../../../../utilities/noLeadingSpaces'

export const noteSchema = z.object({
  id: z.string().optional(),
  note: z
    .string()
    .min(1, { message: ' Notes is required.' })
    .refine(noLeadingSpaces, {
      message: 'Notes is required',
    }),
})
export type NoteSchema = z.infer<typeof noteSchema>
