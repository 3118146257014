import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import FileAttachments from '../../../../components/app/attachments'
import { getOrderDetails } from '../../api'
import { checkDetailPermission } from '../../permission'
import { blockActionsbyStatus } from '../../../../configs/permissionGate'

const OrderFiles = () => {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
  }>()
  const params = useParams()
  const [orderData, setOrderData] = useState<any>()
  const [detailData, setDetailData] = useState<any>()

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_ORDERS_FILES')
      getData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const getData = async () => {
    const { data } = await getOrderDetails(params.id)
    setDetailData(data)
    setOrderData({
      order_number: data.order_number,
      order_id: data.id,
    })
  }

  return (
    <FileAttachments
      model_name="Order"
      disabled={
        blockActionsbyStatus(detailData?.order_status?.code, [
          'cancelled',
          'order_cancelled',
          'rejected',
          'order_completed_payment_done',
        ]) && detailData?.is_locked
      }
      checkModulePermission={checkDetailPermission}
      fromData={orderData}
    />
  )
}

export default OrderFiles
