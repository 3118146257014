import React from 'react'
import { IconProps } from '../../../common/types'

const MenuFavourite: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9997 2C13.0247 2 14.6663 3.66667 14.6663 6C14.6663 10.6667 9.66634 13.3333 7.99967 14.3333C6.33301 13.3333 1.33301 10.6667 1.33301 6C1.33301 3.66667 2.99967 2 4.99967 2C6.23965 2 7.33301 2.66667 7.99967 3.33333C8.66634 2.66667 9.75967 2 10.9997 2ZM8.62227 12.4025C9.21001 12.0323 9.73967 11.6637 10.2363 11.2686C12.2221 9.68867 13.333 7.96233 13.333 6C13.333 4.42717 12.3083 3.33333 10.9997 3.33333C10.2824 3.33333 9.50587 3.71274 8.94247 4.27614L7.99967 5.21895L7.05687 4.27614C6.49346 3.71274 5.71694 3.33333 4.99967 3.33333C3.70571 3.33333 2.66634 4.43767 2.66634 6C2.66634 7.96233 3.77719 9.68867 5.7631 11.2686C6.25967 11.6637 6.78934 12.0323 7.37707 12.4025C7.57607 12.5279 7.77374 12.6486 7.99967 12.7835C8.22561 12.6486 8.42327 12.5279 8.62227 12.4025Z"
        fill="#AAACB0"
      />
    </svg>
  )
}

export default MenuFavourite
