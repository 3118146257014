import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useLocation, useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons/index'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { MODULES } from '../../../../configs/scopes.config'
import { useAuthStore } from '../../../../store/authStore'
import { useAccountFilterStore } from '../../../../store/filterSore/associatedAccountStore'
import { statusClassGen } from '../../../../utilities/generators'
import { getErrorMessage } from '../../../../utilities/parsers'
import {
  cancelActivityRequest,
  getActivityRequestData,
  useFetchActivityRequests,
} from '../../api'
import { checkDetailPermission } from '../../permission'
import ManageActivityRequests from './manage'
import { getColumns } from './requestColumns'
import ReviewActivityRequests from './review'

const ActivityRequests = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { userData } = useAuthStore()
  const [reviewOpen, setReviewOpen] = useState(false)
  const [viewOpen, setViewOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<any>(null)

  const [deleteOpen, setDeleteOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const { pageParams, setPageParams, selectedRows, setSelectedRows } =
    useAccountFilterStore()

  const params = useParams()

  const handleAction = () => {
    setIsOpen(true)
  }
  const { setActiveTab, hasServiceLock, hasOrderLock, getActivityData } =
    useOutletContext<{
      setActiveTab: (id: string) => void
      getActivityData: any
      hasServiceLock: boolean
      hasOrderLock: boolean
    }>()

  useEffect(
    () => {
      setActiveTab('ACTIVITY_DETAILS_REQUESTS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { page, page_size, search, ordering } = pageParams
  const searchParams = {
    order_id: getActivityData()?.item?.order?.id,
    service_id: getActivityData()?.item?.id,
    task_id: params.id,
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }
  const handleDelete = () => {
    cancelActivityRequest(activeRow?.id)
      .then(() => {
        enqueueSnackbar('Request cancelled successfully', {
          variant: 'success',
        })
        handleCloseAll()

        refetch()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message[0] ??
              error?.response?.data?.error.message
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const { data, isFetching, refetch } = useFetchActivityRequests(
    params?.id ?? '',
    searchParams
  )

  useEffect(() => {
    refetch()
  }, [page, page_size, search, ordering, refetch])

  const onViewAction = (r: any, from: string) => {
    if (from == 'linkTo') {
      setActiveRow(r)
      setViewOpen(true)
      setReviewOpen(true)
    }
  }

  const handleSearch = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }
  const handleCloseAll = () => {
    setDeleteOpen(false)
    setIsOpen(false)
    setReviewOpen(false)
    setViewOpen(false)
    setActiveRow(null)
  }
  const handleDeleteOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }
  const handleReviewOpen = (row: any) => {
    setActiveRow(row)
    setReviewOpen(true)
  }
  const handleCallback = () => {
    refetch()
  }
  const detailData = getActivityData()
  const onViewRequest = (id: string) => {
    const searchParams = {
      order_id: getActivityData()?.item?.order?.id,
      service_id: getActivityData()?.item?.id,
      task_id: params.id,
      approvalId: id,
    }
    getActivityRequestData(searchParams).then((res) => {
      setActiveRow(res.data)
      setViewOpen(true)
      setReviewOpen(true)
    })
  }
  const { search: querystring } = useLocation()
  useEffect(() => {
    const urlParams = new URLSearchParams(querystring)
    const id = urlParams.get('entity')
    if (id && getActivityData()) {
      onViewRequest(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querystring, getActivityData()])
  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          selectedRows={selectedRows}
          selection={true}
          search={true}
          isLoading={isFetching}
          columns={
            getColumns({
              onViewAction: onViewAction,
              statusClassGen: statusClassGen,
            }) as any
          }
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                onClick={handleAction}
                label={'Raise Request'}
                className="se"
                icon="plus"
                hidden={
                  !checkDetailPermission('add', MODULES.request) ||
                  detailData?.stakeholder?.manage_type === 'vendor'
                }
                outlined
                disabled={
                  detailData?.status?.code == 'completed'
                    ? true
                    : false || hasServiceLock || hasOrderLock
                }
              />
            </div>
          }
          actionProps={[
            ...(hasServiceLock || hasOrderLock
              ? []
              : [
                  {
                    icon: <Icons name="close" />,
                    action: (row: any) => handleDeleteOpen(row),
                    title: 'Cancel Request',
                    toolTip: 'Cancel Request',
                    hidden: !checkDetailPermission('delete', MODULES.request),
                    hide: (row: any) =>
                      row?.status?.code !== 'requested' ||
                      userData?.id !== row?.created_by?.id,
                  },
                  {
                    hide: (row: any) =>
                      userData?.id !== row?.assignee?.id ||
                      row?.status?.code == 'cancelled',
                    icon: <Icons name="review" />,
                    hidden: !checkDetailPermission('review', MODULES.request),
                    action: (row: any) =>
                      row?.status?.name === 'Rejected' ||
                      row?.status?.name === 'Accepted'
                        ? onViewAction(row, 'linkTo')
                        : handleReviewOpen(row),
                    title: 'Review Request',
                    toolTip: 'Review Request',
                  },
                ]),
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSearch}
          asyncSearch
          handleSearchValue={(key?: string) => handleSearch(key)}
          onSelect={handleOnSlect}
          columnToggle
        />
      </div>

      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleCloseAll()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleCloseAll()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox content={'Are you sure you want to cancel this request?'} />
        }
      />
      <ManageActivityRequests
        isDrawerOpen={isOpen}
        stakeholder_id={getActivityData()?.stakeholder?.id}
        service_id={getActivityData()?.item?.id}
        order_id={getActivityData()?.item?.order?.id}
        task_id={params.id}
        handleClose={() => handleCloseAll()}
        handleCallback={handleCallback}
      />
      <ReviewActivityRequests
        isDrawerOpen={reviewOpen}
        data={activeRow}
        handleClose={() => handleCloseAll()}
        handleCallback={handleCallback}
        disabled={viewOpen || hasServiceLock || hasOrderLock}
      />
    </>
  )
}

export default ActivityRequests
