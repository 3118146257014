import { useEffect, useState } from 'react'
import { Outlet, generatePath, useNavigate, useParams } from 'react-router-dom'

import { TabItemProps } from '../../../../../common/types'
import InfoBox from '../../../../../components/app/alertBox/infoBox'
import { DialogModal, TabContainer } from '../../../../../components/common'
import DetailHeader from '../../../../../components/common/DetailTiles'
import { useSnackbarManager } from '../../../../../components/common/snackbar'
import { router_config } from '../../../../../configs/route.config'
import { useAppStore } from '../../../../../store/appStore'
import { useAuthStore } from '../../../../../store/authStore'
import {
  activateVendorEmployees,
  deactivateVendorEmployees,
} from '../../../api'
import {
  getEmployeeDetails,
  setAdminStatus,
  setOperationsHeadStatus,
} from './api'
import ChangePassword from './changePassword'

const VendorEmployeeDetails = () => {
  const navigate = useNavigate()
  const [edit] = useState(false)
  const [data, setData] = useState<any>()

  const [adminModal, setAdminModal] = useState(false)
  // const [adminFlag, setAdminFlag] = useState(data?.is_admin)
  const [headChangeModal, setHeadChangeModal] = useState(false)
  const { userData } = useAuthStore()

  const [statusModal, setStatusModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)

  const { enqueueSnackbar } = useSnackbarManager()
  const { setIsLoading } = useAppStore()

  const tabData = [
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_EMPLOYEES_BASIC_INFO.label,
      id: 'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_BASIC_INFO',
    },
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_EMPLOYEES_TEAM_INFO.label,
      id: 'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_TEAM_INFO',
    },
    // {
    //   label:
    //     router_config.SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_FRANCHISEE.label,
    //   id: 'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_FRANCHISEE',
    // },
  ]

  const params = useParams()
  const [activeTab, setActiveTab] = useState<string>(
    'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_BASIC_INFO'
  )

  const haandleTabChange = (selected: TabItemProps) => {
    navigate(
      generatePath(router_config[selected.id]?.path as string, {
        id2: params.id2,
      })
    )
  }

  useEffect(() => {
    if (params.id && params.id2) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.id2])

  const getData = async () => {
    setIsLoading(true)
    await getEmployeeDetails(params.id, params.id2).then((res) => {
      console.log(res, 'data')
      setData(res?.data)
      setIsLoading(false)
    })
  }
  // const chipdata = [
  //   { id: 1, status: 'Normal' },
  //   { id: 2, status: 'Normal' },
  //   { id: 3, status: 'Normal' },
  // ]
  const basicData = {
    title: `${data?.name ?? '--'} `,
    // subtitle: `${data?.contact?.name ?? '--'} + ${
    //   data?.contact?.customer_number ?? '--'
    // }`,
    status: `${data?.status ? ' Active' : ' Inactive'}`,
    image: data?.avatar_thumbnail,
  }

  const tiledata = [
    {
      id: 1,
      tiles: [
        {
          icon: 'phone',
          value: data?.user?.mobile ?? '- -',
          id: 2,
        },
        {
          icon: 'email',
          value: data?.user?.email ?? '- -',
          id: 3,
        },
      ],
    },
  ]

  const headerMenuItems = [
    {
      label: 'Change Password',
      Action: () => setPasswordModal(true),
      id: 5,
      hidden: false,
    },
  ]

  const contextData = {
    setActiveTab: (id: string) => setActiveTab(id),
    edit: edit,
    detailsData: data,
    handleCallBack: getData,
  }

  const handleAdminChange = () => {
    setAdminStatus({
      id: params?.id,
      data: { status: data?.is_admin ? false : true },
    })
      .then((res) => {
        enqueueSnackbar(res.message ? res.message : 'Updated successfully', {
          variant: 'success',
        })
        getData()
        setAdminModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }

  const handleHeadChange = () => {
    setOperationsHeadStatus({
      id: params?.id,
      data: { is_operations_head: data?.is_operations_head ? false : true },
    })
      .then((res) => {
        enqueueSnackbar(res.message ? res.message : 'Updated successfully', {
          variant: 'success',
        })
        getData()
        setHeadChangeModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }

  const manageStatus = () =>
    data?.status
      ? deactivateVendorEmployees(params.id, data?.id)
      : activateVendorEmployees(params.id, data?.id)

  const handleStatusChange = () => {
    manageStatus()
      .then((res) => {
        enqueueSnackbar(
          res.message ? res.message : 'Status updated successfully',
          {
            variant: 'success',
          }
        )

        getData()
        setHeadChangeModal(false)
        setStatusModal(false)
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }
  return (
    <div>
      <div>
        <DetailHeader
          tiledata={tiledata}
          disabled={false}
          data={basicData}
          headerMenuItems={userData?.is_admin ? headerMenuItems : []}
        />
        {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      </div>
      <div className="flex gap-4">
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          onClick={haandleTabChange}
        >
          <Outlet context={contextData} />
        </TabContainer>
      </div>

      <DialogModal
        isOpen={adminModal}
        onClose={() => setAdminModal(false)}
        title={'Are you sure?'}
        onSubmit={() => handleAdminChange()}
        secondaryAction={() => setAdminModal(false)}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              // adminFlag
              !data?.is_admin
                ? 'Do you want to make this user as Admin?'
                : 'Do you want to remove this user from Admin?'
            }
          />
        }
      />

      <DialogModal
        isOpen={headChangeModal}
        onClose={() => setHeadChangeModal(false)}
        title={'Are you sure?'}
        onSubmit={() => handleHeadChange()}
        secondaryAction={() => setHeadChangeModal(false)}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              // adminFlag

              !data?.is_operations_head
                ? 'Do you want to make this user as Operations Head?'
                : 'Do you want to remove this user from Operations Head?'
            }
          />
        }
      />

      <DialogModal
        isOpen={statusModal}
        onClose={() => setStatusModal(false)}
        title={'Are you sure?'}
        onSubmit={() => handleStatusChange()}
        secondaryAction={() => setStatusModal(false)}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              data?.status
                ? 'Do you want to deactivate this user?'
                : 'Do you want to activate this user?'
            }
          />
        }
      />

      <ChangePassword
        isOpen={passwordModal}
        vendorId={params?.id}
        paramsId={params?.id2}
        handleClose={() => setPasswordModal(false)}
        handleCallback={getData}
      />
    </div>
  )
}

export default VendorEmployeeDetails
