import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { AdvanceFilter, StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'
import { isAllFranchisee } from '../../configs/permissionGate'

const defaultParams = [
  { title: 'Order Date', slug: 'order_date', isChecked: false },
  { title: 'Account CRE ', slug: 'account_cre', isChecked: false },
  { title: 'Order CRE ', slug: 'order_cre', isChecked: false },
  { title: 'Service Category', slug: 'service_category', isChecked: false },
  { title: 'TL Allocated On', slug: 'allocated_date', isChecked: false },
  { title: 'Target Date', slug: 'target_date', isChecked: false },
  { title: 'Service Status', slug: 'service_status', isChecked: false },
  { title: 'Service Value', slug: 'order_value', isChecked: false },
  { title: 'Amt Allocated ', slug: 'order_collection', isChecked: false },
  { title: 'Ageing', slug: 'aging', isChecked: false },
  { title: 'Created By', slug: 'created_by', isChecked: false },
  { title: 'Activity Assignee', slug: 'activity_assignee', isChecked: false },
  ...(isAllFranchisee()
    ? [{ title: 'Franchisee', slug: 'franchisee_id', isChecked: false }]
    : []),
]

export const useServiceFilterStore = create<ServiceFilterstoreType>()(
  persist(
    (set) => ({
      pageParams: defaultPageParams,
      advanceFilter: {
        filterParams: defaultParams,
        isDetailed: true,
      },
      selectedRows: [],
      setAdvanceFilter: (data) => set(() => ({ advanceFilter: data })),
      setPageParams: (data) => set(() => ({ pageParams: data })),
      clearAdvanceFilter: () =>
        set(() => ({
          advanceFilter: {
            filterParams: defaultParams?.map((item) => ({
              ...item,
              isChecked: false,
            })),
            isDetailed: true,
          },
        })),
      setSelectedRows: (data) => set(() => ({ selectedRows: data })),
      clearSelectedRows: () => set({ selectedRows: [] }),
      clearServiceFilter: (key) =>
        set((state) => ({
          pageParams: key
            ? { ...defaultPageParams, search: state?.pageParams?.search }
            : defaultPageParams,
        })),
    }),
    { name: 'fit__service_store' }
  )
)

type ServiceFilterstoreType = {
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]
  advanceFilter: AdvanceFilter
  setAdvanceFilter: (data: AdvanceFilter) => void
  setSelectedRows: (data: (number | string)[]) => void
  setPageParams: (data: StoreFilterParams) => void
  clearServiceFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
