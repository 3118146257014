import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  patchData,
  patchFormData,
  postData,
  postFormData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

export const getLeadSource = (data: any) => {
  return getData(`${apiUrl.LEAD_SOURCE}${parseQueryParams(data)}`)
}

export const getContacts = (id: any, data?: any) => {
  return getData(
    `${apiUrl.CONTACT_LIST_URL}account/${id}/contacts/${parseQueryParams(data)}`
  )
}
const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}
export const deleteActivity = (id?: string) => {
  return deleteData(`${apiUrl.ACTIVTY_LIST_URL}/${id}`)
}
export const cancelActivityRequest = (id?: string) => {
  return patchData(`${apiUrl.MY_APPROVALS_URL}${id}/cancel/`, {})
}
const fetchActivityList = async ({ input }: { input: any }) => {
  const { type, ...rest } = input
  const url = buildUrlWithParams(apiUrl.ACTIVTY_LIST_URL, {
    ...rest,
    ...(type !== 'all' ? { status_id: undefined, type } : {}),
  })
  const response = await getData(url)
  return response?.data
}
export const activityProgressiveTabStatusList = async (data?: any) => {
  const response = await getData(
    `${apiUrl.ACTIVITY_STATUS_LIST}${parseQueryParams(data)}`
  )
  return response
}

export const activityProgressivTabStatusUpdate = (id: any, data: any) => {
  return patchData(`${apiUrl.ACTIVTY_LIST_URL}/${id}/change_status`, data)
}

const fetchActivityRequestList = async (input: any) => {
  const { order_id, service_id, task_id, ...params } = input
  const url = buildUrlWithParams(
    `${apiUrl.ACTIVTY_LIST_CRE}${order_id}/items/${service_id}/tasks/${task_id}/approval-requests`,
    {
      ...params,
    }
  )
  const response = await getData(url)
  return response?.data
}
export const getActivityRequestData = async (input: any) => {
  const { order_id, service_id, task_id, approvalId } = input
  return getData(
    `${apiUrl.ACTIVTY_LIST_CRE}${order_id}/items/${service_id}/tasks/${task_id}/approval-requests/${approvalId}`
  )
}

export const getActivityDetails = (id: any) => {
  return getData(`${apiUrl.ACTIVTY_LIST_URL}/${id}`)
}
export const activityUpdateAssignee = (
  orderId: any,
  serviceid: any,
  id: any,
  data: any
) => {
  return postData(
    `${apiUrl.ACTIVTY_LIST_CRE}${orderId}/items/${serviceid}/task/${id}`,
    data
  )
}
export const activityUnassignAssignee = (serviceid: any, id: any) => {
  return updateData(
    `${apiUrl.ACTIVTY_LIST_CRE}items/${serviceid}/task/${id}/unassign`,
    {}
  )
}
export const useFetchActivity = ({
  input,
  type,
}: {
  input: any
  type?: string | null
}) => {
  return useQuery(
    ['list_activities', type, input],
    () => fetchActivityList({ input }),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}
export const useFetchActivityRequests = (id?: string, input?: any) => {
  return useQuery(
    ['list_activity_requests', input],
    () => fetchActivityRequestList(input as any),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}
export const editDeal = (data: { input: any; id: string }) => {
  return updateData(`${apiUrl.DEAL_LIST_URL}/${data?.id}`, data?.input)
}
export const useEditDeal = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editDeal, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Deal Updated successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}
export const getFilesAttachments = (data?: any) => {
  const response = getData(
    `${apiUrl.LEAD_FILES_ATTACHMENTS}${parseQueryParams(data)}`
  )
  return response
}
export const attachmentsDelete = (data: any) => {
  return deleteData(`${apiUrl.LEAD_FILES_ATTACHMENTS}/${data}`)
}
export const getApprovalReqType = (id: string, data?: any) => {
  const params = {
    stakeholder__id: id,
    ...data,
  }
  const response = getData(
    `${apiUrl.APPROVAL_TYPES}${parseQueryParams(params)}`
  )
  return response
}
export const getApprovalStatusList = () => {
  const response = getData(`${apiUrl.APPROVALS_STATUS_LIST_URL}?type=review`)
  return response
}
export const getOrderList = (data: any) => {
  return getData(`${apiUrl.ORDER_LIST_URL}order-list/${parseQueryParams(data)}`)
}
export const getApprovalReviewrs = (id: string, data?: any) => {
  const params = {
    stakeholder_id: id,
    ...data,
  }
  const response = getData(
    `${apiUrl.APPROVAL_REVIEWRS}${parseQueryParams(params)}`
  )
  return response
}
export const getDocumentType = () => {
  const response = getData(apiUrl.LEAD_DOCUMENT_TYPE)
  return response
}
export const useCreateActivityRequests = (
  handleSubmission: (data: any) => void,
  service_id: any,
  order_id: any,
  task_id: any
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(
    (datas: any) =>
      createActivityRequests(datas, service_id, order_id, task_id),
    {
      onSuccess: (res: any) => {
        handleSubmission(res.data)
        enqueueSnackbar('Request Created successfully', { variant: 'success' })
      },

      onError: (error: any) => {
        enqueueSnackbar(getErrorMessage(error.response.data.error), {
          variant: 'error',
        })
      },
    }
  )
}
export const useReviewActivityRequests = (
  handleSubmission: (data: any) => void,
  request_id: any
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(
    (datas: any) => reviewActivityRequests(datas, request_id),
    {
      onSuccess: (res: any) => {
        handleSubmission(res.data)
        enqueueSnackbar('Request reviewed successfully', { variant: 'success' })
      },

      onError: (error: any) => {
        enqueueSnackbar(getErrorMessage(error.response.data.error), {
          variant: 'error',
        })
      },
    }
  )
}
export const createActivityRequests = (
  data: any,
  service_id: any,
  order_id: any,
  task_id: any
) => {
  return postFormData(
    `${apiUrl.ACTIVTY_LIST_CRE}${order_id}/items/${service_id}/tasks/${task_id}/approval-requests`,
    data
  )
}
export const reviewActivityRequests = (data: any, request_id: any) => {
  return patchFormData(`${apiUrl.MY_APPROVALS_URL}${request_id}/`, data)
}
