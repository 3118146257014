import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'

export const useTeamFilterStore = create<TeamFilterstoreType>()(
  persist(
    (set) => ({
      pageParams: defaultPageParams,
      selectedRows: [],

      setPageParams: (data) => set({ pageParams: data }),
      setSelectedRows: (data) => set({ selectedRows: data }),
      clearSelectedRows: () => set({ selectedRows: [] }),

      clearTeamFilter: (key) =>
        set((state) => ({
          pageParams: key
            ? { ...defaultPageParams, search: state?.pageParams?.search }
            : defaultPageParams,
        })),
    }),
    { name: 'fit__team_store' }
  )
)

interface TeamFilterstoreType {
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]
  setPageParams: (data: StoreFilterParams) => void
  setSelectedRows: (data: (number | string)[]) => void
  clearTeamFilter: (key?: string) => void
  clearSelectedRows: () => void
}
