import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosRequestConfig } from 'axios'

import {
  deleteData,
  getConfigData,
  getData,
  patchData,
  postData,
  postFormData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
// import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

interface Params {
  [key: string]: string | number | boolean | any
}

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: any) => {
  const { ...rest } = input
  const url = buildUrlWithParams(apiUrl.UNALLOTTED_LEAD_LIST_URL, {
    ...rest,
  })
  const response = await getData(url)
  return response?.data
}
const getLead = async (id: any) => {
  const response = await getData(`${apiUrl.UNALLOTTED_LEAD_LIST_URL}/${id}`)
  return response?.data
}
export const updateLeadFranchisee = (input: any, id: string) => {
  return patchData(
    `${apiUrl.UNALLOTTED_LEAD_LIST_URL}/${id}/change_franchisee`,
    input
  )
}
export const getLeadDetails = async (id: any) => {
  const response = await getData(`${apiUrl.UNALLOTTED_LEAD_LIST_URL}/${id}`)
  return response?.data
}

export const useLead = ({
  input,
  type,
}: {
  input: any
  type?: string | null
}) => {
  return useQuery(['leads', input, type], () => fetchData(input), {
    // refetchOnWindowFocus: true,
    //staleTime: 50000,
  })
}
export const useLeadProfile = (input: any) => {
  return useQuery(['leadprofile', input], () => getLead(input), {
    // refetchOnWindowFocus: true,
    //staleTime: 50000,
  })
}

export const {
  exportLeads,
  getbusinsector,
  getConstCat,
  getbusIndusData,
  getSubIndusData,
  getLeadSource,
  getCountryState,
  getCutomerList,
} = {
  exportLeads: (data: any) =>
    getData(buildUrlWithParams(apiUrl.COMPANY_EXPORT, data)),
  getbusinsector: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.BUSINESS_SECTOR, params)),
  getLeadSource: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.LEAD_SOURCE, params)),
  getConstCat: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.CONSTITUTION_CATEGORY, params)),
  getbusIndusData: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.BUSINESS_CATEGORY, params)),
  getSubIndusData: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.BUSINESS_CATEGORY, params)),
  getCountryState: () => getData(apiUrl.STATE_LIST),
  getCutomerList: (params: Params) =>
    getData(buildUrlWithParams(apiUrl.CONTACT_LIST_URL, params)),
}

export const createLead = (input: any) => {
  return postData(apiUrl.LEAD_CREATE, input)
}

export const useCreateLead = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createLead, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Lead Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error.message[0]), {
        variant: 'error',
      })
    },
  })
}

// DUPLICATES
export const getLeadInfoDuplicate = (data: any, config: AxiosRequestConfig) =>
  getConfigData(
    `${apiUrl.LEAD_INFO_DUPLICATES}${parseQueryParams(data ?? {})}`,
    config
  )
export const listLeadDuplicate = (id?: string, data?: any) =>
  getData(
    `${apiUrl.LEAD_STATUS_LIST_URL}${id}/lead-duplicates${parseQueryParams(
      data
    )}`
  )

//MERGE

export const getLeadMergeDetails = (data: any) => {
  return postData(apiUrl.LEAD_MERGE_DETAILS, data)
}

export const mergeLeads = (data: any, id?: string) => {
  return updateData(`${apiUrl.LEAD_STATUS_LIST_URL}${id}/merge`, data)
}

// FILES AND ATTACHMENTS

export const getFilesAttachments = (data?: any) => {
  const response = getData(
    `${apiUrl.LEAD_FILES_ATTACHMENTS}${parseQueryParams(data)}`
  )
  return response
}

export const getDocumentType = () => {
  const response = getData(apiUrl.LEAD_DOCUMENT_TYPE)
  return response
}

export const createLeadAttachments = (data: any) => {
  return postFormData(apiUrl.LEAD_FILES_ATTACHMENTS, data)
}
export const useFileAttachments = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createLeadAttachments, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Lead Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const attachmentsDelete = (data: any) => {
  return deleteData(`${apiUrl.LEAD_FILES_ATTACHMENTS}/${data}`)
}

//ALLOCATE AND UNALLOCATE CRE
export const leadUpdateCRE = (id: any, data: any) => {
  return updateData(
    `${apiUrl.LEAD_STATUS_LIST_URL}${id}/update-primary-cre`,
    data
  )
}
export const leadUnallocateCRE = (id: any, data: any) => {
  return updateData(
    `${apiUrl.LEAD_STATUS_LIST_URL}${id}/unallocate-supporting-cre`,
    data
  )
}

//PROGRESSIVE TABS
export const progressivTabStatusList = async (id?: string) => {
  const response = await getData(`${apiUrl.LEAD_STATUS_LIST_URL}${id}/status`)
  return response
}

export const leadProgressivTabStatusUpdate = (id: any, data: any) => {
  return updateData(`${apiUrl.LEAD_STATUS_LIST_URL}${id}/status`, data)
}

export const leadConvertionValidation = (id?: string) => {
  return getData(`${apiUrl.LEAD_STATUS_LIST_URL}${id}/convert-validation`)
}

export const leadConvert = (id: string, data: any) => {
  return postData(`${apiUrl.LEAD_STATUS_LIST_URL}${id}/convert`, data)
}
