import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import Icons from '../../../../components/common/icons'

import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import FormLinkView from '../../../../components/common/inputs/FormLink'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { useMasterData } from '../../../../store/masterDataStore'
import { convertUTCtoBrowserTimeZone } from '../../../../utilities/format'
import { getLeadSource } from '../../api'
import { handleOnInitData } from './leadUtils'
import { leadSchema, LeadSchema } from './schema'

type Props = {
  data?: any
  id?: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  disabled?: boolean
  // setEditFlag: () => void
}
const BasicInformation = (props: Props) => {
  const { enqueueSnackbar } = useSnackbarManager()
  const { data, handleEditButton } = props
  const [isEditing] = useState(false)
  const { masterData } = useMasterData()

  const defaultPages: Record<string, number> = {
    lead: 1,
  }
  const [pages, setPages] = useState(defaultPages)

  // const handleEditClick = () => {
  //   setIsEditing(!isEditing)
  // }
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    value: string,
    isDuplicateCheck = false,
    isMobileOrEmail = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    value,
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
    ...(isMobileOrEmail ? { isMobileOrEmail: true } : {}),
  })

  const getLead = async (value?: string, page?: number) => {
    const { data } = await getLeadSource({
      search: value ?? '',
      page: page as number,
    })
    setPages((prev) => ({
      ...prev,
      lead: data?.next ? prev['lead'] + 1 : 0,
    }))
    return data?.map((item: any) => ({
      ...item,
      lead_source_name: item.name,
    }))
  }
  const onInit = () => {
    const onInitData = handleOnInitData(data)
    return onInitData
  }
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const formBuilderProps = [
    {
      ...textField('name', 'Full Name', 'Enter Lead Name', true, data?.name),
      toCapitalize: true,
    },
    textField(
      'phone',
      'Mobile Number 1',
      'Enter Mobile',
      false,
      data?.phone,
      true,
      true
    ),
    textField(
      'phone2',
      'Mobile Number 2',
      'Enter Mobile',
      false,
      data?.lead_additional_data?.phone2,
      true,
      true
    ),
    {
      ...textField(
        'email',
        'Email ID 1',
        'Enter email',
        false,
        data?.email,
        true,
        true
      ),
      type: 'email',
    },
    {
      ...textField(
        'email2',
        'Email ID 2',
        'Enter email',
        false,
        data?.lead_additional_data?.email2,
        true,
        true
      ),
      type: 'email',
    },
    {
      name: 'preferred_language_name',
      label: 'Preferred Language',
      id: 'preferred_language_id',
      desc: 'language_name',
      descId: 'id',
      data: masterData.languages,
      type: 'custom_search_select',
      placeholder: 'Enter Preferred Language',
      value: data?.lead_additional_data?.preferred_language?.language_name,
    },
    {
      name: 'lead_source_name',
      label: 'Lead Source',
      required: false,
      getData: getLead,
      async: true,
      id: 'lead_source_id',
      descId: 'id',
      initialLoad: true,
      desc: 'lead_source_name',
      type: 'auto_complete',
      placeholder: 'Enter Lead Source',
      value: data?.lead_source?.name,
      paginationEnabled: true,
      nextBlock: pages.lead ?? undefined,
    },

    {
      ...textField(
        'datetime_created',
        'Created On',
        'Created On',
        false,
        convertUTCtoBrowserTimeZone(data?.datetime_created)
      ),
      disabled: true,
    },
    {
      ...textField(
        'datetime_updated',
        'Updated On',
        'Updated On',
        false,
        convertUTCtoBrowserTimeZone(data?.datetime_updated)
      ),
      disabled: true,
    },
    {
      ...textField(
        'created_by',
        'Created By',
        'Enter Created By',
        false,
        data?.created_by?.name
      ),
      disabled: true,
    },
  ]

  const methods = useForm<LeadSchema>({
    resolver: zodResolver(leadSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  // const handleSubmission = () => {
  //   methods.reset({
  //     ...onInit(),
  //   })
  //   handleRefresh()
  //   setIsEditing(false)
  // }
  const checkforErrors = () => {
    if (
      methods?.formState?.errors?.next_followup_date ||
      methods.formState.errors.target_date
    ) {
      if (
        methods?.formState?.errors?.next_followup_date &&
        methods.formState.errors.target_date
      ) {
        enqueueSnackbar(
          'Target Date and Next Followup Date of Lead Information is required',
          {
            variant: 'error',
          }
        )
      } else if (methods?.formState?.errors?.next_followup_date) {
        enqueueSnackbar('Next Followup Date of Lead Information is required', {
          variant: 'error',
        })
      } else {
        enqueueSnackbar('Target Date of Lead Information is required', {
          variant: 'error',
        })
      }
    }
  }
  useEffect(() => {
    checkforErrors()
  }, [methods.watch(), methods.formState.errors])
  // const { mutate, isLoading } = useUpdateLead(handleSubmission)
  // const onSubmit = (data: LeadSchema) => {
  //   const input = handleLeadInput(data)
  //   mutate({
  //     input: { ...input },
  //     id: id ? id : '',
  //   })
  // }
  // const { handleSubmit } = methods
  // const handleCancel = () => {
  //   setIsEditing(!isEditing)
  //   methods.reset({
  //     ...onInit(),
  //   })
  //   // handleRefresh()
  // }

  // if (isEditing) {
  //   handleEditButton()
  // }
  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  const linkDatas = [
    ...(data?.deal?.account?.id
      ? [
          {
            label: 'Connected Account',
            value: data?.deal?.account?.business_name,
            id: data?.deal?.account?.id,
            slug: 'account',
          },
        ]
      : []),
    ...(data?.deal?.contact?.id
      ? [
          {
            label: 'Connected Contact',
            value: data?.deal?.contact?.name,
            id: data?.deal?.contact?.id,
            slug: 'contact',
          },
        ]
      : []),
    ...(data?.deal?.id
      ? [
          {
            label: 'Connected Deal',
            value: data?.deal?.deal_number,
            id: data?.deal?.id,
            slug: 'deal',
          },
        ]
      : []),
  ]
  const renderFields = () => {
    return (
      <>
        {formBuilderProps.map((data) => (
          <FormFieldView
            value={data.value}
            type={data?.type}
            label={data.label}
            key={data?.id}
          />
        ))}
      </>
    )
  }
  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText">Basic Information</h4>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">{renderFields()}</div>

      <FormLinkView data={linkDatas} />
    </div>
  )
}

export default BasicInformation
