import React from 'react'
import { IconProps } from '../../../common/types'

const LocationPinIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1122 13.8807C14.3846 14.6083 12.917 16.0758 11.8115 17.1813C11.0305 17.9624 9.7657 17.9623 8.98465 17.1813C7.89904 16.0957 6.45857 14.6552 5.68407 13.8807C3.08057 11.2772 3.08057 7.05612 5.68407 4.45262C8.28756 1.84913 12.5087 1.84913 15.1122 4.45262C17.7157 7.05612 17.7157 11.2772 15.1122 13.8807Z"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8981 9.16667C12.8981 10.5474 11.7788 11.6667 10.3981 11.6667C9.0174 11.6667 7.89811 10.5474 7.89811 9.16667C7.89811 7.78596 9.0174 6.66667 10.3981 6.66667C11.7788 6.66667 12.8981 7.78596 12.8981 9.16667Z"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LocationPinIcon
