import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  patchData,
  postData,
} from '../../../../../apis/api.helpers'
import apiUrl from '../../../../../apis/api.url'
import { QueryParams } from '../../../../../common/types'
import { useSnackbarManager } from '../../../../../components/common/snackbar'
import {
  getErrorMessage,
  parseQueryParams,
} from '../../../../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

export const createEmployee = (input: any) => {
  return postData(apiUrl.EMPLOYEE_LIST_URL, input)
}

export const useCreateEmployee = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createEmployee, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Employee Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.EMPLOYEE_LIST_URL, {
    ...input,
  })
  const response = await getData(url)
  return response
}

export const useEmployee = (input: QueryParams) => {
  return useQuery(['employee', input], () => fetchData(input), {
    // refetchOnWindowFocus: true,
    // staleTime: 0,
  })
}

export const deleteEmployee = (id: any) => {
  return deleteData(`${apiUrl.EMPLOYEE_LIST_URL}${id}`)
}

export const getEmployeeDetails = (id: any, id2: any) => {
  return getData(`${apiUrl.VENDORS}${id}/employees/${id2}`)
}
export const getEmployeeTeamMembers = (id: any, id2: any) => {
  return getData(`${apiUrl.VENDORS}${id}/employees/${id2}/get_team/`)
}

export const editEmployeeRole = (data: {
  input: any
  id: string
  id2: string
}) => {
  return patchData(
    `${apiUrl.VENDORS}${data?.id}/employees/${data?.id2}/change_role/`,
    data?.input
  )
}
export const editEmployee = (data: { input: any; id: string; id2: string }) => {
  return patchData(
    `${apiUrl.VENDORS}${data?.id}/employees/${data?.id2}/`,
    data?.input
  )
}

export const useEditEmployee = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editEmployee, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}
export const useEditEmployeeRole = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editEmployeeRole, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const getRoles = (input: any) => {
  return getData(`${apiUrl.ROLE_LIST_URL}${parseQueryParams(input)}`)
}

export const getEmployeeTeamList = (id: string, input: any) => {
  return getData(`${apiUrl.VENDORS}${id}/teams${parseQueryParams(input)}`)
}

export const changeEmployeeTeamStatus = (
  id: any,
  teamId: string,
  employeeId: string,
  status: boolean
) => {
  const url = status ? 'deactivate' : 'activate'
  return patchData(
    `${apiUrl.VENDORS}${id}/teams/${teamId}/members/${employeeId}/${url}/`,
    {}
  )
}

export const addTeamEmployee = (input: {
  id: any
  teamId: string
  data: any
}) => {
  return postData(
    `${apiUrl.VENDORS}${input.id}/teams/${input.teamId}/members/`,
    input.data
  )
}

export const getEmployeeFranchiseeList = (id: any, input: any) => {
  return getData(
    `${apiUrl.EMPLOYEE_LIST_URL}${id}/get-franchisee${parseQueryParams(input)}`
  )
}
export const getAllFranchiseeList = (input: any) => {
  return getData(`${apiUrl.FRANCHISEE_LIST}${parseQueryParams(input)}`)
}
export const getNotificationEntities = () => {
  return getData(`${apiUrl.NOTIFICATION_ENTITIES}`)
}

export const setDefaultEmployeeFranchisee = (id: string, input: any) => {
  return postData(
    `${apiUrl.EMPLOYEE_LIST_URL}${id}/assign-default-franchisee`,
    input
  )
}

export const getDepartment = (input: any) => {
  return getData(`${apiUrl.DEPARTMENTS}${parseQueryParams(input)}`)
}

export const getDesignation = (input: any) => {
  return getData(`${apiUrl.DESIGNATION}${parseQueryParams(input)}`)
}

export const getReportsTo = (input: any) => {
  return getData(`${apiUrl.EMPLOYEE_LIST_URL}${parseQueryParams(input)}`)
}

export const editWorkProfile = (data: { input: any; id: string }) => {
  return postData(
    `${apiUrl.EMPLOYEE_LIST_URL}${data?.id}/work-profile`,
    data?.input
  )
}

export const useEditWorkProfile = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editWorkProfile, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const setAdminStatus = (input: { id: any; data: any }) => {
  return postData(
    `${apiUrl.EMPLOYEE_LIST_URL}${input.id}/set-admin-status`,
    input.data
  )
}

export const setOperationsHeadStatus = (input: { id: any; data: any }) => {
  return postData(
    `${apiUrl.EMPLOYEE_LIST_URL}${input.id}/set-operations-head`,
    input.data
  )
}

export const setEmployeeStatus = (input: { id: any; data: any }) => {
  return postData(
    `${apiUrl.EMPLOYEE_LIST_URL}${input.id}/set-status`,
    input.data
  )
}

export const setEmployeePassword = (data: any) => {
  const { employee, vendor, ...rest } = data
  return patchData(
    `${apiUrl.VENDORS}${vendor}/employees/${employee}/change_password/`,
    rest
  )
}

export const getFranchesiRoles = (input: any) => {
  return getData(`${apiUrl.ROLE_LIST_URL}${parseQueryParams(input)}`)
}

export const setFranchesiRoles = (input: { id: any; data: any }) => {
  return postData(
    `${apiUrl.FRANCHISEE_DETAILS}${input.id}/change-employee-role`,
    input.data
  )
}
