//import { useState } from 'react'
import { useState } from 'react'
import {
  getCollectablesList,
  getDeliverablesList,
} from '../../../apis/common.apis'
import { getEmployeeList } from '../../../apis/common.apis'

type Props = {
  model_name: string
  paramsId: string
  mode?: string
}

export const GetFilterDetails = ({ model_name, paramsId, mode }: Props) => {
  const defaultPages: Record<string, number> = {
    created_by: 1,
  }

  const [pages, setPages] = useState(defaultPages)

  const loadEmployeeList = async (value?: string, page?: number) => {
    const data = await getEmployeeList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setPages((prev: any) => ({
      ...prev,
      created_by: data?.next ? prev['created_by'] + 1 : 0,
    }))

    return data?.results?.map((item: any) => ({
      ...item,
      employee_name: item.name,
    }))
  }

  const getCollectables = async () => {
    const { data } = await getCollectablesList({
      model_id: paramsId,
      model_name: model_name,
      filter_list: true,
    })
    return data?.results?.map((item: any) => ({
      ...item,
      collectable_name: item?.display_name,
    }))
  }
  const getDeliverables = async () => {
    const { data } = await getDeliverablesList({
      model_id: paramsId,
      model_name: model_name,
      filter_list: true,
    })
    return data?.results?.map((item: any) => ({
      ...item,
      deliverable_name: item?.display_name,
    }))
  }

  return [
    {
      name: 'Document Type / Classification',
      objectId: 'file_classification',
      isPrimary: true,
      descId: 'id',
      initialLoad: true,
      required: true,
      desc: 'classification_name',
      data: [
        { classification_name: 'Order Related', id: 'order_related' },
        { classification_name: 'Permanent', id: 'permanent' },
      ],
      type: 'custom_select',
      placeholder: 'Enter Document Type / Classification',
    },
    {
      name: 'Select Collectables / Deliverables',
      label: 'Select Collectables / Deliverables',
      id: 'id',
      descId: 'id',
      required: false,
      desc: 'name',
      type: 'custom_select',
      data: [
        { name: 'Collectables', id: '1' },
        { name: 'Deliverables', id: '2' },
      ],
      placeholder: 'Select Collectables / Deliverables',
      isPrimary: true,
      objectId: 'mode',
    },

    {
      name: 'Select Collectables',
      label: 'Map File',
      getData: getCollectables,
      async: false,
      id: 'collectable_id',
      descId: 'id',
      initialLoad: true,
      hidden: mode !== '1',
      //hidden: !mapType || mapType !== 'Collectables',
      desc: 'collectable_name',
      type: 'auto_suggestion',
      placeholder: 'Map File',
      isPrimary: true,
      objectId: 'collectable_id',
    },

    {
      name: 'Select Deliverables',
      label: 'Map File',
      getData: getDeliverables,
      async: false,
      id: 'deliverable_id',
      //hidden: !mapType || mapType !== 'Deliverables',
      descId: 'id',
      initialLoad: true,
      required: true,
      hidden: mode !== '2',
      desc: 'deliverable_name',
      type: 'auto_suggestion',
      placeholder: 'Map File',
      isPrimary: true,
      objectId: 'deliverable_id',
    },
    {
      desc: 'employee_name',
      type: 'auto_suggestion',
      descId: 'id',
      async: true,
      slug: 'created_by',
      paginationEnabled: true,
      nextBlock: pages.created_by ?? undefined,
      getData: loadEmployeeList,
      name: 'Uploaded By',
      isPrimary: false,
      objectId: 'created_by_id',
    },
    {
      type: 'date_picker',
      selectRange: true,
      desc: 'created_from_date',
      descSecondary: 'created_to_date',
      isPrimary: false,
      name: 'Uploaded On',
      slug: 'created_date',
    },
  ]
}
