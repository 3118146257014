import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useThemeStore = create<ThemeStoreType>()(
  persist(
    (set) => ({
      isDark: false,
      setIsDark: () => set(() => ({ isDark: true })),
      clearIsDark: () => set(() => ({ isDark: false })),
    }),
    { name: 'theme' }
  )
)

type ThemeStoreType = {
  isDark: boolean
  setIsDark: () => void
  clearIsDark: () => void
}
