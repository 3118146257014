import moment from 'moment'
import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = () => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat

    if (isCustom === 'amount') {
      const propertyValue = getNestedProperty(row, key)
      const numbers =
        typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

      return {
        cell: format(numbers),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else if (isCustom === 'date') {
      return {
        cell: row.target_close_date
          ? moment(row.target_close_date).format('DD-MM-YYYY')
          : '' ?? '',
      }
    } else {
      return {
        cell: getNestedProperty(row, key),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    {
      title: 'Service Name',
      field: 'service_name',
      ...defaultColumnProps3,
      fixed: true,
      // link: true,
      // getPath: (row: any) => onViewAction(row, 'serviceName'),
      customCell: true,
      // getPath: (row: any) => onViewAction(row, 'serviceName'),
    },
    // {
    //   title: 'Service ID',
    //   field: 'unique_id',
    //   ...defaultColumnProps3,
    //   fixed: true,
    //   link: true,
    //   getPath: (row: any) => onViewAction(row, 'serviceName'),
    //   customCell: true,
    // },
    // {
    //   title: 'Service Team Lead',
    //   renderCell: createRenderCell('team.name'),
    //   field: 'name',
    //   customCell: true,
    //   ...defaultColumnProps3,
    // },
    // {
    //   title: 'TL Allocated On',
    //   field: 'assigned_date',
    //   ...defaultColumnProps3,
    // },
    // {
    //   title: 'Target Date',
    //   field: 'target_close_date',
    //   renderCell: createRenderCell('target_close_date', 'date'),
    //   customCell: true,
    //   ...defaultColumnProps3,
    // },
    {
      title: 'Service Value',
      field: 'total_price',
      renderCell: createRenderCell('total_price', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Prof Fees',
      field: 'professional_fee',
      renderCell: createRenderCell('professional_fee', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Vendor Fees',
      field: 'vendor_fee',
      renderCell: createRenderCell('vendor_fee', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Govt Fees',
      field: 'govt_fee',
      renderCell: createRenderCell('govt_fee', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'GST',
      field: 'gst_percent',
      renderCell: createRenderCell('gst_amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Discount',
      field: 'discount_percent',
      renderCell: createRenderCell('discount_amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    // {
    //   title: 'Service Status',
    //   field: 'name',
    //   renderCell: createRenderCell('service_status.status.name'),
    //   customCell: true,
    //   ...defaultColumnProps3,
    // },
  ]

  return column
}
