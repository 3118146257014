import React from 'react'
import { IconProps } from '../../../common/types'

const ProfileIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91996 8.51999C6.74663 8.47999 5.81329 7.51999 5.81329 6.33999C5.81329 5.13332 6.78663 4.15332 7.99996 4.15332C9.20663 4.15332 10.1866 5.13332 10.1866 6.33999C10.18 7.51999 9.25329 8.47999 8.07996 8.51999C8.03329 8.51332 7.97329 8.51332 7.91996 8.51999Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.99999 14.6667C6.26665 14.6667 4.69332 14.0067 3.50665 12.92C3.57332 12.2934 3.97332 11.68 4.68665 11.2C6.51332 9.98671 9.49999 9.98671 11.3133 11.2C12.0267 11.68 12.4267 12.2934 12.4933 12.92C11.3067 14.0067 9.73332 14.6667 7.99999 14.6667Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 14.6667C4.31808 14.6667 1.33331 11.6819 1.33331 8C1.33331 4.3181 4.31808 1.33333 7.99998 1.33333C11.6819 1.33333 14.6666 4.3181 14.6666 8C14.6666 11.6819 11.6819 14.6667 7.99998 14.6667Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ProfileIcon
