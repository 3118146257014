import Icons from '../../../../components/common/icons/index'
import { getNestedProperty } from '../../../../utilities/parsers'

const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell =
    (key: string | string[], type?: string) => (row: any) => {
      switch (type) {
        case 'join':
          return {
            cell: `${getNestedProperty(row, key[0]) ?? ''} ${
              getNestedProperty(row, key[1]) ?? ''
            }`,
          }
        case 'custom':
          return {
            cell: (
              <div className="flex gap-1 items-center">
                {getNestedProperty(row, key[0]) && (
                  <Icons
                    name="phone"
                    toolTip={getNestedProperty(row, key[0])}
                  />
                )}
                {getNestedProperty(row, key[1]) && (
                  <Icons
                    name="email"
                    toolTip={getNestedProperty(row, key[1])}
                  />
                )}
              </div>
            ),
          }
        default:
          return {
            cell: getNestedProperty(row, key as string),
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
      }
    }

  const column = [
    {
      title: 'Lead ID',
      field: 'lead_number',
      ...defaultColumnProps,
      getPath: (row: any) => onViewAction(row),
      link: true,
    },
    {
      title: 'Lead Name',
      field: 'name',
      ...defaultColumnProps,
    },
    {
      title: 'Business Name',
      field: 'business_name',
      ...defaultColumnProps,
    },
    {
      title: 'State',
      renderCell: createRenderCell('lead_address.state'),
      field: 'lead_address.state',
      customCell: true,

      ...defaultColumnProps,
    },
    {
      title: 'City',
      field: 'lead_address.city',
      renderCell: createRenderCell('lead_address.city'),
      customCell: true,
      ...defaultColumnProps,
    },
    {
      title: 'Communication',
      renderCell: createRenderCell(['phone', 'email'], 'custom'),

      field: '',
      customCell: true,
      ...defaultColumnProps,
    },

    {
      title: 'Secondary Communication',
      customCell: true,
      renderCell: createRenderCell(
        ['lead_additional_data.phone2', 'lead_additional_data.email2'],
        'custom'
      ),
      field: '',
      ...defaultColumnProps,
    },
  ]

  return column
}
