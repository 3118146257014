import React from 'react'
import { IconProps } from '../../../common/types'

const UnVerifyIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.6237 1.96289L14.957 13.748M14.7023 3.98956C14.5659 3.99648 14.4285 3.99998 14.2904 3.99998C12.2414 3.99998 10.3723 3.22969 8.95699 1.96289C7.54164 3.22964 5.67262 3.9999 3.6237 3.9999C3.48555 3.9999 3.34821 3.9964 3.21178 3.98948C3.0455 4.63189 2.95703 5.30562 2.95703 6.00001C2.95703 9.72768 5.50658 12.8599 8.95703 13.748C12.4075 12.8599 14.957 9.72768 14.957 6.00001C14.957 5.30564 14.8686 4.63194 14.7023 3.98956Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default UnVerifyIcon
