import React from 'react'
import { IconProps } from '../../../common/types'

const ReplyIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 6.6665H8.66667C11.6122 6.6665 14 9.05432 14 11.9998V13.3332M2 6.6665L6 10.6665M2 6.6665L6 2.6665"
        stroke="#585858"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ReplyIcon
