import moment from 'moment'

import { convertUTCtoBrowserTimeZone } from '../../../../utilities/format'
import {
  LeadAddressInfo,
  LeadBasicInfo,
  LeadBusinessInfo,
  LeadManagementInfo,
} from './schema'

export const handleOnInitData = (data: any) => {
  return {
    datetime_created: convertUTCtoBrowserTimeZone(data?.datetime_created),
    datetime_updated: convertUTCtoBrowserTimeZone(data?.datetime_updated),
    created_by: data?.created_by?.name,
    name: data?.name?.toUpperCase(),
    phone: data?.phone,
    phone2: data?.lead_additional_data?.phone2,
    email: data?.email,
    email2: data?.lead_additional_data?.email2,
    preferred_language_name:
      data?.lead_additional_data?.preferred_language?.language_name,
    preferred_language_id: data?.lead_additional_data?.preferred_language?.id,

    lead_source_name: data?.lead_source?.name,
    lead_source_id: data?.lead_source?.id,
    next_followup_date: data?.next_followup_date ?? null,
    target_date: data?.target_date ?? null,

    //Address
    address_1: data?.lead_address?.address_1 ?? null,
    address_2: data?.lead_address?.address_2 ?? null,
    state: data?.lead_address?.state ?? null,
    country: data?.lead_address?.country ?? null,
    pin_code: data?.lead_address?.pin_code ?? null,
    city: data?.lead_address?.city ?? null,

    //business
    business_name: data?.business_name ?? null,
    sector_name: data?.sector?.business_sector_display_name ?? null,
    sector_id: data?.sector?.id ?? null,
    industry_name: data?.industry?.business_industry_display_name ?? null,
    industry_id: data?.industry?.id ?? null,
    sub_sector_name: data?.sub_sector?.business_industry_display_name ?? null,
    sub_sector_id: data?.sub_sector?.id ?? null,
    service: data?.service ?? null,

    //leadmanagement
    lead_status_name: data?.status?.name ?? null,
    probability: data?.lead_additional_data?.probability ?? null,
    cre_name: data?.primary_cre?.name ?? null,
    cre_name_id: data?.primary_cre?.id ?? null,
    supporting_cre: data?.supporting_cre ?? null,
    ad_id: data?.ad_id ?? null,
    ad_set_id: data?.ad_set_id ?? null,
    campaign: data?.campaign ?? null,
    lead_quality_name: data?.lead_quality?.name ?? null,
    lead_quality_id: data?.lead_quality?.id ?? null,
  }
}

export const handleLeadInput = (data: any) => {
  const preferred_language_id_value =
    data?.preferred_language_id !== '' ? data?.preferred_language_id : null

  const lead_source_id_value =
    data?.lead_source_id !== '' ? data?.lead_source_id : null

  const supportingCreList = data?.supporting_cre?.map((item: any) => item.id)
  const resultIds = data?.service?.map((entry: any) => entry.id)
  const input = {
    ...data,
    email: data?.email !== '' ? data?.email : null,
    email2: data?.email2 !== '' ? data?.email2 : null,
    lead_source_name:
      data?.lead_source_name !== '' ? data?.lead_source_name : null,
    lead_source_id: lead_source_id_value ?? null,
    name: data?.name?.toUpperCase() ?? null,
    phone: data?.phone !== '' ? data?.phone : null,
    phone2: data?.phone2 !== '' ? data?.phone2 : null,
    preferred_language_id: preferred_language_id_value,
    preferred_language_name:
      data?.preferred_language_name !== ''
        ? data?.preferred_language_name
        : null,
    next_followup_date: data?.next_followup_date
      ? moment(data?.next_followup_date).format('YYYY-MM-DD')
      : null,
    target_date: data?.target_date
      ? moment(data?.target_date).format('YYYY-MM-DD')
      : null,

    //Address
    address_1: data?.address_1 !== '' ? data?.address_1 : null,
    address_2: data?.address_2 !== '' ? data?.address_2 : null,
    city: data?.city !== '' ? data?.city : null,
    country: data?.country !== '' ? data?.country : null,
    pin_code: data?.pin_code !== '' ? data?.pin_code : null,
    state: data?.state !== '' ? data?.state : null,

    //business
    business_name: data?.business_name !== '' ? data?.business_name : '',
    industry_id: data?.industry_id !== '' ? data?.industry_id : null,
    sector_id: data?.sector_id !== '' ? data?.sector_id : null,
    sub_sector_id: data?.sub_sector_id !== '' ? data?.sub_sector_id : null,
    service: resultIds,

    //leadmanagement
    lead_status_name: data?.lead_status_name ?? null,
    status_id: data?.status_id !== '' ? data?.status_id : null,
    probability: data?.probability ?? null,
    primary_cre_id: data?.cre_name_id !== '' ? data?.cre_name_id : null,
    supporting_cre: supportingCreList ?? [],
    ad_id: data?.ad_id ?? null,
    ad_set_id: data?.ad_set_id ?? null,
    campaign: data?.campaign ?? null,
    lead_quality_name: data?.lead_quality_name ?? null,
    lead_quality_id: data?.lead_quality_id ?? null,
  }
  return input
}

export const handleLeadBasicInfoInput = (data: LeadBasicInfo) => {
  const preferred_language_id_value =
    data?.preferred_language_id !== '' ? data?.preferred_language_id : null

  const lead_source_id_value =
    data?.lead_source_id !== '' ? data?.lead_source_id : null
  const input = {
    ...data,
    email: data?.email !== '' ? data?.email : null,
    email2: data?.email2 !== '' ? data?.email2 : null,
    lead_source_name:
      data?.lead_source_name !== '' ? data?.lead_source_name : null,
    lead_source_id: lead_source_id_value ?? null,
    name: data?.name?.toUpperCase() ?? null,
    phone: data?.phone !== '' ? data?.phone : null,
    phone2: data?.phone2 !== '' ? data?.phone2 : null,
    preferred_language_id: preferred_language_id_value,
    preferred_language_name:
      data?.preferred_language_name !== ''
        ? data?.preferred_language_name
        : null,
  }
  return input
}

export const handleLeadAddressInfoInput = (data: LeadAddressInfo) => {
  const input = {
    ...data,
    address_1: data?.address_1 !== '' ? data?.address_1 : null,
    address_2: data?.address_2 !== '' ? data?.address_2 : null,
    city: data?.city !== '' ? data?.city : null,
    country: data?.country !== '' ? data?.country : null,
    pin_code: data?.pin_code !== '' ? data?.pin_code : null,
    state: data?.state !== '' ? data?.state : null,
  }
  return input
}

export const handleLeadBusinessInfoInput = (data: LeadBusinessInfo) => {
  const resultIds = data?.service?.map((entry: any) => entry.id)
  const input = {
    ...data,
    business_name: data?.business_name !== '' ? data?.business_name : '',
    industry_id: data?.industry_id !== '' ? data?.industry_id : null,
    sector_id: data?.sector_id !== '' ? data?.sector_id : null,
    sub_sector_id: data?.sub_sector_id !== '' ? data?.sub_sector_id : null,
    service: resultIds,
  }
  return input
}

export const handleLeadManagementInput = (data: LeadManagementInfo) => {
  const supportingCreList = data?.supporting_cre?.map((item: any) => item.id)
  const input = {
    ...data,
    next_followup_date: data?.next_followup_date
      ? moment(data?.next_followup_date).format('YYYY-MM-DD')
      : null,
    target_date: data?.target_date
      ? moment(data?.target_date).format('YYYY-MM-DD')
      : null,
    // lead_status_name: data?.lead_status_name ?? null,
    // status_id: data?.status_id !== '' ? data?.status_id : null,
    probability: data?.probability ?? null,
    primary_cre_id: data?.cre_name_id !== '' ? data?.cre_name_id : null,
    supporting_cre: supportingCreList ?? [],
    ad_id: data?.ad_id ?? null,
    ad_set_id: data?.ad_set_id ?? null,
    campaign: data?.campaign ?? null,
    lead_quality_name: data?.lead_quality_name ?? null,
    lead_quality_id: data?.lead_quality_id ?? null,
  }
  return input
}
