import React from 'react'
import { IconProps } from '../../../common/types'

const CloudUploadIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 10.6663C3.19391 10.6663 2 9.47243 2 7.99967C2 6.72831 2.88971 5.66474 4.08047 5.39764C4.02779 5.16231 4 4.91757 4 4.66634C4 2.82539 5.49238 1.33301 7.33333 1.33301C8.9462 1.33301 10.2915 2.47851 10.6001 4.00033C10.6223 3.99989 10.6444 3.99967 10.6667 3.99967C12.5076 3.99967 14 5.49206 14 7.33301C14 8.94565 12.8548 10.2908 11.3333 10.5997M10 8.66634L8 6.66634M8 6.66634L6 8.66634M8 6.66634L8 14.6663"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CloudUploadIcon
