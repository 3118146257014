import { z } from 'zod'

import noLeadingSpaces from '../../../../utilities/noLeadingSpaces'

export const leadSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  business_name: z.string().optional().nullable(),
  probability: z
    .union([
      z
        .string()
        .transform((val) =>
          val === '' || isNaN(parseFloat(val)) ? null : parseFloat(val)
        )
        .refine((val) => val !== null && val >= 0 && val <= 100, {
          message: 'Enter a valid probability %',
        }),
      z.number().refine((val) => val >= 0 && val <= 100, {
        message: 'Enter a valid probability %',
      }),
      z.null(),
    ])
    .optional()
    .nullable(),
  phone: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (
          !/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){9}\d$/.test(
            value
          )
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid Mobile Number.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  phone2: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (
          !/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){9}\d$/.test(
            value
          )
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid Mobile Number.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  preferred_language_name: z.string().optional().nullable(),
  preferred_language_id: z.string().optional().nullable(),

  sector_id: z.string().optional().nullable(),
  sector_name: z.string().optional().nullable(),

  sub_sector_id: z.string().optional().nullable(),
  sub_sector_name: z.string().optional().nullable(),

  industry_id: z.string().optional().nullable(),
  industry_name: z.string().optional().nullable(),

  service: z
    .object({ id: z.string().optional(), name: z.string().optional() })
    .array()
    .optional(),

  lead_source_id: z.string().optional().nullable(),
  lead_source_name: z.string().optional().nullable(),

  status_id: z.string().optional(),
  lead_status_name: z.string().optional().nullable(),

  cre_name_id: z.string().optional().nullable(),
  cre_name: z.string().optional().nullable(),
  supporting_cre: z
    .object({
      id: z.string().optional(),
      name: z.string().optional().nullable(),
    })
    .array()
    .optional()
    .nullable(),
  email: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  email2: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),

  city: z.string().optional().nullable(),

  state: z.string().optional().nullable(),
  sateName: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  countryName: z.string().optional().nullable(),

  pin_code: z
    .string()
    .trim()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[0-9]{6}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Pincode must be a 6-digit number.',
          })
        }
      }
    })
    .optional()
    .nullable(),
  address_1: z.string().optional().nullable(),
  address_2: z.string().optional().nullable(),
  next_followup_date: z
    .union([
      z.date({
        invalid_type_error: 'Next followup date is required.',
      }),
      z.string({
        invalid_type_error: 'Next followup date is required.',
      }),
    ])
    .refine((date) => date !== undefined && date !== null && date !== '', {
      message: 'Next followup date is required.',
    }),

  target_date: z
    .union([
      z.date({
        invalid_type_error: 'Target date is required.',
      }),
      z.string({
        invalid_type_error: 'Target date is required.',
      }),
    ])
    .refine((date) => date !== undefined && date !== null && date !== '', {
      message: 'Target date is required.',
    }),
  ad_id: z.string().optional().nullable(),
  ad_set_id: z.string().optional().nullable(),
  campaign: z.string().optional().nullable(),
  lead_quality_name: z.string().optional().nullable(),
  lead_quality_id: z
    .union([z.string().optional().nullable(), z.number().optional().nullable()])
    .optional()
    .nullable(),
})

export const leadManageSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  business_name: z.string().optional().nullable(),
  probability: z
    .union([
      z
        .string()
        .transform((val) =>
          val === '' || isNaN(parseFloat(val)) ? null : parseFloat(val)
        )
        .refine((val) => val !== null && val >= 0 && val <= 100, {
          message: 'Enter a valid probability %',
        }),
      z.number().refine((val) => val >= 0 && val <= 100, {
        message: 'Enter a valid probability %',
      }),
      z.null(),
    ])
    .optional()
    .nullable(),
  phone: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (
          !/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){9}\d$/.test(
            value
          )
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid Mobile Number.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  phone2: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (
          !/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){9}\d$/.test(
            value
          )
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid Mobile Number.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  preferred_language_name: z.string().optional().nullable(),
  preferred_language_id: z.string().optional().nullable(),

  sector_id: z.string().optional().nullable(),
  sector_name: z.string().optional().nullable(),

  sub_sector_id: z.string().optional().nullable(),
  sub_sector_name: z.string().optional().nullable(),

  industry_id: z.string().optional().nullable(),
  industry_name: z.string().optional().nullable(),

  service: z
    .object({ id: z.string().optional(), name: z.string().optional() })
    .array()
    .optional(),

  lead_source_id: z.string().optional().nullable(),
  lead_source_name: z.string().optional().nullable(),

  status_id: z.string().optional(),
  lead_status_name: z.string().optional().nullable(),

  cre_name_id: z.string().optional().nullable(),
  cre_name: z.string().optional().nullable(),
  supporting_cre: z
    .object({
      id: z.string().optional(),
      name: z.string().optional().nullable(),
    })
    .array()
    .optional()
    .nullable(),
  email: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  email2: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),

  city: z.string().optional().nullable(),

  state: z.string().optional().nullable(),
  sateName: z.string().optional().nullable(),
  country: z.string().optional().nullable(),
  countryName: z.string().optional().nullable(),

  pin_code: z
    .string()
    .trim()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[0-9]{6}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Pincode must be a 6-digit number.',
          })
        }
      }
    })
    .optional()
    .nullable(),
  address_1: z.string().optional().nullable(),
  ad_id: z.string().optional().nullable(),
  ad_set_id: z.string().optional().nullable(),
  campaign: z.string().optional().nullable(),
  lead_quality_name: z.string().optional().nullable(),
  lead_quality_id: z
    .union([z.string().optional().nullable(), z.number().optional().nullable()])
    .optional()
    .nullable(),
  address_2: z.string().optional().nullable(),
  next_followup_date: z
    .union([
      z.date({
        required_error: 'Next followup date is required',
        invalid_type_error: 'Next followup date is required',
      }),
      z.string({
        required_error: 'Next followup date is required',
        invalid_type_error: 'Next followup date is required',
      }),
    ])
    .refine((date) => date !== undefined && date !== null && date !== '', {
      message: 'Date of Birth is required.',
    }),
  target_date: z
    .union([
      z.date({
        required_error: 'Target date is required',
        invalid_type_error: 'Target date is required',
      }),
      z.string({
        required_error: 'Target date is required',
        invalid_type_error: 'Target date is required',
      }),
    ])
    .refine((date) => date !== undefined && date !== null && date !== '', {
      message: 'Target date is required.',
    }),
})
export const leadCreate = leadSchema.omit({
  // stateName: true,
  state: true,
  city: true,
  cre_name: true,
  cre_name_id: true,
  // supporting_cre_name: true,
  // supporting_cre_name_id: true,
  service: true,
  industry_id: true,
  industry_name: true,
  sector_id: true,
  sector_name: true,
  probability: true,
  business_name: true,
})
export const leadBasicInfo = leadSchema.omit({
  country: true,
  countryName: true,
  // stateName: true,
  state: true,
  city: true,
  cre_name: true,
  cre_name_id: true,
  supporting_cre: true,
  service: true,
  industry_id: true,
  industry_name: true,
  sector_id: true,
  sector_name: true,
  probability: true,
  business_name: true,
  status_id: true,
  lead_status_name: true,
  pin_code: true,
  sub_sector_id: true,
  sub_sector_name: true,
  next_followup_date: true,
  target_date: true,
  ad_id: true,
  ad_set_id: true,
  campaign: true,
  lead_quality_id: true,
  lead_quality_name: true,
})
export const leadAddressInfo = leadSchema.omit({
  email: true,
  email2: true,
  cre_name: true,
  cre_name_id: true,
  lead_source_id: true,
  lead_source_name: true,
  service: true,
  industry_id: true,
  industry_name: true,
  sector_id: true,
  sector_name: true,
  preferred_language_id: true,
  preferred_language_name: true,
  phone: true,
  phone2: true,
  probability: true,
  business_name: true,
  name: true,
  // supporting_cre_name: true,
  // supporting_cre_name_id: true,
  sub_sector_id: true,
  sub_sector_name: true,
  status_id: true,
  lead_status_name: true,
  supporting_cre: true,
  next_followup_date: true,
  target_date: true,
  ad_id: true,
  ad_set_id: true,
  campaign: true,
  lead_quality_id: true,
  lead_quality_name: true,
})

export const leadBusinessInfo = leadSchema.omit({
  supporting_cre: true,
  // stateName: true,
  state: true,
  city: true,
  email: true,
  email2: true,
  cre_name: true,
  cre_name_id: true,
  // supporting_cre_name: true,
  // supporting_cre_name_id: true,
  lead_source_id: true,
  lead_source_name: true,
  preferred_language_id: true,
  preferred_language_name: true,
  phone: true,
  phone2: true,
  probability: true,
  name: true,
  country: true,
  pin_code: true,
  status_id: true,
  lead_status_name: true,
  next_followup_date: true,
  target_date: true,
  ad_id: true,
  ad_set_id: true,
  campaign: true,
  lead_quality_id: true,
  lead_quality_name: true,
  address_1: true,
  address_2: true,
})
export const leadManagementInfo = leadSchema.omit({
  // stateName: true,
  state: true,
  city: true,
  email: true,
  email2: true,
  lead_source_id: true,
  lead_source_name: true,
  service: true,
  industry_id: true,
  industry_name: true,
  sector_id: true,
  sector_name: true,
  preferred_language_id: true,
  preferred_language_name: true,
  phone: true,
  phone2: true,
  business_name: true,
  name: true,
  country: true,
  pin_code: true,
  sub_sector_id: true,
  sub_sector_name: true,
  address_1: true,
  address_2: true,
})

export type LeadSchema = z.infer<typeof leadSchema>
export type LeadManageSchema = z.infer<typeof leadManageSchema>
export type LeadBasicInfo = z.infer<typeof leadBasicInfo>
export type LeadBusinessInfo = z.infer<typeof leadBusinessInfo>
export type LeadAddressInfo = z.infer<typeof leadAddressInfo>
export type LeadManagementInfo = z.infer<typeof leadManagementInfo>
