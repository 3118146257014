import React from 'react'
import { IconProps } from '../../../common/types'

const EmptyCheckMarkIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9233 10.0086C17.9233 14.1459 14.5702 17.4996 10.4343 17.4996C6.29836 17.4996 2.94531 14.1459 2.94531 10.0086C2.94531 5.8713 6.29836 2.51758 10.4343 2.51758C14.5702 2.51758 17.9233 5.8713 17.9233 10.0086Z"
        stroke="#999696"
      />
    </svg>
  )
}

export default EmptyCheckMarkIcon
