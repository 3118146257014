import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import apiUrl from '../../../../apis/api.url'
import CommonNotes from '../../../../components/app/notes/index'
import { checkDetailPermission } from '../../permission'

export default function ContactNotes() {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
  }>()

  useEffect(() => {
    setActiveTab('CONTACT_DETAILS_NOTES')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CommonNotes
      moduleUrl={apiUrl.CONTACT_LIST_URL}
      urlalter={true}
      checkModulePermission={checkDetailPermission}
    />
  )
}
