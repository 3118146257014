import { QbsTable } from 'qbs-react-grid'
import { useEffect } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'
import { useGetQuoteActivityLogList } from '../../api'
import { getColumns } from './servicesColumns'

const QuoteActivityLogs = () => {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack?: () => void
    detailsData?: any
  }>()

  const params = useParams()

  useEffect(
    () => {
      setActiveTab('QUOTE_DETAILS_ACTIVITY_LOGS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { data, isFetching } = useGetQuoteActivityLogList(params?.id ?? '', {
    type: 'quote_document_sent',
  })

  // const onViewAction = (r: any) => {
  //   return `${router_config.SERVICE_AND_ORDERS_BY_SERVICE.path}/${r?.id}/summary`
  // }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data ?? []}
          dataRowKey="id"
          toolbar={true}
          search={true}
          isLoading={isFetching}
          columns={getColumns() as any}
          columnToggle
        />
      </div>
    </>
  )
}

export default QuoteActivityLogs
