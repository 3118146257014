import React from 'react'
import { IconProps } from '../../../common/types'

const Collectables: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M6.66658 3.33333H4.99992C4.07944 3.33333 3.33325 4.07953 3.33325 5V15C3.33325 15.9205 4.07944 16.6667 4.99992 16.6667H14.9999C15.9204 16.6667 16.6666 15.9205 16.6666 15V5C16.6666 4.07953 15.9204 3.33333 14.9999 3.33333H13.3333M9.99992 2.5V9.16667M9.99992 9.16667L12.4999 6.66667M9.99992 9.16667L7.49992 6.66667M3.33325 10.8333H5.48807C5.70909 10.8333 5.92105 10.9211 6.07733 11.0774L8.08917 13.0893C8.24545 13.2455 8.45742 13.3333 8.67843 13.3333H11.3214C11.5424 13.3333 11.7544 13.2455 11.9107 13.0893L13.9225 11.0774C14.0788 10.9211 14.2907 10.8333 14.5118 10.8333H16.6666"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Collectables
