import { Avatar, Divider } from '@mui/material'
import { AutoComplete } from 'qbs-core'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { getAllAccounts } from '../../../apis/common.apis'
import CustomDrawer from '../../../components/common/drawer'
import Icons from '../../../components/common/icons'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { getErrorMessage } from '../../../utilities/parsers'
import { linkAccountToContact } from '../../accounts/api'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback?: (data?: any) => void
  parentParams?: any
}

export default function LinkAccount({
  isDrawerOpen,
  handleClose,
  handleCallback,
}: Props) {
  const [accountPage, setAccountPage] = useState(1)
  const [data, setData] = useState<any>(null)
  const params = useParams()
  const handleClearAndClose = () => {
    setData(null)
    handleClose()
  }
  const getData = async (value?: string, page?: number) => {
    const { data } = await getAllAccounts({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setAccountPage(data?.next ? accountPage + 1 : 0)
    return data?.results?.map((item: any) => ({
      ...item,
      name: item?.business_name,
    }))
  }
  const handleChange = (value: any) => {
    const valid = value?.id ? value : null
    setData(valid)
  }
  const { enqueueSnackbar } = useSnackbarManager()
  const onSubmit = () => {
    const input = { account_id: data?.id }
    linkAccountToContact(params.id as string, input)
      .then(() => {
        enqueueSnackbar('Account linked successfully', { variant: 'success' })
        handleClose()
        handleCallback?.()
        setData(null)
      })
      .catch((err) => {
        enqueueSnackbar(getErrorMessage(err?.response?.data?.error), {
          variant: 'error',
        })
      })
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      title="Link Account "
      handleSubmit={() => onSubmit()}
      disableSubmit={!data}
    >
      <div className="flex flex-col gap-4">
        <AutoComplete
          name="Account Name"
          desc="name"
          type="auto_complete"
          onChange={(value) => handleChange(value)}
          getData={getData}
          paginationEnabled
          nextBlock={accountPage}
          label="Account Name"
          async={true}
          placeholder="Account Name"
          descId={'id'}
        />
        {data && (
          <div className=" bg-cardWrapperBg p-5 flex flex-col gap-4 ">
            {data?.business_name && (
              <>
                <div className="flex flex-col">
                  <p className=" text-primaryText text-base font-bold">
                    {data?.business_name}
                  </p>
                  <p className=" text-linkBlue text-sm flex items-center gap-1">
                    <span className="text-secondary text-xs leading-4 font-semibold">
                      Contact:
                    </span>
                    Primary Contact{' '}
                  </p>
                </div>

                <Divider className="m-0" />
              </>
            )}
            {(data?.email || data?.mobile) && (
              <>
                <div className="flex flex-col gap-2">
                  <p className="text-secondary mb-1 text-xs leading-4 font-semibold">
                    Communication
                  </p>
                  <div className="flex items-center gap-1">
                    {data?.email && (
                      <>
                        <Icons name="email" className="iconWidthXs iconGrey" />
                        <span className=" text-primaryText text-sm font-medium">
                          {data?.email}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="flex items-center gap-1">
                    {data?.mobile && (
                      <>
                        <Icons name="phone" className="iconWidthXs iconGrey" />
                        <span className=" text-primaryText text-sm font-medium">
                          {data?.mobile}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <Divider className="m-0" />
              </>
            )}
            {data?.primary_agent?.name && (
              <div className="">
                <p className="text-secondary mb-3 text-xs leading-4 font-semibold">
                  Primary Agent
                </p>
                <div className="flex items-center gap-1.5 min-h-[30px] h-auto px-2.5 py-[3px] rounded-3xl bg-[#E9E9E9] w-fit">
                  <Avatar
                    className="flex-[16px 16px]"
                    sx={{ width: 16, height: 16 }}
                  />
                  <p className=" text-secondary normal-case text-sm font-medium">
                    {data?.primary_agent?.name}
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </CustomDrawer>
  )
}
