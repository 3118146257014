import { QbsTable } from 'qbs-react-grid'
import { useCallback, useEffect, useState } from 'react'

import InfoBox from '../../components/app/alertBox/infoBox'
import { Button, DialogModal } from '../../components/common'
import Icons from '../../components/common/icons'
import ListingHeader from '../../components/common/ListingTiles/index'
import ExportComponent from '../../components/app/export/index'
import { useSnackbarManager } from '../../components/common/snackbar'
import { calcWindowHeight } from '../../utilities/calcHeight'
// import { statusClassGen } from '../../utilities/generators'
import { getErrorMessage, getSortedColumnName } from '../../utilities/parsers'
import { deletePayout, getPayountDetails, usePayouts } from './api'
import AddPayouts from './create/index'
import { getColumns } from './payoutsColumns'
import { GetFilterDetails } from './filterProperties'
import moment from 'moment'
import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
import { usePayoutFilterStore } from '../../store/filterSore/payoutStore'
import { router_config } from '../../configs/route.config'
import {
  TABlE_COLUMNS,
  useTablePreference,
} from '../../utilities/tableprefrences'
// import { useNavigate } from 'react-router-dom'
import { checkPermission } from './permission'

const PayoutListing = () => {
  const { enqueueSnackbar } = useSnackbarManager()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openExport, setExportOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<any>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [viewMode, setViewMode] = useState(false)
  const [activeSelection, setActiveSelection] = useState<any>(null)
  const [status_code, setStatus_code] = useState(undefined)
  const { getSavedColumns, handleSaveColumns } = useTablePreference()

  const {
    pageParams,
    setPageParams,
    selectedRows,
    setSelectedRows,
    advanceFilter,
    setAdvanceFilter,
  } = usePayoutFilterStore()

  const { page, page_size, search, ordering, filters, filterProps } = pageParams
  const [showDetail, setShowDetail] = useState(true)

  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
    status_code: status_code,
    ...filters,
  }
  const { data, refetch, isLaoding, isFetching } = usePayouts({
    input: { ...searchParams },
    status_code: status_code,
  })

  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }

  const handleColumnToggle = async (columns: any) => {
    handleSaveColumns(columns, TABlE_COLUMNS.PAYOUT)
  }

  const basicData = {
    title: 'Payouts',
    icon: 'user',
  }

  const handleClose = () => {
    setExportOpen(false)
  }

  const filterDetails = GetFilterDetails()

  const handleFilterChange = (dta: any, name: string, id: string) => {
    const currentParams = usePayoutFilterStore.getState()?.pageParams || {}

    const getDefaultFilters = () => ({
      ...currentParams.filters,
      [id]: dta?.id ?? undefined,
    })

    const getDefaultFilterProps = () => ({
      ...currentParams.filterProps,
      [name]: dta[name] ?? undefined,
    })
    const filters = getDefaultFilters()
    const filterProps = getDefaultFilterProps()

    setPageParams({
      ...currentParams,
      page: 1,
      filters,
      filterProps,
    })
  }

  useEffect(() => {
    if (
      filterProps.endDate ||
      (filterProps.endDate === null && filterProps.startDate === null)
    ) {
      handleDatepicker()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  const handleDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.startDate
          ? moment(filterProps?.startDate).format('DD-MM-YYYY')
          : '',
        toDate: filterProps?.endDate
          ? moment(filterProps.endDate).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.endDate])

  useEffect(() => {
    if (
      filterProps.created_to_date ||
      (filterProps.created_to_date === null &&
        filterProps.created_from_date === null)
    ) {
      handleInitiatedDatepicker()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.created_to_date])

  const handleInitiatedDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        created_from_date: filterProps?.created_from_date
          ? moment(filterProps?.created_from_date).format('DD-MM-YYYY')
          : '',
        created_to_date: filterProps?.created_to_date
          ? moment(filterProps.created_to_date).format('DD-MM-YYYY')
          : '',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterProps.created_to_date])

  const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
    setPageParams({
      ...pageParams,
      filterProps: {
        ...filterProps,
        [desc]: event?.value[0],
        [desc2]: event?.value[1],
      },
    })
  }

  const manageManagedList = (code: any) => {
    setStatus_code(code)
    setPageParams({
      ...pageParams,
      page: 1,
    })
  }

  const handleSHowHide = () => {
    return advanceFilter?.filterParams?.find((item) => item.isChecked)
      ? true
      : false
  }

  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }

  const handleOnSlect = (rows: any) => {
    setSelectedRows([...rows])
  }

  const handleDelete = () => {
    deletePayout(activeRow.id)
      .then(() => {
        enqueueSnackbar('Deleted successfully', {
          variant: 'success',
        })
        refetch()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error.message ??
              error?.response?.data?.errors.message
          ),
          {
            variant: 'error',
          }
        )
      })
    handleDeleteClose()
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }

  // const navigate = useNavigate()

  const onViewAction = (rowData: any, from?: string) => {
    //   const { data } = await fetchtaskDetails(rowData.id)
    if (from == 'view_service') {
      if (rowData?.service?.id) {
        return `${router_config.SERVICE_AND_ORDERS_BY_SERVICE.path}/${rowData?.service?.id}/summary`
      }
    } else if (from == 'view_account') {
      if (rowData?.account?.id) {
        return `${router_config.ACCOUNTS.path}/${rowData?.account?.id}`
      }
    } else {
      setActiveSelection(rowData)
      setViewMode(true)
      setOpenDrawer(true)
    }
  }

  const handleCloseDrawer = () => {
    setOpenDrawer(false)
    setViewMode(false)
    setActiveRow(null)
    setActiveSelection(null)
    setEdit(false)
  }
  const handleDeleteOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }
  // const handleOpenDrawer = () => {
  //   setOpenDrawer(true)
  // }

  const handleEdit = (id: string) => {
    if (id) {
      getPayountDetails(id).then((res) => {
        setActiveSelection(res?.data)
        setEdit(true)
        setOpenDrawer(true)
      })
    }
  }

  const [columns, setColumns] = useState<any>([])

  useEffect(() => {
    setColumns(
      getSavedColumns(
        getColumns({
          onViewAction: onViewAction,
        }),
        TABlE_COLUMNS.PAYOUT
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
      }),
    ])
    handleSaveColumns(
      getColumns({
        onViewAction: onViewAction,
      }),
      TABlE_COLUMNS.PAYOUT
    )
  }

  return (
    <div className="con">
      <ListingHeader
        data={basicData}
        setGroupNameCode={manageManagedList}
        onActionClick={() => {
          setOpenDrawer(true)
        }}
        permissions={{ export: 'export_paymentout' }}
        checkPermission={checkPermission}
        actionProps={{ hideExport: data?.results?.length > 0 ? false : true }}
        onHandleExport={() => setExportOpen(true)}
      />

      <div className=" p-4">
        <QbsTable
          // columns={
          //   getColumns({
          //     onViewAction: onViewAction,
          //     statusClassGen: statusClassGen,
          //   }) as any
          // }
          columns={columns}
          dataRowKey="id"
          isLoading={isLaoding || isFetching}
          toolbar
          search
          selectedRows={selectedRows}
          data={data?.results ?? []}
          sortType={pageParams.sortType}
          height={calcWindowHeight(303)}
          tableBodyHeight="calc(100vh - 500px)"
          sortColumn={pageParams.sortColumn}
          handleColumnToggle={handleColumnToggle}
          handleResetColumns={() => handleResetColums()}
          handleColumnSort={handleSort}
          onSelect={handleOnSlect}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
              ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
              : [10, 20, 30, 50],
          }}
          primaryFilter={
            <PrimaryFilterComponent
              filterParams={filterDetails}
              handleFilterChange={handleFilterChange}
              filterProps={filterProps}
              advanceFilter={advanceFilter}
              handleFilterDateChange={handleFilterDateChange}
              isPrimary={true}
              setAdvanceFilter={setAdvanceFilter}
            />
          }
          advancefilter={
            showDetail && (
              <PrimaryFilterComponent
                filterParams={filterDetails}
                handleFilterDateChange={handleFilterDateChange}
                handleFilterChange={handleFilterChange}
                filterProps={filterProps}
                isPrimary={false}
                advanceFilter={advanceFilter}
              />
            )
          }
          actionProps={[
            {
              title: 'View',
              action: (rowData) => onViewAction(rowData),
              icon: <Icons name="eye" />,
              toolTip: 'View Payout',
              hidden: !checkPermission('view'),
            },
            {
              title: 'Edit',
              action: (row: any) => {
                handleEdit(row?.id)
              },
              icon: <Icons name="edit" />,
              hidden: !checkPermission('change'),
              hide: (row) => (row.franchisee ? false : true),
              toolTip: 'Edit',
            },
            {
              title: 'Delete Payout',
              action: (row: any) => {
                handleDeleteOpen(row)
              },
              icon: <Icons name="delete" />,
              hidden: !checkPermission('delete'),
              hide: (row) => (row.franchisee ? false : true),
              toolTip: 'Delete Payout',
            },
          ]}
          tableHeaderActions={
            <div className="flex gap-2">
              {handleSHowHide() && (
                <Button
                  onClick={() => {
                    setShowDetail(!showDetail)
                  }}
                  label={showDetail ? 'Hide' : 'Show'}
                  className="se"
                  primary
                />
              )}
            </div>
          }
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          columnToggle
        />
      </div>
      <ExportComponent
        isOpen={openExport}
        handleClose={handleClose}
        slug={'PAYOUT_EXPORT'}
        pageParams={searchParams}
      />
      <AddPayouts
        isDrawerOpen={openDrawer}
        rowData={activeSelection}
        setViewMode={setViewMode}
        viewMode={viewMode}
        edit={edit}
        subSection={true}
        handleClose={() => handleCloseDrawer()}
        handleCallback={refetch}
      />
      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleDeleteClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={<InfoBox content={'Are you sure you want to delete?'} />}
      />
    </div>
  )
}

export default PayoutListing
