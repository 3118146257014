import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { getService } from '../../../apis/common.apis'
import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import { useAuthStore } from '../../../store/authStore'
import { useServiceAllocated } from '../api'
import { allocatedSchema, AllocatedSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback: () => void
  id?: string
  datas: any
  availableAllocation?: any
}

export default function Create({
  isDrawerOpen,
  handleClose,
  handleCallback,
  id,
  datas,
  availableAllocation,
}: Props) {
  const [servicePage, setServicePage] = useState(1)
  const { userData } = useAuthStore()
  const todayDate = moment().toDate()
  const formatedDate = moment(todayDate).format('DD-MM-YYYY')
  const onInit = () => {
    return {
      service_name: datas?.service_name,
      order_item_id: datas?.id,
      service_total: datas?.total_price,
      allocated_amount: datas?.service_allocated_amount ?? '0.00',
      remaining_amount: datas?.balance_amount_to_allocate,
      allocated_by: userData?.name,
      allocated_on: formatedDate,
      available_allocation: availableAllocation,
    }
  }

  const methods = useForm<AllocatedSchema>({
    resolver: zodResolver(allocatedSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  const { allocation_amount } = methods.watch()
  const calcBalance =
    allocation_amount && allocation_amount > 0
      ? datas?.balance_amount_to_allocate - allocation_amount
      : parseFloat(datas?.balance_amount_to_allocate)

  useEffect(() => {
    methods.setValue(
      'remaining_amount',
      calcBalance ? calcBalance?.toFixed(2).toString() : '0.00'
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allocation_amount])

  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas])

  const getServiceList = async (search?: string, page?: number) => {
    const { data } = await getService({
      search: search as string,
      page: page as number,
    })
    const next = data?.next ? servicePage + 1 : 0
    setServicePage(next)

    return data?.results
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text',
    disabled = false
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    ...(disabled ? { disabled: true } : {}),
  })

  const formBuilderProps = [
    {
      name: 'service_name',
      label: 'Service Name',
      required: false,
      getData: getServiceList,
      async: true,
      id: 'order_item_id',
      paginationEnabled: true,
      nextBlock: servicePage,
      descId: 'id',
      initialLoad: true,
      disabled: true,
      desc: 'service_name',
      type: 'auto_complete',
      placeholder: 'Enter Service Name',
      value: datas?.id,
    },
    textField(
      'service_total',
      'Service Total',
      'Enter Service Total',
      false,
      'number',
      true
    ),
    textField(
      'allocated_amount',
      'Amount Allocated So Far',
      'Enter Amount Allocated',
      false,
      'number',
      true
    ),
    textField(
      'available_allocation',
      'Available For Allocation',
      'Available For Allocation',
      false,
      'text',
      true
    ),
    textField(
      'allocation_amount',
      'Allocation Amount',
      'Enter Allocation Amount',
      true,
      'number'
    ),
    textField(
      'allocation_note',
      'Allocation Notes',
      'Enter Allocation Notes',
      false,
      'textarea',
      false
    ),
    textField(
      'remaining_amount',
      'Balance Amount (After Allocation)',
      'Enter Balance Amount',
      false,
      'text',
      true
    ),
    textField(
      'allocated_by',
      'Allocated By',
      'Enter Allocated By',
      false,
      'text',
      true
    ),
    textField(
      'allocated_on',
      'Allocated On',
      'Enter Allocated On',
      false,
      'text',
      true
    ),
  ]

  const handleSubmission = () => {
    methods.reset()
    handleCallback()
    handleClose()
  }

  const { mutate, isLoading } = useServiceAllocated(handleSubmission)
  const onSubmit = (data: AllocatedSchema) => {
    const input = {
      allocated_amount: data?.allocation_amount ?? '',
      allocation_note: data?.allocation_note ?? '',
      order_item_id: data?.order_item_id ?? datas?.id,
    }
    mutate({ input: input, id: id ? id : '' })
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      actionLoader={isLoading}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      title="Allocate Payment"
      disableSubmit={isLoading}
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
