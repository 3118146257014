import { z } from 'zod'

export const activitySchema = z.object({
  stakeholder_id: z.string().optional().nullable(),
  stakeholder_name: z.string().min(1, 'Required'),

  task_id: z.string().min(1, 'Required'),
  task_name: z.string().optional().nullable(),
  days_for_completion: z
    .number({ invalid_type_error: 'Days for completion is required.' })
    .min(1, 'Days for completion is required.')
    .optional(),
  remarks: z.string().optional().nullable(),

  show_for_customer: z.string().min(1, 'Required'),

  show_for_customer_name: z.string().min(1, 'Required'),

  requires_client_confirmation: z
    .union([z.boolean(), z.null()])
    .refine((val) => val !== null, { message: 'Required' }),
  requires_stakeholder_confirmation: z
    .union([z.boolean(), z.null()])
    .refine((val) => val !== null, { message: 'Required' }),
  requires_client_approval: z
    .union([z.boolean(), z.null()])
    .refine((val) => val !== null, { message: 'Required' }),
  requires_stakeholder_approval: z
    .union([z.boolean(), z.null()])
    .refine((val) => val !== null, { message: 'Required' }),

  collectibles: z
    .object({ id: z.string().optional(), name: z.string().optional() })
    .array()
    .optional()
    .nullable(),

  deliverables: z
    .object({ id: z.string().optional(), name: z.string().optional() })
    .array()
    .optional()
    .nullable(),

  assignee_id: z.string().optional().nullable(),
  assignee_name: z.string().optional().nullable(),
})

export type ActivitySchema = z.infer<typeof activitySchema>
