import React from 'react'
import { IconProps } from '../../../common/types'

const MyTaskIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M9.23359 4.26665H7.056C5.85336 4.26665 4.87842 5.2619 4.87842 6.48961V19.8273C4.87842 21.055 5.85336 22.0503 7.056 22.0503H17.9439C19.1466 22.0503 20.1215 21.055 20.1215 19.8273V6.48961C20.1215 5.2619 19.1466 4.26665 17.9439 4.26665H15.7663M9.23359 4.26665C9.23359 5.49436 10.2085 6.48961 11.4112 6.48961H13.5888C14.7914 6.48961 15.7663 5.49436 15.7663 4.26665M9.23359 4.26665C9.23359 3.03895 10.2085 2.0437 11.4112 2.0437H13.5888C14.7914 2.0437 15.7663 3.03895 15.7663 4.26665M12.5 12.047H15.7663M12.5 16.4929H15.7663M9.23359 12.047H9.24448M9.23359 16.4929H9.24448"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default MyTaskIcon
