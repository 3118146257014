import { useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

export const fetchSupplierCategoryData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.SUPPLIER_CATEGORIES, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useSupplierCategories = (input: QueryParams) => {
  return useQuery(
    ['SupplierCategories', input],
    () => fetchSupplierCategoryData(input),
    {
      refetchOnWindowFocus: false,
    }
  )
}

export const deleteSupplierCategories = (id: any) => {
  return deleteData(`${apiUrl.SUPPLIER_CATEGORIES}/${id}`)
}

export const getSupplierCategoriesDetails = (id: any) => {
  return getData(`${apiUrl.SUPPLIER_CATEGORIES}/${id}/`)
}

export const addSupplierCategories = (data: any) => {
  return postData(`${apiUrl.SUPPLIER_CATEGORIES}`, data)
}

export const updateSupplierCategoriess = (id: string, data: any) => {
  return updateData(`${apiUrl.SUPPLIER_CATEGORIES}/${id}`, data)
}
