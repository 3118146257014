import React from 'react'

type ListingProps = {
  data: any[]
}

const MergePreview: React.FC<ListingProps> = ({ data }) => {
  return (
    <>
      {data?.map((item) => (
        <div
          key={item.id}
          className=" bg-white shadow-previewCard rounded-lg p-4 flex flex-row flex-wrap items-end"
        >
          <div className="flex gap-3 items-start flex-1">
            <div className="flex flex-col gap-1">
              <p className="text-sm font-bold text-primaryText">{item.item1}</p>
              <p className="text-sm font-normal text-primaryText">
                {`${item.item2} ${item.item2_sub ? `, ${item.item2_sub}` : ''}`}
              </p>
              <p className="text-sm font-normal text-primaryText">
                {`${item.item3} ${item.item3_sub ? `, ${item.item3_sub}` : ''}`}
              </p>
              <p className="text-sm font-normal text-primaryText">
                {item.item4}
              </p>
              <p className="text-sm font-normal text-primaryText">
                {item.item5}
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default MergePreview
