import React from 'react'

import { IconProps } from '../../../common/types'

const Prospect: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.93121 15.4521L3.24479 18.1386C2.70262 18.6807 2.70262 19.5598 3.24479 20.1019L3.4255 20.2827C3.96767 20.8248 4.8467 20.8248 5.38886 20.2827L8.07529 17.5962"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M8.95615 10.047C10.1308 8.87233 12.0354 8.87233 13.2101 10.047V10.047C14.3848 11.2217 14.3848 13.1263 13.2101 14.301L12.4803 15.0308C12.2509 15.2602 11.8788 15.2602 11.6493 15.0308L8.22638 11.6079C7.99689 11.3784 7.99689 11.0063 8.22638 10.7768L8.95615 10.047Z"
        stroke="currentColor"
      />
      <circle
        cx="14.6002"
        cy="8.92714"
        r="1.64642"
        transform="rotate(45 14.6002 8.92714)"
        stroke="currentColor"
      />
      <circle
        cx="12.5273"
        cy="11"
        r="7.72033"
        transform="rotate(45 12.5273 11)"
        stroke="currentColor"
        strokeWidth="1.2"
      />
    </svg>
  )
}

export default Prospect
