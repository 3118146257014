import { useEffect, useState } from 'react'
import { Outlet, generatePath, useNavigate, useParams } from 'react-router-dom'

import { TabItemProps } from '../../../../../common/types'
import { TabContainer } from '../../../../../components/common'
import DetailHeader from '../../../../../components/common/DetailTiles'
import { blockActionsbyStatus } from '../../../../../configs/permissionGate'
import { router_config } from '../../../../../configs/route.config'
import { checkPermission } from '../../../permission'
import { getRoleDetails } from './api'
let updateKey = 1
const VendorRoleDetails = () => {
  const navigate = useNavigate()
  const [edit] = useState(false)
  const [data, setData] = useState<any>()
  const [isFetching, setIsFetching] = useState(false)
  const params = useParams()
  const tabData = [
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_ROLE_SUMMARY.label,
      id: 'SYSTEM_SETTINGS_VENDOR_ROLE_SUMMARY',
    },
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_ROLE_PERMISIIONS.label,
      id: 'SYSTEM_SETTINGS_VENDOR_ROLE_PERMISIIONS',
    },
  ]
  const [activeTab, setActiveTab] = useState<string>(
    'SYSTEM_SETTINGS_VENDOR_ROLE_SUMMARY'
  )

  const haandleTabChange = (selected: TabItemProps) => {
    navigate(
      generatePath(router_config[selected.id]?.path as string, {
        id: params.id,
      })
    )
  }

  useEffect(() => {
    if (params.id && params.id2) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.id2])

  const getData = async () => {
    setIsFetching(true)
    getRoleDetails(params.id, params.id2)
      .then((res) => {
        // console.log(res)
        setData(res?.data)
        updateKey = updateKey + 1
        setIsFetching(false)
      })
      .catch(() => {
        setIsFetching(false)
      })
  }

  const basicData = {
    title: `${data?.name ?? '--'} `,
  }

  const contextData = {
    setActiveTab: (id: string) => setActiveTab(id),
    edit: edit,
    isFetching: isFetching,
    roleData: data,
    permissions: data?.permissions || [],
    handleCallBack: getData,
  }
  const handleDisable = () => {
    return (
      blockActionsbyStatus(data?.order_status?.code) ||
      !checkPermission('change')
    )
  }
  return (
    <div>
      <div>
        <DetailHeader disabled={handleDisable()} data={basicData} />
        {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      </div>
      <div className="flex gap-4">
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          onClick={haandleTabChange}
        >
          <Outlet context={contextData} key={updateKey} />
        </TabContainer>
      </div>
    </div>
  )
}

export default VendorRoleDetails
