import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { router_config } from '../../../../configs/route.config'
import { useVendorEmployeeFilterStore } from '../../../../store/filterSore/vendorEmployeeStore'
import {
  activateVendorEmployees,
  deactivateVendorEmployees,
  useVendorEmployee,
} from '../../api'
import ChangeVendorEmployeeRole from './changeRole'
import AddEmployee from './create'
import { getColumns } from './employeesColumns'

const VendorEmployees = () => {
  const { pageParams, setPageParams } = useVendorEmployeeFilterStore()
  const { page, page_size, search, ordering } = pageParams
  const params = useParams()
  const [activeRow, setActiveRow] = useState<any>()
  const [employeeOpen, setEmployeeOpen] = useState(false)
  const [openRole, setOpenRole] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const [changeStatusOpen, setStatusOpen] = useState(false)
  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }

  const { data, refetch, isFetching } = useVendorEmployee(
    params.id as string,
    searchParams
  )

  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, refetch])

  const onViewAction = (r: any, type: string) => {
    console.log(r)
    if (type === 'employee') {
      return `${router_config.SYSTEM_SETTINGS_VENDORS.path}/${params.id}/${router_config.SYSTEM_SETTINGS_VENDOR_EMPLOYEES.path}/${r?.id}/basic-info`
    } else if (type === 'role') {
      return `${router_config.SYSTEM_SETTINGS_VENDORS.path}/${params.id}/${router_config.SYSTEM_SETTINGS_VENDOR_ROLE.path}/${r?.group?.id}/basic-info`
    }
  }

  // const handleResetColums = () => {
  //   setColumns([
  //     ...getColumns({
  //       onViewAction: onViewAction,
  //     }),
  //   ])
  // }
  const manageStatus = () =>
    activeRow?.status
      ? deactivateVendorEmployees(params.id, activeRow?.id)
      : activateVendorEmployees(params.id, activeRow?.id)

  const handleStatusChange = () => {
    manageStatus()
      .then((res) => {
        enqueueSnackbar(
          res.message ? res.message : 'Status updated successfully',
          {
            variant: 'success',
          }
        )

        setStatusOpen(false)
        setActiveRow(null)
        refetch()
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }
  const { setActiveTab, handleParentCallback } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
  }>()

  useEffect(() => {
    setActiveTab('SYSTEM_SETTINGS_VENDOR_EMPLOYEES')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRefresh = () => {
    refetch()
    handleParentCallback?.()
  }

  const handleSearch = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }

  const handleAction = () => {
    setEmployeeOpen(true)
  }

  const handleRoleOpen = (row: any) => {
    setActiveRow(row)
    setOpenRole(true)
  }
  const handleRoleClose = () => {
    setOpenRole(false)
    setActiveRow(null)
  }

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  const handleStatusChangeClose = () => {
    setStatusOpen(false)
    setActiveRow(null)
  }
  const handleStatusChangeOpen = (row: any) => {
    setActiveRow(row)
    setStatusOpen(true)
  }
  return (
    <div>
      <QbsTable
        columns={getColumns({ onViewAction: onViewAction })}
        data={data?.data?.results ?? []}
        toolbar
        sortColumn=""
        search
        searchValue={pageParams?.search}
        onSearch={handleSearch}
        asyncSearch
        handleSearchValue={(key?: string) => handleSearch(key)}
        isLoading={isFetching}
        sortType="asc"
        // handleResetColumns={() => handleResetColums()}
        tableHeaderActions={
          <div className="flex gap-2">
            <Button onClick={handleAction} label={'Add New'} icon="plus" />
          </div>
        }
        pagination
        paginationProps={{
          onPagination: onChangePage,
          total: data?.data?.count,
          currentPage: pageParams?.page,
          rowsPerPage: Number(pageParams.page_size ?? data?.data?.page_size),
          onRowsPerPage: onChangeRowsPerPage,
          dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
            ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
            : [10, 20, 30, 50],
        }}
        actionProps={[
          {
            icon: <Icons name="link" />,
            action: (row) => handleRoleOpen(row),
            title: 'Change Role',
            toolTip: 'Change Role',
          },
          {
            title: 'Activate',
            action: (rowData) => handleStatusChangeOpen(rowData),
            icon: <Icons name="activate-icon" />,
            toolTip: 'Activate',
            hide: (rowData) => rowData?.status,
          },
          {
            title: 'Deactivate',
            action: (rowData) => handleStatusChangeOpen(rowData),
            icon: <Icons name="deactivate-icon" />,
            toolTip: 'Deactivate',
            hide: (rowData) => !rowData?.status,
          },
        ]}
      />
      <DialogModal
        isOpen={changeStatusOpen}
        onClose={() => handleStatusChangeClose()}
        title={'Are you sure?'}
        onSubmit={() => handleStatusChange()}
        secondaryAction={() => handleStatusChangeClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={`Are you sure you want to ${
              activeRow?.status ? 'Activate' : 'Deactivate'
            }?`}
          />
        }
      />
      <AddEmployee
        isDrawerOpen={employeeOpen}
        handleClose={() => setEmployeeOpen(false)}
        handleCallback={employeeOpen ? handleRefresh : undefined}
        parentParams={{ id: params.id }}
      />
      <ChangeVendorEmployeeRole
        isOpen={openRole}
        data={activeRow}
        handleClose={handleRoleClose}
        vendorId={params?.id as string}
        handleCallback={handleRefresh}
        paramsId={activeRow?.id as string}
      />
    </div>
  )
}

export default VendorEmployees
