import moment from 'moment'
import { MODULES } from '../../../configs/scopes.config'
import { Button } from '../../common'
import Icons from '../../common/icons'
import { getParsedValue } from '../../common/inputs/FormFieldView'

type Props = {
  handleAction?: (data: any) => void
  handleComment?: (data: any) => void
  data?: any
  disabled?: boolean
  checkModulePermission?: (key: string, sub: string) => boolean
}

export default function NotesContainer({
  handleAction,
  handleComment,
  disabled = false,
  data,
  checkModulePermission,
}: Props) {
  return (
    <div className="flex items-center justify-between gap-2 group">
      <div className="flex items-start justify-start flex-1 gap-2">
        <div className="w-5 h-5 min-w-[20px] rounded-full overflow-hidden flex items-center justify-center bg-[#EDF1FD] text-[10px] leading-4 font-medium text-primary dark:text-white">
          {data?.created_by?.name?.split(' ')[0]?.[0] || ''}
          {data?.created_by?.name?.split(' ')[1]?.[0] || ''}
        </div>
        <div className="flex flex-col items-start">
          <p className=" text-sm text-primaryText dark:text-white font-bold mb-0.5">
            {data?.created_by?.name}
          </p>
          <div className="flex items-center gap-2 mb-1">
            <span className="text-xxs leading-4 font-semibold text-secondary dark:text-white">
              Note Created on
            </span>
            <span className="text-xxs leading-4  text-secondary dark:text-white">
              {data?.datetime_created
                ? moment(data?.datetime_created).format('DD-MM-YYYY')
                : '--'}
            </span>
          </div>
          <p className="text-xxs leading-4 mb-2 dark:text-white text-secondary whitespace-pre">
            {data?.note ? getParsedValue(data?.note) : ''}
          </p>
          <Button
            className="btn-secondary dark:text-white"
            outlined={true}
            size="xs"
            hidden={!checkModulePermission?.('add', MODULES.notecomment)}
            icon="chat"
            disabled={disabled}
            label="Comment"
            onClick={() => handleComment?.(data)}
          />
        </div>
      </div>
      <div className="flex items-center justify-center">
        {!disabled && checkModulePermission?.('change', MODULES.note) && (
          <div
            className=" cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity group-hover:transition-opacity"
            onClick={() => handleAction?.(data)}
          >
            <Icons name="edit" className="iconWidthSm iconGrey" />
          </div>
        )}
      </div>
    </div>
  )
}
