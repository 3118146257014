import { z } from 'zod'

export const adminCommunicationSchema = z
  .object({
    emails: z
      .array(
        z
          .string()
          .transform((val) => (val === '' ? null : val))
          .refine(
            (val) => val === null || z.string().email().safeParse(val).success,
            {
              message: 'Enter a valid email',
            }
          )

          .nullable()
      )
      .optional(),
  })
  .superRefine((emails, ctx) => {
    const seenEmails = new Set()
    emails?.emails?.forEach((email, index) => {
      if (seenEmails.has(email)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Duplicate emails are not allowed',
          path: ['emails', index],
        })
      } else {
        seenEmails.add(email)
      }
    })
  })

export type AdminCommunicationSchema = z.infer<typeof adminCommunicationSchema>
