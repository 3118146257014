const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}

export const getColumns = ({ onViewAction }: any) => {
  return [
    {
      title: 'Stakeholder Name',
      field: 'stakeholder_name',
      ...defaultColumnProps,
      colWidth: 300,
      // fixed: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'stakeholder_name'),
    },
    {
      title: 'Type',
      field: 'stakeholder_type',
      ...defaultColumnProps,
    },
  ]
}
