import { MODULES } from '../../../../configs/scopes.config'
import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'
import { checkDetailPermission } from '../../permission'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat

    if (isCustom === 'amount') {
      const propertyValue = getNestedProperty(row, key)
      const numbers =
        typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

      return {
        cell: format(numbers),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else {
      return {
        cell: getNestedProperty(row, key),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    {
      title: 'Allocation ID',
      field: 'unique_id',
      ...defaultColumnProps3,
      rowClick: (row: any) => onViewAction(row, 'allocateId'),
      link: checkDetailPermission('detail', MODULES.payment),
      fixed: true,
    },
    {
      title: 'Order Id',
      field: 'id',
      ...defaultColumnProps3,
      link: true,
      renderCell: createRenderCell('order_details.order_number'),
      getPath: (row: any) => onViewAction(row, 'orderId'),
      customCell: true,
    },
    {
      title: 'Account Name',
      renderCell: createRenderCell('order_details.account.business_name'),
      field: 'business_name',
      ...defaultColumnProps3,
      getPath: (row: any) => onViewAction(row, 'accountName'),
      link: true,
      customCell: true,
    },
    {
      title: 'Service Name',
      field: 'service_name',
      renderCell: createRenderCell('order_item_details.service_name'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Amount Allocated',
      field: 'allocated_amount',
      ...defaultColumnProps3,
    },
    {
      title: 'Allocation Date',
      field: 'datetime_created',
      type: 'date',
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Allocated By',
      field: 'created_by',
      ...defaultColumnProps3,
    },
    {
      title: 'Professional Fee',
      field: 'professional_fee',
      renderCell: createRenderCell('professional_fee', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Vendor Fee',
      field: 'vendor_fee',
      renderCell: createRenderCell('vendor_fee', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Govt Fee',
      field: 'govt_fee',
      renderCell: createRenderCell('govt_fee', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'GST Amount',
      field: 'gst_amount',
      renderCell: createRenderCell('gst_amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
  ]

  return column
}
