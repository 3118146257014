import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { getDealDetails } from '../../api'
import BasicInformation from './basicInformation'
import PricingInformation from './pricingInformation'

const ContactProfiles = () => {
  const params = useParams()

  const { setActiveTab, edit, disabled, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    edit?: boolean
    disabled: boolean
    detailsData: any
  }>()
  const [data, setData] = useState<any>()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  useEffect(
    () => {
      setActiveTab('DEAL_DETAILS_SUMMARY')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])
  useEffect(() => {
    setData(detailsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsData])

  const getData = async () => {
    const { data } = await getDealDetails(params.id)
    setData(data)
  }
  const handleRefresh = () => {
    getData()
  }
  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation
        data={data}
        edit={edit}
        disabled={isDisabled}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
      />
      <PricingInformation
        detailData={data}
        // id={params.id ?? ''}
        // handleRefresh={handleRefresh}
      />
    </div>
  )
}

export default ContactProfiles
