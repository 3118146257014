import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { checkDetailPermission } from '../../permission'

import TaskComponent from '../../../../components/app/task/index'
import { blockActionsbyStatus } from '../../../../configs/permissionGate'
const ServiceTasks = () => {
  const { setActiveTab, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    detailsData: any
  }>()
  const [detailData, setDetailData] = useState<any>()
  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_ORDERS_TASKS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setDetailData(detailsData)
  }, [detailsData])
  return (
    <TaskComponent
      model_name="Order"
      disabled={
        blockActionsbyStatus(detailData?.order_status?.code, [
          'cancelled',
          'order_cancelled',
          'rejected',
          'order_completed_payment_done',
        ]) && detailData.is_locked
      }
      checkModulePermission={checkDetailPermission}
    />
  )
}

export default ServiceTasks
