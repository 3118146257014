import React from 'react'
import { IconProps } from '../../../common/types'

const SendIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25189 7.8879L7.63186 7.88319M11.766 5.8231C14.1608 7.02047 14.1561 8.9768 11.766 10.1789L6.42975 12.847C2.84236 14.6431 1.37158 13.1723 3.16763 9.58491L3.95959 8.00099L3.16763 6.41707C1.37158 2.82969 2.83765 1.36362 6.42975 3.15495L11.766 5.8231Z"
        stroke="#999696"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SendIcon
