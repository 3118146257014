import { MODULES } from '../../configs/scopes.config'
import { checkPermissionAny } from '../../configs/permissionGate'

const module = MODULES.unallotted_lead
const module_lead = MODULES.lead
export const checkPermission = (slug: string) => {
  const permissionSlug = `${slug}_${module}`
  return checkPermissionAny(permissionSlug)
}
export const checkDetailPermission = (slug: string, subModule?: string) => {
  const permissionSlug = `${slug}_${module_lead}_${subModule}`
  return checkPermissionAny(permissionSlug)
}
