import { Link } from 'react-router-dom'
import { router_config } from '../../../../configs/route.config'
import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}

export const getColumns = ({ onViewAction, statusClassGen }: any) => {
  const createRenderCell =
    (key: string, customData = '') =>
    (row: any) => {
      const format = useCurrencyFormat
      switch (customData) {
        case 'Allocation':
          return {
            cell: (
              <div>{`${row.allotted_services_count} / ${row.total_services_count}`}</div>
            ),
            toolTip: getNestedProperty(row, key),
          }
        case 'services': {
          return {
            cell: (
              <>
                {row?.service?.slice(0, 3).map((item: any, index: number) => {
                  return (
                    <span key={item.id}>
                      <Link
                        to={`${router_config.SERVICE_AND_ORDERS_BY_SERVICE.path}/${item.id}/summary`}
                      >
                        {item.service_name}
                      </Link>
                      {index < 2 && row?.service.length - 1 !== index && ', '}
                    </span>
                  )
                })}
                {row?.service?.length > 3 && (
                  <span className=" p-1 bg-bgBadgeColor rounded-xl px-2 text-white ml-1">
                    +{row.service.length - 3}
                  </span>
                )}
              </>
            ),
            toolTip: getNestedProperty(row, key) ?? '',
          }
        }

        case 'amount':
          const propertyValue = getNestedProperty(row, key)
          const numbers =
            typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

          return {
            cell: format(numbers),
            toolTip: getNestedProperty(row, key) ?? '',
          }
        case 'status':
          return {
            cell: (
              <div className="text-capitalize">
                <span
                  className={`statusLabel  ${
                    row.status?.name
                      ? statusClassGen(row?.status?.name)
                      : 'Active'
                  }`}
                >
                  {row?.status?.name}
                </span>
              </div>
            ),
            toolTip: getNestedProperty(row, key),
          }
        default:
          return {
            cell: getNestedProperty(row, key),
            toolTip: getNestedProperty(row, key),
          }
      }
    }

  const column = [
    {
      title: 'Prospect ID',
      field: 'deal_number',
      ...defaultColumnProps,
    },
    {
      title: 'Account Name',
      field: 'name',
      renderCell: createRenderCell('account.business_name'),
      link: true,
      getPath: (row: any) => onViewAction(row, 'accountName'),
      ...defaultColumnProps,
    },
    {
      title: 'Contact Name',
      field: 'business_name',
      renderCell: createRenderCell('contact.name'),
      getPath: (row: any) => onViewAction(row, 'contactName'),
      link: true,
      customCell: true,
      ...defaultColumnProps,
    },
    {
      title: 'Services',
      field: 'services',
      renderCell: createRenderCell('service', 'services'),
      customCell: true,

      ...defaultColumnProps,
    },
    {
      title: 'Quote Value',
      field: 'amount',
      renderCell: createRenderCell('amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps,
    },
    {
      title: 'Prospect Status',
      field: 'name',
      renderCell: createRenderCell('status.name', 'status'),
      ...defaultColumnProps,
      customCell: true,
      // sortKey: 'order_status__name',
    },

    {
      title: 'Created By',
      field: 'name',
      renderCell: createRenderCell('created_by.name'),
      ...defaultColumnProps,
      customCell: true,
      // sortKey: 'created_by__user__first_name',
    },
    // {
    //   title: 'Created On',
    //   field: 'datetime_created',
    //   ...defaultColumnProps,
    // sortKey: 'datetime_created',
    // },
  ]

  return column
}
