import { useNavigate } from 'react-router-dom'

import CreateTask from '../components/app/task/create/index'
import { router_config } from '../configs/route.config'
import AccountCreate from '../pages/accounts/create/index'
import ContactCreate from '../pages/contacts/create/index'
import DealCreate from '../pages/deals/create/index'
import LeadCreate from '../pages/leads/create/index'
import OrderCreate from '../pages/order/create/index'
import QuoteCreate from '../pages/quote/create/index'
import ProspectCreate from '../pages/prospects/create'
import AddGovtFee from '../pages/payouts/create'

export default function SwitchEntities({ model, open, handleClose }: any) {
  const navigate = useNavigate()
  const handleDealCallback = (data: any) => {
    navigate(`${router_config.DEALS.path}/${data?.data.id}/summary`)
  }
  const handleProspectCallback = (data: any) => {
    navigate(`${router_config.PROSPECTS.path}/${data?.data.id}/summary`)
  }
  const handleOrderCallback = (data: any) => {
    navigate(
      `${router_config.SERVICE_AND_ORDERS_BY_ORDERS.path}/${data?.data?.id}/summary`
    )
  }
  const handleQuoteCallback = (data: any) => {
    navigate(`${router_config.QUOTES.path}/${data?.data?.id}/summary`)
  }
  switch (model) {
    case 'Lead':
      return (
        <LeadCreate
          isDrawerOpen={open}
          handleClose={handleClose}
          handleRefresh={() => console.log('df')}
        />
      )
    case 'Deal':
      return (
        <DealCreate
          isOpen={open}
          handleClose={handleClose}
          handleCallback={handleDealCallback}
        />
      )
    case 'Prospect':
      return (
        <ProspectCreate
          isOpen={open}
          handleClose={handleClose}
          handleCallback={handleProspectCallback}
        />
      )
    case 'Quote':
      return (
        <QuoteCreate
          isOpen={open}
          handleClose={handleClose}
          handleCallback={handleQuoteCallback}
        />
      )
    case 'CustomerCompany':
      return (
        <AccountCreate
          isDrawerOpen={open}
          handleClose={handleClose}
          handleRefresh={() => console.log('df')}
        />
      )
    case 'Customer':
      return (
        <ContactCreate
          isDrawerOpen={open}
          handleClose={handleClose}
          handleRefresh={() => console.log('df')}
        />
      )
    case 'Order':
      return (
        <OrderCreate
          isOpen={open}
          handleClose={handleClose}
          handleCallback={handleOrderCallback}
        />
      )
    case 'Task':
      return (
        <CreateTask
          isDrawerOpen={open}
          model_name={model}
          handleClose={() => handleClose()}
          isGeneral={true}
        />
      )
    case 'payout':
      return (
        <AddGovtFee
          isDrawerOpen={open}
          subSection={true}
          handleClose={() => handleClose()}
        />
      )

    default:
      return null
  }
}
