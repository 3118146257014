import { Divider } from '@mui/material'
import { AutoComplete } from 'qbs-core'
import { useState } from 'react'

import { getTeamList } from '../../../../apis/common.apis'
import CustomDrawer from '../../../../components/common/drawer'
import Icons from '../../../../components/common/icons'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { getErrorMessage } from '../../../../utilities/parsers'
import { assignTeam } from '../../api'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleCallback?: (data?: any) => void
  orderId: string
  serviceId?: string
}

export default function AssignTeam({
  isDrawerOpen,
  handleClose,
  handleCallback,
  orderId,
  serviceId,
}: Props) {
  const [employeePage, setEmployeePage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)

  const [data, setData] = useState<any>(null)
  const handleClearAndClose = () => {
    setData(null)
    handleClose()
  }
  const getData = async (value?: string, page?: number) => {
    const data = await getTeamList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })

    setEmployeePage(data?.next ? employeePage + 1 : 0)
    return data?.results?.map((item: any) => ({
      ...item,
    }))
  }
  const handleChange = (value: any) => {
    const valid = value?.id ? value : null
    setData(valid)
  }
  const { enqueueSnackbar } = useSnackbarManager()
  const onSubmit = () => {
    setIsLoading(true)
    const input = { team_id: data?.id }
    assignTeam(orderId as string, serviceId as string, input)
      .then(() => {
        enqueueSnackbar('Activity Assigned successfully', {
          variant: 'success',
        })
        handleClose()
        handleCallback?.()
        setData(null)
        setIsLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar(getErrorMessage(err?.response?.data?.error), {
          variant: 'error',
        })
        setIsLoading(false)
      })
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      title="Allocate Team "
      handleSubmit={() => onSubmit()}
      actionLoader={isLoading}
      disableSubmit={!data}
    >
      <div className="flex flex-col gap-4">
        <AutoComplete
          name="assignee"
          desc="name"
          type="auto_complete"
          onChange={(value) => handleChange(value)}
          getData={getData}
          paginationEnabled
          nextBlock={employeePage}
          label="Team Name"
          async={true}
          placeholder="Team Name"
          descId={'id'}
        />
        {data && (
          <div className=" bg-cardWrapperBg p-5 flex flex-col gap-4 ">
            {data?.name && (
              <>
                <div className="flex flex-col ">
                  <p className=" text-primaryText text-base font-bold mb-3">
                    {data?.name}
                  </p>
                  <p className="text-[#999696] mb-1.5 text-xs leading-4 font-semibold">
                    Team Lead Name
                  </p>
                  <p className=" text-primaryText text-sm font-bold mb-3">
                    {data?.lead?.name ?? '- -'}
                  </p>
                  <p className="text-[#999696] mb-1.5 text-xs leading-4 font-semibold ">
                    Role
                  </p>
                  <p className=" text-primaryText text-sm font-bold">
                    {data?.lead?.group?.name ?? '- -'}
                  </p>
                </div>

                <Divider className="m-0" />
              </>
            )}
            {(data?.lead?.user?.email || data?.user?.mobile) && (
              <>
                <div className="flex flex-col gap-2">
                  <p className="text-secondary mb-1 text-xs leading-4 font-semibold">
                    Communication
                  </p>
                  <div className="flex items-center gap-1">
                    {data?.lead?.user?.email && (
                      <>
                        <Icons name="email" className="iconWidthXs iconGrey" />
                        <span className=" text-primaryText text-sm font-medium">
                          {data?.lead?.user?.email}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="flex items-center gap-1">
                    {data?.lead?.user?.mobile && (
                      <>
                        <Icons name="phone" className="iconWidthXs iconGrey" />
                        <span className=" text-primaryText text-sm font-medium">
                          {data?.lead?.user?.mobile}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <Divider className="m-0" />
              </>
            )}
            {(data?.lead?.employee_profile?.department ||
              data?.lead?.employee_profile?.designation ||
              data?.lead?.employee_profile?.reports_to) && (
              <div className="flex flex-col ">
                <p className="text-secondary mb-3 text-xs leading-4 font-semibold">
                  Work Profile
                </p>
                {data?.lead?.employee_profile?.department && (
                  <>
                    <div className=" text-[#999696] text-xs leading-4 font-bold mb-1.5">
                      Department
                    </div>
                    <div className="font-bold text-sm text-primaryText mb-3">
                      {data?.lead?.employee_profile?.department?.name}
                    </div>
                  </>
                )}

                {data?.lead?.employee_profile?.designation && (
                  <>
                    <div className=" text-[#999696] text-xs leading-4 font-bold mb-1.5">
                      Designation
                    </div>
                    <div className="font-bold text-sm text-primaryText mb-3">
                      {data?.lead?.employee_profile?.designation?.name}
                    </div>
                  </>
                )}

                {data?.lead?.employee_profile?.reports_to && (
                  <>
                    <div className=" text-[#999696] text-xs leading-4 font-bold mb-1.5">
                      Reports To
                    </div>
                    <div className="font-bold text-sm text-primaryText mb-3">
                      {data?.lead?.employee_profile?.reports_to?.name}
                    </div>
                  </>
                )}
                {/* <Divider className="m-0" /> */}
              </div>
            )}

            {/* <div className="flex flex-col gap-2 leading-6">
              <p className="text-secondary mb-3 text-xs leading-4 font-semibold">
                Teams
              </p>
              <span className="  text-sm font-medium">
                {data?.employee_profile?.team?.map((item: any) => {
                  return <div key={item.id}> {item.name} </div>
                })}
              </span>
            </div> */}
          </div>
        )}
      </div>
    </CustomDrawer>
  )
}
