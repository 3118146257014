import React from 'react'
import { IconProps } from '../../../common/types'

const VerifySmallIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 8.00001L7.33333 9.33334L10 6.66667M13.7453 3.98956C13.6088 3.99648 13.4715 3.99998 13.3333 3.99998C11.2844 3.99998 9.41531 3.22969 7.99995 1.96289C6.58461 3.22964 4.71559 3.9999 2.66667 3.9999C2.52852 3.9999 2.39118 3.9964 2.25475 3.98948C2.08847 4.63189 2 5.30562 2 6.00001C2 9.72769 4.54955 12.8599 8 13.748C11.4505 12.8599 14 9.72769 14 6.00001C14 5.30564 13.9115 4.63194 13.7453 3.98956Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default VerifySmallIcon
