import { getNestedProperty, useCurrencyFormat } from '../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat
    switch (isCustom) {
      case 'amount':
        const propertyValue = getNestedProperty(row, key)
        const numbers =
          typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

        return {
          cell: numbers === 0 ? '--' : format(numbers),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      default:
        return {
          cell: getNestedProperty(row, key),
          toolTip: getNestedProperty(row, key) ?? '',
        }
    }
  }
  const column = [
    {
      title: 'Payout ID',
      field: 'unique_id',
      link: true,
      fixed: true,
      ...defaultColumnProps3,
      rowClick: (row: any) => onViewAction(row, 'payoutId'),
    },
    {
      title: 'Service ID',
      field: 'service.unique_id',
      link: true,
      ...defaultColumnProps3,
      getPath: (row: any) => onViewAction(row, 'view_service'),
      renderCell: createRenderCell('service.unique_id'),
      customCell: true,
    },
    {
      title: 'Payment Date ',
      field: 'payment_date',
      ...defaultColumnProps3,
    },
    {
      title: 'Amount',
      field: 'amount',
      ...defaultColumnProps3,
    },
    {
      title: 'Account',
      field: 'account.business_name',
      ...defaultColumnProps3,
      getPath: (row: any) => onViewAction(row, 'view_account'),
      renderCell: createRenderCell('account.business_name'),
      customCell: true,
    },
    {
      title: 'Supplier',
      field: 'supplier.name',
      ...defaultColumnProps3,
      renderCell: createRenderCell('supplier.name'),
      customCell: true,
    },
    {
      title: 'Supplier Category',
      field: 'supplier_category.name',
      ...defaultColumnProps3,
      renderCell: createRenderCell('supplier_category.name'),
      customCell: true,
    },
    {
      title: 'Payment Method ',
      field: 'payment_method',
      renderCell: createRenderCell('paymentout_method.name'),
      ...defaultColumnProps3,
      customCell: true,
    },
    {
      title: 'Payment Type',
      field: 'payment_type',
      ...defaultColumnProps3,
      renderCell: createRenderCell('paymentout_type.name'),
      customCell: true,
    },
    {
      title: 'Account Number',
      field: 'account_number',
      ...defaultColumnProps3,
    },
    {
      title: 'Bank Account Name',
      field: 'account_name',
      ...defaultColumnProps3,
    },
    {
      title: 'Account Type',
      field: 'account_type',
      ...defaultColumnProps3,
    },
    {
      title: 'Bank Name',
      field: 'bank_name',
      ...defaultColumnProps3,
    },
    {
      title: 'Branch Name',
      field: 'branch_name',
      ...defaultColumnProps3,
    },
    {
      title: 'Transaction Number',
      field: 'transaction_number',
      ...defaultColumnProps3,
    },
    {
      title: 'Receipt Number',
      field: 'receipt_number',
      ...defaultColumnProps3,
    },
    {
      title: 'Remarks',
      field: 'remark',
      ...defaultColumnProps3,
    },
    {
      title: 'Created By',
      field: 'created_by.name',
      ...defaultColumnProps3,
      renderCell: createRenderCell('created_by.name'),
      customCell: true,
    },
  ]

  return column
}
