import React from 'react'
import { IconProps } from '../../../common/types'

const Favourited: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9997 2C13.0247 2 14.6663 3.66667 14.6663 6C14.6663 10.6667 9.66634 13.3333 7.99967 14.3333C6.33301 13.3333 1.33301 10.6667 1.33301 6C1.33301 3.66667 2.99967 2 4.99967 2C6.23965 2 7.33301 2.66667 7.99967 3.33333C8.66634 2.66667 9.75967 2 10.9997 2Z"
        fill="#FF681D"
      />
    </svg>
  )
}

export default Favourited
