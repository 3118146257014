import React from 'react'
import { IconProps } from '../../../common/types'

const CheckboxCheck: React.FC<IconProps> = () => {
  return (
    <svg
      width="8"
      height="6"
      viewBox="0 0 8 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 3.21739L2.89883 6L8 1.06994L6.89494 0L2.89883 3.86768L1.09728 2.14745L0 3.21739Z"
        fill="white"
      />
    </svg>
  )
}

export default CheckboxCheck
