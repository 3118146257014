import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { handleError } from '../../../../utilities/commonUtilities'
import { getVendorDetails } from '../../api'
import { checkPermission } from '../../permission'
import BasicInformation from './basicInfo'
import AddressInformation from './addressInfo'
import ContactInformation from './contactInfo'
import { useAuthStore } from '../../../../store/authStore'

const VendorSummary = () => {
  const [data, setData] = useState<any>()
  const params = useParams()
  const [editFlag, setEditFlag] = useState(false)

  const { setActiveTab, handleParentCallback, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
    detailsData?: any
  }>()

  useEffect(() => {
    setActiveTab('SYSTEM_SETTINGS_VENDOR_SUMMARY')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setData(detailsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsData])
  const { franchisee, setFranchisee } = useAuthStore()
  const getData = async () => {
    getVendorDetails(params.id)
      .then((res) => {
        setData(res?.data)
        if (res?.data?.id === franchisee.id) {
          setFranchisee({
            ...franchisee,
            franchisee_name: res?.data?.franchisee_name,
          })
        }
      })
      .catch((err) => {
        handleError(err)
      })
  }
  const handleRefresh = () => {
    getData()
    handleParentCallback?.()
  }

  const handleEditButton = (flag: boolean) => {
    setEditFlag(flag)
  }
  const handlePermission = () => {
    return checkPermission('change')
  }
  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation
        data={{
          ...data,
        }}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        hasPermission={handlePermission()}
      />
      <AddressInformation
        data={{ ...data?.address?.[0] }}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        hasPermission={handlePermission()}
      />
      <ContactInformation
        data={{
          ...data,
        }}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        hasPermission={handlePermission()}
      />
    </div>
  )
}

export default VendorSummary
