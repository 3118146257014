import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { AdvanceFilter, StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'
import { isAllFranchisee } from '../../configs/permissionGate'

const defaultParams = [
  { title: 'Requested On', slug: 'requested_date', isChecked: false },
  { title: 'Requested By', slug: 'requested_by', isChecked: false },
  { title: 'Requested To', slug: 'requested_to', isChecked: false },
  { title: 'Reviewed On', slug: 'review_date', isChecked: false },
  { title: 'Target Date', slug: 'target_date', isChecked: false },
  ...(isAllFranchisee()
    ? [{ title: 'Franchisee', slug: 'franchisee_id', isChecked: false }]
    : []),
]

export const useMyPaymentApprovalStore = create<MyPaymentApprovalStore>()(
  persist(
    (set) => ({
      pageParams: defaultPageParams,
      advanceFilter: {
        filterParams: defaultParams,
        isDetailed: true,
      },
      selectedRows: [],

      setAdvanceFilter: (data) => set(() => ({ advanceFilter: data })),
      clearSelectedRows: () => set({ selectedRows: [] }),
      setPageParams: (data) => set(() => ({ pageParams: data })),
      clearAdvanceFilter: () =>
        set(() => ({
          advanceFilter: {
            filterParams: defaultParams?.map((item) => ({
              ...item,
              isChecked: false,
            })),
            isDetailed: true,
          },
        })),
      setSelectedRows: (data) => set(() => ({ selectedRows: data })),
      clearMyPaymentApprovalFilter: (key) =>
        set((state) => ({
          pageParams: key
            ? { ...defaultPageParams, search: state?.pageParams?.search }
            : defaultPageParams,
        })),
    }),
    { name: 'fit__my_payment_aprvl_store' }
  )
)

type MyPaymentApprovalStore = {
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]
  advanceFilter: AdvanceFilter
  setSelectedRows: (data: (number | string)[]) => void
  setPageParams: (data: StoreFilterParams) => void
  setAdvanceFilter: (data: AdvanceFilter) => void
  clearMyPaymentApprovalFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
