import React from 'react'
import { IconProps } from '../../../common/types'

const CardsIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.6665 10.5083H15.8332"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8332 14.5252C15.8082 16.9002 15.1581 17.5002 12.6831 17.5002H4.81653C2.29986 17.5002 1.6665 16.8752 1.6665 14.3918V8.56683C1.6665 6.31683 2.1915 5.59183 4.1665 5.47516C4.3665 5.46683 4.5832 5.4585 4.81653 5.4585H12.6831C15.1998 5.4585 15.8332 6.0835 15.8332 8.56683V14.5252Z"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3332 11.4333C18.3332 13.6833 17.8082 14.4083 15.8332 14.525V8.56667C15.8332 6.08333 15.1998 5.45833 12.6831 5.45833H4.81653C4.5832 5.45833 4.3665 5.46667 4.1665 5.475C4.1915 3.1 4.84153 2.5 7.31653 2.5H15.1831C17.6998 2.5 18.3332 3.125 18.3332 5.60833V11.4333Z"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.375 14.8418H5.80831"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5918 14.8418H10.4585"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CardsIcon
