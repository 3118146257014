import React from 'react'
import { IconProps } from '../../../common/types'
const TopbarMenu: React.FC<IconProps> = () => {
  return (
    <svg
      width="27"
      height="16"
      viewBox="0 0 27 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="27" height="3.2" rx="1.6" fill="#FF681D" />
      <rect y="12.8" width="27" height="3.2" rx="1.6" fill="#FF681D" />
      <rect y="6.39999" width="22.5" height="3.2" rx="1.6" fill="#003C71" />
    </svg>
  )
}

export default TopbarMenu
