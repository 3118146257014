import { z } from 'zod'

import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'

export const teamSchema = z.object({
  name: z
    .string({ invalid_type_error: 'Team Name is required.' })
    .min(1, { message: 'Team Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
})

export type TeamSchema = z.infer<typeof teamSchema>

export const addTeamSchema = z.object({
  team_name: z
    .string({ invalid_type_error: 'Team Name is required.' })
    .min(1, { message: 'Team Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
})

export type AddTeamSchema = z.infer<typeof addTeamSchema>

export const teamLeadSchema = z.object({
  team_name: z
    .string({ invalid_type_error: 'Team Name is required.' })
    .min(1, { message: 'Team Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  lead_name: z.string().min(1, 'Team Lead is required'),
  lead_id: z.string().min(1, 'Team Lead is required'),
})

export type TeamLeadSchema = z.infer<typeof teamLeadSchema>

export const memberSchema = z.object({
  employee: z.string().min(1, 'Required'),
  employee_id: z.string().min(1, 'Required'),
})

export type MemberSchema = z.infer<typeof memberSchema>

export const serviceSchema = z.object({
  service: z.string().min(1, 'Required'),
  service_id: z.string().min(1, 'Required'),
})

export type ServiceSchema = z.infer<typeof serviceSchema>
