import { useQuery } from '@tanstack/react-query'
import {
  deleteData,
  getData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { parseQueryParams } from '../../utilities/parsers'

interface Params {
  [key: string]: string | number | boolean
}

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}
export const getPaymentOutMethods = (params: Params) => {
  const url = buildUrlWithParams(apiUrl.PAYMENT_OUT_METHODS, params)
  return getData(url)
}
export const getPaymentOutList = (params: Params) => {
  const url = buildUrlWithParams(apiUrl.PAYMENT_OUT_LIST, params)
  return getData(url)
}
export const addPayout = (data: any) => {
  return postData(`${apiUrl.PAYMENT_OUT}`, data)
}
export const updatePayout = (id: string, data: any) => {
  return updateData(`${apiUrl.PAYMENT_OUT}/${id}`, data)
}
export const fetchFranchiseeBankAccount = async (data: any) => {
  const url = buildUrlWithParams(
    `${apiUrl.FRANCHISEE_DETAILS}${data?.id}/bank_accounts/`,
    {
      ...data?.input,
    }
  )
  const response = await getData(url)
  return response
}
export const deletePayoutFile = (id: string) => {
  return deleteData(`${apiUrl.PAYMENT_OUT}/${id}/delete-attachment
`)
}

const fetchData = async (input: QueryParams, status_code?: string) => {
  const url = buildUrlWithParams(apiUrl.PAYOUTS, {
    ...input,
    ...(status_code ? { status_code } : {}),
  })
  const response = await getData(url)
  return response?.data
}

export const useSupplier = (input: QueryParams) => {
  return useQuery(['payout', input], () => fetchData(input), {
    refetchOnWindowFocus: false,
  })
}

export const deletePayout = (id: any) => {
  return deleteData(`${apiUrl.PAYOUTS}/${id}`)
}

export const getPayountDetails = (id: string) => {
  return getData(`${apiUrl.PAYOUTS}/${id}`)
}

export const addSupplier = (data: any) => {
  return postData(`${apiUrl.PAYOUTS}`, data)
}

export const updateSuppliers = (id: string, data: any) => {
  return updateData(`${apiUrl.PAYOUTS}/${id}`, data)
}

export const getPaymentType = (params?: Params) => {
  const url = buildUrlWithParams(apiUrl.PAYMENT_OUT_LIST, params)
  return getData(url)
}
export const getPaymentMethods = () => {
  return getData(`${apiUrl.PAYMENT_OUT_METHODS}`)
}
export const fetchSupplierData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.SUPPLIER, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}
export const fetchSupplierCategoryData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.SUPPLIER_CATEGORIES, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const usePayouts = ({
  input,
  status_code,
}: {
  input: QueryParams
  status_code?: string
}) => {
  return useQuery(['payouts', input], () => fetchData(input, status_code), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}
