import moment from 'moment'

import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'

type Props = {
  data?: any
}
const ParentOrderInformation = (props: Props) => {
  const { data } = props

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    value: string
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    value,
  })

  const formBuilderProps = [
    textField(
      'order_date',
      'Order Date',
      'Order Date',
      false,
      data?.order?.order_date
        ? moment(data?.order?.order_date).format('DD-MM-YYYY')
        : '--'
    ),
    textField(
      'order_service_status',
      'Order Status',
      'Order Status',
      false,
      data?.order?.order_status?.name
    ),
    textField(
      'customer_name',
      'Account Name',
      'Account Name',
      false,
      data?.order?.account?.business_name
    ),
    textField(
      'name',
      'Contact Name',
      'Contact Name',
      false,
      data?.order?.contact?.name
    ),
    textField(
      'order_cre',
      'Order CRE',
      'Order CRE',
      false,
      data?.order?.order_cre
    ),
    textField(
      'unique_id',
      'Order Value',
      'Order Value',
      false,
      data?.order?.grand_total
    ),
    textField(
      'paid_amount',
      'Order Payments',
      'Order Payments',
      false,
      data?.order?.paid_amount
    ),
    textField(
      'remaining_amount',
      'Due Payments',
      'Due Payments',
      false,
      data?.order?.remaining_amount
    ),
  ]

  const renderFields = () => {
    return (
      <>
        {formBuilderProps.map((data) => (
          <FormFieldView
            value={data.value}
            label={data.label}
            key={data?.id}
            type={data?.type}
          />
        ))}
      </>
    )
  }
  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText">
            Parent Order Information
          </h4>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">{renderFields()}</div>
    </div>
  )
}

export default ParentOrderInformation
