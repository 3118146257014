import React from 'react'
import { IconProps } from '../../../common/types'

const ActivitiesIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.37 8.87988H17.62M6.38 8.87988L7.13 9.62988L9.38 7.37988M12.37 15.8799H17.62M6.38 15.8799L7.13 16.6299L9.38 14.3799M15 22C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22H15Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ActivitiesIcon
