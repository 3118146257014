import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { getActivityDetails } from '../../api'
import Collectables from './Collectables'
import Settings from './Settings'
import BasicInformation from './basicInformation'

const ActivitySummary = () => {
  const params = useParams()

  const { setActiveTab, deatailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    deatailsData?: any
  }>()

  const [detailData, setDetailData] = useState<any>(deatailsData)
  useEffect(
    () => {
      setActiveTab('ACTIVITY_DETAILS_SUMMARY')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setDetailData(deatailsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deatailsData])

  const getData = async () => {
    const data = await getActivityDetails(params.id)

    setDetailData(data)
  }

  const handleRefresh = () => {
    getData()
  }

  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation
        data={detailData}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
      />

      <Settings
        data={detailData}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
      />
      <Collectables
        data={detailData}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
      />
    </div>
  )
}

export default ActivitySummary
