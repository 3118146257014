import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import FileAttachments from '../../../../components/app/attachments'
import { getActivityDetails } from '../../api'
import { checkDetailPermission } from '../../permission'

const ActivityFiles = () => {
  const { setActiveTab, hasServiceLock, hasOrderLock } = useOutletContext<{
    setActiveTab: (id: string) => void
    hasServiceLock: boolean
    hasOrderLock: boolean
  }>()
  const [data, setData] = useState<any>()
  const params = useParams()

  useEffect(
    () => {
      setActiveTab('ACTIVITY_DETAILS_FILES')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    const data = await getActivityDetails(params?.id)
    setData({
      order_number: data.item?.order?.order_number,
      order_id: data.item?.order?.id,
      order_service: data.item?.service?.service_name,
      order_service_id: data.item?.service?.id,
    })
  }

  return (
    <FileAttachments
      model_name="OrderItemTaskEmployee"
      fromData={data}
      disabled={hasServiceLock || hasOrderLock}
      checkModulePermission={checkDetailPermission}
    />
  )
}

export default ActivityFiles
