import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}
interface Params {
  [key: string]: string | number | boolean
}
const fetchDealList = async (input: any) => {
  const { type, ...rest } = input
  const url = buildUrlWithParams(apiUrl.DEAL_LIST_URL, {
    ...rest,
    ...(type === 'overdue' ? { is_due: true } : {}),
    ...(type === 'lead' ? { created_from: 'lead' } : {}),
    ...(type === 'deal' ? { created_from: 'deal' } : {}),
    ...(type === 'prospect' ? { created_from: 'prospect' } : {}),
    ...(type === 'direct' ? { created_from: 'direct' } : {}),
  })
  const response = await getData(url)
  return response?.data
}

export const listDealDuplicate = (id: string, data?: any) =>
  getData(`crm/${id}/deal-duplicates${parseQueryParams(data)}`)

export const useFetchDeals = ({
  input,
  type,
}: {
  input: any
  type?: string | null
}) => {
  return useQuery(['list_deals', input, type], () => fetchDealList(input), {
    refetchOnWindowFocus: false,
    //staleTime: 50000,
  })
}

export const { getOrderStatusList, getServiceCategoryList, getAllServiceList } =
  {
    getOrderStatusList: (params: Params) =>
      getData(buildUrlWithParams(apiUrl.ORDER_STATUS, params)),
    getServiceCategoryList: (params: Params) =>
      getData(buildUrlWithParams(apiUrl.SERVICE_CATEGORY, params)),
    getAllServiceList: (params: Params) =>
      getData(buildUrlWithParams(`${apiUrl.SERVICE_LIST_URL}list/`, params)),
  }

export const getDealStatusList = (data: any) => {
  return getData(
    `${apiUrl.DEAL_NOTE_LIST_URL}status/list${parseQueryParams(data)}`
  )
}
export const getDealDetails = (id: any) => {
  return getData(`${apiUrl.DEAL_LIST_URL}/${id}`)
}
export const getServiceDetails = (id: any) => {
  return getData(`${apiUrl.DEAL_LIST_URL}/${id}`)
}

const getDealServiceList = async (id: string, input: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.DEAL_NOTE_LIST_URL}${id}/services`, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useOrderServiceDetails = (id: string, input: QueryParams) => {
  return useQuery(
    ['assosiated_deal_service_list', input, id],
    () => getDealServiceList(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

export const addService = (data: { input: any; id: string }) => {
  return postData(
    `${apiUrl.DEAL_NOTE_LIST_URL}${data?.id}/services`,
    data?.input
  )
}

export const useAddService = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(addService, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Service successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data.errors[0].message[0] ?? error.response.data.errors
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
export const getDealMergeDetails = (data: any) => {
  return postData(apiUrl.DEAL_MERGE_DETAILS, data)
}

export const mergeDeals = (data: any, id?: string) => {
  return updateData(`${apiUrl.DEAL_DETAILS_NOTES}${id}/merge`, data)
}
export const deleteDealService = (id?: string, serviceId?: string) => {
  return deleteData(`${apiUrl.DEAL_DETAILS_NOTES}${id}/services/${serviceId}`)
}
export const cancelDeal = (id?: string) => {
  return deleteData(`${apiUrl.DEAL_LIST_URL}/${id}`)
}
export const getLeadSource = (data: any) => {
  return getData(`${apiUrl.LEAD_SOURCE}${parseQueryParams(data)}`)
}
export const getDealStatus = (id?: string) => {
  return getData(`${apiUrl.DEAL_NOTE_LIST_URL}${id}/status`)
}
export const updateDealStatus = (id?: string, data?: any) => {
  return updateData(`${apiUrl.DEAL_NOTE_LIST_URL}${id}/status`, data)
}
export const dealConvertionValidation = (id?: string) => {
  return getData(`${apiUrl.DEAL_NOTE_LIST_URL}${id}/validate-deal`)
}
export const dealConvertion = (id?: string, data?: any) => {
  return postData(`${apiUrl.DEAL_NOTE_LIST_URL}${id}/convert-deal`, data)
}

export const getContacts = (id: any, data?: any) => {
  return getData(
    `${apiUrl.CONTACT_LIST_URL}account/${id}/contacts/${parseQueryParams(data)}`
  )
}

const orderPaymentFetchData = async (id: string, input?: QueryParams) => {
  const url = buildUrlWithParams(`${apiUrl.DEAL_LIST_URL}${id}/payments`, {
    ...input,
  })
  const response = await getData(url)
  return response?.data
}

export const useOrderPayment = (id: string, input?: QueryParams) => {
  return useQuery(
    ['order-payment', id, input],
    () => orderPaymentFetchData(id, input),
    {
      refetchOnWindowFocus: false,
      //staleTime: 50000,
    }
  )
}

export const editDeal = (data: { input: any; id: string }) => {
  return updateData(`${apiUrl.DEAL_LIST_URL}/${data?.id}`, data?.input)
}
export const useEditDeal = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  const handleError = (error: any) => {
    const errorMessage = extractErrorMessage(error)
    enqueueSnackbar(errorMessage, { variant: 'error' })
  }
  return useMutation(editDeal, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Deal Updated successfully', { variant: 'success' })
    },

    onError: handleError,
  })
}
const extractErrorMessage = (error: any): string => {
  const defaultMessage = 'An error occurred'

  if (!error.response || !error.response.data || !error.response.data.error) {
    return defaultMessage
  }

  const errorMessages = error.response.data.error.message
  if (typeof errorMessages === 'string') {
    return errorMessages
  }

  const firstErrorMessage = Object.values(errorMessages)[0] as
    | string[]
    | undefined
  return firstErrorMessage?.[0] || defaultMessage
}

export const add_deal = (val: any) => postData(`${apiUrl.DEAL_LIST_URL}`, val)
export const dealUpdateCRE = (id: any, data: any) => {
  return updateData(
    `${apiUrl.DEAL_DETAILS_NOTES}${id}/update-primary-cre`,
    data
  )
}
export const dealunAllocateCRE = (id: any, data: any) => {
  return updateData(
    `${apiUrl.DEAL_DETAILS_NOTES}${id}/unallocate-dealsupporting-cre`,
    data
  )
}
