import React from 'react'
import { Button, Checkbox } from '../../common'
import Icons from '../../common/icons'

type ListingProps = {
  data: any[]
  from: string
  handleCheckItems?: (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => void
  parentId?: string
  handleSetAsParent?: (id?: string) => void
}

const MergeListing: React.FC<ListingProps> = ({
  data,
  handleCheckItems,
  parentId,
  from,
  handleSetAsParent,
}) => {
  return (
    <>
      {data.map((item, index) => (
        <div
          key={item.id}
          className=" border border-formBorder bg-white rounded-lg p-4 min-h-[123px]  flex flex-row flex-wrap "
        >
          <div className="flex justify-between gap-4 w-full">
            <div className="flex gap-3 items-start flex-1">
              <div className="">
                <Checkbox
                  id={`${item.id}`}
                  checked={item.checked}
                  handleChange={(e) => handleCheckItems?.(e, index)}
                />
              </div>
              <div className="flex flex-col gap-1">
                <p className="text-sm font-bold text-primaryText">
                  {item.item1}
                </p>
                <p className="text-sm font-normal text-primaryText">
                  {`${item.item2} ${
                    item.item2_sub ? `, ${item.item2_sub}` : ''
                  }`}
                </p>
                <p className="text-sm font-normal text-primaryText">
                  {`${item.item3} ${
                    item.item3_sub ? `, ${item.item3_sub}` : ''
                  }`}
                </p>
                <p className="text-sm font-normal text-primaryText">
                  {item.item4}
                </p>
                <p className="text-sm font-normal text-primaryText">
                  {item.item5}
                </p>
              </div>
            </div>
            {item.checked && (
              <div className="flex flex-col items-end justify-end">
                {parentId === item.id ? (
                  <span className="flex gap-1 items-center">
                    <Icons
                      className="iconsSuccess tileIconWidth"
                      name="check-circle"
                    />
                    <p className="text-success text-xxs leading-4 font-semibold">
                      {`Parent ${from}`}
                    </p>
                  </span>
                ) : (
                  <Button
                    className="btn-secondary"
                    outlined={true}
                    size="xs"
                    label={`Set as Parent ${from}`}
                    onClick={() => handleSetAsParent?.(item.id)}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </>
  )
}

export default MergeListing
