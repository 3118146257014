import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios'
import { deepDebounce } from '../../../utilities/deepDebounce'
import { getContactDuplicate } from '../api'

let cancelTokenSource: CancelTokenSource | null = null

export const getContactDuplicateData = deepDebounce(
  async (fieldName: string, value?: string, id?: string): Promise<any[]> => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation cancelled due to new request.')
    }

    cancelTokenSource = axios.CancelToken.source()
    const config: AxiosRequestConfig = {
      cancelToken: cancelTokenSource.token,
    }

    try {
      const response = await getContactDuplicate(
        {
          [fieldName]: value,
          contact_id: id,
        },
        config
      )

      const data = response?.data?.map((item: any) => ({
        ...item,
        first_name: item?.default_contact?.first_name,
        mobile: item?.default_contact?.mobile,
        mobile_2: item?.default_contact?.mobile_2,
        email: item?.default_contact?.email,
        email_2: item?.default_contact?.email_2,
        last_name: item?.default_contact?.last_name,
      }))

      return data
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request cancelled', error.message)
      } else {
        console.error('An error occurred:', error)
      }
      return [] // return an empty array or handle the error as needed
    }
  },
  1000
)
