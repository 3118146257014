import { getNestedProperty } from '../../../../utilities/parsers'

const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}

export const getColumns = () => {
  const createRenderCell =
    (key: string | string[], type?: string) => (row: any) => {
      switch (type) {
        case 'status':
          return {
            cell: <>{row.is_active ? 'Active' : 'Inactive'}</>,
          }
        default:
          return {
            cell: getNestedProperty(row, key as string),
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
      }
    }

  const column = [
    {
      title: 'Service Name',
      ...defaultColumnProps,
      field: '',
      renderCell: createRenderCell('service.service_name'),
      customCell: true,
      // colWidth: window.innerWidth - 350,
    },
    {
      title: 'Status',
      ...defaultColumnProps,
      field: '',
      renderCell: createRenderCell('status', 'status'),
      customCell: true,
      // colWidth: window.innerWidth - 350,
    },
  ]

  return column
}
