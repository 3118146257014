import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { getCountryState } from '../../api'
import { handleOnInitData } from './leadUtils'
import { leadSchema, LeadSchema } from './schema'

type Props = {
  data?: any
  id?: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  disabled?: boolean
}
const AddressInformation = (props: Props) => {
  const { data } = props

  const getState = async () => {
    const { data } = await getCountryState()
    return data?.state
  }
  const getCountry = async () => {
    const { data } = await getCountryState()
    return data?.countries
  }
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    value: string
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    value,
  })
  const onInit = () => {
    const onInitData = handleOnInitData(data)
    return onInitData
  }
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const formBuilderProps = [
    textField(
      'address_1',
      'Address Line 1',
      'Enter Address Line 1',
      false,
      data?.lead_address?.address_1
    ),
    textField(
      'address_2',
      'Address Line 2',
      'Enter Address Line 2',
      false,
      data?.lead_address?.address_2
    ),
    {
      name: 'state',
      label: 'State',
      required: false,
      getData: getState,
      id: 'state',
      descId: 'state',
      initialLoad: true,
      async: false,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter state',
      value: data?.lead_address?.state,
    },
    textField('city', 'City', 'Enter City', false, data?.lead_address?.city),
    {
      name: 'country',
      label: 'Country',
      required: false,
      getData: getCountry,
      id: 'countryName',
      descId: 'country',
      initialLoad: true,
      async: false,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter Country',
      value: data?.lead_address?.country,
    },
    textField(
      'pin_code',
      'Pincode',
      'Enter Pincode',
      false,
      data?.lead_address?.pin_code
    ),
  ]
  const methods = useForm<LeadSchema>({
    resolver: zodResolver(leadSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  const renderFields = () => {
    return (
      <>
        {formBuilderProps.map((data) => (
          <FormFieldView
            value={data.value}
            type={data?.type}
            // required={data.required}
            label={data.label}
            key={data?.id}
          />
        ))}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="location-pin" />
          <h4 className=" font-bold text-primaryText">Address Information</h4>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">{renderFields()}</div>
    </div>
  )
}

export default AddressInformation
