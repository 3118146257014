import { QbsTable } from 'qbs-react-grid'
import { useState } from 'react'

import InfoBox from '../../components/app/alertBox/infoBox'
import { DialogModal } from '../../components/common'
import Icons from '../../components/common/icons'
import ListingHeader from '../../components/common/ListingTiles/index'
import { useSnackbarManager } from '../../components/common/snackbar'
import { blockActionsbyStatus } from '../../configs/permissionGate'
import { useSupplierCategoriesFilterStore } from '../../store/filterSore/supplierCategoriesStore'
import { calcWindowHeight } from '../../utilities/calcHeight'
import { getErrorMessage, getSortedColumnName } from '../../utilities/parsers'
import { deleteSupplierCategories, useSupplierCategories } from './api'
import CreateSupplierCategories from './create/index'
import { checkPermission } from './permission'

const SupplierCategoriesListing = () => {
  const { enqueueSnackbar } = useSnackbarManager()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [activeRow, setActiveRow] = useState<any>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { pageParams, setPageParams } = useSupplierCategoriesFilterStore()
  const { page, page_size, search, ordering } = pageParams
  const [edit, setEdit] = useState<boolean>(false)
  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }
  const { data, refetch, isLaoding, isFetching } = useSupplierCategories({
    ...searchParams,
  })

  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  // const onViewAction = (r: any) => {
  //   return `${router_config.SYSTEM_SETTINGS_ORGANIZATION_SupplierCategoriesS.path}/${r.id}/info`
  // }

  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }

  const basicData = {
    title: 'Supplier Categories',
    icon: 'user',
  }

  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }

  const handleDelete = () => {
    deleteSupplierCategories(activeRow.id)
      .then(() => {
        enqueueSnackbar('Supplier Category deleted successfully', {
          variant: 'success',
        })
        handleDeleteClose()
        refetch()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.errors[0]?.message[0] ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }
  const handleDeleteOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }
  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }
  const handleCallback = () => {
    refetch()
  }

  const column = [
    {
      title: 'Category Name',
      field: 'name',
      sortable: true,
      isVisible: true,
      resizable: true,
      sortKey: 'name',
      colWidth: window.innerWidth - 350,
      // link: true,
      // getPath: (row: any) => onViewAction(row),
    },
  ]
  return (
    <div className="con">
      <ListingHeader
        data={basicData}
        onActionClick={handleOpenDrawer}
        checkPermission={checkPermission}
        actionProps={{ hideExport: true }}
      />

      <div className=" p-4">
        <QbsTable
          columns={column}
          dataRowKey="id"
          isLoading={isLaoding || isFetching}
          toolbar
          search
          data={data?.results ?? []}
          sortType={pageParams.sortType}
          height={calcWindowHeight(303)}
          tableBodyHeight="calc(100vh - 500px)"
          sortColumn={pageParams.sortColumn}
          handleColumnSort={handleSort}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
              ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
              : [10, 20, 30, 50],
          }}
          actionProps={[
            // {
            //   title: 'View',
            //   action: (rowData) => navigate(onViewAction(rowData)),
            //   icon: <Icons name="eye" />,
            //   hidden: !checkPermission('view'),
            //   toolTip: 'View',
            // },
            {
              title: 'Edit',
              action: (rowData) => {
                setActiveRow(rowData)
                setEdit(true)
                setOpenDrawer(true)
              },
              icon: <Icons name="edit" />,
              hidden: !checkPermission('change'),
              toolTip: 'Edit',
            },
            {
              title: 'Delete',
              hide: (rowData) =>
                blockActionsbyStatus(rowData?.order_status?.code),
              action: (rowData) => handleDeleteOpen(rowData),
              icon: <Icons name="delete" />,
              hidden: !checkPermission('delete'),

              toolTip: 'Delete',
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          columnToggle={false}
        />
      </div>
      {openDrawer && (
        <CreateSupplierCategories
          isDrawerOpen={openDrawer}
          edit={edit}
          data={activeRow}
          handleClose={() => {
            setOpenDrawer(false)
            setActiveRow(undefined)
            setEdit(false)
          }}
          handleCallback={handleCallback}
        />
      )}
      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleDeleteClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={<InfoBox content={'Are you sure you want to delete?'} />}
      />
    </div>
  )
}

export default SupplierCategoriesListing
