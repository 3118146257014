import axios, { CancelTokenSource, AxiosRequestConfig } from 'axios'
import { deepDebounce } from '../../../utilities/deepDebounce'
import { getAccountDuplicate } from '../api'

let cancelTokenSource: CancelTokenSource | null = null

export const getAccountDuplicateData = deepDebounce(
  async (fieldName: string, value?: string, id?: string): Promise<any[]> => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation cancelled due to new request.')
    }

    cancelTokenSource = axios.CancelToken.source()
    const config: AxiosRequestConfig = {
      cancelToken: cancelTokenSource.token,
    }

    try {
      const response = await getAccountDuplicate(
        {
          [fieldName]: value,
          company_id: id,
        },
        config
      )
      const data = response?.data?.map((item: any) => ({
        ...item,
        primaryContact: `${item?.primary_contact?.default_contact?.first_name} ${item?.primary_contact?.default_contact?.last_name}`,
      }))

      return data
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request cancelled', error.message)
      } else {
        console.error('An error occurred:', error)
      }
      return [] // return an empty array or handle the error as needed
    }
  },
  1000
)
