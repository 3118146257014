import { useEffect, useState } from 'react'

import Icons from '../../../../components/common/icons'
import { numberFormat } from '../../../../utilities/parsers'

type Props = {
  detailData: any
}
const PricingInformation = ({ detailData }: Props) => {
  const [data, setData] = useState<any>()
  useEffect(() => {
    setData(detailData)
  }, [detailData])

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="cards" />
          <h4 className=" font-bold text-primaryText">Pricing Information</h4>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-5">
        <div className=" col-span-2 xl:col-span-1 bg-white border border-formBorder rounded-xl p-5 flex flex-col gap-4">
          <p className=" text-base text-secondary font-bold">
            Deal Service Cost Splits (Aggregated)
          </p>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Professional Fee</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.professional_fee_total)}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Vendor Fee</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.vendor_fee_total)}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Govt. Fee</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.govt_fee_total)}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">GST </p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.total_tax)}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Discount</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.discount_total)}
            </p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Rounding</p>
            <p className=" text-sm text-secondary font-bold">
              {numberFormat(data?.price_adjustment_total)}
              {/* {`${getRoundingSign()}${getFeeData('price_adjustment')}`} */}
            </p>
          </div>
          <div className="w-full h-px bg-formBorder"></div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-primaryText font-bold">Total Amount</p>
            <p className=" text-sm text-primaryText font-bold">
              {/* ('amount')} */}
              {numberFormat(data?.total_amount)}
            </p>
          </div>
        </div>
        {/* <div className=" col-span-2 xl:col-span-1 bg-cardWrapperBg  rounded-xl p-5 flex flex-col gap-4 h-fit">
          <p className=" text-base text-secondary font-bold">
            Order Service Payment Allocation
          </p>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">Order Payment Collection </p>
            <p className=" text-sm text-secondary font-bold">4,000.00</p>
          </div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-secondary">
              Service Payment Allocation
            </p>
            <p className=" text-sm text-secondary font-bold">1,500.00</p>
          </div>
          <div className="w-full h-px bg-formBorder"></div>
          <div className="flex items-center justify-between gap-2">
            <p className=" text-sm text-primaryText font-bold">
              Available for Allocation
            </p>
            <p className=" text-sm text-primaryText font-bold">1,499.00</p>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default PricingInformation
