import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { handleError } from '../../../../../../utilities/commonUtilities'
import { checkDetailPermission } from '../../../../permission'
import { getEmployeeTeamMembers } from '../api'
import TeamInformation from './teamInfo'

const VendorEmployeeTeams = () => {
  const [data, setData] = useState<any>()
  const params = useParams()
  const [editFlag, setEditFlag] = useState(false)

  const { setActiveTab, handleParentCallback } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
  }>()

  useEffect(
    () => {
      setActiveTab('SYSTEM_SETTINGS_VENDOR_EMPLOYEES_TEAM_INFO')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    getEmployeeTeamMembers(params.id, params.id2)
      .then((res) => {
        setData(res?.data)
      })
      .catch((err) => {
        handleError(err)
      })
  }
  const handleRefresh = () => {
    getData()
    handleParentCallback?.()
  }

  const handleEditButton = (flag: boolean) => {
    setEditFlag(flag)
  }
  const handlePermission = () => {
    return checkDetailPermission('change', 'tean')
  }

  return (
    <TeamInformation
      data={data}
      id={params.id ?? ''}
      handleRefresh={handleRefresh}
      handleEditButton={handleEditButton}
      editFlag={editFlag}
      hasPermission={handlePermission()}
    />
  )
}

export default VendorEmployeeTeams
