import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { useVendorServiceFilterStore } from '../../../../store/filterSore/vendorServiceStore'
import { getErrorMessage } from '../../../../utilities/parsers'
import {
  activateVendorServices,
  deactivateVendorServices,
  deleteVendorServices,
  useVendorServices,
} from '../../api'
import AddService from './create'
import { getColumns } from './serviceColumns'

const VendorServices = () => {
  const { pageParams, setPageParams } = useVendorServiceFilterStore()
  const { page, page_size, search, ordering } = pageParams
  const params = useParams()
  const [activeRow, setActiveRow] = useState<any>()
  const [serviceOpen, setServiceOpen] = useState(false)
  const [statusOpen, setStatusOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()

  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }

  const { data, refetch, isFetching } = useVendorServices(
    params.id as string,
    searchParams
  )

  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, refetch])

  const { setActiveTab, handleParentCallback } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
  }>()

  useEffect(() => {
    setActiveTab('SYSTEM_SETTINGS_VENDOR_SERVICE')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRefresh = () => {
    refetch()
    handleParentCallback?.()
  }

  const handleAction = () => {
    setServiceOpen(true)
  }

  const handleSearch = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  const manageStatus = () =>
    activeRow.is_active
      ? deactivateVendorServices(params.id, activeRow.id)
      : activateVendorServices(params.id, activeRow.id)
  const handleStatusChange = () => {
    manageStatus()
      .then((res) => {
        enqueueSnackbar(
          res.message ? res.message : 'Status updated successfully',
          {
            variant: 'success',
          }
        )

        setStatusOpen(false)
        setActiveRow(null)
        refetch()
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' })
      })
  }
  const handleDelete = () => {
    deleteVendorServices(params.id, activeRow.id)
      .then(() => {
        enqueueSnackbar('Service deleted successfully', {
          variant: 'success',
        })
        handleDeleteClose()
        handleRefresh()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.errors[0]?.message[0] ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }
  const handleDeletetOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  const handleStatusClose = () => {
    setStatusOpen(false)
    setActiveRow(null)
  }
  const handleStatusOpen = (row: any) => {
    setActiveRow(row)
    setStatusOpen(true)
  }
  return (
    <div>
      <QbsTable
        columns={getColumns()}
        data={data?.data?.results ?? []}
        toolbar
        sortColumn=""
        isLoading={isFetching}
        search
        searchValue={pageParams?.search}
        onSearch={handleSearch}
        asyncSearch
        handleSearchValue={(key?: string) => handleSearch(key)}
        sortType="asc"
        tableHeaderActions={
          <div className="flex gap-2">
            <Button onClick={handleAction} label={'Add New'} icon="plus" />
          </div>
        }
        pagination
        paginationProps={{
          onPagination: onChangePage,
          total: data?.data?.count,
          currentPage: pageParams?.page,
          rowsPerPage: Number(pageParams.page_size ?? data?.data?.page_size),
          onRowsPerPage: onChangeRowsPerPage,
          dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
            ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
            : [10, 20, 30, 50],
        }}
        actionProps={[
          {
            icon: <Icons name="delete" />,
            action: (row) => handleDeletetOpen(row),
            title: 'Delete',
            toolTip: 'Delete',
          },
          {
            title: 'Deactivate',
            toolTip: 'Deactivate',
            action: (rowData) => handleStatusOpen(rowData),
            icon: <Icons name="deactivate-icon" />,

            hide: (row) => !row.is_active,
          },
          {
            title: 'Activate',
            toolTip: 'Activate',
            action: (rowData) => handleStatusOpen(rowData),
            icon: <Icons name="activate-icon" />,

            hide: (row) => row.is_active,
          },
        ]}
      />
      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleDeleteClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={<InfoBox content={'Are you sure you want to delete service?'} />}
      />
      <DialogModal
        isOpen={statusOpen}
        onClose={() => handleStatusClose}
        title={'Are you sure?'}
        onSubmit={() => handleStatusChange()}
        secondaryAction={() => handleStatusClose}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              activeRow?.is_active
                ? 'Do you want to deactivate this service?'
                : 'Do you want to activate this service?'
            }
          />
        }
      />
      <AddService
        isDrawerOpen={serviceOpen}
        handleClose={() => setServiceOpen(false)}
        handleCallback={serviceOpen ? handleRefresh : undefined}
        parentParams={{ id: params.id }}
      />
    </div>
  )
}

export default VendorServices
