import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  patchData,
  postData,
  updateData,
} from '../../../../../apis/api.helpers'
import apiUrl from '../../../../../apis/api.url'
import { QueryParams } from '../../../../../common/types'
import { useSnackbarManager } from '../../../../../components/common/snackbar'
import {
  getErrorMessage,
  parseQueryParams,
} from '../../../../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.TEAMS_LIST_URL, {
    ...input,
  })
  const response = await getData(url)
  return response
}

export const useTeams = (input: QueryParams) => {
  return useQuery(['team', input], () => fetchData(input), {
    refetchOnWindowFocus: false,
  })
}

export const deleteTeam = (id: any) => {
  return deleteData(`${apiUrl.TEAMS_LIST_URL}${id}/`)
}

export const getTeamDetails = (id: any, id2: any) => {
  return getData(`${apiUrl.VENDORS}${id}/teams/${id2}/`)
}

export const editTeam = (data: { input: any; id: string; id2: string }) => {
  return updateData(
    `${apiUrl.VENDORS}${data?.id}/teams/${data?.id2}/`,
    data?.input
  )
}

export const useEditTeam = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editTeam, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const addTeamEmployee = (data: any) => {
  return postData(`${apiUrl.TEAMS_LIST_URL}`, data)
}

export const assignTeamLead = (teamId: string, LeadId: string, data: any) => {
  return postData(`${apiUrl.TEAM}${teamId}/set-team-lead/${LeadId}`, data)
}

export const getTeamMembers = (id: any, id2: any) => {
  return getData(`${apiUrl.VENDORS}${id}/teams/${id2}/members`)
}
export const activateTeamMembers = (id: any, id2: any, id3: any) => {
  return patchData(
    `${apiUrl.VENDORS}${id}/teams/${id2}/members/${id3}/activate/`,
    {}
  )
}
export const deactivateTeamMembers = (id: any, id2: any, id3: any) => {
  return patchData(
    `${apiUrl.VENDORS}${id}/teams/${id2}/members/${id3}/deactivate/`,
    {}
  )
}

export const getTeamServices = (id: any) => {
  return getData(`${apiUrl.TEAM}${id}/services`)
}

export const deleteEmployeeFromTeam = (id: any, memberId: any) => {
  return deleteData(`${apiUrl.TEAM}${id}/members/${memberId}`)
}

export const deleteServiceFromTeam = (id: any, serviceId: any) => {
  return deleteData(`${apiUrl.TEAM}${id}/services/${serviceId}`)
}

export const addMemberToTeam = (
  vendorId: string,
  teamId: string,
  data: any
) => {
  return postData(`${apiUrl.VENDORS}${vendorId}/teams/${teamId}/members/`, data)
}

export const addServiceToTeam = (id: string, data: any) => {
  return postData(`${apiUrl.TEAM}${id}/add-service`, data)
}

export const updateTeams = (id: string, data: any) => {
  return updateData(`${apiUrl.TEAMS_LIST_URL}${id}/`, data)
}
