import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import apiUrl from '../../../../apis/api.url'
import CommonNotes from '../../../../components/app/notes'
import { checkDetailPermission } from '../../permission'

const ActivityNotes = () => {
  const { setActiveTab, hasServiceLock, hasOrderLock } = useOutletContext<{
    setActiveTab: (id: string) => void
    hasServiceLock: boolean
    hasOrderLock: boolean
  }>()

  useEffect(
    () => {
      setActiveTab('ACTIVITY_DETAILS_NOTES')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <CommonNotes
      moduleUrl={apiUrl.ACTIVITY_NOTE_LIST_URL}
      disabled={hasServiceLock || hasOrderLock}
      checkModulePermission={checkDetailPermission}
    />
  )
}

export default ActivityNotes
