import { Divider } from '@mui/material'
import { AutoComplete } from 'qbs-core'
import { useEffect, useState } from 'react'

import { getEmployeeList } from '../../../apis/common.apis'
import CustomDrawer from '../../../components/common/drawer'
import Icons from '../../../components/common/icons'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { getErrorMessage } from '../../../utilities/parsers'
import { assignEmployeeToActivity } from '../api'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback?: (data?: any) => void
  parentParams?: any
  orderId: string
  serviceId?: string
  taskId: string
  assigneeData: any
}

export default function AssignActivity({
  isDrawerOpen,
  handleClose,
  handleCallback,
  orderId,
  serviceId,
  taskId,
  assigneeData,
}: Props) {
  const [employeePage, setEmployeePage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState<any>(null)
  const [data, setData] = useState<any>(null)
  useEffect(() => {
    if (isDrawerOpen && assigneeData) {
      setData(assigneeData)
      setValue(assigneeData.name)
    }
  }, [isDrawerOpen, assigneeData])
  const handleClearAndClose = () => {
    setData(null)
    handleClose()
  }
  const getData = async (value?: string, page?: number) => {
    const data = await getEmployeeList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })

    setEmployeePage(data?.next ? employeePage + 1 : 0)
    return data?.results?.map((item: any) => ({
      ...item,
    }))
  }
  const handleChange = (value: any) => {
    const valid = value?.id ? value : null
    setData(valid)
  }
  const { enqueueSnackbar } = useSnackbarManager()
  const onSubmit = () => {
    setIsLoading(true)
    const input = { employee_id: data?.id }
    assignEmployeeToActivity(
      orderId as string,
      serviceId as string,
      taskId as string,
      input
    )
      .then(() => {
        enqueueSnackbar('Activity Assigned successfully', {
          variant: 'success',
        })
        handleClose()
        handleCallback?.()
        setData(null)
        setIsLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar(getErrorMessage(err?.response?.data?.error), {
          variant: 'error',
        })
        setIsLoading(false)
      })
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      title="Assign Activity"
      actionLoader={isLoading}
      handleSubmit={() => onSubmit()}
      disableSubmit={!data}
    >
      <div className="flex flex-col gap-4">
        <AutoComplete
          name="assignee"
          desc="name"
          type="auto_complete"
          onChange={(value) => handleChange(value)}
          getData={getData}
          paginationEnabled
          value={value}
          nextBlock={employeePage}
          label="Assignee"
          async={true}
          placeholder="Assignee"
          descId={'id'}
        />
        {data && (
          <div className=" bg-cardWrapperBg p-5 flex flex-col gap-4 rounded-lg ">
            {data?.name && (
              <>
                <div className="flex flex-col">
                  <p className=" text-primaryText text-base font-bold">
                    {data?.name}
                  </p>
                  <p className=" text-linkBlue text-sm flex items-center gap-1">
                    {data?.group?.name}
                  </p>
                </div>

                <Divider className="m-0" />
              </>
            )}
            {(data?.user?.email || data?.user?.mobile) && (
              <>
                <div className="flex flex-col gap-2">
                  <p className="text-secondary mb-1 text-xs leading-4 font-semibold">
                    Communication
                  </p>
                  <div className="flex items-center gap-1">
                    {data?.user?.email && (
                      <>
                        <Icons name="email" className="iconWidthXs iconGrey" />
                        <span className=" text-primaryText text-sm font-medium">
                          {data?.user?.email}
                        </span>
                      </>
                    )}
                  </div>
                  <div className="flex items-center gap-1">
                    {data?.user?.mobile && (
                      <>
                        <Icons name="phone" className="iconWidthXs iconGrey" />
                        <span className=" text-primaryText text-sm font-medium">
                          {data?.user?.mobile}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <Divider className="m-0" />
              </>
            )}
            {(data?.employee_profile?.department ||
              data?.employee_profile?.designation ||
              data?.employee_profile?.reports_to) && (
              <div className="flex flex-col gap-2">
                <p className="text-secondary mb-1 text-xs leading-4 font-bold">
                  Work Profile
                </p>
                {data?.employee_profile?.department && (
                  <>
                    <div className=" text-[#999696] text-sm leading-4 font-bold mb-1.5">
                      Department
                    </div>
                    <div className="text-sm font-bold text-primaryText">
                      {data?.employee_profile?.department?.name}
                    </div>
                  </>
                )}

                {data?.employee_profile?.designation && (
                  <>
                    <div className=" text-[#999696] text-sm leading-4 font-bold mb-1.5">
                      Designation
                    </div>
                    <div className="text-sm font-bold text-primaryText">
                      {data?.employee_profile?.designation?.name}
                    </div>
                  </>
                )}

                {data?.employee_profile?.reports_to && (
                  <>
                    <div className=" text-[#999696] text-sm leading-4 font-bold mb-1.5">
                      Reports To
                    </div>
                    <div className="text-sm font-bold text-primaryText">
                      {data?.employee_profile?.reports_to?.name}
                    </div>
                  </>
                )}
                <Divider className="m-0" />
              </div>
            )}

            <div className="flex flex-col gap-2">
              <p className="text-secondary mb-1 text-xs leading-4 font-bold">
                Teams
              </p>
              <span className="  text-sm font-bold text-primaryText">
                <>
                  {data?.employee_profile?.team?.length ? (
                    <>
                      {data?.employee_profile?.team?.map((item: any) => {
                        return <div key={item.id}> {item.name} </div>
                      })}
                    </>
                  ) : (
                    <>--</>
                  )}
                </>
              </span>
            </div>
          </div>
        )}
      </div>
    </CustomDrawer>
  )
}
