import { Suspense } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { checkPermissionAny } from '../../configs/permissionGate'
import { useAuthStore } from '../../store/authStore'

type Props = {
  children: React.ReactNode
}

const GuestRoute = ({ children }: Props) => {
  const authenticated = useAuthStore((state) => state.authenticated)
  return !authenticated ? (
    <Suspense
      fallback={
        <div className="w-screeen h-screen flex text-[52px] bg-white dark:bg-black text-black dark:text-white">
          <section className="dots-container">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </section>
        </div>
      }
    >
      <>{children}</> || <Outlet />
    </Suspense>
  ) : (
    <Navigate
      to={checkPermissionAny('view_dashboard') ? '/dashboard' : '/my-tasks'}
      replace
    />
  )
}

export default GuestRoute
