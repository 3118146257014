import { getNestedProperty } from '../../../../utilities/parsers'

const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell =
    (key: string | string[], type?: string) => (row: any) => {
      console.log(row)
      switch (type) {
        case 'join':
          return {
            cell: `${getNestedProperty(row, key[0]) ?? ''} ${
              getNestedProperty(row, key[1]) ?? ''
            }`,
          }
        case 'status':
          return {
            cell: <div>{row.status ? 'Active' : 'Inactive'}</div>,
          }
        default:
          return {
            cell: getNestedProperty(row, key as string),
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
      }
    }

  const column = [
    {
      title: 'Employee Name',
      ...defaultColumnProps,
      field: '',
      renderCell: createRenderCell('name'),
      customCell: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'employee'),
    },
    {
      title: 'Email',
      ...defaultColumnProps,
      field: '',
      renderCell: createRenderCell('user.email'),
      customCell: true,
    },
    {
      title: 'Phone',
      ...defaultColumnProps,
      field: '',
      renderCell: createRenderCell('user.mobile'),
      customCell: true,
    },
    {
      title: 'Role ',
      ...defaultColumnProps,
      field: '',
      renderCell: createRenderCell('group.name'),
      customCell: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'role'),
    },
    {
      title: 'Status',
      renderCell: createRenderCell('status', 'status'),
      customCell: true,
      ...defaultColumnProps,
      field: 'status',
    },
  ]

  return column
}
