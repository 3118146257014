import React from 'react'
import { IconProps } from '../../../common/types'

const TableEdit: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 15H16.375M14.5 7.5L16.75 5.25L13.75 2.25L11.5 4.5M14.5 7.5L7 15H4V12L11.5 4.5M14.5 7.5L11.5 4.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TableEdit
