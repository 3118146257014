import { z } from 'zod'

import noLeadingSpaces from '../../../../../utilities/noLeadingSpaces'
import isValidIfscCode from '../../../../../utilities/isValidIfscCode'

export const bankAccountSchema = z.object({
  account_name: z
    .string({ invalid_type_error: 'Name In Account is required.' })
    .min(1, { message: 'Name In Account is required.' })
    .max(45, { message: 'Name In Account length exceeds' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  account_number: z
    .string({ invalid_type_error: 'Account Number is required.' })
    .min(1, { message: 'Account Number is required.' })
    .regex(/^\d+$/, {
      message: 'Must contain only numbers',
    }),
  bank_name: z
    .string({ invalid_type_error: 'Bank Name is required.' })
    .min(1, { message: 'Bank Name is required.' })
    .max(45, { message: 'Bank Name length exceeds' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  branch_name: z
    .string({ invalid_type_error: 'Branch Name is required.' })
    .min(1, { message: 'Branch Name is required.' })
    .max(45, { message: 'Branch Name length exceeds' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  ifsc_code: z
    .string({ invalid_type_error: 'IFSC Code is required.' })
    .min(1, { message: 'IFSC Code is required.' })
    .max(45, { message: 'IFSC Code length exceeds' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .refine(isValidIfscCode, {
      message: 'Invalid IFSC Code',
    }),
  account_type_name: z
    .string({
      invalid_type_error: 'Account type is required.',
    })
    .min(1, { message: 'Account type is required.' }),
  account_type: z
    .string({ invalid_type_error: 'Account type is required.' })
    .min(1, { message: 'Account type is required.' }),
  display_name: z
    .string({ invalid_type_error: 'Display Name is required.' })
    .nullable()
    .optional(),
})

export type AddBankAccountSchema = z.infer<typeof bankAccountSchema>
