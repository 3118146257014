import { z } from 'zod'

export const serviceDetailsSchema = z.object({
  order_date: z.string().optional().nullable(),
  unique_id: z.string().optional().nullable(),
  assigned_vendor_team_lead: z.string().optional().nullable(),
  target_close_date: z.union([z.date(), z.string()]).optional().nullable(),
  order_service_status: z.string().optional().nullable(),
  service_progress_percentage: z.string().optional().nullable(),
})

export const serviceBasicInfoSchema = z.object({
  target_close_date: z.union([z.date(), z.string()]).optional().nullable(),
})

export type ServiceDetailsSchema = z.infer<typeof serviceDetailsSchema>
export type ServiceBasicInfoSchema = z.infer<typeof serviceBasicInfoSchema>
