import { useEffect, useState } from 'react'
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'

import { TabItemProps } from '../../../../../common/types'
import { TabContainer } from '../../../../../components/common'
import DetailHeader from '../../../../../components/common/DetailTiles'
import { blockActionsbyStatus } from '../../../../../configs/permissionGate'
import { router_config } from '../../../../../configs/route.config'
import { getTeamDetails } from './api'
import { checkPermission } from '../../../permission'

const VendorTeamDetails = () => {
  const navigate = useNavigate()
  const [edit] = useState(false)
  const [data, setData] = useState<any>()

  const params = useParams()
  const tabData = [
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_TEAM_SUMMARY.label,
      id: 'SYSTEM_SETTINGS_VENDOR_TEAM_SUMMARY',
    },
    {
      label: router_config.SYSTEM_SETTINGS_VENDOR_TEAM_MEMBERS.label,
      id: 'SYSTEM_SETTINGS_VENDOR_TEAM_MEMBERS',
    },
    // {
    //   label: router_config.SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE.label,
    //   id: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE',
    // },
  ]
  const [activeTab, setActiveTab] = useState<string>(
    'SYSTEM_SETTINGS_VENDOR_TEAM_MEMBERS'
  )

  const haandleTabChange = (selected: TabItemProps) => {
    navigate(
      generatePath(router_config[selected.id]?.path as string, {
        id: params.id,
      })
    )
  }

  useEffect(() => {
    if (params.id && params.id2) getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.id2])

  const getData = async () => {
    const data = await getTeamDetails(params.id, params.id2)
    setData(data)
  }

  const basicData = {
    title: `${data?.name ?? '--'}`,
    subtitle: `${data?.lead?.name ?? '--'}`,
    // image: data?.logo,
  }

  const contextData = {
    setActiveTab: (id: string) => setActiveTab(id),
    edit: edit,

    teamData: data,
    handleParentCallback: getData,
  }
  const handleDisable = () => {
    return (
      blockActionsbyStatus(data?.order_status?.code) ||
      !checkPermission('change')
    )
  }
  return (
    <div>
      <div>
        <DetailHeader disabled={handleDisable()} data={basicData} />
        {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      </div>
      <div className="flex gap-4">
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          onClick={haandleTabChange}
        >
          <Outlet context={contextData} />
        </TabContainer>
      </div>
    </div>
  )
}

export default VendorTeamDetails
