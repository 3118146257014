import { z } from 'zod'
import { useAuthStore } from '../../../store/authStore'
const franchisee = useAuthStore.getState().franchisee

export const formSchema = z.object({
  account_name: z.string().refine((val) => val !== '', {
    message: 'Account is required',
  }),
  account_id: z
    .string()
    .refine((val) => val !== '', { message: 'Account is required' }),
  contact_name: z.string().refine((val) => val !== '', {
    message: 'Contact is required',
  }),
  contact_id: z
    .string()
    .refine((val) => val !== '', { message: 'Contact is required' }),
  primary_cre: z
    .string()
    .refine((val) => val !== '', { message: 'Primary CRE is required' })
    .optional(),
  primary_cre_id: z
    .string()
    .refine((val) => val !== '', { message: 'Primary CRE is required' })
    .optional(),
  deal_source: z.string().optional().nullable(),
  deal_source_id: z.string().optional().nullable(),

  target_date: z.union([
    z.date({
      invalid_type_error: 'Target date is required',
      required_error: 'Target date is required',
    }),
    z.string({
      invalid_type_error: 'Target date is required',
      required_error: 'Target date is required',
    }),
  ]),

  next_followup_date: z.union([
    z.date({
      required_error: 'Next followup date is required',
      invalid_type_error: 'Next followup date is required',
    }),
    z.string({
      invalid_type_error: 'Next followup date is required',
      required_error: 'Next followup date is required',
    }),
  ]),
  // probability: z.number().optional().nullable(),
  probability: z.union([
    z
      .string({ invalid_type_error: 'Probability is Required' })
      .min(1, 'Probability is Required')
      .transform((val) =>
        val === '' || isNaN(parseFloat(val)) ? null : parseFloat(val)
      )
      .refine((val) => val !== null && val >= 0 && val <= 100, {
        message: 'Enter a valid probability %',
      }),
    z.number().refine((val) => val >= 0 && val <= 100, {
      message: 'Enter a valid probability %',
    }),
    // z.null(),
  ]),
  // .optional()
  // .nullable(),
  pipeline_days: z.number().optional().nullable(),

  remarks: z.string().optional().nullable(),
})

export type ProspectSchema = z.infer<typeof formSchema>

export const serviceItemSchema = z.object({
  discount_percent: z
    .number({ invalid_type_error: 'Required' })
    .min(0, 'Discount percent must be non-negative')
    .max(100, 'Discount percent cannot exceed 100'),
  govt_fee: z
    .number({ invalid_type_error: 'Required' })
    .min(0, 'Government fee must be a non-negative number'),
  gst_percent: franchisee?.has_gst
    ? z
        .number({ invalid_type_error: 'Required' })
        .min(0.01, 'GST percent must be greater than 0')
        .max(100, 'GST percent cannot exceed 100')
    : z.number().optional(),
  service_id: z.string().optional(),
  service_name: z.string().min(1, { message: 'Required' }),
  professional_fee: z
    .number({ invalid_type_error: 'Required' })
    .min(0, 'Professional fee must be a non-negative number'),
  vendor_fee: z
    .number()
    .nonnegative({ message: 'Vendor fee must be a non-negative number.' })
    .optional()
    .or(z.null()),
  rounding: z.string().optional().nullable(),
  service_total: z.string().optional().nullable(),
})
export type ServiceItemSchema = z.infer<typeof serviceItemSchema>

export const itemSchema = serviceItemSchema.omit({})

const arrayOfItemsSchema = z.array(itemSchema)
export const orderItemSchema = arrayOfItemsSchema
export type ProspectItemShema = z.infer<typeof itemSchema>

export const getMergedSchema = (condition?: boolean) => {
  return condition
    ? formSchema.extend({
        service: arrayOfItemsSchema,
      })
    : formSchema
}
