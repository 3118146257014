import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../components/app/formBuilder'
import CustomDrawer from '../../../../../components/common/drawer'
import {
  getApprovalReqType,
  getApprovalReviewrs,
  useCreateActivityRequests,
} from '../../../api'
import {
  ACCEPTED_IMAGE_TYPES,
  attachmentsSchema,
  AttachmentsSchema,
} from './schema'

type Props = {
  isDrawerOpen: boolean
  stakeholder_id: string
  handleClose: () => void
  service_id?: string
  order_id?: string
  task_id?: string
  handleCallback?: () => void
}
interface reqForProps {
  id: string
  name: string
  requires_payment: boolean
}

export default function ManageActivityRequests({
  isDrawerOpen,
  handleClose,
  stakeholder_id,
  service_id,
  order_id,
  task_id,
  handleCallback,
}: Props) {
  const handleClearAndClose = () => {
    handleReset()
    handleClose()
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
  })
  const getApprovalFFor = async () => {
    const { data } = await getApprovalReqType(stakeholder_id, {
      type: 'dropdown',
    })
    const extractedStatuses: reqForProps[] = []

    data?.forEach((result: reqForProps) => {
      const { id, name, requires_payment } = result
      extractedStatuses.push({ id, name, requires_payment })
    })

    return extractedStatuses
  }
  const getReviewrs = async () => {
    const { data } = await getApprovalReviewrs(stakeholder_id, {
      type: 'dropdown',
    })
    const extractedStatuses: any[] = []

    data?.forEach((result: any) => {
      const { id, name } = result
      extractedStatuses.push({ id, name })
    })

    return extractedStatuses
  }
  const methods = useForm<AttachmentsSchema>({
    resolver: zodResolver(attachmentsSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const handleApprovalforCallBack = (d: reqForProps) => {
    methods.setValue('requires_payment', d.requires_payment)
  }
  const formBuilderProps = [
    {
      name: 'approval_type_name',
      label: 'Request for',
      getData: getApprovalFFor,
      async: false,
      id: 'approval_type',
      descId: 'id',
      initialLoad: true,
      handleCallBack: handleApprovalforCallBack,
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Request for',
    },
    {
      name: 'assignee_name',
      label: 'Select Reveiwer',
      getData: getReviewrs,
      async: false,
      id: 'assignee',
      descId: 'id',
      initialLoad: true,
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Select Reveiwer',
    },
    ...(methods.watch().requires_payment
      ? [
          {
            ...textField('amount', 'Amount', 'Enter Amount'),
            type: 'number',
            required: methods.watch().requires_payment,
          },
        ]
      : []),

    {
      ...textField('request_remark', 'Add Notes', 'Add Notes', false),
      type: 'textarea',
    },
    {
      ...textField('target_date', 'Target Date', 'Enter target date', true),
      minDate: new Date(),
      type: 'date',
    },
    {
      name: 'attachments',
      required: false,
      id: 'attachments',
      label: 'Attach file',
      descId: 'id',
      isMultiple: true,
      supportedFiles: ACCEPTED_IMAGE_TYPES,
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      fileSize: 50,
      type: 'file_upload',
    },
  ]

  const handleSubmission = () => {
    methods.reset()
    handleClearAndClose()
    handleCallback?.()
  }
  const handleReset = () => {
    methods.reset({
      approval_type_name: undefined,
      approval_type: undefined,
      assignee_name: undefined,
      assignee: undefined,
      amount: undefined,
      requires_payment: false,
      request_remark: undefined,
      attachments: undefined,
      target_date: '',
    })
  }
  // textarea
  useEffect(() => {
    handleReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  const { mutate, isLoading } = useCreateActivityRequests(
    handleSubmission,
    service_id,
    order_id,
    task_id
  )
  const onSubmit = (data: AttachmentsSchema) => {
    const formData = new FormData()
    formData.append('approval_type', data?.approval_type)
    formData.append('assignee', data?.assignee)
    formData.append('amount', String(data?.amount || 0))
    formData.append('request_remark', data?.request_remark ?? '')
    formData.append(
      'target_date',
      data.target_date ? moment(data.target_date).format('YYYY-MM-DD') : ''
    )

    data?.attachments?.forEach((atta: any) => {
      formData.append(`attachments`, atta)
    })

    mutate(formData)
  }
  const { handleSubmit } = methods

  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      disableSubmit={isLoading}
      title="Raise Request"
      actionLoader={isLoading}
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
