import React from 'react'
import { IconProps } from '../../../common/types'

const RaiseDeficiencyIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 5.16699V8.66699"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0528 10.2797C14.0528 11.0263 13.6528 11.7197 13.0061 12.0997L9.04614 14.3864C8.39948 14.7597 7.59946 14.7597 6.94613 14.3864L2.98612 12.0997C2.33946 11.7264 1.93945 11.033 1.93945 10.2797V5.7197C1.93945 4.97303 2.33946 4.27967 2.98612 3.89967L6.94613 1.61301C7.59279 1.23967 8.39281 1.23967 9.04614 1.61301L13.0061 3.89967C13.6528 4.27967 14.0528 4.96636 14.0528 5.7197V10.2797Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 10.7998V10.8665"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RaiseDeficiencyIcon
