import { convertDateFormat } from '../../../../utilities/format'
import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}

export const getColumns = ({ onViewAction, statusClassGen }: any) => {
  const format = useCurrencyFormat

  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    switch (isCustom) {
      case 'status':
        return {
          cell: (
            <div className="text-capitalize">
              <span
                className={`statusLabel  ${
                  row.order?.order_status?.name
                    ? statusClassGen(row?.order?.order_status?.name)
                    : 'Active'
                }`}
              >
                {row?.order?.order_status?.name}
              </span>
            </div>
          ),
          toolTip: getNestedProperty(row, key),
        }
      case 'date':
        return {
          cell: convertDateFormat(
            getNestedProperty(row, key) as string,
            'date'
          ),
          toolTip: getNestedProperty(row, key) ?? '',
        }
      case 'amount':
        const propertyValue = getNestedProperty(row, key)
        const numbers =
          typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

        return {
          cell: format(numbers),
          toolTip: getNestedProperty(row, key) ?? '',
        }

      default:
        return {
          cell: getNestedProperty(row, key),
          toolTip: getNestedProperty(row, key) ?? '',
        }
    }
  }

  const column = [
    {
      title: 'Invoice Date',
      field: 'invoice_date',
      fixed: true,
      type: 'date',
      customCell: true,
      ...defaultColumnProps,
    },
    {
      title: 'Invoice ID',
      field: 'invoice_number',
      link: true,
      rowClick: (row: any) => onViewAction(row, 'view'),
      ...defaultColumnProps,
    },
    {
      title: 'Order Date',
      renderCell: createRenderCell('order.order_date', 'date'),
      field: 'order.order_date',
      type: 'date',
      customCell: true,
      ...defaultColumnProps,
    },
    {
      title: 'Order ID',
      renderCell: createRenderCell('order.order_number'),
      field: 'order_number',

      customCell: true,
      sortKey: 'order__order_number',
      link: true,
      getPath: (row: any) => onViewAction(row, 'orderId'),
      ...defaultColumnProps,
    },
    {
      title: 'Account Name',
      renderCell: createRenderCell('order.account.business_name'),
      field: 'business_name',
      customCell: true,
      sortKey: 'order__account__business_name',
      link: true,
      getPath: (row: any) => onViewAction(row, 'accountName'),
      ...defaultColumnProps,
    },
    {
      title: 'Primary Contact',
      renderCell: createRenderCell('order.contact.name'),
      field: 'name',
      customCell: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'primaryContact'),
      ...defaultColumnProps,
    },
    {
      title: 'Invoice Value',
      renderCell: createRenderCell('invoice_amount', 'amount'),
      field: 'invoice_amount',
      customCell: true,
      align: 'right',
      sortKey: 'invoice_amount',
      ...defaultColumnProps,
    },
    {
      title: 'Order Value',
      renderCell: createRenderCell('order.grand_total', 'amount'),
      field: 'grand_total',
      customCell: true,
      align: 'right',
      ...defaultColumnProps,
    },
    {
      title: 'Invoice Status',
      field: 'status',
      ...defaultColumnProps,
    },
    {
      title: 'Cancellation Reason',
      field: 'cancellation_reason',
      ...defaultColumnProps,
      sortable: false,
    },
    {
      title: 'Order Status',
      renderCell: createRenderCell('order.order_status.name', 'status'),
      customCell: true,
      field: 'name',
      ...defaultColumnProps,
    },
    {
      title: 'Created Date',
      field: 'datetime_created',
      sortKey: 'datetime_created',
      type: 'date',
      customCell: true,
      ...defaultColumnProps,
    },
    {
      title: 'Created By',
      field: 'created_by',
      ...defaultColumnProps,
    },
  ]

  return column
}
