import { zodResolver } from '@hookform/resolvers/zod'

import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

import { FormProvider } from 'react-hook-form'
import FormBuilder from '../../../../../components/app/formBuilder'
import { DialogModal } from '../../../../../components/common'
import { getRoles } from '../../../api'
import { useEditEmployeeRole } from '../details/api'
import { EmployeeRoleSchema, employeeRoleSchema } from './changeRoleSchema'

type Props = {
  isOpen: boolean
  data: any
  handleClose: () => void
  handleCallback?: (data?: any) => void
  vendorId?: any
  paramsId?: any
}

export default function ChangeVendorEmployeeRole({
  isOpen,
  handleClose,
  vendorId,
  handleCallback,
  data,
}: Props) {
  const [roleList, setRoleList] = useState([])
  const methods = useForm<EmployeeRoleSchema>({
    resolver: zodResolver(employeeRoleSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const getRoleList = async (search?: string, page?: number) => {
    await getRoles(vendorId as string, {
      search: search as string,
      page: page as number,
      // type: 'dropdown',
    }).then((res) => {
      setRoleList(res.data)
    })
  }
  const onInit = () => {
    return {
      role: data?.group?.name,
      role_id: data?.group?.id,
    }
  }
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  useEffect(() => {
    if (isOpen && vendorId) {
      getRoleList()
    }
  }, [isOpen, vendorId])
  const { reset } = useForm<EmployeeRoleSchema>({
    mode: 'onChange',
    resolver: zodResolver(employeeRoleSchema),
    defaultValues: {
      ...onInit(),
    },
  })
  const handleSubmission = () => {
    handleClearAndClose()
    handleCallback?.()
    methods.reset({
      ...onInit(),
    })
  }
  const { mutate } = useEditEmployeeRole(handleSubmission)
  const onSubmit = (d: any) => {
    mutate({
      input: {
        user_type: 'vendor_employee',
        user: {
          username: data.username,
          first_name: data?.user?.first_name ?? '',
          last_name: data?.user?.last_name ?? '',
          email: data?.user?.email ?? '',
          mobile: data?.user?.mobile ?? '',
        },
        group_id: d.role_id,
      },

      id: vendorId,
      id2: data?.id,
    })
  }

  const formBuilderProps = [
    {
      name: 'role',
      label: 'Role',
      required: true,
      data: roleList,

      id: 'role_id',

      descId: 'id',
      // initialLoad: true,
      desc: 'name',
      type: 'custom_select',
      placeholder: 'Enter Role',
    },
  ]
  const handleClearAndClose = () => {
    reset({})
    handleClose()
  }

  return (
    <>
      <DialogModal
        isOpen={isOpen}
        onClose={handleClearAndClose}
        title="Change Role"
        secondaryAction={handleClearAndClose}
        onSubmit={methods.handleSubmit((data) => onSubmit(data))}
        secondaryActionLabel="Cancel"
        actionLabel="Save"
        body={
          <div className="flex flex-col ">
            <FormProvider {...methods}>
              <FormBuilder data={formBuilderProps} edit={true} />
            </FormProvider>
          </div>
        }
      />
    </>
  )
}
