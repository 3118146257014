import moment from 'moment'
import { Link } from 'react-router-dom'

import { router_config } from '../../../../configs/route.config'
import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps3 = {
  sortable: false,
  isVisible: true,
  resizable: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell = (key: string, isCustom?: string) => (row: any) => {
    const format = useCurrencyFormat

    if (isCustom === 'amount') {
      const propertyValue = getNestedProperty(row, key)
      const numbers =
        typeof propertyValue === 'string' ? parseFloat(propertyValue) : 0

      return {
        cell: format(numbers),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else if (isCustom == 'services') {
      return {
        cell: (
          <>
            {row?.order?.order_items
              ?.slice(0, 3)
              .map((item: any, index: number) => (
                <span key={item.id}>
                  <Link
                    to={`${router_config.SERVICE_AND_ORDERS_BY_SERVICE.path}/${item.id}/summary`}
                  >
                    {item?.name}
                  </Link>
                  {index < 2 &&
                    row?.order?.order_items.length - 1 !== index &&
                    ', '}
                </span>
              ))}
            {row?.order?.order_items?.length > 3 && (
              <span className=" p-1 bg-bgBadgeColor rounded-xl px-2 text-white ml-1">
                +{row.order?.order_items.length - 3}
              </span>
            )}
          </>
        ),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else if (isCustom == 'date') {
      return {
        cell: (
          <>
            {row?.order?.order_date
              ? moment(row?.order?.order_date).format('DD-MM-YYYY')
              : '--'}
          </>
        ),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else if (isCustom == 'createddate') {
      return {
        cell: (
          <>
            {row?.datetime_created
              ? moment(row?.datetime_created).format('DD-MM-YYYY')
              : '--'}
          </>
        ),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    } else {
      return {
        cell: getNestedProperty(row, key),
        toolTip: getNestedProperty(row, key) ?? '',
      }
    }
  }

  const column = [
    {
      title: 'Quote Date',
      renderCell: createRenderCell('order?.order_date', 'date'),
      customCell: true,
      field: 'order_date',
      ...defaultColumnProps3,
      fixed: true,
    },
    {
      title: 'Quote Document ID',
      field: 'unique_id',
      rowClick: (row: any) => onViewAction(row, 'view'),
      link: true,
      fixed: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Account Name',
      renderCell: createRenderCell('order.account.business_name'),
      customCell: true,
      field: 'business_name',
      ...defaultColumnProps3,
    },
    {
      title: 'Contact Name',
      renderCell: createRenderCell('order.contact.name'),
      customCell: true,
      field: 'name',
      ...defaultColumnProps3,
    },
    {
      title: 'Quote Value',
      field: 'quote_value',
      renderCell: createRenderCell('quote_value', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps3,
    },
    {
      title: 'Quote Status',
      field: 'name',
      renderCell: createRenderCell('status.name'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Created By',
      field: 'name',
      renderCell: createRenderCell('created_by.name'),
      customCell: true,
      ...defaultColumnProps3,
    },
    {
      title: 'Created On',
      field: 'datetime_created',
      renderCell: createRenderCell('datetime_created', 'createddate'),
      customCell: true,
      ...defaultColumnProps3,
    },
  ]

  return column
}
