import { convertUTCtoBrowserTimeZone } from '../../../../utilities/format'
import {
  getNestedProperty,
  useCurrencyFormat,
} from '../../../../utilities/parsers'

const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}
const defaultColumnProps1 = {
  isVisible: true,
  sortable: false,
  resizable: true,
}
const defaultColumnProps2 = {
  isVisible: false,
  sortable: false,
  resizable: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell =
    (key: string, customData = '') =>
    (row: any) => {
      const format = useCurrencyFormat
      switch (customData) {
        case 'Allocation':
          return {
            cell: (
              <div>{`${row.allotted_services_count} / ${row.total_services_count}`}</div>
            ),
            toolTip: getNestedProperty(row, key),
          }
        case 'fulldate': {
          const propertyValue = getNestedProperty(row, key)

          return {
            cell: convertUTCtoBrowserTimeZone(propertyValue),
            toolTip: getNestedProperty(row, key) ?? '',
          }
        }
        case 'amount':
          const propertyValue: any = getNestedProperty(row, key)
          const numbers =
            typeof propertyValue === 'string'
              ? parseFloat(propertyValue)
              : propertyValue
          return {
            cell: format(numbers),
            toolTip: getNestedProperty(row, key) ?? '',
          }
        case 'status':
          return {
            cell: (
              <div className="text-capitalize">
                <span
                  className={`statusLabel  ${
                    row.order_status?.name ? '' : 'Active'
                  }`}
                >
                  {row?.order_status?.name}
                </span>
              </div>
            ),
            toolTip: getNestedProperty(row, key),
          }

        default:
          return {
            cell: getNestedProperty(row, key),
            toolTip: getNestedProperty(row, key),
          }
      }
    }

  return [
    {
      title: 'Order ID',
      field: 'order_number',
      ...defaultColumnProps,
      fixed: true,
      // link: true,
      // getPath: (row: any) => onViewAction(row, 'orderId'),
      sortKey: 'order_number',
    },
    {
      title: 'Vendor Name',
      field: 'order_number',
      ...defaultColumnProps,

      customCell: true,
      renderCell: createRenderCell('vendor.vendor_name', 'assign'),
      // fixed: true,
      // link: true,
      // getPath: (row: any) => onViewAction(row, 'orderId'),
      sortKey: 'order_number',
    },
    {
      title: 'Order Date',
      field: 'order_date',
      ...defaultColumnProps,
      type: 'date',
      customCell: true,
      // renderCell: createRenderCell('order.order_date'),
      sortKey: 'order_date',
      // customCell: true,
    },
    {
      title: 'Order CRE',
      field: 'order_cre',
      ...defaultColumnProps,
      customCell: true,
      renderCell: createRenderCell('order_cre', 'assign'),
      sortKey: 'assigned_to__user__first_name',
    },
    {
      title: 'Account CRE',
      field: 'account_cre',
      renderCell: createRenderCell('account.account_cre'),
      customCell: true,
      ...defaultColumnProps,
      sortKey: 'account__primary_agent',
    },
    {
      title: 'Account Name',
      field: 'business_name',
      renderCell: createRenderCell('account.business_name'),
      link: true,
      getPath: (row: any) => onViewAction(row, 'accountName'),
      ...defaultColumnProps,
      customCell: true,
      sortKey: 'account__business_name',
    },
    {
      title: 'Primary Contact',
      field: 'name',
      renderCell: createRenderCell('contact.name'),
      getPath: (row: any) => onViewAction(row, 'contactName'),
      link: true,
      ...defaultColumnProps,
      customCell: true,
      sortKey: 'contact__default_contact__first_name',
    },
    {
      title: 'Order Status',
      field: 'name',
      renderCell: createRenderCell('order_status.name', 'status'),
      ...defaultColumnProps,
      customCell: true,
      sortKey: 'order_status__name',
    },
    {
      title: 'Ageing',
      field: 'aging',
      // renderCell: createRenderCell('order_status.name', 'status'),
      ...defaultColumnProps,
      // customCell: true,
      // sortKey: 'order_status__name',
    },
    {
      title: 'Order Value',
      field: 'grand_total',
      ...defaultColumnProps,
      renderCell: createRenderCell('grand_total', 'amount'),
      customCell: true,
      align: 'right',
      sortKey: 'grand_total',
    },
    {
      title: 'Amount Paid',
      field: 'paid_amount',
      renderCell: createRenderCell('paid_amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps,
      sortKey: 'paid_amount',
    },
    {
      title: 'Amount Due',
      field: 'remaining_amount',
      renderCell: createRenderCell('remaining_amount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps,
      sortKey: 'remaining_amount',
    },
    {
      title: 'Allocation',
      field: 'allotted_services_count',
      renderCell: createRenderCell('allotted_services_count', 'Allocation'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Service Names',
      renderCell: createRenderCell('order_items.name'),
      field: 'order_cre',
      ...defaultColumnProps,
      customCell: true,
      sortKey: 'order_items__service_name',
    },
    {
      title: 'Service Count',
      field: 'total_services_count',
      ...defaultColumnProps2,
    },
    {
      title: 'Service Allocated',
      field: 'allotted_services_count',
      ...defaultColumnProps2,
    },
    {
      title: 'Professional Fee',
      field: 'professional_fee_total',
      renderCell: createRenderCell('professional_fee_total', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps2,
      sortKey: 'professional_fee_total',
    },
    {
      title: 'Vendor Fee',
      field: 'vendor_fee_total',
      renderCell: createRenderCell('vendor_fee_total', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps2,
      sortKey: 'vendor_fee_total',
    },
    {
      title: 'Government Fee',
      field: 'govt_fee_total',
      ...defaultColumnProps2,
      renderCell: createRenderCell('govt_fee_total', 'amount'),
      customCell: true,
      align: 'right',
      sortKey: 'govt_fee_total',
    },
    {
      title: 'GST',
      field: 'tax',
      renderCell: createRenderCell('tax', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps2,
      sortKey: 'tax',
    },
    {
      title: 'Discount',
      field: 'discount',
      renderCell: createRenderCell('discount', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps2,
      sortKey: 'discount',
    },
    {
      title: 'Source of Sale',
      field: 'lead_source',
      ...defaultColumnProps2,
      sortKey: 'lead_source__name',
    },
    {
      title: 'Spot Incentive',
      field: 'spot_incentive',
      renderCell: createRenderCell('spot_incentive', 'amount'),
      customCell: true,
      align: 'right',
      ...defaultColumnProps2,
      sortKey: 'spot_incentive',
    },
    {
      title: 'Internal Campaign',
      field: 'internal_campaign_id',
      ...defaultColumnProps,
      sortKey: 'internal_campaign',
    },

    {
      title: 'Created On',
      field: 'datetime_created',
      ...defaultColumnProps,
      type: 'text',
      renderCell: createRenderCell('datetime_created', 'fulldate'),
      customCell: true,
      sortKey: 'datetime_created',
    },
    {
      title: 'Updated On',
      field: 'datetime_updated',
      sortKey: 'datetime_updated',
      type: 'text',
      renderCell: createRenderCell('datetime_updated', 'fulldate'),
      customCell: true,
      ...defaultColumnProps,
    },
    {
      title: 'Created By',
      field: 'created_by',
      ...defaultColumnProps,
      sortKey: 'created_by__user__first_name',
    },
  ]
}
