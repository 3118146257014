import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import FileAttachments from '../../../../components/app/attachments'
import { checkDetailPermission } from '../../permission'

export default function ContactAttachments() {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
  }>()
  const params = useParams()
  const [data, setData] = useState<any>()

  useEffect(() => {
    setActiveTab('CONTACT_FILES_AND_ATTACHMENTS')
    setData({
      contact_id: params.id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FileAttachments
      model_name="Customer"
      checkModulePermission={checkDetailPermission}
      fromData={data}
    />
  )
}
