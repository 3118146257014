import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { fetchAccountContacts, getAccounts } from '../../../../apis/common.apis'
import FormBuilder from '../../../../components/app/formBuilder/index'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import FormLinkView from '../../../../components/common/inputs/FormLink'
import { router_config } from '../../../../configs/route.config'
import { getLeadSource, useEditProspect } from '../../api'
import { formSchema, ProspectSchema } from '../../create/prospectSchema'
import { checkPermission } from '../../permission'
import { convertUTCtoBrowserTimeZone } from '../../../../utilities/format'

type Props = {
  data: any
  id: string
  edit?: boolean
  disabled?: boolean
  handleRefresh: () => void
}
const BasicInformation = (props: Props) => {
  const { data, id, handleRefresh, disabled = false } = props
  const [isEditing, setIsEditing] = useState(false)
  const [accountPage, setAccountPage] = useState(1)

  const [contactPage, setContactPage] = useState(1)
  // const [employeePage, setEmployeePage] = useState(1)
  const [leadPage, setLeadPage] = useState(1)

  const getAcccount = async (search?: string, page?: number) => {
    const data = await getAccounts({
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })
    const next = data.next ? accountPage + 1 : 0
    setAccountPage(next)
    return data?.results
  }

  const getContact = async (search?: string, page?: number) => {
    const { data } = await fetchAccountContacts(
      {
        search: search as string,
        page: page as number,
        type: 'dropdown',
      },
      methods.watch()?.account_id
    )
    const next = data.next ? contactPage + 1 : 0
    setContactPage(next)
    return data?.map((items: any) => ({
      ...items,
      name:
        items.customer_info.first_name + ' ' + items.customer_info.last_name,
    }))
  }

  // const loadEmployeeList = async (value?: string, page?: number) => {
  //   const datas = await getEmployeeList({
  //     search: value ?? '',
  //     page: page as number,
  //   })
  //   const next = datas.next ? employeePage + 1 : 0
  //   setEmployeePage(next)
  //   return datas?.results?.map((item: any) => ({
  //     ...item,
  //     employee_name: item.name,
  //   }))
  // }

  const getLead = async (value?: string, page?: number) => {
    const { data } = await getLeadSource({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    const next = data.next ? leadPage + 1 : 0
    setLeadPage(next)
    return data?.map((item: any) => ({
      ...item,
      lead_source_name: item.name,
    }))
  }

  const onInit = () => {
    return {
      account_id: data?.account?.id,
      account_name: data?.account?.business_name,
      primary_cre: data?.primary_cre?.name,
      primary_cre_id: data?.primary_cre?.id,

      contact_id: data?.contact?.id,
      contact_name: data?.contact?.name,

      prospect_number: data?.deal_number,
      amount:
        data?.total_amount && data.total_amount !== ''
          ? Number(data.total_amount)
          : 0,

      target_date: data?.target_date ? new Date(data?.target_date) : undefined,
      next_followup_date: data?.next_followup_date
        ? new Date(data?.next_followup_date)
        : undefined,

      deal_source_id: data?.deal_source?.id ?? null,
      deal_source: data?.deal_source?.name ?? null,

      total_services_count: data?.total_services_count,
      spot_incentive: data?.spot_incentive,
      datetime_created: convertUTCtoBrowserTimeZone(data?.datetime_created),
      datetime_updated: convertUTCtoBrowserTimeZone(data?.datetime_updated),
      created_by: data?.created_by?.name,
      remarks: data?.remarks ?? '',
      probability:
        data?.probability && data.probability !== ''
          ? Number(data.probability)
          : undefined,
    }
  }

  const methods = useForm<ProspectSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const handleUpdate = async (count: any) => {
    if (count > 0) {
      const data = await getLead()
      const item = data?.find((items: any) => items.name === 'Existing')
      methods.setValue('deal_source', item.name)
      methods.setValue('deal_source_id', item.id)
    } else {
      methods.setValue('deal_source', null)
      methods.setValue('deal_source_id', null)
    }
  }
  const handleAccountCallback = useCallback((sel: any) => {
    handleUpdate(sel?.active_order_count)

    methods.setValue('contact_id', '')
    methods.setValue('contact_name', '', {
      shouldValidate: true,
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: string,
    required = false,
    type = 'text',
    disabled = false,
    spacing?: number
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    value,
    spacing,
    ...(required ? { required: true } : {}),
    ...(disabled ? { disabled: true } : {}),
  })

  const { account_id } = methods.watch()
  const formBuilderProps = [
    textField(
      'prospect_number',
      'Prospect ID',
      'Enter Prospect ID',
      data?.prospect_number !== null
        ? data?.prospect_number
        : data?.deal_number,
      true,
      'text',
      true,
      6
    ),
    textField(
      'amount',
      'Prospect Value',
      'Enter prospect value',
      data?.total_amount ?? '0.00',
      true,
      'number',
      true,
      6
    ),

    {
      name: 'account_name',
      label: 'Account',
      required: true,
      getData: getAcccount,
      handleCallBack: handleAccountCallback,
      async: true,
      id: 'account_id',
      link: `${router_config.ACCOUNTS.path}/${data?.account?.id}/summary`,

      paginationEnabled: true,
      nextBlock: accountPage,
      descId: 'id',
      initialLoad: true,
      desc: 'business_name',
      type: 'auto_complete',
      placeholder: 'Enter Account',
      value: data?.account?.business_name,
      spacing: 6,
    },
    {
      name: 'contact_name',
      label: 'Contact',
      required: true,
      getData: getContact,
      async: true,
      id: 'contact_id',
      paginationEnabled: true,
      nextBlock: contactPage,
      descId: 'customer_id',
      link: `${router_config.CONTACTS.path}/${data?.contact?.id}/summary`,

      initialLoad: true,
      desc: 'name',
      disabled: !account_id,
      type: 'auto_complete',
      placeholder: 'Enter Contact',
      value: data?.contact?.name,
      spacing: 6,
    },
    {
      name: 'deal_source',
      label: 'Source Of Sale',
      id: 'deal_source_id',
      desc: 'name',
      descId: 'id',
      getData: getLead,
      async: true,
      initialLoad: true,
      paginationEnabled: true,
      nextBlock: leadPage,
      type: 'auto_complete',
      placeholder: 'Enter Source Of Sale',
      value: data?.deal_source?.name,
      required: false,
      spacing: 6,
    },
    textField(
      'probability',
      'Probability %',
      'Enter Probability %',
      data?.probability,
      true,
      'number',
      false,
      6
    ),

    {
      ...textField(
        'target_date',
        'Target Date',
        'Enter Target Date',
        data?.target_date ? moment(data?.target_date).format('DD-MM-YYYY') : '',
        true
      ),
      spacing: 6,
      type: 'date',
      minDate: new Date(),
    },
    {
      ...textField(
        'next_followup_date',
        'Next Followup Date',
        'Enter next followup date',
        data?.next_followup_date
          ? moment(data?.next_followup_date).format('DD-MM-YYYY')
          : '',
        true
      ),
      spacing: 6,
      minDate: new Date(),
      type: 'date',
    },

    {
      ...textField(
        'pipeline_days',
        'Days in Pipeline',
        'Enter Days in Pipeline',
        data?.pipeline_days
      ),
      type: 'number',
      spacing: 6,
    },
    textField(
      'created_by',
      'Created By',
      'Enter Created By',
      data?.created_by?.name,
      true,
      'text',
      true,
      6
    ),
    {
      ...textField(
        'datetime_created',
        'Created On',
        'Created On',
        convertUTCtoBrowserTimeZone(data?.datetime_created)
      ),
      maxDate: new Date(),
      type: 'text',
      required: true,
      spacing: 6,
      disabled: true,
    },
    {
      ...textField(
        'datetime_updated',
        'Updated On',
        'Created On',
        convertUTCtoBrowserTimeZone(data?.datetime_updated)
      ),
      maxDate: new Date(),
      type: 'text',
      required: true,
      spacing: 6,
      disabled: true,
    },

    textField(
      'remarks',
      'Remarks',
      'Enter Remarks',
      data?.remarks,
      false,
      'textarea',
      false,
      12
    ),
  ]

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const { mutate, isLoading } = useEditProspect(handleSubmission)
  const linkDatas = [
    ...(data?.lead?.id
      ? [
          {
            label: 'Connected Lead',
            value: data?.lead?.name,
            id: data?.lead?.id,
            slug: 'lead',
          },
        ]
      : []),

    ...(data?.quote?.id
      ? [
          {
            label: 'Connected Quote',
            value: data?.quote?.order_number,
            id: data?.quote?.id,
            slug: 'quote',
          },
        ]
      : []),

    ...(data?.deal_number
      ? [
          {
            label: 'Connected Deal',
            value: data?.deal_number,
            id: data?.id,
            slug: 'deal',
          },
        ]
      : []),
    // { label: 'Connected Order', value: data?.order?.name, id: data?.lead?.id,slug:'order' },
    // { label: 'Connected Prospect', value: data?.prospect?.name, id: data?.prospect?.id,slug:'prospect' },
  ]
  const onSubmit = (data: ProspectSchema) => {
    mutate({
      input: {
        ...data,
        target_date: data?.target_date
          ? moment(data.target_date).format('YYYY-MM-DD')
          : null,
        next_followup_date: data?.next_followup_date
          ? moment(data.next_followup_date).format('YYYY-MM-DD')
          : null,
        remarks: data?.remarks && data?.remarks !== '' ? data?.remarks : null,
        probability: String(data?.probability),
        deal_source_id: data?.deal_source_id ? data?.deal_source_id : null,
        deal_source: data?.deal_source ? data?.deal_source : null,
      },
      id,
    })
  }
  const { handleSubmit } = methods

  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={isEditing} spacing />
          </FormProvider>
        ) : (
          <>
            {formBuilderProps.map((basicInformation: any) => (
              <div
                key={data?.id}
                className={`col-span-12 md:col-span-${
                  basicInformation.spacing || 12
                }`}
              >
                <FormFieldView
                  value={basicInformation.value}
                  link={basicInformation.link}
                  type={basicInformation?.type}
                  required={basicInformation?.required}
                  label={basicInformation.label}
                />
              </div>
            ))}
          </>
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText">Basic Information</h4>
        </div>
        {checkPermission('change') && (
          <div className="iconBlack cursor-pointer">
            {isEditing ? (
              <div className="flex gap-2">
                <div>
                  <Button
                    onClick={handleCancel}
                    size="xs"
                    label="Cancel"
                    outlined={true}
                  />
                </div>
                <div>
                  <Button
                    size="xs"
                    label="Save"
                    isLoading={isLoading}
                    outlined={false}
                    onClick={handleSubmit((data) => onSubmit(data))}
                  />
                </div>
              </div>
            ) : (
              <>
                {!disabled && <Icons onClick={handleEditClick} name="edit" />}
              </>
            )}
          </div>
        )}
      </div>
      <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
      <FormLinkView data={linkDatas} />
    </div>
  )
}

export default BasicInformation
