import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'

export const useFranchiseeServiceFilterStore =
  create<FranchiseeServiceFilterstoreType>()(
    persist(
      (set) => ({
        pageParams: defaultPageParams,
        selectedRows: [],

        setPageParams: (data) => set({ pageParams: data }),
        setSelectedRows: (data) => set({ selectedRows: data }),
        clearSelectedRows: () => set({ selectedRows: [] }),

        clearFranchiseeServiceFilter: (key) =>
          set((state) => ({
            pageParams: key
              ? { ...defaultPageParams, search: state?.pageParams?.search }
              : defaultPageParams,
          })),
      }),
      { name: 'fit__franchisee_service_store' }
    )
  )

interface FranchiseeServiceFilterstoreType {
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]
  setPageParams: (data: StoreFilterParams) => void
  setSelectedRows: (data: (number | string)[]) => void
  clearFranchiseeServiceFilter: (key?: string) => void
  clearSelectedRows: () => void
}
