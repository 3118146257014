import { z } from 'zod'

const MAX_FILE_SIZE = 50000000

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const attachmentsSchema = z.object({
  status_name: z.string(),
  status: z.string(),
  remark: z.string().nullable().optional(),
  attachments: z
    .instanceof(File, { message: 'Attachment required' })
    .refine((files: File) => files.size <= MAX_FILE_SIZE, {
      message: 'Maximum size 5mb',
    })
    .refine((files: File) => ACCEPTED_IMAGE_TYPES.includes(files?.type), {
      message: 'File type not allowed',
    })
    .array()
    .nullable()
    .optional(),
})

export type AttachmentsSchema = z.infer<typeof attachmentsSchema>
