import moment from 'moment'
import { useEffect, useState } from 'react'

import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
  hasPermission?: boolean
}
const BasicInformation = (props: Props) => {
  const { data } = props
  const [detailData, setDetailData] = useState<any>([])
  const formBuilderProps = [
    {
      label: 'Activity Name',
      value: data?.display_name,
      required: true,
      spacing: 12,
    },
    {
      label: 'Remarks',
      value: data?.remarks,
      required: false,
      spacing: 6,
    },
    {
      label: 'Target Date',
      value: data?.expected_completion_date
        ? moment(data?.expected_completion_date).format('DD-MM-YYYY')
        : '--',
      required: false,
      spacing: 6,
    },
    {
      label: 'Assigned On',
      value: data?.assigned_at
        ? moment(data?.assigned_at).format('DD-MM-YYYY')
        : '--',
      required: true,
      spacing: 6,
    },
    {
      label: 'Assigned To',
      value: data?.assignee?.name,
      required: true,
      spacing: 6,
    },
  ]
  useEffect(() => {
    setDetailData(formBuilderProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const renderFields = () => {
    return (
      <>
        {detailData.map((basicInformation: any) => (
          <div
            key={data?.id}
            className={`col-span-12 md:col-span-${
              basicInformation.spacing || 12
            }`}
          >
            <FormFieldView
              value={basicInformation.value}
              type={basicInformation?.type}
              required={basicInformation?.required}
              label={basicInformation.label}
            />
          </div>
        ))}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText">Basic Information</h4>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
    </div>
  )
}

export default BasicInformation
