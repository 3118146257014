import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

// import { getOrderDetails } from '../../api'
import BasicInformation from './basicInformation'
import PricingInformation from './pricingInformation'

const QuoteSummary = () => {
  const [data, setData] = useState<any>()
  const params = useParams()

  const { setActiveTab, handleParentCallBack, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack: () => void
    detailsData: any
  }>()

  useEffect(
    () => {
      setActiveTab('QUOTE_DETAILS_SUMMARY')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setData(detailsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsData])

  const handleRefresh = () => {
    handleParentCallBack?.()
  }

  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation
        data={data || {}}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
      />
      <PricingInformation
        data={data || []}
        // id={params.id ?? ''}
        // handleRefresh={handleRefresh}
      />
    </div>
  )
}

export default QuoteSummary
