import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { AdvanceFilter, StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'
import { isAllFranchisee } from '../../configs/permissionGate'

const defaultParams = [
  { title: 'Service Name', slug: 'service_name', isChecked: false },
  { title: 'Quote Value', slug: 'order_value', isChecked: false },
  { title: 'Created Date', slug: 'created_date', isChecked: false },
  { title: 'Created By', slug: 'created_by', isChecked: false },
  { title: 'Ageing', slug: 'aging', isChecked: false },
  { title: 'Team', slug: 'team', isChecked: false },
  ...(isAllFranchisee()
    ? [{ title: 'Franchisee', slug: 'franchisee_id', isChecked: false }]
    : []),
]
const filterDefaultPrams = {
  ...defaultPageParams,
  // filterProps: {
  //   created_to_date: '',
  //   created_from_date: '',
  //   endDate: '',
  //   startDate: '',
  // },
}

export const useQuoteFilterStore = create<QuoteFilterstoreType>()(
  persist(
    (set) => ({
      pageParams: filterDefaultPrams,
      advanceFilter: {
        filterParams: defaultParams,
        isDetailed: true,
      },
      selectedRows: [],

      // Actions
      setAdvanceFilter: (data) => set({ advanceFilter: data }),
      setPageParams: (data) => set({ pageParams: data }),
      setSelectedRows: (data) => set({ selectedRows: data }),
      clearSelectedRows: () => set({ selectedRows: [] }),
      clearAdvanceFilter: () =>
        set({
          advanceFilter: {
            filterParams: defaultParams?.map((item) => ({
              ...item,
              isChecked: false,
            })),
            isDetailed: true,
          },
        }),
      clearQuoteFilter: (key) =>
        set((state) => ({
          pageParams: key
            ? { ...filterDefaultPrams, search: state?.pageParams?.search }
            : filterDefaultPrams,
        })),
    }),
    { name: 'fit__quote_store' }
  )
)

interface QuoteFilterstoreType {
  advanceFilter: AdvanceFilter
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]
  // Actions
  setAdvanceFilter: (data: AdvanceFilter) => void
  setPageParams: (data: StoreFilterParams) => void
  setSelectedRows: (data: (number | string)[]) => void
  clearQuoteFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
