import React from 'react'
import { IconProps } from '../../../common/types'

const PaymentReceiptIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1665 5.69504C15.1665 6.74837 14.4998 7.41504 13.4465 7.41504H11.1665V2.75504C11.1665 2.01504 11.7732 1.41504 12.5132 1.41504C13.2398 1.42171 13.9065 1.71504 14.3865 2.19504C14.8665 2.6817 15.1665 3.34837 15.1665 4.08171V5.69504Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.8335 14.0817C1.8335 14.635 2.46016 14.9484 2.90016 14.615L4.04016 13.7617C4.30683 13.5617 4.68016 13.5884 4.92016 13.8284L6.02683 14.9417C6.28683 15.2017 6.7135 15.2017 6.9735 14.9417L8.0935 13.8217C8.32683 13.5884 8.70016 13.5617 8.96016 13.7617L10.1002 14.615C10.5402 14.9417 11.1668 14.6284 11.1668 14.0817V2.74837C11.1668 2.01504 11.7668 1.41504 12.5002 1.41504H5.16683H4.50016C2.50016 1.41504 1.8335 2.60837 1.8335 4.08171V4.74837V14.0817Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 8.75586H8.5"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 6.08887H8.5"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.49707 8.74902H4.50306"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.49707 6.08203H4.50306"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PaymentReceiptIcon
