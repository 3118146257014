import React from 'react'
import { IconProps } from '../../../common/types'

const CheckCircleIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6501 8.03477C13.6501 11.1552 11.1205 13.6848 8.0001 13.6848C4.87969 13.6848 2.3501 11.1552 2.3501 8.03477C2.3501 4.91436 4.87969 2.38477 8.0001 2.38477C11.1205 2.38477 13.6501 4.91436 13.6501 8.03477ZM11.4961 7.53078C12.1014 6.92547 12.1014 5.94406 11.4961 5.33875C10.8908 4.73344 9.90939 4.73344 9.30408 5.33875L7.2001 7.44273L6.69611 6.93875C6.0908 6.33344 5.10939 6.33344 4.50408 6.93875C3.89877 7.54406 3.89877 8.52547 4.50408 9.13078L6.10408 10.7308C6.70939 11.3361 7.6908 11.3361 8.29611 10.7308L11.4961 7.53078Z"
        fill="#299957"
        stroke="#299957"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default CheckCircleIcon
