import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FormProvider } from 'react-hook-form'

import { getService } from '../../../apis/common.apis'
import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import { getServiceTotal, numberFormat } from '../../../utilities/parsers'
import { useAddService } from '../api'
import { serviceItemSchema, ServiceItemSchema } from '../create/dealSchema'
import { useAuthStore } from '../../../store/authStore'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback?: (data: any) => void
  id?: string
}

export default function Create({
  isDrawerOpen,
  handleClose,
  handleCallback,
  subSection,
  id,
}: Props) {
  const [servicePage, setServicePage] = useState(1)

  const onInit = () => {
    return {
      rounding: null,
      service_total: null,
    }
  }

  const methods = useForm<ServiceItemSchema>({
    resolver: zodResolver(serviceItemSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getServiceList = async (search?: string, page?: number) => {
    const { data } = await getService({
      search: search as string,
      page: page as number,
    })
    const next = data.next ? servicePage + 1 : 0
    setServicePage(next)

    return data?.results
  }

  const getRounding = (value: any) => {
    if (!value) {
      return 0.0
    }
    const rounded_total: any = parseFloat(value).toFixed(0)
    const rounding = rounded_total - value
    return rounding
  }

  useEffect(() => {
    methods.setValue(
      'rounding',
      numberFormat(
        getRounding(
          getServiceTotal(
            methods.watch()?.professional_fee,
            methods.watch()?.govt_fee,
            methods.watch()?.gst_percent,
            methods.watch()?.discount_percent,
            methods.watch()?.vendor_fee as number
          )
        )
      )
    )
    methods.setValue(
      'service_total',
      numberFormat(
        parseFloat(
          getServiceTotal(
            methods.watch()?.professional_fee,
            methods.watch()?.govt_fee,
            methods.watch()?.gst_percent,
            methods.watch()?.discount_percent,
            methods.watch()?.vendor_fee as number
          )
        ).toFixed(0)
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods?.watch().professional_fee,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch()?.govt_fee,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch()?.gst_percent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch()?.discount_percent,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch()?.vendor_fee as number,
  ])

  const franchisee = useAuthStore.getState().franchisee

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text',
    disabled = false
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
    ...(disabled ? { disabled: true } : {}),
  })

  const formBuilderProps = [
    {
      name: 'service_name',
      label: 'Service Name',
      required: true,
      getData: getServiceList,
      async: true,
      id: 'service_id',
      paginationEnabled: true,
      nextBlock: servicePage,
      descId: 'id',
      initialLoad: true,
      disabled: false,
      desc: 'service_name',
      type: 'auto_complete',
      placeholder: 'Enter Service Name',
    },
    textField(
      'professional_fee',
      'Professional Fee',
      'Enter Professional Fee',
      true,
      'number'
    ),
    textField('vendor_fee', 'Vendor Fee', 'Enter Vendor Fee', false, 'number'),
    textField(
      'govt_fee',
      'Government Fee',
      'Enter Government Fee',
      true,
      'number'
    ),
    ...(!!franchisee?.has_gst
      ? [
          textField(
            'gst_percent',
            'GST Percentage',
            'Enter GST Percentage',
            true,
            'number'
          ),
        ]
      : []),
    textField(
      'discount_percent',
      'Discount Percentage',
      'Enter Discount Percentage',
      true,
      'number'
    ),
    textField('rounding', 'Rounding', 'Enter Rounding', false, 'text', true),
    textField(
      'service_total',
      'Service Total',
      'Enter Service Total',
      false,
      'text',
      true
    ),
  ]

  const handleSubmission = () => {
    methods.reset(onInit())
    handleClearAndClose()
  }

  const { mutate, data, isLoading } = useAddService(handleSubmission)
  const onSubmit = (data: ServiceItemSchema) => {
    const input = {
      service: [
        {
          discount_percent: data.discount_percent,
          govt_fee: data.govt_fee,
          gst_percent: data.gst_percent,
          service_id: data.service_id ?? '',
          professional_fee: data.professional_fee,
          vendor_fee: data.vendor_fee ?? null,
        },
      ],
    }
    mutate({ input: input, id: id ? id : '' })
  }

  useEffect(() => {
    if (subSection) handleCallback?.(data)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      actionLoader={isLoading}
      handleSaveAndContinue={
        subSection ? undefined : handleSubmit((data) => onSubmit(data))
      }
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      disableSubmit={isLoading}
      title="Add New Service"
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
