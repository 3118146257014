import React from 'react'
import { IconProps } from '../../../common/types'

const PreviousMost: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.6 12.2L1 6.59995L6.6 0.999953M13 12.2L7.4 6.59995L13 0.999953"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PreviousMost
