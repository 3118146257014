import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'

import { deepDebounce } from '../../../utilities/deepDebounce'
import { getLeadInfoDuplicate } from '../api'

let cancelTokenSource: CancelTokenSource | null = null

export const getLeadInfoDuplicateData = deepDebounce(
  async (
    fieldName: string,
    value?: string,
    lead_id?: string
  ): Promise<any[]> => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Operation cancelled due to new request.')
    }

    cancelTokenSource = axios.CancelToken.source()
    const config: AxiosRequestConfig = {
      cancelToken: cancelTokenSource.token,
    }

    try {
      const response = await getLeadInfoDuplicate(
        {
          [fieldName]: value,
          lead_id: lead_id ? lead_id : '',
        },
        config
      )
      const data = response?.data?.map((item: any) => ({
        ...item,
        phone: item?.phone,
        phone2: item?.lead_additional_data?.phone_2,
        email: item?.email,
        email2: item?.lead_additional_data?.email_2,
        name: item?.name,
        business_name: item?.business_name,
        service_name:
          item?.service?.length > 1
            ? item?.service
                ?.map((service: any) => service.service_name)
                ?.join(', ')
            : item?.service?.[0]?.service_name,
      }))
      return data
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request cancelled', error.message)
      } else {
        console.error('An error occurred:', error)
      }
      return [] // return an empty array or handle the error as needed
    }
  },
  1000
)
