import React from 'react'
import { IconProps } from '../../../common/types'

const RaiseMissingIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.83303 6.2168V7.55013M8.83303 10.2168H8.8397M4.21423 12.8835H13.4518C14.4782 12.8835 15.1197 11.7724 14.6065 10.8835L9.98773 2.88346C9.47453 1.99457 8.19153 1.99457 7.67833 2.88346L3.05953 10.8835C2.54633 11.7724 3.18783 12.8835 4.21423 12.8835Z"
        stroke="#313131"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RaiseMissingIcon
