import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import EventComponent from '../../../../components/app/eventlogs'
import { checkDetailPermission } from '../../permission'

const ServiceLog = () => {
  const { setActiveTab, disabled } = useOutletContext<{
    setActiveTab: (id: string) => void
    disabled: boolean
  }>()

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_LOGS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <EventComponent
      model_name="OrderItem"
      disabled={disabled}
      checkModulePermission={checkDetailPermission}
    />
  )
}

export default ServiceLog
