import React from 'react'
import { IconProps } from '../../../common/types'

const DashboardIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.14124 8.37276C2.36124 8.99276 1.86124 10.3028 2.03124 11.2828L3.36124 19.2428C3.60124 20.6628 4.96124 21.8128 6.40124 21.8128H17.6012C19.0312 21.8128 20.4012 20.6528 20.6412 19.2428L21.9712 11.2828C22.1312 10.3028 21.6312 8.99276 20.8612 8.37276L13.9312 2.83278C12.8612 1.97278 11.1312 1.97277 10.0712 2.82277L3.14124 8.37276Z"
        stroke="#999696"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0012 15.5027C10.6205 15.5027 9.50122 14.3834 9.50122 13.0027C9.50122 11.622 10.6205 10.5027 12.0012 10.5027C13.3819 10.5027 14.5012 11.622 14.5012 13.0027C14.5012 14.3834 13.3819 15.5027 12.0012 15.5027Z"
        stroke="#999696"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DashboardIcon
