export const handleError = (err: any) => {
  const HTTP_NOT_FOUND = 404
  const HTTP_403_FORBIDDEN = 403
  if (err?.response?.status === HTTP_NOT_FOUND) {
    window.location.href = '/dashboard'
  } else if (err?.response?.status === HTTP_403_FORBIDDEN) {
    return false
  } else {
    return true
  }
}

export const getFileNameFromUrl = (url: any) => {
  const urlObj = new URL(url)
  const pathSegments = urlObj.pathname.split('/')
  const fileNameWithExtension = pathSegments[pathSegments.length - 1]

  // Decode any URL-encoded characters in the file name
  const decodedFileName = decodeURIComponent(fileNameWithExtension)

  return decodedFileName
}

export const downloadFromServer = async (link: string, name?: string) => {
  if (link) {
    try {
      const response = await fetch(link, {
        headers: new Headers({
          Origin: window.location.origin,
        }),
        mode: 'cors',
      })

      if (!response.ok) throw new Error('Network response was not ok.')

      const blob = await response.blob()

      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = name ?? getFileNameFromUrl(link)
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (error) {
      console.error('Download failed:', error)
    }
  }
}

export const generateFileExtension = (file: string) => {
  return file.split('.').pop()
}

export const isValidFile = (file?: string, support?: string[]) => {
  if (file && support) {
    return support.some(
      (format: string) => format.toLowerCase() === file.toLowerCase()
    )
  }
  return false
}
