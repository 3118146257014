import { zodResolver } from '@hookform/resolvers/zod'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import FormBuilder from '../../../../components/app/formBuilder'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { getAllServiceList } from '../../../order/api'
import {
  getbusIndusData,
  getbusinsector,
  getSubIndusData,
  useUpdateLead,
} from '../../api'
import { getLeadInfoDuplicateData } from '../../common/commonUtils'
import { duplicateFields } from '../../store'
import { handleLeadBusinessInfoInput, handleOnInitData } from './leadUtils'
import { leadBusinessInfo, LeadBusinessInfo } from './schema'

// import { Link } from 'react-router-dom'
// import { router_config } from '../../../../configs/route.config'

type Props = {
  data?: any
  id?: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  disabled?: boolean
}
const BusinessInformation = (props: Props) => {
  // const { enqueueSnackbar } = useSnackbarManager()
  const { data, id, handleRefresh, handleEditButton, disabled, editFlag } =
    props
  const [isEditing, setIsEditing] = useState(false)
  const [duplicates, setDuplicates] = useState<any>({})
  const params = useParams()
  const defaultPages: Record<string, number> = {
    sector: 1,
    subIndustry: 1,
    service: 1,
    industry: 1,
  }
  const [pages, setPages] = useState(defaultPages)
  const onInit = () => {
    const onInitData = handleOnInitData(data)
    return onInitData
  }
  const methods = useForm<LeadBusinessInfo>({
    resolver: zodResolver(leadBusinessInfo),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })

  const { industry_id, sector_id } = methods.watch()

  const getBusinessSector = async (value?: string, page?: number) => {
    const { data } = await getbusinsector({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setPages((prev) => ({
      ...prev,
      sector: data?.next ? prev['sector'] + 1 : 0,
    }))
    return data?.map((item: any) => ({
      ...item,
      sector_name: item.business_sector_name, // descname
    }))
  }
  const getIndustry = async (value?: string, page?: number) => {
    const { data } = await getbusIndusData({
      search: value ?? '',
      page: page as number,
      industry_only: true,
      business_sector_id: sector_id,
    })
    setPages((prev) => ({
      ...prev,
      industry: data?.next ? prev['industry'] + 1 : 0,
    }))
    return data?.results?.map((item: any) => ({
      ...item,
      industry_name: item.business_industry_display_name,
    }))
  }
  const getSubIndustry = async (value?: string, page?: number) => {
    const { data } = await getSubIndusData({
      search: value ?? '',
      page: page as number,
      sub_industry_only: true,
      industry_id: industry_id,
    })
    setPages((prev) => ({
      ...prev,
      subIndustry: data?.next ? prev['subIndustry'] + 1 : 0,
    }))
    return data.results.map((item: any) => ({
      ...item,
      sub_sector_name: item.business_industry_name,
    }))
  }
  const loadServiceList = async (value?: string, page?: number) => {
    const { data } = await getAllServiceList({
      search: value ?? '',
      page: page as number,
    })
    setPages((prev) => ({
      ...prev,
      service: data?.next ? prev['service'] + 1 : 0,
    }))
    return data?.results?.map((item: any) => ({
      ...item,
      service_name: item.service_name,
    }))
  }
  const handleSectorCalBack = () => {
    methods.setValue('industry_name', '', { shouldValidate: true })
    methods.setValue('industry_id', '', { shouldValidate: true })
    methods.setValue('sub_sector_name', '', { shouldValidate: true })
    methods.setValue('sub_sector_id', '', { shouldValidate: true })
  }
  const handleIndutryCallBack = () => {
    methods.setValue('sub_sector_name', '', { shouldValidate: true })
    methods.setValue('sub_sector_id', '', { shouldValidate: true })
  }
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    value: string,
    isDuplicateCheck = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    value,
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
  })
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const handleServiceValue = () => {
    if (data?.service?.length) {
      return data.service.map((item: any, i: number) => (
        <React.Fragment key={i}>
          {/* <Link to={`${router_config.SERVICE_AND_ORDERS_BY_SERVICE.path}/${item.id}/summary`}> */}
          <>
            {item.service_name}
            {data.service.length - 1 === i ? '' : ', '}
          </>
          {/* </Link> */}
        </React.Fragment>
      ))
    }
    return '--'
    return ''
    // if (data?.service?.length === 1) {
    //   value = [data.service[0].service_name]
    // } else if (data?.service?.length > 1) {
    //   const count = data?.service?.length - 1
    //   value = [
    //     <span key={0}>{data.service[0].service_name}</span>,
    //     <span
    //       key={1}
    //       className="p-1 bg-bgBadgeColor rounded-xl px-2 text-white ml-1"
    //     >
    //       +{count} more
    //     </span>,
    //   ]
    // } else {
    //   value = '--'
    // }

    // return value
  }
  const handleDuplicate = async (fieldName: string, value?: string) => {
    const newItemList: any[] = []
    const result = await getLeadInfoDuplicateData(fieldName, value, params?.id)
    if (result?.length > 0) {
      result.map((item: any) => {
        newItemList.push({
          name: item.name,
          phone: item?.phone,
          phone2: item?.lead_additional_data?.phone2,
          email: item?.email,
          email2: item?.lead_additional_data?.email2,
          business_name: item?.business_name,
          service_name: item?.service_name,
        })
      })
    }
    setDuplicates({ [fieldName]: newItemList ?? [] })
  }
  const handleClearDuplicates = (fieldName: string) => {
    setDuplicates({ [fieldName]: [] })
  }

  const formBuilderProps = [
    {
      ...textField(
        'business_name',
        'Business Name',
        'Enter Business Name',
        false,
        data?.business_name,
        true
      ),
      toCapitalize: true,
      spacing: 6,
      formSpacing: 6,
    },
    {
      name: 'sector_name',
      label: 'Sector',
      required: false,
      async: true,
      id: 'sector_id',
      descId: 'id',
      paginationEnabled: true,
      nextBlock: pages.sector ?? undefined,
      getData: getBusinessSector,
      handleCallBack: handleSectorCalBack,
      desc: 'sector_name',
      placeholder: 'Enter Sector',
      value: data?.sector?.business_sector_display_name,
      type: 'auto_complete',
      initialLoad: true,
      spacing: 6,
      formSpacing: 6,
    },
    {
      name: 'industry_name',
      id: 'industry_id',
      label: 'Industry',
      required: false,
      async: true,
      desc: 'industry_name',
      descId: 'id',
      paginationEnabled: true,
      nextBlock: pages.industry ?? undefined,
      getData: getIndustry,
      disabled: !sector_id,
      handleCallBack: handleIndutryCallBack,
      placeholder: 'Enter Industry',
      value: data?.industry?.business_industry_display_name,
      type: 'auto_complete',
      initialLoad: true,
      spacing: 6,
      formSpacing: 6,
    },
    {
      name: 'sub_sector_name',
      id: 'sub_sector_id',
      label: 'Sub Sector',
      required: false,
      getData: getSubIndustry,
      disabled: !industry_id,
      async: true,
      initialLoad: true,
      desc: 'sub_sector_name',
      descId: 'id',
      type: 'auto_complete',
      placeholder: 'Enter Sub Sector',
      value: data?.sub_sector?.business_industry_display_name,
      paginationEnabled: true,
      nextBlock: pages.subIndustry ?? undefined,
      spacing: 6,
      formSpacing: 6,
    },
    {
      name: 'service',
      id: 'service_id',
      descId: 'id',
      label: 'Interested Service',
      required: false,
      getData: loadServiceList,
      async: true,
      initialLoad: true,
      desc: 'service_name',
      type: 'multi_select',
      placeholder: 'Enter Service',
      // value: data?.service?.service_name,
      value: handleServiceValue(),
      selectedItems: data?.service,
      paginationEnabled: true,
      nextBlock: pages.service ?? undefined,
      isMultiple: true,
      spacing: 12,
      formSpacing: 6,
    },
  ]
  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const { mutate, isLoading } = useUpdateLead(handleSubmission)
  const onSubmit = (data: LeadBusinessInfo) => {
    const input = handleLeadBusinessInfoInput(data)
    mutate({
      input: { ...input, type: 'business_info' },
      id: id ? id : '',
    })
  }
  const { handleSubmit } = methods
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
    // handleRefresh()
  }
  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  const checkforErrors = () => {
    // if (
    //   methods?.formState?.errors?.next_followup_date ||
    //   methods.formState.errors.target_date
    // ) {
    //   if (
    //     methods?.formState?.errors?.next_followup_date &&
    //     methods.formState.errors.target_date
    //   ) {
    //     enqueueSnackbar(
    //       'Target Date and Next Followup Date of Lead Information is required',
    //       {
    //         variant: 'error',
    //       }
    //     )
    //   } else if (methods?.formState?.errors?.next_followup_date) {
    //     enqueueSnackbar('Next Followup Date of Lead Information is required', {
    //       variant: 'error',
    //     })
    //   } else {
    //     enqueueSnackbar('Target Date of Lead Information is required', {
    //       variant: 'error',
    //     })
    //   }
    // }
  }
  useEffect(() => {
    checkforErrors()
  }, [methods.watch(), methods.formState.errors])
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder
              data={formBuilderProps}
              edit={true}
              duplicates={duplicates}
              duplicateFields={duplicateFields}
              clearDuplicates={handleClearDuplicates}
              getDuplicates={handleDuplicate}
              spacing={true}
            />
          </FormProvider>
        ) : (
          formBuilderProps.map((data) => (
            <div
              key={data?.id}
              className={`col-span-12 md:col-span-${data.spacing || 12}`}
            >
              <FormFieldView
                value={data?.value}
                type={data?.type}
                // required={data.required}
                label={data?.label}
                key={data?.id}
              />
            </div>
          ))
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="phone-calling" />
          <h4 className=" font-bold text-primaryText">Business Information</h4>
        </div>
        <div className="iconBlack cursor-pointer">
          <div className="iconBlack cursor-pointer">
            {isEditing ? (
              <div className="flex gap-2">
                <div>
                  <Button
                    onClick={handleCancel}
                    size="xs"
                    label="Cancel"
                    outlined={true}
                  />
                </div>
                <div>
                  <Button
                    size="xs"
                    label="Save"
                    isLoading={isLoading}
                    outlined={false}
                    onClick={handleSubmit((data) => onSubmit(data))}
                  />
                </div>
              </div>
            ) : !editFlag && !disabled ? (
              <Icons onClick={handleEditClick} name="edit" />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
    </div>
  )
}

export default BusinessInformation
