import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import BasicInformation from './basicInfo'

const RoleDetails = () => {
  const { setActiveTab, stakeholderData } = useOutletContext<{
    setActiveTab: (id: string) => void
    stakeholderData: any
    handleParentCallback: () => void
  }>()
  const [data, setData] = useState<any>(stakeholderData)
  useEffect(
    () => {
      setActiveTab('SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setData(stakeholderData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stakeholderData])

  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation data={data} />
    </div>
  )
}

export default RoleDetails
