import { useEffect, useState } from 'react'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
}
const Collectables = (props: Props) => {
  const { data } = props
  const [detailData, setDetailData] = useState<any>([])
  const generateArrayToString = (col = [], key: string) => {
    const outputArray: string[] = []
    col.forEach((item: any, ind) => {
      outputArray.push(`${ind !== 0 ? ' ' : ''}${item[key]?.display_name}`)
    })
    return String(outputArray)
  }

  const formBuilderProps = [
    {
      label: 'Collectable List',
      value: generateArrayToString(
        data?.collectables,
        'service_checklist_collectable'
      ),
      required: true,
      spacing: 12,
    },
    {
      label: 'Deliverable List',
      value: generateArrayToString(
        data?.deliverables,
        'service_checklist_deliverable'
      ),
      required: true,
      spacing: 12,
    },
  ]
  useEffect(() => {
    setDetailData(formBuilderProps)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const renderFields = () => {
    return (
      <>
        {detailData.map((basicInformation: any) => (
          <div key={data?.id} className="col-span-12 md:col-span-6">
            <FormFieldView
              value={basicInformation.value}
              type={basicInformation?.type}
              required={basicInformation?.required}
              label={basicInformation.label}
            />
          </div>
        ))}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="collectable-secion" />
          <h4 className=" font-bold text-primaryText">
            Activity Collectables & Deliverables
          </h4>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
    </div>
  )
}

export default Collectables
