export const duplicateFields = {
  item1: { label: '', field: 'business_name', newline: true },
  item2: { label: '', field: 'customer_mobile' },
  item3: { label: '', field: 'mobile' },
  item4: { label: '', field: 'mobile_2', newline: true },
  item5: { label: '', field: 'customer_email' },
  item6: { label: '', field: 'email' },
  item7: { label: '', field: 'email_2', newline: true },
  // item8: { label: '', field: 'customer_name', newline: true },
  item9: { label: 'PAN No :', field: 'pan_no', newline: true },
  item10: {
    label: 'Primary Contact :',
    field: 'primaryContact',
    newline: true,
  },
}
