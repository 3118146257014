import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router-dom'

import InfoBox from '../../../components/app/alertBox/infoBox'
import MergeListing from '../../../components/app/merge/listing'
import MergePreview from '../../../components/app/merge/preview'
import ListingHeader from '../../../components/common/ListingTiles'
import DialogModal from '../../../components/common/modal/DialogModal'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { useDealFilterStore } from '../../../store/filterSore/dealStore'
import { getDealMergeDetails, mergeDeals } from '../api'
import { checkPermission } from '../permission'

const DealMerge = () => {
  const basicData = {
    title: 'Merge Deals',
    icon: 'merge',
  }
  const { selectedRows, reDirection, refetch } = useOutletContext<{
    selectedRows: (number | string)[]
    reDirection: string
    refetch: any
  }>()
  const { clearSelectedRows } = useDealFilterStore()
  const [selectedData, setSelctedData] = useState<any[]>([])
  const [parentId, setParentId] = useState<string>()
  const [openMerge, setOpenMerge] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbarManager()
  useEffect(() => {
    if (selectedRows && selectedRows?.length !== 0) {
      handleGetMergeDetails()
    } else {
      handleActionCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows])
  const getServiceText = (servicesArray: any[]) => {
    const contentArray: string[] = []
    servicesArray.forEach((item) => {
      contentArray.push(item?.service?.service_name)
    })
    return String(contentArray)
  }
  const handleGetMergeDetails = async () => {
    const { data } = await getDealMergeDetails({ deal_ids: selectedRows })
    setSelctedData(data)
    if (data) {
      const altData = data?.map((items: any) => {
        return {
          ...items,
          item1: items.account?.business_name ?? '',
          item2: items.contact?.name ?? '',
          item3: getServiceText(items.service || []) || ' --',
          item4: `Value : ${items.amount || 0}`,
          item5: `Stage : ${items.status?.name || '--'}`,
          // item2_sub: items.lead_additional_data.email2 ?? '',
          // item3_sub: items.lead_additional_data.mobile2 ?? '',
          // item4: items.business_name ?? '',
          checked: true,
        }
      })
      setSelctedData(altData)
    }
  }
  const navigate = useNavigate()

  const handleActionCancel = () => {
    navigate(`${reDirection}`)
    refetch?.()
  }
  const handleDisable = () => {
    return selectedData?.filter((item) => item.checked)?.length >= 2
      ? false
      : true
  }
  const headerProps = {
    hideExport: true,
    actionTitle: 'Merge',
    disableSubmit: handleDisable(),
    actionCancel: handleActionCancel,
  }
  const handleMergeAction = () => {
    if (!parentId) {
      enqueueSnackbar('Select parent Deal ', { variant: 'error' })
      return false
    }
    setOpenMerge(true)
  }
  const handleMerge = () => {
    const selectedDealIds = selectedData
      .filter((deal) => deal.id !== parentId)
      .map((deal) => deal.id)
    const payload = {
      children_deals: selectedDealIds,
    }
    mergeDeals(payload, parentId)
      .then((res) => {
        enqueueSnackbar(res.data, { variant: 'success' })
        setOpenMerge(false)
        handleActionCancel()
        clearSelectedRows()
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, { variant: 'error' })
      })
  }

  const handleCheckItems = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { checked } = e.target
    const altData: any[] = selectedData?.map((item: any, keyIndex: number) => {
      return index === keyIndex ? { ...item, checked: checked } : item
    })
    setSelctedData(altData)
  }
  const handleSetAsParent = (id?: string) => {
    setParentId(id)
  }
  const handlePreview = () => {
    return selectedData?.filter((items) => items.id === parentId) ?? []
  }

  return (
    <div>
      <ListingHeader
        data={basicData}
        onActionClick={handleMergeAction}
        actionProps={headerProps}
        checkPermission={checkPermission}
      />
      <div className="p-4 border-t border-[#D6D8DC] text-[#222222] bg-white dark:bg-black  dark:text-white font-bold flex flex-col gap-2.5 ">
        <div className="border border-formBorder p-5 rounded-[4px] flex flex-col gap-4 min-h-[calc(100vh-200px)]">
          <div className="grid grid-cols-12 gap-6">
            <div className=" col-span-12 lg:col-span-4">
              <InfoBox
                content={
                  'Merging deals is a way to eliminate duplicate deals in the system. After merging, only one deal, known as the Primary Deal, will be kept, and all other duplicate deals will be deleted. The Primary Deal will contain all the information from the duplicate deal, including notes, tasks and more.'
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-12 lg:col-span-6 flex flex-col gap-3">
              <h4 className="text-base text-primaryText font-bold">
                Selected Records
              </h4>
              <div className="flex flex-col gap-4">
                <MergeListing
                  data={selectedData}
                  from="Deal"
                  parentId={parentId}
                  handleCheckItems={handleCheckItems}
                  handleSetAsParent={handleSetAsParent}
                />
              </div>
            </div>
            <div className="col-span-12 lg:col-span-3 flex flex-col gap-3">
              <h4 className="text-base text-primaryText font-bold">
                Record after merging
              </h4>
              <div className="flex flex-col gap-4">
                <MergePreview data={handlePreview()} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogModal
        isOpen={openMerge}
        onClose={() => setOpenMerge(false)}
        title={'Are you sure?'}
        onSubmit={() => handleMerge()}
        secondaryAction={() => setOpenMerge(false)}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Are you sure you want to merge these Deals? This action is irreversible, and the merged deal will retain all related information from the duplicates.'
            }
          />
        }
      />
    </div>
  )
}

export default DealMerge
