import { useState } from 'react'
import {
  getPaymentType,
  getPaymentMethods,
  fetchSupplierData,
  fetchSupplierCategoryData,
} from './api'

export const GetFilterDetails = () => {
  const defaultPages: Record<string, number> = {
    pay_method: 1,
    pay_type: 1,
  }

  const [pages, setPages] = useState(defaultPages)

  const loadPaymentTypeList = async () => {
    const data = await getPaymentType({ show_in_franchisee: true })
    setPages((prev) => ({
      ...prev,
      pay_type: data?.next ? prev['pay_type'] + 1 : 0,
    }))

    return data?.data?.map((item: any) => ({
      ...item,
      payment_type_list: item.name,
    }))
  }

  const loadPaymentMethod = async () => {
    const data = await getPaymentMethods()
    setPages((prev) => ({
      ...prev,
      pay_method: data?.next ? prev['pay_method'] + 1 : 0,
    }))

    return data?.data?.map((item: any) => ({
      ...item,
      payment_method: item.name,
    }))
  }

  const getSuppliers = async (value: any) => {
    const resp = await fetchSupplierData({
      search: value ?? '',
      page: 0,
    })

    return resp?.results?.map((item: any) => ({
      ...item,
      supplier_name: item?.name,
    }))
  }

  const getSupplierCategory = async (value: any) => {
    const resp = await fetchSupplierCategoryData({
      search: value ?? '',
      page: 0,
    })

    return resp?.results?.map((item: any) => ({
      ...item,
      supplier_category_name: item?.name,
    }))
  }

  return [
    {
      type: 'date_picker',
      selectRange: true,
      desc: 'startDate',
      descSecondary: 'endDate',
      isPrimary: true,
      name: 'Payment Date',
      slug: 'transaction_date',
    },
    {
      desc: 'payment_type_list',
      type: 'auto_suggestion',
      descId: 'paymentTypeId',
      async: true,
      isPrimary: true,
      paginationEnabled: true,
      nextBlock: pages.pay_type ?? undefined,
      getData: loadPaymentTypeList,
      name: 'Payment Type',
      objectId: 'paymentout_type_id',
      slug: 'payment_type',
    },
    {
      desc: 'payment_method',
      type: 'auto_suggestion',
      descId: 'paymentMethodId',
      async: true,
      isPrimary: true,
      paginationEnabled: true,
      nextBlock: pages.pay_method ?? undefined,
      getData: loadPaymentMethod,
      name: 'Payment Method',
      objectId: 'paymentout_method_id',
      slug: 'payment_method',
    },
    {
      desc: 'supplier_name',
      type: 'auto_suggestion',
      descId: 'supplierId',
      async: true,
      isPrimary: false,
      paginationEnabled: false,
      getData: getSuppliers,
      name: 'Supplier',
      objectId: 'supplier_id',
      slug: 'supplier_name',
    },
    {
      desc: 'supplier_category_name',
      type: 'auto_suggestion',
      descId: 'supplierCategoryId',
      async: true,
      isPrimary: false,
      paginationEnabled: false,
      getData: getSupplierCategory,
      name: 'Supplier Category',
      objectId: 'supplier_category_id',
      slug: 'supplier_category_name',
    },
  ]
}
