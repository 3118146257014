import { z } from 'zod'

const MAX_FILE_SIZE = 50000000

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const attachmentsSchema = z
  .object({
    approval_type_name: z.string(),
    approval_type: z.string(),
    assignee_name: z.string(),
    requires_payment: z.boolean(),
    assignee: z.string(),
    amount: z.number().nullable().optional(),
    request_remark: z.string().nullable().optional(),
    target_date: z
      .union([
        z.date({ invalid_type_error: 'Target date is required' }),
        z.string({ invalid_type_error: 'Target date is required' }),
      ])
      .refine((val) => val !== '', {
        message: 'Target date is required',
      }),
    attachments: z
      .instanceof(File, { message: 'Attachment required' })
      .refine((files: File) => files.size <= MAX_FILE_SIZE, {
        message: 'Maximum size 5mb',
      })
      .refine((files: File) => ACCEPTED_IMAGE_TYPES.includes(files?.type), {
        message: 'File type not allowed',
      })
      .array()
      .nullable()
      .optional(),
  })
  .superRefine((data, ctx) => {
    if (data.requires_payment) {
      if (!data.amount || data.amount === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Amount should be greater than 0',
          path: ['amount'],
        })
      } else if (isNaN(Number(data.amount))) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Enter a valid amount',
          path: ['amount'],
        })
      } else if (data.amount <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Amount should be greater than 0',
          path: ['amount'],
        })
      }
    }
  })

export type AttachmentsSchema = z.infer<typeof attachmentsSchema>
