import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import WebSocketComponent from './components/app/websocket/index'
import { useSnackbarManager } from './components/common/snackbar'
import Maintenance from './pages/maintenance/index'
import MainRoutes from './routes/mainRoutes'
import { useAppStore } from './store/appStore'
import { useAuthStore } from './store/authStore'
// import useIdleTimeout from './utilities/idleTimer'
import { useClearFilter } from './store/filterSore/clearStore'
import { useThemeStore } from './store/themeStore'
import {
  useReloadOnStorageChange,
  useTabVisibility,
} from './utilities/visibility'

import './App.css'
import './styles/styles.scss'

// function isTodaySaturday() {
//   const today = new Date()
//   return today.getDay() === 6
// }
const App = () => {
  const { isDark } = useThemeStore()

  const { enqueueSnackbar } = useSnackbarManager()
  const navigate = useNavigate()
  const { clearAuthenticated } = useAuthStore()
  const { setUnderMaintenance, underMaintenance } = useAppStore()
  const handleClear = useClearFilter()

  useEffect(() => {
    const handleNavigation = (e: any) => {
      const { path } = e?.detail
      navigate(path)
    }

    window.addEventListener('navigate', handleNavigation)

    return () => {
      window.removeEventListener('navigate', handleNavigation)
    }
  }, [navigate])

  // useEffect(() => {
  //   if (isTodaySaturday()) {
  //     const lastLogoutDate = localStorage.getItem('lastAutoLogoutDate')
  //     const today = new Date().toISOString().slice(0, 10)

  //     if (lastLogoutDate !== today) {
  //       clearAuthenticated()
  //       handleClear()
  //       localStorage.clear()

  //       localStorage.setItem('lastAutoLogoutDate', today)
  //     }
  //   }
  // }, [])

  useEffect(() => {
    const handleSession = () => {
      clearAuthenticated()
      localStorage.setItem('shouldReload', 'false')
      handleClear()
    }

    window.addEventListener('session-expired', handleSession)

    return () => {
      window.removeEventListener('session-expired', handleSession)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  useEffect(() => {
    const handleSession = () => {
      setUnderMaintenance(true)
    }

    window.addEventListener('maintenance-session', handleSession)

    return () => {
      window.removeEventListener('maintenance-session', handleSession)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    const handleShowSnackbar = (e: any) => {
      const { message, variant } = e?.detail
      enqueueSnackbar(message, { variant })
    }

    window.addEventListener('show-snackbar', handleShowSnackbar)

    return () => {
      window.removeEventListener('show-snackbar', handleShowSnackbar)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqueueSnackbar])

  window.addEventListener('error', (event) => {
    if (event.error && event.error.name === 'ChunkLoadError') {
      window.location.reload()
    }
  })

  useEffect(() => {
    // Check if the sessionStorage item is set
    const message = sessionStorage.getItem('showSnackbar')
    if (message) {
      // Show the snackbar
      enqueueSnackbar(message, { variant: 'success' })

      // Remove the item so it doesn't show again on refresh
      sessionStorage.removeItem('showSnackbar')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useTabVisibility()
  useReloadOnStorageChange()

  return (
    <div className={`${isDark ? 'dark' : 'light'}`}>
      <WebSocketComponent />

      {underMaintenance ? <Maintenance /> : <MainRoutes />}
    </div>
  )
}

export default App
