import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import InfoBox from '../../../../../components/app/alertBox/infoBox'
import { TextField } from '../../../../../components/common'
import Button from '../../../../../components/common/buttons/Button'
import DialogModal from '../../../../../components/common/modal/DialogModal'
import { useSnackbarManager } from '../../../../../components/common/snackbar'
import { blockActionsbyStatus } from '../../../../../configs/permissionGate'
import { router_config } from '../../../../../configs/route.config'
import { downloadFromServer } from '../../../../../utilities/commonUtilities'
import { getErrorMessage } from '../../../../../utilities/parsers'
import { deleteInvoice, getOrderInvoice } from '../../../api'
import { checkDetailPermission } from '../../../permission'
import ShareInvoice from '../../../shareInvoice'

const ViewInvoice = () => {
  const [invoiceData, setInvoiceData] = useState<any>()
  const [cancelInvoice, setCancelInvoice] = useState(false)
  const [reason, setReason] = useState('')

  const [shareInvoice, setShareInvoice] = useState(false)
  const params = useParams()
  const { enqueueSnackbar } = useSnackbarManager()
  const navigate = useNavigate()

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = () => {
    getOrderInvoice(params.invo_id).then((res: any) => {
      setInvoiceData(res.data)
    })
  }

  const handleCancelInvoice = () => {
    deleteInvoice(invoiceData?.id ?? '', reason)
      .then(() => {
        enqueueSnackbar('Invoice Deleted successfully', {
          variant: 'success',
        })
        setInvoiceData('')
        setCancelInvoice(false)
        return navigate(
          `${router_config.SERVICE_AND_ORDERS_BY_ORDERS.path}/${params.id}/invoices/`
        )
      })
      .catch((error: any) => {
        enqueueSnackbar(getErrorMessage(error?.response.data.error), {
          variant: 'error',
        })
      })
  }
  const onDownloadAction = async (r: any) => {
    if (r?.document) {
      downloadFromServer(r.document, r.display_file_name)
    }
  }
  return (
    <div className="p-4  ">
      {invoiceData?.html_view && (
        <div className="template_Wrapper  bg-bgGrey p-4 max-w-7xl w-full mx-auto rounded-lg ">
          <div className="flex items-center justify-between mb-3">
            <h4 className=" text-lg text-primaryText font-bold">Invoice</h4>
            <div className="flex  gap-2 items-center justify-end">
              {invoiceData?.status == 'Active' ? (
                <>
                  <Button
                    className="btn-secondary"
                    outlined={true}
                    onClick={() => setCancelInvoice(true)}
                    label="Cancel Invoice"
                    icon={'close'}
                    hidden={
                      !checkDetailPermission('delete', 'invoice') ||
                      (blockActionsbyStatus(
                        invoiceData?.order?.order_status?.code,
                        [
                          'cancelled',
                          'converted',
                          'rejected',
                          'order_cancelled',
                          'order_completed_payment_done',
                        ]
                      ) &&
                        invoiceData?.order?.is_locked)
                    }
                    size="xs"
                  />
                  <Button
                    label={'Share'}
                    icon={'share-alt'}
                    hidden={
                      !checkDetailPermission('send', 'invoice') ||
                      (blockActionsbyStatus(
                        invoiceData?.order?.order_status?.code,
                        ['cancelled', 'rejected', 'order_cancelled']
                      ) &&
                        invoiceData?.order?.is_locked)
                    }
                    outlined={true}
                    onClick={() => setShareInvoice(true)}
                    size="xs"
                  />

                  <Button
                    label={'Download'}
                    hidden={
                      !checkDetailPermission('download', 'invoice') ||
                      (blockActionsbyStatus(
                        invoiceData?.order?.order_status?.code,
                        ['cancelled', 'rejected', 'order_cancelled']
                      ) &&
                        invoiceData?.order?.is_locked)
                    }
                    onClick={() => onDownloadAction(invoiceData)}
                    size="xs"
                    icon={'download'}
                  />
                </>
              ) : (
                ''
              )}
              {/* <Button
              className="btn-secondary"
              outlined={true}
              onClick={() => setCancelInvoice(true)}
              label="Cancel"
            />
            <Button
              label={'Share'}
              icon={'plus'}
              outlined={true}
              onClick={() => setShareInvoice(true)}
            />
            <Link to={invoiceData?.document} target="_blank" download>
              <Button
                label={'Download'}
                onClick={() => console.log('download')}
              />
            </Link> */}
            </div>
          </div>
          <div
            className="template_Block"
            dangerouslySetInnerHTML={{
              __html: invoiceData?.html_view,
            }}
          ></div>
        </div>
      )}

      <DialogModal
        isOpen={cancelInvoice}
        onClose={() => setCancelInvoice(false)}
        title={'Are you sure?'}
        onSubmit={() => handleCancelInvoice()}
        actionLabel="Okay"
        body={
          <InfoBox
            content={
              <div>
                <div className="mb-5">
                  Are you sure you want to cancel this invoice? This is an
                  irreversible process and the invoice will be permanently
                  deleted.
                </div>
                <TextField
                  id="1"
                  name="reason"
                  label="Cancel Reason"
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Enter Cancel Reason"
                  required={false}
                />
              </div>
            }
          />
        }
      />

      <ShareInvoice
        isDrawerOpen={shareInvoice}
        handleClose={() => setShareInvoice(false)}
        id={params.invo_id}
      />
    </div>
  )
}

export default ViewInvoice
