import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'

import TaskComponent from '../../../../components/app/task'
import { checkDetailPermission } from '../../permission'

const DealTasks = () => {
  const { setActiveTab, disabled } = useOutletContext<{
    setActiveTab: (id: string) => void
    disabled: boolean
  }>()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  useEffect(
    () => {
      setActiveTab('PROSPECT_DETAILS_TASKS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])

  return (
    <TaskComponent
      model_name="Deal"
      disabled={isDisabled}
      checkModulePermission={checkDetailPermission}
    />
  )
}

export default DealTasks
