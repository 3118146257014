import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import {
  Outlet,
  useNavigate,
  useOutletContext,
  useParams,
} from 'react-router-dom'

import { router_config } from '../../../../configs/route.config'
import { useDealFilterStore } from '../../../../store/filterSore/dealStore'
import { statusClassGen } from '../../../../utilities/generators'
import { listDealDuplicate } from '../../api'
import { checkPermission } from '../../permission'
import { getColumns } from './columns'

const DealPosiibleDuplicates = () => {
  const { setActiveTab, disabled } = useOutletContext<{
    setActiveTab: (id: string) => void
    detailsData: any
    disabled: boolean
  }>()
  const onViewAction = (r: any, from?: string) => {
    if (from == 'contactName') {
      return `${router_config.CONTACTS.path}/${r.contact?.id}/summary`
    } else if (from == 'accountName') {
      return `${router_config.ACCOUNTS.path}/${r.account?.id}/summary`
    } else {
      return `${router_config.DEALS.path}/${r.id}/summary`
    }
  }
  const params = useParams()
  const [data, setData] = useState<any>()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [columns, setColumns] = useState<any>(() =>
    getColumns({ onViewAction: onViewAction, statusClassGen: statusClassGen })
  )
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])
  const [pageParams, setPageParams] = useState({
    // service:detailsData?.service[0]?.service?.id,
    page_size: 30,
    page: 1,
  })
  const [mergeIsActive, setMergeIsActve] = useState(false)
  const navigate = useNavigate()
  const { selectedRows, setSelectedRows } = useDealFilterStore()
  const handleGetDuplicates = async () => {
    setIsLoading(true)
    const { data } = await listDealDuplicate(params?.id as string, pageParams)
    setData(data)
    setIsLoading(false)
  }
  useEffect(
    () => {
      setActiveTab('DEAL_DETAILS_POSSIBLE_DUPLICATES')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
  }
  useEffect(() => {
    handleGetDuplicates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, pageParams])

  const handleOnSlect = (rows: any) => {
    setSelectedRows(rows)
  }
  const handleMerge = () => {
    setMergeIsActve(true)
    navigate(`${router_config.DEAL_MERGE.path}`)
  }

  const reDirection = `${router_config.DEALS.path}/${params?.id}/${router_config.DEAL_DETAILS_POSSIBLE_DUPLICATES.path}`
  if (mergeIsActive) {
    return <Outlet context={{ selectedRows, reDirection }} />
  }
  const handleDisable = () => {
    return isDisabled || checkPermission('merge')
  }
  return (
    <div>
      <QbsTable
        columns={columns}
        data={data?.results}
        pagination
        toolbar
        selection={!handleDisable()}
        onSelect={handleOnSlect}
        sortColumn=""
        isLoading={isLoading}
        handleResetColumns={() => handleResetColums()}
        sortType="asc"
        paginationProps={{
          onPagination: onChangePage,
          total: data?.count,
          currentPage: pageParams?.page,
          rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
          onRowsPerPage: onChangeRowsPerPage,
          dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
            ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
            : [10, 20, 30, 50],
        }}
        selectedRowActions={[
          {
            actionTitle: 'Merge',
            action: () => handleMerge(),
            customHide: '>2',
          },
        ]}
      />
    </div>
  )
}

export default DealPosiibleDuplicates
