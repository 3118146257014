import React from 'react'

import { IconProps } from '../../../common/types'

const StarIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <g clipPath="url(#clip0_918_44793)">
        <path
          d="M7.49231 3.4032C7.79169 2.48194 9.09503 2.48194 9.3944 3.4032L10.0151 5.31329C10.149 5.72528 10.5329 6.00422 10.9661 6.00423L12.9745 6.00431C13.9432 6.00435 14.346 7.2439 13.5623 7.8133L11.9375 8.99388C11.5871 9.24852 11.4404 9.69985 11.5743 10.1119L12.1948 12.022C12.4941 12.9433 11.4397 13.7094 10.656 13.14L9.03111 11.9596C8.68064 11.7049 8.20608 11.7049 7.8556 11.9596L6.23072 13.14C5.44702 13.7094 4.3926 12.9433 4.6919 12.022L5.31246 10.1119C5.44631 9.69985 5.29966 9.24852 4.94921 8.99388L3.32442 7.81331C2.54076 7.2439 2.94351 6.00435 3.91219 6.00431L5.9206 6.00423C6.3538 6.00422 6.73773 5.72528 6.87161 5.31329L7.49231 3.4032Z"
          stroke="#313131"
          strokeWidth="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_918_44793">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.443359 0.476562)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StarIcon
