import React from 'react'
import { IconProps } from '../../../common/types'

const CancelIcon: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M5.42383 15L10.4238 10M10.4238 10L15.4238 5M10.4238 10L5.42383 5M10.4238 10L15.4238 15"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CancelIcon
