import React from 'react'
import { IconProps } from '../../../common/types'

const ShareIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.78925 7.89462C4.9241 7.62543 5 7.32158 5 7C5 6.67842 4.9241 6.37457 4.78925 6.10538M4.78925 7.89462C4.46089 8.55006 3.78299 9 3 9C1.89543 9 1 8.10457 1 7C1 5.89543 1.89543 5 3 5C3.78299 5 4.46089 5.44994 4.78925 6.10538M4.78925 7.89462L9.21075 10.1054M4.78925 6.10538L9.21075 3.89462M9.21075 3.89462C9.53911 4.55006 10.217 5 11 5C12.1046 5 13 4.10457 13 3C13 1.89543 12.1046 1 11 1C9.89543 1 9 1.89543 9 3C9 3.32158 9.0759 3.62543 9.21075 3.89462ZM9.21075 10.1054C9.0759 10.3746 9 10.6784 9 11C9 12.1046 9.89543 13 11 13C12.1046 13 13 12.1046 13 11C13 9.89543 12.1046 9 11 9C10.217 9 9.53911 9.44994 9.21075 10.1054Z"
        stroke="#313131"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ShareIcon
