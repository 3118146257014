import React from 'react'
import { IconProps } from '../../../common/types'

const EyeIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8292 7.60224C12.8292 9.165 11.5623 10.4318 9.99962 10.4318C8.43687 10.4318 7.17004 9.165 7.17004 7.60224C7.17004 6.03949 8.43687 4.77271 9.99962 4.77271C11.5623 4.77271 12.8292 6.03949 12.8292 7.60224Z"
        stroke="#4A5568"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 7.60225C2.20187 3.77566 5.77679 1 10 1C14.2232 1 17.7981 3.77569 19 7.60225C17.7981 11.4288 14.2232 14.2045 10 14.2045C5.77678 14.2045 2.20185 11.4288 1 7.60225Z"
        stroke="#4A5568"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EyeIcon
