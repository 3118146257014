import { getNestedProperty } from '../../utilities/parsers'

const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}
const defaultColumnProps2 = {
  sortable: true,
  resizable: true,
  isVisible: true,
}

export const getColumns = ({ onViewAction, statusClassGen }: any) => {
  const createRenderCell =
    (key: string, customData = '') =>
    (row: any) => {
      switch (customData) {
        case 'status':
          return {
            cell: (
              <div className="text-capitalize">
                <span
                  className={`statusLabel  ${
                    row.order_status?.name
                      ? statusClassGen(row?.order_status?.name)
                      : 'Active'
                  }`}
                >
                  {row?.status ? 'Active' : 'Inactive'}
                </span>
              </div>
            ),
            toolTip: getNestedProperty(row, key),
          }
        case 'place_of_supply':
          return {
            cell: row.place_of_supply
              ? `${row.place_of_supply.name} (${row.place_of_supply.code})`
              : '',
          }
        default:
          return {
            cell: getNestedProperty(row, key),
            toolTip: getNestedProperty(row, key),
          }
      }
    }

  return [
    {
      title: 'Vendor ID',
      field: 'unique_id',
      ...defaultColumnProps2,
      sortKey: 'unique_id',
      fixed: true,
      link: true,
      getPath: (row: any) => onViewAction(row, 'franchiseeName'),
    },
    {
      title: 'Vendor Name',
      field: 'vendor_name',
      ...defaultColumnProps2,
      sortKey: 'vendor_name',
      link: true,
      getPath: (row: any) => onViewAction(row, 'franchiseeName'),
    },
    {
      title: 'Display Name',
      field: 'vendor_display_name',
      ...defaultColumnProps2,
      sortKey: 'vendor_display_name',
    },
    {
      title: 'Email',
      field: 'email',
      ...defaultColumnProps2,
      sortKey: 'email',
    },
    {
      title: 'Phone',
      field: 'phone',
      ...defaultColumnProps,
    },
    {
      title: 'Website',
      field: 'website',
      ...defaultColumnProps,
    },
    {
      title: 'Place of supply',
      field: '',
      renderCell: createRenderCell('place_of_supply.name', 'place_of_supply'),
      customCell: true,
      ...defaultColumnProps,
      colWidth: 300,
    },
    {
      title: 'Status',
      renderCell: createRenderCell('status', 'status'),
      ...defaultColumnProps,
      customCell: true,
    },
  ]
}
