import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  getEmployeeList,
  getLeadQualityList,
} from '../../../../apis/common.apis'
import FormBuilder from '../../../../components/app/formBuilder'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import { useUpdateLead } from '../../api'
import { handleLeadManagementInput, handleOnInitData } from './leadUtils'
import { leadManagementInfo, LeadManagementInfo } from './schema'

type Props = {
  data?: any
  id?: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  disabled?: boolean
}
const LeadManagement = (props: Props) => {
  const { data, id, handleRefresh, handleEditButton, disabled, editFlag } =
    props
  const [isEditing, setIsEditing] = useState(false)
  const [qualities, setQualities] = useState([])
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    value: string,
    disabled = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    value,
    ...(disabled ? { disabled: true } : {}),
  })
  const [pageParams, setPageParams] = useState({
    accountPage: 1,
    employeePage: 1,
  })
  const { employeePage } = pageParams
  const getEmployee = async (search?: string, page?: number) => {
    const data = await getEmployeeList({
      search: search as string,
      page: page as number,
      type: 'dropdown',
    })
    const next = data.next ? employeePage + 1 : 0
    setPageParams({ ...pageParams, employeePage: next })
    return data?.results
  }
  const getLeadQualities = async () => {
    const data = await getLeadQualityList()
    setQualities(data?.data || [])
  }
  useEffect(() => {
    getLeadQualities()
  }, [])
  const onInit = () => {
    const onInitData = handleOnInitData(data)
    return onInitData
  }
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const handleCreValue = () => {
    let value
    if (data?.supporting_cre?.length === 1) {
      value = [data.supporting_cre[0].name]
    } else if (data?.supporting_cre?.length > 1) {
      const count = data?.supporting_cre?.length - 1
      value = [
        <span key={0}>{data?.supporting_cre[0]?.name} </span>,
        <span
          key={1}
          className="p-1 bg-bgBadgeColor rounded-xl px-2 text-white ml-1"
        >
          +{count} more
        </span>,
        ,
      ]
    } else {
      value = '--'
    }
    return value
  }
  const formBuilderProps = [
    {
      ...textField(
        'lead_status_name',
        'Lead Status',
        'Enter Lead Status',
        false,
        data?.status?.name,
        true
      ),
      spacing: 6,
      formSpacing: 6,
    },
    {
      ...textField(
        'probability',
        'Probability %',
        'Enter Probability %',
        false,
        data?.lead_additional_data?.probability
      ),
      spacing: 6,
      formSpacing: 6,
    },
    {
      ...textField(
        'target_date',
        'Target Date',
        'Enter Target Date',
        true,
        data?.target_date !== null
          ? moment(data?.target_date).format('DD-MM-YYYY')
          : ''
      ),
      minDate: new Date(),
      type: 'date',
      spacing: 6,
      formSpacing: 6,
    },
    {
      ...textField(
        'next_followup_date',
        'Next Followup Date',
        'Enter Next Followup Date',
        true,
        data?.next_followup_date !== null
          ? moment(data?.next_followup_date).format('DD-MM-YYYY')
          : ''
      ),
      minDate: new Date(),
      type: 'date',
      spacing: 6,
      formSpacing: 6,
    },
    {
      name: 'cre_name',
      label: 'Primary CRE',
      required: false,
      getData: getEmployee,
      async: true,
      id: 'cre_name_id',
      paginationEnabled: true,
      nextBlock: employeePage,
      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Enter primary CRE',
      value: data?.primary_cre?.name,
      spacing: 6,
      formSpacing: 6,
    },
    {
      name: 'supporting_cre',
      label: 'Supporting CRE',
      required: false,
      getData: getEmployee,
      async: true,
      id: 'supporting_cre_name_id',
      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'multi_select',
      placeholder: 'Enter Supporting CRE',
      value: handleCreValue(),
      selectedItems: data?.supporting_cre,
      isMultiple: true,
      paginationEnabled: true,
      nextBlock: employeePage,
      spacing: 6,
      formSpacing: 6,
    },
    {
      name: 'lead_quality_name',
      label: 'Lead Quality',
      required: false,
      data: qualities,
      // async: true,
      id: 'lead_quality_id',

      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'custom_select',
      placeholder: 'Enter lead quality',
      value: data?.lead_quality?.name,
      spacing: 6,
      formSpacing: 6,
    },
    {
      ...textField(
        'campaign',
        'Campaign',
        'Enter Campaign',
        false,
        data?.campaign
      ),
      spacing: 6,
      formSpacing: 6,
    },
    {
      ...textField(
        'ad_set_id',
        'AD Set ID',
        'Enter AD Set ID',
        false,
        data?.ad_set_id
      ),
      spacing: 6,
      formSpacing: 6,
    },
    {
      ...textField('ad_id', 'AD ID', 'AD ID', false, data?.ad_id),
      spacing: 6,
      formSpacing: 6,
    },
  ]

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const methods = useForm<LeadManagementInfo>({
    resolver: zodResolver(leadManagementInfo),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const { mutate, isLoading } = useUpdateLead(handleSubmission)
  const onSubmit = (data: LeadManagementInfo) => {
    const input = handleLeadManagementInput(data)
    mutate({
      input: { ...input, type: 'lead_info' },
      id: id ? id : '',
    })
  }
  const { handleSubmit } = methods
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
    // handleRefresh()
  }
  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder
              data={formBuilderProps}
              edit={true}
              spacing={true}
              // schemaName={'lead_basic_info_update'}
            />
          </FormProvider>
        ) : (
          formBuilderProps.map((data) => (
            <div
              key={data?.id}
              className={`col-span-12 md:col-span-${data.spacing || 12}`}
            >
              <FormFieldView
                value={data.value}
                type={data?.type}
                // required={data.required}
                label={data.label}
                key={data?.id}
              />
            </div>
          ))
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText">Lead Information</h4>
        </div>
        <div className="iconBlack cursor-pointer">
          <div className="iconBlack cursor-pointer">
            {isEditing ? (
              <div className="flex gap-2">
                <div>
                  <Button
                    onClick={handleCancel}
                    size="xs"
                    label="Cancel"
                    outlined={true}
                  />
                </div>
                <div>
                  <Button
                    size="xs"
                    label="Save"
                    isLoading={isLoading}
                    outlined={false}
                    onClick={handleSubmit((data) => onSubmit(data))}
                  />
                </div>
              </div>
            ) : !editFlag && !disabled ? (
              <Icons onClick={handleEditClick} name="edit" />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
    </div>
  )
}

export default LeadManagement
