import React from 'react'
import { IconProps } from '../../../common/types'

const Home: React.FC<IconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.69044 22.2256H5.02854C3.55841 22.2256 2.36664 21.0339 2.36664 19.5637V10.1404C2.36664 9.36329 2.7062 8.62504 3.2962 8.11933L9.95093 2.41525C10.9478 1.5608 12.4188 1.5608 13.4157 2.41525L20.0704 8.11933C20.6604 8.62504 21 9.36329 21 10.1404V19.5637C21 21.0339 19.8082 22.2256 18.3381 22.2256H15.6762M7.69044 22.2256V14.2399C7.69044 13.5048 8.28634 12.9089 9.0214 12.9089H14.3452C15.0803 12.9089 15.6762 13.5048 15.6762 14.2399V22.2256M7.69044 22.2256H15.6762"
        stroke="#AAACB0"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Home
