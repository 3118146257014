import React from 'react'
import { IconProps } from '../../../common/types'

const ClosePopupIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.58661 3.43433C3.89903 3.12191 4.40556 3.12191 4.71798 3.43433L8.1523 6.86864L11.5866 3.43433C11.899 3.12191 12.4056 3.12191 12.718 3.43433C13.0304 3.74675 13.0304 4.25328 12.718 4.5657L9.28367 8.00001L12.718 11.4343C13.0304 11.7467 13.0304 12.2533 12.718 12.5657C12.4056 12.8781 11.899 12.8781 11.5866 12.5657L8.1523 9.13138L4.71798 12.5657C4.40556 12.8781 3.89903 12.8781 3.58661 12.5657C3.27419 12.2533 3.27419 11.7467 3.58661 11.4343L7.02092 8.00001L3.58661 4.5657C3.27419 4.25328 3.27419 3.74675 3.58661 3.43433Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default ClosePopupIcon
