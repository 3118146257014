import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import { router_config } from '../../../../configs/route.config'
import { useLeadFilterStore } from '../../../../store/filterSore/leadStore'
import { listLeadDuplicate } from '../../api'
import { checkPermission } from '../../permission'
import { getColumns } from './columns'

export default function LeadPossibleDuplicates() {
  const { setActiveTab, disabled } = useOutletContext<{
    setActiveTab: (id: string) => void
    disabled: boolean
  }>()
  const params = useParams()
  const [data, setData] = useState<any>()
  const [pageParams, setPageParams] = useState({
    page_size: 30,
    page: 1,
  })
  const navigate = useNavigate()
  const { setSelectedRows } = useLeadFilterStore()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleGetDuplicates = async () => {
    setIsLoading(true)
    const { data } = await listLeadDuplicate(params?.id, pageParams)
    setData(data)
    setIsLoading(false)
  }
  useEffect(() => {
    setActiveTab('LEAD_DETAILS_POSSIBLE_DUPLICATES')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  useEffect(() => {
    handleGetDuplicates()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id, pageParams])

  const handleOnSlect = (rows: any) => {
    setSelectedRows(rows)
  }

  const handleMerge = () => {
    navigate(`${router_config.LEADS.path}/${params.id}/merge`)
    // clearSelectedRows()
  }
  const onViewAction = (r: any) => {
    return `${router_config.LEADS.path}/${r?.id}/summary`
  }
  return (
    <div>
      <QbsTable
        columns={getColumns({ onViewAction: onViewAction } as any)}
        data={data?.results}
        pagination
        toolbar
        selection={!isDisabled}
        onSelect={handleOnSlect}
        sortColumn=""
        sortType="asc"
        isLoading={isLoading}
        paginationProps={{
          onPagination: onChangePage,
          total: data?.count,
          currentPage: pageParams?.page,
          rowsPerPage: Number(pageParams?.page_size ?? data?.page_size),
          onRowsPerPage: onChangeRowsPerPage,
          dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
            ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
            : [10, 20, 30, 50],
        }}
        selectedRowActions={[
          {
            actionTitle: 'Merge',
            action: () => handleMerge(),
            hidden: !checkPermission('merge'),
            customHide: '>2',
          },
        ]}
      />
    </div>
  )
}
