import { useEffect, useState } from 'react'

import CustomDrawer from '../../../components/common/drawer'
import { getServiceDetailsSummary } from '../api'
import { getParsedValue } from '../../../components/common/inputs/FormFieldView'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback?: (data?: any) => void
  handleUnAllocatePayment?: () => void
  datas: any
}

export default function LinkContact({
  isDrawerOpen,
  handleClose,
  datas,
}: Props) {
  const [data, setData] = useState<any>()

  // const onSubmit = () => {
  //   handleUnAllocatePayment?.()
  //   handleClose()
  // }

  const getData = () => {
    if (datas.id) {
      getServiceDetailsSummary(datas.id).then((res) => {
        setData(res.data)
      })
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datas])

  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClose()}
      title="View Payment Allocation"
      // handleSubmit={() => {
      //   handleUnAllocatePayment ? onSubmit() : undefined
      // }}
      // actionLabel={handleUnAllocatePayment ? 'Unallocate' : undefined}
      disableSubmit={!data}
      contentBg={true}
    >
      <div className="flex flex-col gap-4 px-5 py-4 bg-cardWrapperBg">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-1.5">
            <div className=" text-[#999696] text-xs leading-4 font-bold">
              Service Name *
            </div>
            <div className="text-sm font-medium text-primaryText">
              {data?.order_item_details?.service_name ?? '--'}
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className=" text-[#999696] text-xs leading-4 font-bold">
              Service Total *
            </div>
            <div className="text-sm font-medium text-primaryText">
              {data?.order_item_details?.total_price ?? '--'}
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className=" text-[#999696] text-xs leading-4 font-bold">
              Amount Allocated So Far *
            </div>
            <div className="text-sm font-medium text-primaryText">
              {data?.order_item_details?.service_allocated_amount ?? '--'}
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className=" text-[#999696] text-xs leading-4 font-bold">
              Allocation Amount *
            </div>
            <div className="text-sm font-medium text-primaryText">
              {data?.allocated_amount ?? '--'}
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className=" text-[#999696] text-xs leading-4 font-bold">
              Allocation Notes
            </div>
            <div className="text-sm font-medium text-primaryText whitespace-pre">
              {data?.description !== ''
                ? getParsedValue(data?.description)
                : '--'}
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className=" text-[#999696] text-xs leading-4 font-bold">
              Professional Fee
            </div>
            <div className="text-sm font-medium text-primaryText">
              {data?.professional_fee ?? '--'}
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className=" text-[#999696] text-xs leading-4 font-bold">
              Vendor Fee
            </div>
            <div className="text-sm font-medium text-primaryText">
              {data?.vendor_fee ?? '--'}
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className=" text-[#999696] text-xs leading-4 font-bold">
              Govt Fee
            </div>
            <div className="text-sm font-medium text-primaryText">
              {data?.govt_fee ?? '--'}
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className=" text-[#999696] text-xs leading-4 font-bold">
              GST Amount
            </div>
            <div className="text-sm font-medium text-primaryText">
              {data?.gst_amount ?? '--'}
            </div>
          </div>

          <div className="flex flex-col gap-1.5">
            <div className=" text-[#999696] text-xs leading-4 font-bold">
              Balance Amount
            </div>
            <div className="text-sm font-medium text-primaryText">
              {data?.order_item_details?.balance_amount_to_allocate ?? '--'}
            </div>
          </div>
        </div>
      </div>
    </CustomDrawer>
  )
}
