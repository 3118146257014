import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

// import { handleError } from '../../../../../../utilities/commonUtilities'
import { checkPermission } from '../../../../permission'
// import { getEmployeeDetails } from '../api'
import BasicInformation from './basicInfo'
// import RoleInformation from './roleInfo'
// import WorkProfile from './workProfile'

const VendorEmployeeSummary = () => {
  const params = useParams()
  const [editFlag, setEditFlag] = useState(false)

  const { setActiveTab, handleCallBack, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleCallBack: () => void
    detailsData?: any
  }>()
  const [data, setData] = useState<any>(detailsData)
  console.log('...details', data)
  useEffect(
    () => {
      setActiveTab('SYSTEM_SETTINGS_VENDOR_EMPLOYEES_BASIC_INFO')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setData(detailsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsData])

  // const getData = async () => {
  //   getEmployeeDetails(params.id, params.id2)
  //     .then((res) => {
  //       setData(res?.data?.employee)
  //     })
  //     .catch((err) => {
  //       handleError(err)
  //     })
  // }
  const handleRefresh = () => {
    // getData()
    handleCallBack?.()
  }

  const handleEditButton = (flag: boolean) => {
    setEditFlag(flag)
  }
  const handlePermission = () => {
    return checkPermission('change')
  }
  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation
        data={data}
        id={params.id ?? ''}
        id2={params.id2 ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        hasPermission={handlePermission()}
      />
      {/* <RoleInformation
        data={data}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        hasPermission={handlePermission()}
      /> */}
      {/* <WorkProfile
        data={data}
        id={params.id ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        hasPermission={handlePermission()}
      /> */}
    </div>
  )
}

export default VendorEmployeeSummary
