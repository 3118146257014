import { z } from 'zod'

const MAX_FILE_SIZE = 5000000

export const ACCEPTED_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf',
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

export const attachmentsSchema = z
  .object({
    name: z.string().min(1, { message: 'Required' }),
    document_type_id: z.string(),
    document_type_name: z.string(),
    attachment: z
      .instanceof(File, { message: 'Attachment required' })
      .refine((files: File) => files.size <= MAX_FILE_SIZE, {
        message: 'Maximum size 5mb',
      })
      .refine((files: File) => ACCEPTED_IMAGE_TYPES.includes(files?.type), {
        message: 'File type not allowed',
      })
      .nullable(),
    order_number_id: z.string().optional().nullable(),
    order_number_name: z.string().optional().nullable(),

    order_service_id: z.string().optional().nullable(),
    order_service_name: z.string().optional().nullable(),

    mapType: z.string().optional().nullable(),
    collectable_id: z.string().optional().nullable(),
    deliverable_id: z.string().optional().nullable(),
    collectable_name: z.string().optional().nullable(),
    deliverable_name: z.string().optional().nullable(),

    model_name: z.string().optional().nullable(),
  })
  .superRefine((data, ctx) => {
    if (
      data.model_name === 'CustomerCompany' ||
      data.model_name === 'Customer'
    ) {
      if (
        data.document_type_name === 'Order Related' &&
        data.order_number_name == undefined
      ) {
        ctx.addIssue({
          path: ['order_number_name'],
          message: 'Required',
          code: z.ZodIssueCode.custom,
        })
      }
    }
    if (data.mapType === 'Collectables') {
      if (!data.collectable_name) {
        ctx.addIssue({
          path: ['collectable_name'],
          message: 'Required',
          code: z.ZodIssueCode.custom,
        })
      }
    }
    if (data.mapType === 'Deliverables') {
      if (!data.deliverable_name) {
        ctx.addIssue({
          path: ['deliverable_name'],
          message: 'Required',
          code: z.ZodIssueCode.custom,
        })
      }
    }
  })

export type AttachmentsSchema = z.infer<typeof attachmentsSchema>
