import React from 'react'
import { IconProps } from '../../../common/types'

const ExplorerCloseIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 23.68L16 17.68L22 23.68L23.68 22L17.68 16L23.68 10L22 8.32L16 14.32L10 8.32L8.32 10L14.32 16L8.32 22L10 23.68ZM16 32C13.8133 32 11.7467 31.58 9.8 30.74C7.85333 29.9 6.15333 28.7533 4.7 27.3C3.24667 25.8467 2.1 24.1467 1.26 22.2C0.42 20.2533 0 18.1867 0 16C0 13.7867 0.42 11.7067 1.26 9.76C2.1 7.81333 3.24667 6.12 4.7 4.68C6.15333 3.24 7.85333 2.1 9.8 1.26C11.7467 0.42 13.8133 0 16 0C18.2133 0 20.2933 0.42 22.24 1.26C24.1867 2.1 25.88 3.24 27.32 4.68C28.76 6.12 29.9 7.81333 30.74 9.76C31.58 11.7067 32 13.7867 32 16C32 18.1867 31.58 20.2533 30.74 22.2C29.9 24.1467 28.76 25.8467 27.32 27.3C25.88 28.7533 24.1867 29.9 22.24 30.74C20.2933 31.58 18.2133 32 16 32ZM16 29.6C19.7867 29.6 23 28.2733 25.64 25.62C28.28 22.9667 29.6 19.76 29.6 16C29.6 12.2133 28.28 9 25.64 6.36C23 3.72 19.7867 2.4 16 2.4C12.24 2.4 9.03333 3.72 6.38 6.36C3.72667 9 2.4 12.2133 2.4 16C2.4 19.76 3.72667 22.9667 6.38 25.62C9.03333 28.2733 12.24 29.6 16 29.6Z"
        fill="white"
      />
    </svg>
  )
}

export default ExplorerCloseIcon
