import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { handleError } from '../../../../utilities/commonUtilities'
import { getLeadDetails } from '../../api'
import { checkPermission } from '../../permission'
import AddressInformation from './addressInformation'
import BasicInformation from './basicInformation'
import BusinessInformation from './contactInformation'
import LeadManagement from './leadManagement'

const UnallottedLeadProfiles = () => {
  const params = useParams()
  const { setActiveTab, handleParentCallback, disabled, detailData } =
    useOutletContext<{
      setActiveTab: (id: string) => void
      handleParentCallback: () => void
      disabled: boolean
      detailData: any
    }>()
  const [data, setData] = useState<any>()
  const [editFlag, setEditFlag] = useState(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  useEffect(() => {
    setData(detailData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData])
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])
  const getData = async () => {
    getLeadDetails(params.id)
      .then((res) => {
        setData(res)
      })
      .catch((err) => {
        handleError(err)
      })
  }

  const handleRefresh = () => {
    getData()
    handleParentCallback?.()
  }

  useEffect(() => {
    setActiveTab('UNALLOTTED_LEAD_DETAILS_PROFILE')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditButton = (flag: boolean) => {
    setEditFlag(flag)
  }
  const handleDisble = () => {
    return isDisabled || !checkPermission('change')
  }
  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation
        data={data}
        id={params.id ?? params.id}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        disabled={handleDisble()}
      />
      <AddressInformation
        data={data}
        id={params.id ?? params.id}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        disabled={handleDisble()}
      />
      <BusinessInformation
        data={data}
        id={params.id ?? params.id}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        disabled={handleDisble()}
      />
      <LeadManagement
        data={data}
        id={params.id ?? params.id}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        disabled={handleDisble()}
      />
    </div>
  )
}

export default UnallottedLeadProfiles
