import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  postData,
  postFormData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

export const createStakeholder = (input: any) => {
  return postData(apiUrl.ROLE_LIST_URL, input)
}

export const useCreateStakeholder = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createStakeholder, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Stakeholder Created successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.STAKEHOLDER_LIST_URL, {
    ...input,
  })
  const response = await getData(url)

  return response
}
export const getEmployeeTeamList = (input: any) => {
  return getData(`${apiUrl.EMPLOYEE_LIST_URL}teams${parseQueryParams(input)}`)
}

export const useStakeholder = (input: any) => {
  return useQuery(['stakeholder-list', input], () => fetchData(input), {
    refetchOnWindowFocus: false,
  })
}

export const addTeamEmployee = (input: { id: any; data: any }) => {
  return postFormData(
    `${apiUrl.STAKEHOLDER_LIST_URL}/${input.id}/teams/`,
    input.data
  )
}
export const deleteStakeholderFromTeam = (id: any, id2: any) => {
  return deleteData(`${apiUrl.STAKEHOLDER_LIST_URL}/${id}/teams/${id2}`)
}

export const getStakeholderDetails = (id: any) => {
  return getData(`${apiUrl.STAKEHOLDER_LIST_URL}/${id}`)
}
export const getStakeholderDetailsTeams = (id: any, input: any) => {
  return getData(
    `${apiUrl.STAKEHOLDER_LIST_URL}/${id}/teams${parseQueryParams(input)}`
  ).then((res) => res.data)
}
