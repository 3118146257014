import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { router_config } from '../../../../configs/route.config'
import { useAuthStore } from '../../../../store/authStore'
import { useVendorEmployeeFilterStore } from '../../../../store/filterSore/vendorEmployeeStore'
import { getErrorMessage } from '../../../../utilities/parsers'
import { changeVendorEmployeeStatus, useVendorRoles } from '../../api'
import AddEmployee from './create'
import { deleteVendorRole } from './details/api'
import { getColumns } from './roleColumns'

const VendorRoles = () => {
  const { pageParams, setPageParams } = useVendorEmployeeFilterStore()
  const { search, ordering } = pageParams
  const params = useParams()
  const [activeRow, setActiveRow] = useState<any>()
  const { userData } = useAuthStore()
  const [employeeOpen, setEmployeeOpen] = useState(false)
  // const [deleteOpen, setDeleteOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const [changeStatus, setChangeStatus] = useState<any>(null)
  const [changeStatusOpen, setStatusOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const searchParams = {
    page: 1,
    // page_size: page_size,
    search: search,
    ordering: ordering,
  }
  const navigate = useNavigate()
  const { data, refetch, isFetching } = useVendorRoles(
    params.id as string,
    searchParams
  )

  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, refetch])

  const onViewAction = (r: any, type: string) => {
    console.log(r)
    if (type === 'role') {
      return `${router_config.SYSTEM_SETTINGS_VENDORS.path}/${params.id}/${router_config.SYSTEM_SETTINGS_VENDOR_ROLE.path}/${r?.id}/basic-info`
    }
  }

  const { setActiveTab, handleParentCallback } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
  }>()

  useEffect(() => {
    setActiveTab('SYSTEM_SETTINGS_VENDOR_ROLE')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleRefresh = () => {
    refetch()
    handleParentCallback?.()
  }
  const handleStatusChange = () => {
    changeVendorEmployeeStatus({
      input: { is_active: changeStatus, franchisee: activeRow?.franchisee?.id },
      id: activeRow?.employee?.id,
    })
      .then(() => {
        enqueueSnackbar('Status changed successfully', {
          variant: 'success',
        })
        handleStatusChangeClose()
        refetch()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.error?.message
          ),
          {
            variant: 'error',
          }
        )
      })
  }
  const handleSearch = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }

  const handleAction = () => {
    setEmployeeOpen(true)
  }
  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }

  const handleDeletetOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }
  const handleDelete = () => {
    deleteVendorRole(params.id, activeRow.id)
      .then(() => {
        enqueueSnackbar('Role deleted successfully', {
          variant: 'success',
        })
        handleDeleteClose()
        handleRefresh()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.errors[0]?.message[0] ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }
  // const onChangeRowsPerPage = (count: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     page_size: count,
  //     page: 1,
  //   })
  // }
  // const onChangePage = (row: any) => {
  //   setPageParams({
  //     ...pageParams,
  //     page: row,
  //   })
  // }
  const handleStatusChangeClose = () => {
    setStatusOpen(false)
    setActiveRow(null)
    setChangeStatus(null)
  }
  // const handleStatusChangeOpen = (row: any, status: boolean) => {
  //   setActiveRow(row)
  //   setStatusOpen(true)
  //   setChangeStatus(status)
  // }
  return (
    <div>
      <QbsTable
        columns={getColumns({ onViewAction: onViewAction })}
        data={data?.data ?? []}
        toolbar
        sortColumn=""
        search
        searchValue={pageParams?.search}
        onSearch={handleSearch}
        asyncSearch
        handleSearchValue={(key?: string) => handleSearch(key)}
        isLoading={isFetching}
        sortType="asc"
        tableHeaderActions={
          <div className="flex gap-2">
            <Button onClick={handleAction} label={'Add New'} icon="plus" />
          </div>
        }
        // pagination
        // paginationProps={{
        //   onPagination: onChangePage,
        //   total: data?.data?.count,
        //   currentPage: pageParams?.page,
        //   rowsPerPage: Number(pageParams.page_size ?? data?.data?.page_size),
        //   onRowsPerPage: onChangeRowsPerPage,
        //   dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
        //     ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
        //     : [10, 20, 30, 50],
        // }}
        actionProps={[
          {
            icon: <Icons name="delete" />,
            action: (row) => handleDeletetOpen(row),
            title: 'Delete',
            toolTip: 'Delete',
            hidden: Boolean(!userData?.is_admin),
          },
          {
            title: 'Edit',
            action: (rowData) => {
              navigate(`${onViewAction(rowData, 'role')}`, {
                state: { edit: true },
              })
            },
            icon: <Icons name="edit" />,
            toolTip: 'Edit',
            hidden: Boolean(!userData?.is_admin),
          },
          // {
          //   title: 'Deactivate',
          //   action: (rowData) => handleStatusChangeOpen(rowData, false),
          //   icon: <Icons name="deactivate-icon" />,
          //   toolTip: 'Deactivate',
          //   hide: (rowData) =>
          //     rowData?.status !== 'Active' ||
          //     rowData?.employee?.franchisee?.id === params.id,
          // },
        ]}
      />
      <DialogModal
        isOpen={changeStatusOpen}
        onClose={() => handleStatusChangeClose()}
        title={'Are you sure?'}
        onSubmit={() => handleStatusChange()}
        secondaryAction={() => handleStatusChangeClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={`Are you sure you want to ${
              changeStatus ? 'Activate' : 'Deactivate'
            }?`}
          />
        }
      />
      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleDeleteClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={<InfoBox content={'Are you sure you want to delete service?'} />}
      />
      <AddEmployee
        isDrawerOpen={employeeOpen}
        handleClose={() => setEmployeeOpen(false)}
        handleCallback={employeeOpen ? handleRefresh : undefined}
        parentParams={{ id: params.id }}
      />
    </div>
  )
}

export default VendorRoles
