import React from 'react'
import { IconProps } from '../../../common/types'

const MenuSectionIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33301 13.3334C2.06634 13.3334 1.83301 13.2306 1.63301 13.025C1.43301 12.8195 1.33301 12.5889 1.33301 12.3334V3.66669C1.33301 3.41113 1.43301 3.18058 1.63301 2.97502C1.83301 2.76946 2.06634 2.66669 2.33301 2.66669H6.59967C6.73794 2.66669 6.86973 2.69446 6.99504 2.75002C7.12035 2.80558 7.22794 2.87829 7.31781 2.96815L8.01634 3.66669H13.6663C13.9219 3.66669 14.1525 3.76946 14.358 3.97502C14.5636 4.18058 14.6663 4.41113 14.6663 4.66669V12.3334C14.6663 12.5889 14.5636 12.8195 14.358 13.025C14.1525 13.2306 13.9219 13.3334 13.6663 13.3334H2.33301ZM2.33301 12.3334H13.6663V4.66669H7.59967L6.59967 3.66669H2.33301V12.3334Z"
        fill="white"
      />
    </svg>
  )
}

export default MenuSectionIcon
