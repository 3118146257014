import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'

type Props = {
  data: any
}

const BasicInformation = (props: Props) => {
  const { data } = props

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    value,
    ...(required ? { required: true } : {}),
  })

  const formBuilderProps = [
    textField(
      'stakeholder_name',
      'Stakeholder Name',
      'Enter Stakeholder name',
      data?.stakeholder_name,
      true
    ),
  ]

  const renderFields = () => {
    return (
      <>
        {formBuilderProps.map((addressInformation) => (
          <div key={data?.id} className=" col-span-2 md:col-span-1">
            <FormFieldView
              value={addressInformation.value}
              required={addressInformation?.required}
              label={addressInformation.label}
              type={addressInformation?.type}
            />
          </div>
        ))}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="menu" />
          <h4 className=" font-bold text-primaryText">Basic Information</h4>
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-4">
        {renderFields()}
      </div>
    </div>
  )
}

export default BasicInformation
