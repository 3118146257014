import React from 'react'
import { IconProps } from '../../../common/types'

const RetryIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.66675 2.66675V6.00008H3.05443M13.2922 7.33341C12.9641 4.70254 10.7198 2.66675 8.00008 2.66675C5.76183 2.66675 3.84561 4.04553 3.05443 6.00008M3.05443 6.00008H6.00008M13.3334 13.3334V10.0001H12.9457M12.9457 10.0001C12.1546 11.9546 10.2383 13.3334 8.00008 13.3334C5.28034 13.3334 3.03608 11.2976 2.70801 8.66675M12.9457 10.0001H10.0001"
        stroke="#313131"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RetryIcon
