import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'

export const useServiceManagementFilterStore =
  create<ServiceManagementFilterstoreType>()(
    persist(
      (set) => ({
        pageParams: defaultPageParams,
        selectedRows: [],

        // Actions
        setPageParams: (data) => set({ pageParams: data }),
        setSelectedRows: (data) => set({ selectedRows: data }),
        clearSelectedRows: () => set({ selectedRows: [] }),
        clearAdvanceFilter: () => set({ selectedRows: [] }),
        clearServiceManagementFilter: (key) =>
          set((state) => ({
            pageParams: key
              ? { ...defaultPageParams, search: state?.pageParams?.search }
              : defaultPageParams,
          })),
      }),
      { name: 'fit__const_store' }
    )
  )

interface ServiceManagementFilterstoreType {
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]
  // Actions
  setPageParams: (data: StoreFilterParams) => void
  setSelectedRows: (data: (number | string)[]) => void
  clearServiceManagementFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
