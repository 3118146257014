import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { useServicePaymentRequestDetails } from '../../api'
import { statusClassGen } from '../../../../utilities/generators'
import { getColumns } from './paymentRequestColumns'
import ReviewActivityRequests from '../../../activities/details/requests/review'

const ServicePayments = () => {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack: () => void
  }>()
  const [activeRow, setActiveRow] = useState<any>(null)
  const [reviewOpen, setReviewOpen] = useState(false)

  const params = useParams()
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { data, isFetching } = useServicePaymentRequestDetails({
    approval_type__requires_payment: true,
    task__item_id: params?.id ?? '',
  })
  const handleCloseAll = () => {
    setReviewOpen(false)
    setActiveRow(null)
  }
  const onViewAction = (r: any, from: string) => {
    if (from == 'linkTo') {
      setActiveRow(r)
      setReviewOpen(true)
    }
  }

  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data?.results ?? []}
          dataRowKey="id"
          toolbar={true}
          search={true}
          isLoading={isFetching}
          columns={
            getColumns({
              onViewAction: onViewAction,
              statusClassGen: statusClassGen,
            }) as any
          }
          columnToggle
        />
        <ReviewActivityRequests
          isDrawerOpen={reviewOpen}
          data={activeRow}
          disabled={true}
          handleClose={() => handleCloseAll()}
        />
      </div>
    </>
  )
}

export default ServicePayments
