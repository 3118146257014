import React from 'react'
import { IconProps } from '../../../common/types'

const PhoneCallingIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.4988 16.1833C18.3571 16.4833 18.1738 16.7666 17.9321 17.0333C17.5238 17.4833 17.0738 17.8083 16.5654 18.0166C16.0654 18.225 15.5238 18.3333 14.9404 18.3333C14.0904 18.3333 13.1821 18.1333 12.2238 17.725C11.2654 17.3166 10.3071 16.7666 9.3571 16.075C8.39876 15.375 7.49043 14.6 6.62376 13.7416C5.76543 12.875 4.99043 11.9666 4.29876 11.0166C3.61543 10.0666 3.06543 9.11663 2.66543 8.17496C2.26543 7.22496 2.06543 6.31663 2.06543 5.44996C2.06543 4.88329 2.16543 4.34163 2.36543 3.84163C2.56543 3.33329 2.8821 2.86663 3.32376 2.44996C3.8571 1.92496 4.44043 1.66663 5.0571 1.66663C5.29043 1.66663 5.52376 1.71663 5.7321 1.81663C5.94876 1.91663 6.14043 2.06663 6.29043 2.28329L8.22376 5.00829C8.37376 5.21663 8.4821 5.40829 8.5571 5.59163C8.6321 5.76663 8.67376 5.94163 8.67376 6.09996C8.67376 6.29996 8.61543 6.49996 8.49876 6.69163C8.39043 6.88329 8.2321 7.08329 8.0321 7.28329L7.39876 7.94163C7.3071 8.03329 7.26543 8.14163 7.26543 8.27496C7.26543 8.34163 7.27376 8.39996 7.29043 8.46663C7.31543 8.53329 7.34043 8.58329 7.3571 8.63329C7.5071 8.90829 7.76543 9.26663 8.1321 9.69996C8.5071 10.1333 8.9071 10.575 9.34043 11.0166C9.79043 11.4583 10.2238 11.8666 10.6654 12.2416C11.0988 12.6083 11.4571 12.8583 11.7404 13.0083C11.7821 13.025 11.8321 13.05 11.8904 13.075C11.9571 13.1 12.0238 13.1083 12.0988 13.1083C12.2404 13.1083 12.3488 13.0583 12.4404 12.9666L13.0738 12.3416C13.2821 12.1333 13.4821 11.975 13.6738 11.875C13.8654 11.7583 14.0571 11.7 14.2654 11.7C14.4238 11.7 14.5904 11.7333 14.7738 11.8083C14.9571 11.8833 15.1488 11.9916 15.3571 12.1333L18.1154 14.0916C18.3321 14.2416 18.4821 14.4166 18.5738 14.625C18.6571 14.8333 18.7071 15.0416 18.7071 15.275C18.7071 15.575 18.6404 15.8833 18.4988 16.1833Z"
        stroke="#E76458"
        strokeWidth="1.5"
      />
      <path
        d="M15.8151 7.49992C15.8151 6.99992 15.4234 6.23325 14.8401 5.60825C14.3068 5.03325 13.5984 4.58325 12.8984 4.58325"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7318 7.49996C18.7318 4.27496 16.1234 1.66663 12.8984 1.66663"
        stroke="#E76458"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PhoneCallingIcon
