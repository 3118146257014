import { create } from 'zustand'

import { StoreFilterParams, AdvanceFilter } from '../../common/types'
import { defaultPageParams } from '../constants'

const defaultParams = [
  { title: 'Uploaded By', slug: 'created_by', isChecked: false },
  { title: 'Uploaded On', slug: 'created_date', isChecked: false },
]
export const useAttachmentFilterStore = create<AttachmentFilterstoreType>()(
  (set) => ({
    pageParams: defaultPageParams,
    advanceFilter: {
      filterParams: defaultParams,
      isDetailed: true,
    },
    selectedRows: [],

    setAdvanceFilter: (data) => set(() => ({ advanceFilter: data })),
    setPageParams: (data) => set(() => ({ pageParams: data })),
    clearAdvanceFilter: () =>
      set(() => ({
        advanceFilter: {
          filterParams: defaultParams?.map((item: any) => ({
            ...item,
            isChecked: false,
          })),
          isDetailed: true,
        },
      })),
    setSelectedRows: (data) => set(() => ({ selectedRows: data })),
    clearSelectedRows: () => set({ selectedRows: [] }),
    clearAttachmentFilter: (key) =>
      set((state) => ({
        pageParams: key
          ? { ...defaultPageParams, search: state?.pageParams?.search }
          : defaultPageParams,
      })),
  })
)

type AttachmentFilterstoreType = {
  advanceFilter: AdvanceFilter
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]

  setAdvanceFilter: (data: AdvanceFilter) => void
  setSelectedRows: (data: (number | string)[]) => void
  setPageParams: (data: StoreFilterParams) => void
  clearAttachmentFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
