import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import TaskComponent from '../../../../components/app/task/index'
import { checkDetailPermission } from '../../permission'

export default function ContactTask() {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
  }>()

  useEffect(() => {
    setActiveTab('CONTACT_TASKS')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TaskComponent
      model_name="Customer"
      checkModulePermission={checkDetailPermission}
    />
  )
}
