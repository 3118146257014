import React from 'react'
import { IconProps } from '../../../common/types'

const EditIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.73144 1.33337H6.39811C3.06478 1.33337 1.73145 2.66671 1.73145 6.00004V10C1.73145 13.3334 3.06478 14.6667 6.39811 14.6667H10.3981C13.7314 14.6667 15.0648 13.3334 15.0648 10V8.66671"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83836 7.26671C5.63836 7.46671 5.43836 7.86005 5.39836 8.14671L5.11169 10.1534C5.00502 10.88 5.51836 11.3867 6.24502 11.2867L8.25169 11C8.53169 10.96 8.92502 10.76 9.13169 10.56L14.385 5.30671C15.2917 4.40005 15.7184 3.34671 14.385 2.01338C13.0517 0.680046 11.9984 1.10671 11.0917 2.01338L5.83836 7.26671Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3389 2.7666C10.7855 4.35994 12.0322 5.6066 13.6322 6.05994"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EditIcon
