import React from 'react'
import { IconProps } from '../../../common/types'

const TableDelete: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.89375 15.75C4.58438 15.75 4.31953 15.6398 4.09922 15.4195C3.87891 15.1992 3.76875 14.9344 3.76875 14.625V3.9375H3V2.8125H6.525V2.25H11.475V2.8125H15V3.9375H14.2313V14.625C14.2313 14.925 14.1188 15.1875 13.8938 15.4125C13.6688 15.6375 13.4062 15.75 13.1063 15.75H4.89375ZM13.1063 3.9375H4.89375V14.625H13.1063V3.9375ZM6.88125 13.0125H8.00625V5.53125H6.88125V13.0125ZM9.99375 13.0125H11.1188V5.53125H9.99375V13.0125Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default TableDelete
