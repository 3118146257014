import React from 'react'
import { IconProps } from '../../../common/types'

const PhoneIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4.29867C2 3.56229 2.59695 2.96533 3.33333 2.96533H5.51949C5.80645 2.96533 6.06121 3.14895 6.15195 3.42118L7.15049 6.41681C7.25541 6.73155 7.11292 7.07554 6.81618 7.22391L5.31134 7.97633C6.04617 9.60614 7.35919 10.9192 8.989 11.654L9.74142 10.1492C9.88979 9.85241 10.2338 9.70993 10.5485 9.81484L13.5442 10.8134C13.8164 10.9041 14 11.1589 14 11.4458V13.632C14 14.3684 13.403 14.9653 12.6667 14.9653H12C6.47715 14.9653 2 10.4882 2 4.96533V4.29867Z"
        stroke="#222222"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PhoneIcon
