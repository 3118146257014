import { useEffect, useState } from 'react'
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom'

import { TabItemProps } from '../../../common/types'
import { TabContainer } from '../../../components/common'
import DetailHeader from '../../../components/common/DetailTiles'
import { router_config } from '../../../configs/route.config'
import { getStakeholderDetails } from '../api'
import { handleError } from '../../../utilities/commonUtilities'

let updateKey = 1
const StakeholderDetailsSummary = () => {
  const navigate = useNavigate()

  const [data, setData] = useState<any>()

  const params = useParams()
  const tabData = [
    {
      label:
        router_config.SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY
          .label,
      id: 'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY',
    },
    {
      label:
        router_config.SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS
          .label,
      id: 'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS',
    },
  ]
  const [activeTab, setActiveTab] = useState<string>(
    'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS'
  )

  const haandleTabChange = (selected: TabItemProps) => {
    navigate(
      generatePath(router_config[selected.id]?.path as string, {
        id: params.id,
      })
    )
  }

  useEffect(() => {
    if (params.id) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const getData = async () => {
    getStakeholderDetails(params.id)
      .then((res) => {
        setData(res)
        updateKey = updateKey + 1
      })
      .catch((err) => {
        handleError(err)
      })
  }

  const basicData = {
    title: `${data?.stakeholder_name ?? '--'} `,
  }

  const contextData = {
    setActiveTab: (id: string) => setActiveTab(id),

    stakeholderData: data,

    handleCallBack: getData,
  }

  return (
    <div>
      <div>
        <DetailHeader data={basicData} />
        {/* <PageTiles data={dashtiledata} handleTileClick={handleTileClick} /> */}
      </div>
      <div className="flex gap-4">
        <TabContainer
          data={tabData}
          activeTab={activeTab}
          onClick={haandleTabChange}
        >
          <Outlet context={contextData} key={updateKey} />
        </TabContainer>
      </div>
    </div>
  )
}

export default StakeholderDetailsSummary
