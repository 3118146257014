import { zodResolver } from '@hookform/resolvers/zod'

import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { DialogModal } from '../../../../../../components/common'
import Icons from '../../../../../../components/common/icons'
import { useSnackbarManager } from '../../../../../../components/common/snackbar'
import { getErrorMessage } from '../../../../../../utilities/parsers'
import { setEmployeePassword } from '../api'
import { ResetSchema, resetSchema } from './changePasswordSchema'

type Props = {
  isOpen: boolean
  handleClose: () => void
  handleCallback?: (data: any) => void
  vendorId?: any
  paramsId?: any
}

const passwordEndAdorement =
  'rounded appearance-none relative block w-full pl-3 pr-6 py-2 border border-formBorder placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-purple-500 focus:border-gray-300 focus:z-10 sm:text-sm'
export default function CreateEmployee({
  isOpen,
  handleClose,
  vendorId,
  paramsId,
}: Props) {
  const { enqueueSnackbar } = useSnackbarManager()

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirm_password: false,
  })

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm<ResetSchema>({
    mode: 'onChange',
    resolver: zodResolver(resetSchema),
    defaultValues: { password: '', confirm_password: '' },
  })

  const onSubmit = (data: any) => {
    setEmployeePassword({
      password: data?.password,
      confirm_password: data?.confirm_password,
      employee: paramsId,
      vendor: vendorId,
    })
      .then(() => {
        handleClearAndClose()
        enqueueSnackbar('Password changed successfully', { variant: 'success' })
      })
      .catch((error: any) => {
        enqueueSnackbar(getErrorMessage(error?.response.data.error), {
          variant: 'error',
        })
      })
  }

  const handleClearAndClose = () => {
    reset({})
    handleClose()
  }

  return (
    <>
      <DialogModal
        isOpen={isOpen}
        onClose={handleClearAndClose}
        title="Change Password"
        secondaryAction={handleClearAndClose}
        onSubmit={handleSubmit((data) => onSubmit(data))}
        secondaryActionLabel="Cancel"
        actionLabel="Save"
        body={
          <div className="flex flex-col gap-4 ">
            <div className="">
              <label className="labels label-text" htmlFor={'password'}>
                Password
              </label>
              <div className="relative ">
                <input
                  id={'password'}
                  type={showPassword.password ? 'text' : 'password'}
                  required={true}
                  className={passwordEndAdorement}
                  placeholder={'Enter Password'}
                  {...register('password')}
                />
                <button
                  type="button"
                  className="absolute right-2 top-2.5 z-10"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      password: !showPassword.password,
                    })
                  }
                >
                  {showPassword.password ? (
                    <Icons name="eye" />
                  ) : (
                    <Icons name="eye-close" />
                  )}
                </button>
              </div>
              {errors.password && (
                <div className="text-xs text-red-500">
                  {errors?.password?.message as string}
                </div>
              )}
            </div>
            <div className="">
              <label className="labels label-text" htmlFor={'confirm_password'}>
                Confirm Password
              </label>
              <div className="relative">
                <input
                  id={'confirm_password'}
                  type={showPassword.confirm_password ? 'text' : 'password'}
                  required={true}
                  className={passwordEndAdorement}
                  placeholder={'Confirm Password'}
                  {...register('confirm_password')}
                />
                <button
                  type="button"
                  className="absolute right-2 top-2.5 z-10"
                  onClick={() =>
                    setShowPassword({
                      ...showPassword,
                      confirm_password: !showPassword.confirm_password,
                    })
                  }
                >
                  {showPassword.confirm_password ? (
                    <Icons name="eye" />
                  ) : (
                    <Icons name="eye-close" />
                  )}
                </button>
              </div>
              {errors.confirm_password && (
                <div className="text-xs text-red-500">
                  {errors?.confirm_password?.message as string}
                </div>
              )}
            </div>
          </div>
        }
      />
    </>
  )
}
