import { getNestedProperty } from '../../../../utilities/parsers'
import Icons from '../../../../components/common/icons/index'

const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}

export const getColumns = ({ onViewAction }: any) => {
  const createRenderCell =
    (key: string | string[], type?: string) => (row: any) => {
      switch (type) {
        case 'join':
          return {
            cell: `${getNestedProperty(row, key[0]) ?? ''} ${
              getNestedProperty(row, key[1]) ?? ''
            }`,
          }
        case 'custom':
          return {
            cell: (
              <div className="flex gap-1 items-center">
                {getNestedProperty(row, key[0]) && (
                  <Icons
                    name="phone"
                    toolTip={getNestedProperty(row, key[0])}
                  />
                )}
                {getNestedProperty(row, key[1]) && (
                  <Icons
                    name="email"
                    toolTip={getNestedProperty(row, key[1])}
                  />
                )}
              </div>
            ),
          }
        default:
          return {
            cell: getNestedProperty(row, key as string),
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
      }
    }

  const column = [
    {
      title: 'Account ID',
      field: 'company_code',
      getPath: (row: any) => onViewAction(row),
      link: true,
      ...defaultColumnProps,
    },
    {
      title: 'Account Name',
      field: 'business_name',

      ...defaultColumnProps,
    },
    {
      title: 'PAN Number',
      field: 'pan_no',
      ...defaultColumnProps,
    },
    {
      title: 'Primary Contact',
      field: 'customer_number',
      renderCell: createRenderCell('primary_contact.customer_number'),
      getPath: (row: any) => onViewAction(row, 'primaryContact'),
      link: true,
      customCell: true,

      ...defaultColumnProps,
    },
    // {
    //   title: 'Primary Account Name',
    //   field: 'primary_company',
    //   ...defaultColumnProps,
    // },
    {
      title: 'State',
      field: '',
      renderCell: createRenderCell('address.state'),
      customCell: true,

      ...defaultColumnProps,
    },
    {
      title: 'City',
      field: '',
      renderCell: createRenderCell('address.city'),

      customCell: true,

      ...defaultColumnProps,
    },
    {
      title: 'Communication',
      renderCell: createRenderCell(['mobile', 'email'], 'custom'),

      field: '',
      customCell: true,
      ...defaultColumnProps,
    },

    {
      title: 'Secondary Communication',
      customCell: true,
      renderCell: createRenderCell(['mobile_2', 'email_2'], 'custom'),
      field: '',
      ...defaultColumnProps,
    },
  ]

  return column
}
