import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { getMinServiceList } from '../../../../../apis/common.apis'
import FormBuilder from '../../../../../components/app/formBuilder/index'
import CustomDrawer from '../../../../../components/common/drawer'
import { useCreateVendorService } from '../../../api'
import { createServiceSchema, serviceSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleRefresh?: () => void
  subSection?: boolean
  parentParams?: { id?: string; name?: string }
  handleCallback?: () => void
}

export default function AddService({
  isDrawerOpen,
  handleClose,
  // subSection,
  handleCallback,
  handleRefresh,
  parentParams,
}: Props) {
  const defaultPages: Record<string, number> = {
    service: 1,
  }
  const [pages, setPages] = useState(defaultPages)
  const getServiceList = async (value: string, page?: number) => {
    const { data } = await getMinServiceList({
      search: value ?? '',
      page: page as number,
      type: 'franchisee-dropdown',
    })
    setPages((prev) => ({
      ...prev,
      service: data?.next ? prev['service'] + 1 : 0,
    }))

    return data?.results
  }

  const formBuilderProps = [
    {
      name: 'service',
      label: 'Service',
      required: true,
      getData: getServiceList,
      paginationEnabled: true,
      nextBlock: pages.service ?? undefined,
      async: true,
      id: 'service_id',
      descId: 'id',
      initialLoad: true,
      desc: 'service_name',
      type: 'multi_select',
      placeholder: 'Select Service',
      isMultiple: true,
    },
  ]

  const methods = useForm<createServiceSchema>({
    resolver: zodResolver(serviceSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const handleSubmission = () => {
    methods.reset()
    handleCallback?.()
  }
  const { mutate, data, isLoading } = useCreateVendorService(handleSubmission)
  const getServiceIds = (data: any) => {
    const selectedIds: any = []
    data.forEach((item: any) => {
      selectedIds.push(item.id)
    })
    return String(selectedIds)
  }
  console.log(methods.watch())
  const onSubmit = (data: createServiceSchema) => {
    setTimeout(() => {
      mutate({
        input: { service_id: getServiceIds(data.service) },
        id: parentParams?.id,
      })
      handleClearAndClose()
      handleRefresh?.()
      handleCallback?.()
    }, 500)
  }

  useEffect(() => {
    handleCallback?.()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({ service: undefined })
    handleClose()
  }
  // const handleClear = () => {
  //   methods.reset({
  //    service:undefined
  //   })
  // }
  console.log(methods.watch(), methods.formState.errors)
  return (
    <>
      <CustomDrawer
        className="formDrawer"
        open={isDrawerOpen}
        handleClose={() => handleClearAndClose()}
        // handleSaveAndContinue={
        //   subSection ? undefined : handleSubmit((data) => onSubmit(data, false))
        // }
        // saveAndContinueLabel={'Save and Add Another'}
        handleSubmit={handleSubmit((data) => onSubmit(data))}
        title="Add Service"
        actionLoader={isLoading}
      >
        <div className="flex flex-col gap-4">
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
          </FormProvider>
        </div>
      </CustomDrawer>
    </>
  )
}
