import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'

import { getCountryState } from '../../../apis/common.apis'
import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import { getPointOfSupply, useCreateVendor } from '../api'
import { ACCEPTED_IMAGE_TYPES, VendorSchema, formSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleRefresh?: () => void
  subSection?: boolean
  parentParams?: { id?: string; name?: string }
  handleCallback?: () => void
}

export default function CreateVendor({
  isDrawerOpen,
  handleClose,
  handleCallback,
}: Props) {
  const getPlaceOfSupply = async (value: any) => {
    const { data } = await getPointOfSupply({
      search: value ?? '',
      type: 'dropdown',
    })
    return data?.map((item: any) => ({
      ...item,
      place_of_supply_name: `${item.name} (${item.code})`,
    }))
  }

  const getState = async () => {
    const { data } = await getCountryState()
    return data?.state?.map((item: any) => ({
      ...item,
      state_name: item.name,
    }))
  }

  const getCountry = async () => {
    const { data } = await getCountryState()
    return data?.countries?.map((item: any) => ({
      ...item,
      country_name: item.name,
    }))
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    isDuplicateCheck = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
  })

  const formBuilderProps = [
    {
      ...textField('vendor_name', 'Name', 'Enter name', true, false),
    },
    {
      ...textField(
        'vendor_display_name',
        'Display name',
        'Enter display name',
        true,
        false
      ),
    },
    {
      ...textField('email', 'Email', 'Enter email', true, false),
      type: 'email',
    },
    textField('phone', 'Phone', 'Enter Phone', true, false),
    textField('website', 'Website', 'https://www.website.com', true, false),
    textField(
      'address_1',
      'Address Line 1',
      'Enter Address Line 1',
      true,
      false
    ),
    textField(
      'address_2',
      'Address Line 2',
      'Enter Address Line 2',
      true,
      false
    ),
    textField('city', 'City', 'Enter City', true, false),
    {
      name: 'state_name',
      label: 'State',
      required: true,
      getData: getState,
      async: false,
      id: 'state',
      descId: 'state_name',
      initialLoad: true,
      desc: 'state_name',
      type: 'auto_complete',
      placeholder: 'Enter state',
    },
    textField('pin_code', 'Pincode', 'Enter Pincode', true, false),
    {
      name: 'country_name',
      label: 'Country',
      required: true,
      getData: getCountry,
      async: false,
      id: 'country',
      descId: 'country_name',
      initialLoad: true,
      desc: 'country_name',
      type: 'auto_complete',
      placeholder: 'Enter country',
    },
    textField('gst_no', 'GST No', 'Enter GST No', false, false),
    {
      ...textField('pan_no', 'PAN No', 'Enter PAN No', false, false),
      toCapitalize: true,
    },
    textField('cin', 'CIN', 'Enter CIN', false, false),
    {
      name: 'place_of_supply_name',
      label: 'Place of Supply',
      required: false,
      getData: getPlaceOfSupply,
      async: false,
      id: 'place_of_supply_id',
      descId: 'id',
      initialLoad: true,
      desc: 'place_of_supply_name',
      type: 'auto_complete',
      placeholder: 'Enter Place of Supply ',
    },
    {
      ...textField('status', 'Status', 'Select Status', false, false),
      type: 'radio_group',
      group_data: [
        { id: 'status', value: 'True', label: 'Active' },
        { id: 'status2', value: 'False', label: 'Inactive' },
      ],
    },
    {
      ...textField('remark', 'Remarks', 'Enter Remarks', false, false),
      type: 'textarea',
    },
    {
      name: 'logo',
      label: 'Logo',
      required: false,
      id: 'logo',
      descId: 'id',
      type: 'file_upload',
      acceptedFiles: ['PNG, JPG, JPEG'],
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      accept: 'image/*',
    },
  ]

  const formBuilderProps2 = [
    textField(
      'contact_first_name',
      'First Name',
      'Enter First name',
      false,
      false
    ),
    textField(
      'contact_last_name',
      'Last Name',
      'Enter Last name',
      false,
      false
    ),
    {
      ...textField('contact_email', 'Email', 'Enter Email', false, false),
      type: 'email',
    },
    textField('contact_phone', 'Phone', 'Enter Phone', false, false),
  ]

  const methods = useForm<VendorSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: { status: 'True' },
    reValidateMode: 'onChange',
  })
  const handleSubmission = () => {
    methods.reset()
    handleClearAndClose()
    handleCallback?.()
  }

  const { mutate, isLoading } = useCreateVendor(handleSubmission)
  const onSubmit = (data: any) => {
    const formData = new FormData()
    Object.keys(data)?.forEach((item: string) => {
      if (item === 'logo') {
        if (typeof data?.logo !== 'string' && data.logo !== undefined) {
          formData.append('logo', data?.logo)
        }
      } else if (item === 'status') {
        formData.append('status', data.status ?? 'False')
      } else {
        formData.append(item, data[item] ?? '')
      }
    })
    setTimeout(() => {
      mutate(formData)
    }, 500)
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }
  return (
    <>
      <CustomDrawer
        className="formDrawer"
        open={isDrawerOpen}
        handleClose={() => handleClearAndClose()}
        handleSubmit={handleSubmit((data) => onSubmit(data))}
        title="Create New Vendor"
        actionLoader={isLoading}
      >
        <div className="flex flex-col gap-4">
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={true} />
            <p className=" font-semibold text-md border-b py-2">
              Point of Contact
            </p>
            <FormBuilder data={formBuilderProps2} edit={true} />
          </FormProvider>
        </div>
      </CustomDrawer>
    </>
  )
}
