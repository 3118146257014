import { lazy } from 'react'
import { Route, Routes } from 'react-router-dom'

const PaymentReceipt = lazy(() => import('../components/app/payment/receipt'))
const AccountAddresses = lazy(
  () => import('../pages/accounts/details/addresses')
)
const AccountAgents = lazy(() => import('../pages/accounts/details/agents'))
const AccountAttachments = lazy(
  () => import('../pages/accounts/details/attachments')
)
const AccountBasicInfo = lazy(
  () => import('../pages/accounts/details/basic-info')
)
const AccountContacts = lazy(() => import('../pages/accounts/details/contacts'))
const AccountEventLogs = lazy(
  () => import('../pages/accounts/details/eventlogs')
)
const AccountNotes = lazy(() => import('../pages/accounts/details/notes'))
const AccountOrders = lazy(
  () => import('../pages/accounts/details/orders/index')
)

import { router_config } from '../configs/route.config'

import AccountPossibleDuplicates from '../pages/accounts/details/possibleduplicates/index'
import AccountTask from '../pages/accounts/details/task'
import ActivityFiles from '../pages/activities/details/files'
import ActivityLogs from '../pages/activities/details/log'
import ActivityNotes from '../pages/activities/details/notes'
import ActivityRequests from '../pages/activities/details/requests'
import ActivitySummary from '../pages/activities/details/summary'
import ActivityTasks from '../pages/activities/details/tasks'
import ContactAccounts from '../pages/contacts/details/accounts'
import ContactAttachments from '../pages/contacts/details/attachments'
import ContactEventLogs from '../pages/contacts/details/eventlogs'
import ContactNotes from '../pages/contacts/details/notes'
import ContactSummary from '../pages/contacts/details/summary'
import ContactTask from '../pages/contacts/details/task'
import DealLogs from '../pages/deals/details/eventLogs'
import DealFiles from '../pages/deals/details/files'
import DealNotes from '../pages/deals/details/notes'
import DealPossibleDuplicates from '../pages/deals/details/possibleDuplicates'
import DealServices from '../pages/deals/details/services'
import DealSummary from '../pages/deals/details/summary'
import DealTasks from '../pages/deals/details/tasks'

import { MODULES } from '../configs/scopes.config'
import LeadAttachments from '../pages/leads/details/attachments/index'
import LeadEventLogs from '../pages/leads/details/eventlogs/index'
import LeadNotes from '../pages/leads/details/notes/index'
import LeadPossibleDuplicates from '../pages/leads/details/possibleduplicates/index'
import LeadProfiles from '../pages/leads/details/profile/index'
import LeadTask from '../pages/leads/details/task/index'
import LeadMerge from '../pages/leads/merge/index'
import OrderFiles from '../pages/order/details/files'
import OrderInvoices from '../pages/order/details/invoices'
import OrderInvoicesId from '../pages/order/details/invoices/view'
import OrderLogs from '../pages/order/details/log'
import OrderNote from '../pages/order/details/notes'
import OrderPayments from '../pages/order/details/payments'
import OrderServices from '../pages/order/details/services'
import OrderSummary from '../pages/order/details/summary'
import OrderTasks from '../pages/order/details/tasks'
import ProspectLogs from '../pages/prospects/details/eventLogs'
import ProspectFiles from '../pages/prospects/details/files'
import ProspectNotes from '../pages/prospects/details/notes'
import ProspectPossibleDuplicates from '../pages/prospects/details/possibleDuplicates'
import ProspectServices from '../pages/prospects/details/services'
import ProspectSummary from '../pages/prospects/details/summary'
import ProspectTasks from '../pages/prospects/details/tasks'
import QuoteDocument from '../pages/quote/details/document'
import QuoteEventLogs from '../pages/quote/details/eventlogs'
import QuoteFiles from '../pages/quote/details/files'
import QuoteNotes from '../pages/quote/details/notes'
import QuoteServices from '../pages/quote/details/services'
import QuoteSummary from '../pages/quote/details/summary'
import QuoteTask from '../pages/quote/details/task'
import ServiceActivityProgress from '../pages/service/details/activityProgress'
import ServiceFiles from '../pages/service/details/files/index'
import ServiceLog from '../pages/service/details/log/index'
import ServiceNotes from '../pages/service/details/notes/index'
import ServicePaymentApproval from '../pages/service/details/paymentRequest/index'
import ServicePayments from '../pages/service/details/payments/index'
import ServiceSummary from '../pages/service/details/summary/index'
import ServiceTasks from '../pages/service/details/tasks/index'
import ChildRoute from './components/childRoute'
import GuestRoute from './components/guestRoute'
import UserRoute from './components/userRoute'
const isAllFranchisee = () =>
  useAuthStore.getState().franchisee?.franchisee_name === 'All Franchisee'
const Login = lazy(() => import('../pages/userManagement/login'))
const ForgetPassword = lazy(
  () => import('../pages/userManagement/forgetPassword')
)

// Dashboard

const Dashboard = lazy(() => import('../pages/dashboard/dashboard'))

// Leads
const QuoteDocumentID = lazy(() => import('../pages/quote/quoteDoc'))

const LeadsList = lazy(() => import('../pages/leads'))
const LeadDetails = lazy(() => import('../pages/leads/details'))

// Accounts
const MergeAccount = lazy(() => import('../pages/accounts/merge'))
const Accounts = lazy(() => import('../pages/accounts'))
const AccountDetails = lazy(() => import('../pages/accounts/details'))

// Contacts

const Contacts = lazy(() => import('../pages/contacts'))
const ContactDetails = lazy(() => import('../pages/contacts/details'))
const ContactMerge = lazy(() => import('../pages/contacts/merge'))
// const ContactSummary = lazy(() => import('../pages/contacts/details/summary'))
// const ContactAccounts = lazy(() => import('../pages/contacts/details/accounts'))

// Employees

const Employees = lazy(() => import('../pages/employees'))
const EmployeeDetails = lazy(() => import('../pages/employees/details/'))
const EmployeeBasicInfo = lazy(
  () => import('../pages/employees/details/basicInfo')
)
const EmployeeTeamInfo = lazy(
  () => import('../pages/employees/details/teamInfo')
)
const EmployeeFranchiseeInfo = lazy(
  () => import('../pages/employees/details/franchisee')
)
// Service orders

// const ServiceOrders = lazy(() => import('../pages/service-orders'))

// Service orders - orders

const OrderList = lazy(() => import('../pages/order'))
const OrderDetails = lazy(() => import('../pages/order/details'))

// Service orders - service

const ServiceList = lazy(() => import('../pages/service'))

// Teams

const Teams = lazy(() => import('../pages/teams'))

const TeamDetails = lazy(() => import('../pages/teams/details'))
const TeamInfo = lazy(() => import('../pages/teams/details/basicInfo'))
const TeamMembers = lazy(() => import('../pages/teams/details/members'))
const TeamServices = lazy(() => import('../pages/teams/details/services'))

// Roles

const Roles = lazy(() => import('../pages/roles'))
const RoleDetails = lazy(() => import('../pages/roles/details'))
const RoleDetailsBasics = lazy(() => import('../pages/roles/details/summary'))
const RoleDetailsPermissions = lazy(
  () => import('../pages/roles/details/permissions')
)

// Franchisee

const Franchisee = lazy(() => import('../pages/franchisee'))
const FranchiseeDetails = lazy(() => import('../pages/franchisee/details'))
const FranchiseeDetailsSummary = lazy(
  () => import('../pages/franchisee/details/summary')
)
const FranchiseeDetailsService = lazy(
  () => import('../pages/franchisee/details/services')
)
const FranchiseeDetailsEmployees = lazy(
  () => import('../pages/franchisee/details/employees')
)
const FranchiseeDetailsPayment = lazy(
  () => import('../pages/franchisee/details/payment-gateway')
)

// service management

const Service = lazy(() => import('../pages/service-management/services'))
const ServiceManagementDetails = lazy(
  () => import('../pages/service-management/services/details')
)
const ServiceManagementDetailsInfo = lazy(
  () => import('../pages/service-management/services/details/basic-info')
)
const ServiceManagementDetailsActivity = lazy(
  () => import('../pages/service-management/services/details/checklist')
)

const ServiceManagementDetailsStatus = lazy(
  () => import('../pages/service-management/services/details/serviceStatus')
)
const ServiceActivities = lazy(
  () => import('../pages/service-management/activities')
)

// Dropdowns
const Dropdown = lazy(() => import('../pages/dropdowns/'))

const ConstitutionCategories = lazy(
  () => import('../pages/dropdowns/contitution-categories')
)

const ServiceCategories = lazy(
  () => import('../pages/dropdowns/service-categories')
)

const BusinessSector = lazy(() => import('../pages/dropdowns/bussiness-sector'))
// Services
const ServiceDetails = lazy(() => import('../pages/service/details'))

// Services

// const ServiceActivities = lazy(() => import('../pages/activities'))

// My Tasks

const MyTasks = lazy(() => import('../pages/my-tasks'))
const Notifications = lazy(() => import('../pages/notifications'))

// Invoices

const Invoices = lazy(() => import('../pages/invoices'))
const InvoiceDetails = lazy(() => import('../pages/invoices/details'))

// Payments

const Payments = lazy(() => import('../pages/payments'))

// My Approvals

const MyApprovals = lazy(() => import('../pages/my-approvals'))

// My Payment Approvals

const MyPaymentApprovals = lazy(() => import('../pages/my-payment-approvals'))

// Export
const ExportList = lazy(() => import('../pages/exports'))
// Reports

const Reports = lazy(() => import('../pages/reports'))
const CREPerformanceSummaryComponent = lazy(
  () => import('../pages/reports/cre-performance-summary')
)
const TeamPerformanceSummaryComponent = lazy(
  () => import('../pages/reports/team-performance-summary')
)
const CREServiceStatusComponent = lazy(
  () => import('../pages/reports/cre-service-status')
)

const ContactPossibleDuplicates = lazy(
  () => import('../pages/contacts/details/possibleduplicates')
)

const DealList = lazy(() => import('../pages/deals'))
const DealDetails = lazy(() => import('../pages/deals/details'))
// const DealSummary = lazy(() => import('../pages/deals/details/summary'))
// const DealTasks = lazy(() => import('../pages/deals/details/tasks'))
// const DealLogs = lazy(() => import('../pages/deals/details/eventLogs'))
// const DealNotes = lazy(() => import('../pages/deals/details/notes'))
// const DealFiles = lazy(() => import('../pages/deals/details/files'))
// const DealPossibleDuplicates = lazy(
//   () => import('../pages/deals/details/possibleDuplicates')
// )
// const DealServices = lazy(() => import('../pages/deals/details/services'))

const ProspectList = lazy(() => import('../pages/prospects'))
const ProspectDetails = lazy(() => import('../pages/prospects/details'))

const QuoteList = lazy(() => import('../pages/quote'))
const QuoteDetails = lazy(() => import('../pages/quote/details'))
// const QuoteSummary = lazy(() => import('../pages/quote/details/summary'))

// const QuoteNotes = lazy(() => import('../pages/quote/details/notes'))
// const QuoteFiles = lazy(() => import('../pages/quote/details/files'))
// const QuoteDocument = lazy(() => import('../pages/quote/details/document'))

// const QuoteServices = lazy(() => import('../pages/quote/details/services'))
const Activities = lazy(() => import('../pages/activities'))
const ActivityDetails = lazy(() => import('../pages/activities/details'))
const ShareDeliverables = lazy(
  () => import('../pages/service/shareDeliverables/index')
)
const ShareDeliverableDetails = lazy(
  () => import('../pages/service/shareDeliverables/deliverables')
)

import DealMerge from '../pages/deals/merge/index'
import ProspectMerge from '../pages/prospects/merge/index'

import OrderPaymentReceipt from '../components/app/payment/receipt/orderReciept'
import { checkPermissionAny } from '../configs/permissionGate'
import FranchiseeDetailsCommunication from '../pages/franchisee/details/communication'
import FranchiseeDetailsBankAccount from '../pages/franchisee/details/bankAccounts'
import FranchiseeBankAccountDetails from '../pages/franchisee/details/bankAccounts/details'
import FranchiseeBankAccountBasicInfo from '../pages/franchisee/details/bankAccounts/details/basicInfo'
// import Profile from '../pages/profile'
import Stakeholder from '../pages/stakeholders'
import StakeholderDetails from '../pages/stakeholders/details'
import StakeholderDetailsSummary from '../pages/stakeholders/details/summary'
import StakeholderDetailTeams from '../pages/stakeholders/details/teams'
import ForceChangePassword from '../pages/userManagement/changePassword'
import ResetPassword from '../pages/userManagement/resetPassword'
import VendorsList from '../pages/vendors'
import VendorDetails from '../pages/vendors/details'
import VendorEmployees from '../pages/vendors/details/employees'

import VendorEmployeeDetails from '../pages/vendors/details/employees/details'
import VendorEmployeeSummary from '../pages/vendors/details/employees/details/basicInfo'
import VendorEmployeeTeams from '../pages/vendors/details/employees/details/teamInfo'
import VendorRoles from '../pages/vendors/details/roles'
import VendorRoleDetails from '../pages/vendors/details/roles/details'
import VendorRolePermissions from '../pages/vendors/details/roles/details/permissions'
import VendorRoleSummary from '../pages/vendors/details/roles/details/summary'
import VendorServices from '../pages/vendors/details/services'
import VendorSummary from '../pages/vendors/details/summary'
import VendorTeams from '../pages/vendors/details/teams'
import VendorBankAccounts from '../pages/vendors/details/bankAccounts'
import VendorBankAccountsDetails from '../pages/vendors/details/bankAccounts/details'
import VendorBankAccountBasicInfo from '../pages/vendors/details/bankAccounts/details/basicInfo'
import { useAuthStore } from '../store/authStore'
import VendorTeamDetails from '../pages/vendors/details/teams/details'
import VendorTeamSummay from '../pages/vendors/details/teams/details/basicInfo'
import VendorTeamMembersList from '../pages/vendors/details/teams/details/members'
import VendorOrders from '../pages/order/details/orders'
import UnallottedLeadsList from '../pages/unallottedLeads'
import UnallottedLeadProfiles from '../pages/unallottedLeads/details/profile'
import UnallottedLeadDetails from '../pages/unallottedLeads/details'
import SupplierCategoriesListing from '../pages/supplierCategories'
import SupplierListing from '../pages/suppliers'
import PayoutListing from '../pages/payouts'
import QuoteActivityLogs from '../pages/quote/details/activitylogs'
import OrderActivityLogs from '../pages/order/details/activitylogs'

const routes = [
  { slug: 'DASHBOARD', component: <Dashboard /> },
  {
    slug: 'ACCOUNTS',
    component: <Accounts />,
    isPrimary: true,
  },
  {
    slug: 'ACCOUNT_MERGE',
    component: <MergeAccount />,
  },
  {
    slug: 'ACCOUNT_DETAILS_MERGE',
    component: <MergeAccount />,
  },
  {
    slug: 'LEADS',
    isPrimary: true,
    component: <LeadsList />,
  },
  {
    slug: 'LEAD_DETAILS',
    component: <LeadDetails />,
    children: [
      {
        slug: 'LEAD_DETAILS_PROFILE',
        component: <LeadProfiles />,
      },
      {
        slug: 'LEAD_DETAILS_EVENT_LOGS',
        component: <LeadEventLogs />,
      },
      {
        slug: 'LEAD_TASKS',
        component: <LeadTask />,
      },
      {
        slug: 'LEAD_DETAILS_POSSIBLE_DUPLICATES',
        component: <LeadPossibleDuplicates />,
      },
      {
        slug: 'LEAD_FILES_AND_ATTACHMENTS',
        component: <LeadAttachments />,
      },
      {
        slug: 'LEAD_DETAILS_NOTES',
        component: <LeadNotes />,
      },
    ],
  },
  {
    slug: 'UNALLOTTED_LEADS',
    isPrimary: true,
    component: <UnallottedLeadsList />,
  },
  {
    slug: 'UNALLOTTED_LEAD_DETAILS',
    component: <UnallottedLeadDetails />,
    children: [
      {
        slug: 'UNALLOTTED_LEAD_DETAILS_PROFILE',
        component: <UnallottedLeadProfiles />,
      },
    ],
  },
  {
    slug: 'LEAD_DETAILS_MERGE',
    component: <LeadMerge />,
  },
  {
    slug: 'LEAD_MERGE',
    component: <LeadMerge />,
  },
  {
    slug: 'SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT_SUPPLIER_CATEGORIES',
    component: <SupplierCategoriesListing />,
  },
  {
    slug: 'SYSTEM_SETTINGS_SUPPLIER_MANAGEMENT_SUPPLIER',
    component: <SupplierListing />,
  },
  {
    slug: 'ACCOUNT_DETAILS',
    component: <AccountDetails />,
    children: [
      {
        slug: 'ACCOUNT_DETAILS_BASIC_INFO',
        component: <AccountBasicInfo />,
      },
      {
        slug: 'ACCOUNT_DETAILS_CONTACTS',
        component: <AccountContacts />,
      },
      {
        slug: 'ACCOUNT_DETAILS_EVENT_LOGS',
        component: <AccountEventLogs />,
      },
      {
        slug: 'ACCOUNT_TASKS',
        component: <AccountTask />,
      },

      {
        slug: 'ACCOUNT_FILES_AND_ATTACHMENTS',
        component: <AccountAttachments />,
      },
      {
        slug: 'ACCOUNT_DETAILS_AGENTS',
        component: <AccountAgents />,
      },
      {
        slug: 'ACCOUNT_DETAILS_ORDERS',
        component: <AccountOrders />,
      },
      {
        slug: 'ACCOUNT_DETAILS_ADDRESSES',
        component: <AccountAddresses />,
      },
      {
        slug: 'ACCOUNT_DETAILS_NOTES',
        component: <AccountNotes />,
      },
      {
        slug: 'ACCOUNT_POSSIBLE_DUPLICATES',
        component: <AccountPossibleDuplicates />,
      },
    ],
  },
  {
    slug: 'ACCOUNT_MERGE',
    component: <MergeAccount />,
  },
  {
    slug: 'ACCOUNT_DETAILS_MERGE',
    component: <MergeAccount />,
  },
  {
    slug: 'CONTACTS',
    component: <Contacts />,
    isPrimary: true,
  },
  {
    slug: 'CONTACT_MERGE',
    component: <ContactMerge />,
  },
  {
    slug: 'CONTACT_DETAILS_MERGE',
    component: <ContactMerge />,
  },
  {
    slug: 'EXPORTS',
    component: <ExportList />,
  },
  {
    slug: 'ACTIVITIES',
    component: <Activities />,
    isPrimary: true,
  },
  {
    slug: 'ACTIVITY_DETAILS',
    component: <ActivityDetails />,
    // isPrimary: true,
    children: [
      {
        slug: 'ACTIVITY_DETAILS_SUMMARY',
        component: <ActivitySummary />,
      },
      {
        slug: 'ACTIVITY_DETAILS_NOTES',
        component: <ActivityNotes />,
      },
      {
        slug: 'ACTIVITY_DETAILS_REQUESTS',
        component: <ActivityRequests />,
      },
      {
        slug: 'ACTIVITY_DETAILS_FILES',
        component: <ActivityFiles />,
      },
      {
        slug: 'ACTIVITY_DETAILS_TASKS',
        component: <ActivityTasks />,
      },
      {
        slug: 'ACTIVITY_DETAILS_LOGS',
        component: <ActivityLogs />,
      },
    ],
  },

  {
    slug: 'CONTACT_DETAILS',
    component: <ContactDetails />,
    children: [
      {
        slug: 'CONTACT_DETAILS_SUMMARY',
        component: <ContactSummary />,
      },
      {
        slug: 'CONTACT_DETAILS_ACCOUNTS',
        component: <ContactAccounts />,
      },
      {
        slug: 'CONTACT_DETAILS_EVENT_LOGS',
        component: <ContactEventLogs />,
      },
      {
        slug: 'CONTACT_TASKS',
        component: <ContactTask />,
      },

      {
        slug: 'CONTACT_FILES_AND_ATTACHMENTS',
        component: <ContactAttachments />,
      },
      {
        slug: 'CONTACT_DETAILS_NOTES',
        component: <ContactNotes />,
      },
      {
        slug: 'CONTACT_DETAILS_POSSIBLE_DUPLICATES',
        component: <ContactPossibleDuplicates />,
        children: [
          {
            slug: 'CONTACT_MERGE',
            component: <ContactMerge />,
          },
        ],
      },
    ],
  },

  {
    slug: 'MY_TASKS',
    component: <MyTasks />,
  },
  {
    slug: 'NOTIFICATIONS',
    component: <Notifications />,
  },
  {
    slug: 'MY_TASKS_VIEW',
    component: <MyTasks detail={true} />,
  },
  {
    slug: 'INVOICES',
    component: <Invoices />,
  },
  {
    slug: 'INVOICES_DETAILS',
    component: <InvoiceDetails />,
  },
  {
    slug: 'PAYMENTS',
    component: <Payments />,
  },
  {
    slug: 'PAYOUTS',
    component: <PayoutListing />,
  },
  {
    slug: 'MY_PAYMENT_APPROVALS',
    component: <MyPaymentApprovals />,
  },
  {
    slug: 'MY_PAYMENT_APPROVAL_DETAILS',
    component: <MyPaymentApprovals />,
  },

  {
    slug: 'MY_APPROVALS',
    component: <MyApprovals />,
  },
  {
    slug: 'MY_APPROVAL_DETAILS',
    component: <MyApprovals />,
  },
  {
    slug: 'REPORTS',
    component: <Reports />,
    isPrimary: true,
  },
  {
    slug: 'REPORT_CRE_PERFORMANCE_SUMMARY',
    component: <CREPerformanceSummaryComponent />,
  },
  {
    slug: 'REPORT_TEAM_PERFORMANCE_SUMMARY',
    component: <TeamPerformanceSummaryComponent />,
  },
  {
    slug: 'REPORT_CRE_SERVICE_STATUS',
    component: <CREServiceStatusComponent />,
  },
  {
    slug: 'SERVICE_AND_ORDERS_BY_ORDERS',
    component: <OrderList />,
  },
  {
    slug: 'SERVICE_AND_ORDERS_BY_ORDERS_INVOICES_ID',
    component: <OrderInvoicesId />,
  },

  {
    slug: 'SERVICE_AND_ORDERS_BY_ORDERS_DETAILS',
    component: <OrderDetails />,
    children: [
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_SUMMARY',
        component: <OrderSummary />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_SERVICES',
        component: <OrderServices />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS',
        component: <OrderPayments />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_TRANSACTION',
        component: <OrderPayments />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_INVOICES',
        component: <OrderInvoices />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_NOTES',
        component: <OrderNote />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_FILES',
        component: <OrderFiles />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_ACTIVITY_LOGS',
        component: <OrderActivityLogs />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_TASKS',
        component: <OrderTasks />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_VENDOR_ORDERS',
        component: <VendorOrders />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_ORDERS_LOGS',
        component: <OrderLogs />,
      },
    ],
  },
  {
    slug: 'SERVICE_AND_ORDERS_BY_ORDERS_PAYMENTS_RECEIPT',
    component: <OrderPaymentReceipt />,
  },
  {
    slug: 'PAYMENTS_RECEIPT',
    component: <PaymentReceipt />,
  },
  {
    slug: 'DEALS',
    isPrimary: true,
    component: <DealList />,
    children: [
      {
        slug: 'DEAL_MERGE',
        component: <DealMerge />,
      },
    ],
  },

  {
    slug: 'DEAL_DETAILS',
    component: <DealDetails />,
    children: [
      {
        slug: 'DEAL_DETAILS_SUMMARY',
        component: <DealSummary />,
      },
      {
        slug: 'DEAL_DETAILS_TASKS',
        component: <DealTasks />,
      },
      {
        slug: 'DEAL_DETAILS_EVENT_LOGS',
        component: <DealLogs />,
      },
      {
        slug: 'DEAL_DETAILS_NOTES',
        component: <DealNotes />,
      },
      {
        slug: 'DEAL_DETAILS_FILES',
        component: <DealFiles />,
      },
      {
        slug: 'DEAL_DETAILS_SERVICES',
        component: <DealServices />,
      },
      {
        slug: 'DEAL_DETAILS_POSSIBLE_DUPLICATES',
        component: <DealPossibleDuplicates />,
        children: [
          {
            slug: 'DEAL_MERGE',
            component: <DealMerge />,
          },
        ],
      },
    ],
  },

  {
    slug: 'PROSPECTS',
    isPrimary: true,
    component: <ProspectList />,
    children: [
      {
        slug: 'PROSPECT_MERGE',
        component: <ProspectMerge />,
      },
    ],
  },

  {
    slug: 'PROSPECT_DETAILS',
    component: <ProspectDetails />,
    children: [
      {
        slug: 'PROSPECT_DETAILS_SUMMARY',
        component: <ProspectSummary />,
      },
      {
        slug: 'PROSPECT_DETAILS_TASKS',
        component: <ProspectTasks />,
      },
      {
        slug: 'PROSPECT_DETAILS_EVENT_LOGS',
        component: <ProspectLogs />,
      },
      {
        slug: 'PROSPECT_DETAILS_NOTES',
        component: <ProspectNotes />,
      },
      {
        slug: 'PROSPECT_DETAILS_FILES',
        component: <ProspectFiles />,
      },
      {
        slug: 'PROSPECT_DETAILS_SERVICES',
        component: <ProspectServices />,
      },
      {
        slug: 'PROSPECT_DETAILS_POSSIBLE_DUPLICATES',
        component: <ProspectPossibleDuplicates />,
        children: [
          {
            slug: 'PROSPECT_MERGE',
            component: <ProspectMerge />,
          },
        ],
      },
    ],
  },
  {
    slug: 'QUOTES',
    isPrimary: true,
    component: <QuoteList />,
  },
  {
    slug: 'QUOTE_DETAILS_DOCUMENT_ID',
    component: <QuoteDocumentID />,
  },
  {
    slug: 'QUOTE_DETAILS',
    // isPrimary: true,
    component: <QuoteDetails />,
    children: [
      {
        slug: 'QUOTE_DETAILS_SUMMARY',
        component: <QuoteSummary />,
      },
      {
        slug: 'QUOTE_DETAILS_FILES',
        component: <QuoteFiles />,
      },
      {
        slug: 'QUOTE_DETAILS_EVENT_LOGS',
        component: <QuoteEventLogs />,
      },
      {
        slug: 'QUOTE_TASKS',
        component: <QuoteTask />,
      },

      {
        slug: 'QUOTE_DETAILS_NOTES',
        component: <QuoteNotes />,
      },
      {
        slug: 'QUOTE_DETAILS_SERVICES',
        component: <QuoteServices />,
      },
      {
        slug: 'QUOTE_DETAILS_DOCUMENT',
        component: <QuoteDocument />,
      },
      {
        slug: 'QUOTE_DETAILS_ACTIVITY_LOGS',
        component: <QuoteActivityLogs />,
      },
    ],
  },

  {
    slug: 'SERVICE_AND_ORDERS_BY_SERVICE',
    primary: true,
    component: <ServiceList />,
  },
  {
    slug: 'SERVICE_AND_ORDERS_BY_SERVICE_DETAILS',
    component: <ServiceDetails />,
    children: [
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_SUMMARY',
        component: <ServiceSummary />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_ACTIVITY_PROGRESS',
        component: <ServiceActivityProgress />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENTS',
        component: <ServicePayments />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_NOTES',
        component: <ServiceNotes />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_FILES',
        component: <ServiceFiles />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_LOGS',
        component: <ServiceLog />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_TASKS',
        component: <ServiceTasks />,
      },
      {
        slug: 'SERVICE_AND_ORDERS_BY_SERVICE_PAYMENT_APPROVAL',
        component: <ServicePaymentApproval />,
      },
    ],
  },
  ...(!isAllFranchisee()
    ? [
        {
          slug: 'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEES',
          isPrimary: true,
          component: <Employees />,
        },
        {
          slug: 'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_DETAILS',
          component: <EmployeeDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_BASIC_INFO',
              component: <EmployeeBasicInfo />,
            },
            {
              slug: 'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_TEAM_INFO',
              component: <EmployeeTeamInfo />,
            },
            {
              slug: 'SYSTEM_SETTINGS_ORGANIZATION_EMPLOYEE_FRANCHISEE',
              component: <EmployeeFranchiseeInfo />,
            },
          ],
        },
        {
          slug: 'SYSTEM_SETTINGS_VENDOR_ROLE_DETAILS',
          component: <VendorRoleDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_ROLE_SUMMARY',
              component: <VendorRoleSummary />,
            },
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_ROLE_PERMISIIONS',
              component: <VendorRolePermissions />,
            },
          ],
        },
        {
          slug: 'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_DETAILS',
          component: <VendorEmployeeDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_BASIC_INFO',
              component: <VendorEmployeeSummary />,
            },
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_EMPLOYEES_TEAM_INFO',
              component: <VendorEmployeeTeams />,
            },
          ],
        },
        {
          slug: 'SYSTEM_SETTINGS_VENDOR_TEAMDETAILS',
          component: <VendorTeamDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_TEAM_SUMMARY',
              component: <VendorTeamSummay />,
            },
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_TEAM_MEMBERS',
              component: <VendorTeamMembersList />,
            },
          ],
        },
        {
          slug: 'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_DETAILS',
          component: <VendorBankAccountsDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT_BASIC_INFO',
              component: <VendorBankAccountBasicInfo />,
            },
          ],
        },
        {
          slug: 'SYSTEM_SETTINGS_VENDORS',
          component: <VendorsList />,
        },
        {
          slug: 'SYSTEM_SETTINGS_VENDOR_DETAILS',
          component: <VendorDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_SUMMARY',
              component: <VendorSummary />,
            },
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_SERVICE',
              component: <VendorServices />,
            },
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_EMPLOYEES',
              component: <VendorEmployees />,
            },
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_ROLE',
              component: <VendorRoles />,
            },
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_TEAM',
              component: <VendorTeams />,
            },
            {
              slug: 'SYSTEM_SETTINGS_VENDOR_BANK_ACCOUNT',
              component: <VendorBankAccounts />,
            },
          ],
        },
        {
          slug: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS',
          component: <Teams />,
        },
        {
          slug: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_DETAILS',
          component: <TeamDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_BASIC_INFO',
              component: <TeamInfo />,
            },
            {
              slug: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_MEMBER',
              component: <TeamMembers />,
            },
            {
              slug: 'SYSTEM_SETTINGS_ORGANIZATION_TEAMS_SERVICE',
              component: <TeamServices />,
            },
          ],
        },
        {
          slug: 'SYSTEM_SETTINGS_ORGANIZATION_ROLES',
          component: <Roles />,
        },
        {
          slug: 'SYSTEM_SETTINGS_FRANCHISEE',
          component: <Franchisee />,
        },
        {
          slug: 'SYSTEM_SETTINGS_FRANCHISEE_DETAILS',
          component: <FranchiseeDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_FRANCHISEE_SUMMARY',
              component: <FranchiseeDetailsSummary />,
            },
            {
              slug: 'SYSTEM_SETTINGS_FRANCHISEE_SERVICE',
              component: <FranchiseeDetailsService />,
            },
            {
              slug: 'SYSTEM_SETTINGS_FRANCHISEE_EMPLOYEES',
              component: <FranchiseeDetailsEmployees />,
            },
            {
              slug: 'SYSTEM_SETTINGS_FRANCHISEE_PAYMENT_GATEWAY',
              component: <FranchiseeDetailsPayment />,
            },
            {
              slug: 'SYSTEM_SETTINGS_FRANCHISEE_COMMUNICATION',
              component: <FranchiseeDetailsCommunication />,
            },
            {
              slug: 'SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT',
              component: <FranchiseeDetailsBankAccount />,
            },
          ],
        },

        {
          slug: 'SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT_DETAILS',
          component: <FranchiseeBankAccountDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_FRANCHISEE_BANK_ACCOUNT_BASIC_INFO',
              component: <FranchiseeBankAccountBasicInfo />,
            },
          ],
        },

        {
          slug: 'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS',
          component: <Stakeholder />,
        },
        {
          slug: 'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS',
          component: <StakeholderDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_SUMMARY',
              component: <StakeholderDetailsSummary />,
            },
            {
              slug: 'SYSTEM_SETTINGS_ORGANIZATION_STACKHOLDERS_DETAILS_TEAMS',
              component: <StakeholderDetailTeams />,
            },
          ],
        },
        {
          slug: 'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS',
          component: <RoleDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_SUMMARY',
              component: <RoleDetailsBasics />,
            },
            {
              slug: 'SYSTEM_SETTINGS_ORGANIZATION_ROLES_DETAILS_PERMISSIONS',
              component: <RoleDetailsPermissions />,
            },
          ],
        },
        {
          slug: 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES',
          isPrimary: true,
          component: <Service />,
        },
        {
          slug: 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_DETAILS',
          component: <ServiceManagementDetails />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_BASIC_INFO',
              component: <ServiceManagementDetailsInfo />,
            },
            {
              slug: 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_CHECKLIST',
              component: <ServiceManagementDetailsActivity />,
            },
            {
              slug: 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_SERVICES_STATUS',
              component: <ServiceManagementDetailsStatus />,
            },
          ],
        },
        {
          slug: 'SYSTEM_SETTINGS_SERVICE_MANAGEMENT_ACTIVITIES',
          component: <ServiceActivities />,
        },
        {
          slug: 'SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS',
          component: <Dropdown />,
          children: [
            {
              slug: 'SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_CONSTITUTION_CATEGORIES',
              component: <ConstitutionCategories />,
            },
            {
              slug: 'SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_SERVICE_CATEGORIES',
              component: <ServiceCategories />,
            },
            {
              slug: 'SYSTEM_SETTINGS_DATA_MANAGEMENT_DROPDOWNS_SERVICE_BUSINESS_SECTOR',
              component: <BusinessSector />,
            },
          ],
        },
      ]
    : []),

  {
    isAuthRoute: true,
    slug: 'LOGIN',
    component: <Login />,
  },
  {
    isAuthRoute: true,
    slug: 'CHANGE_PASSWORD',
    component: <ForceChangePassword />,
  },
  {
    isAuthRoute: true,
    slug: 'FORGET_PASSWORD',
    component: <ForgetPassword />,
  },
  {
    isAuthRoute: true,
    slug: 'RESET_PASSWORD',
    component: <ResetPassword />,
  },
  // {
  //   slug: 'USER_PROFILE',
  //   component: <Profile />,
  // },
  {
    slug: 'SHARE_DELIVERABLES',
    component: <ShareDeliverables />,
    children: [
      {
        slug: 'SHARE_DELIVERABLES_SUMMARY',
        component: <ServiceSummary />,
      },
      {
        slug: 'SHARE_DELIVERABLES_DETAILS',
        component: <ShareDeliverableDetails />,
      },
    ],
  },
]
export default function MainRoutes() {
  return (
    <>
      <Routes>
        {routes.map((route) => (
          <>
            {route.isAuthRoute ? (
              <Route
                element={<GuestRoute>{route.component}</GuestRoute>}
                path={router_config[route.slug].path}
                key={router_config[route.slug].path}
              />
            ) : (
              <>
                <Route
                  element={
                    <UserRoute
                      slug_key={route.slug}
                      hasChild={
                        route?.isPrimary
                          ? false
                          : Boolean(route?.children?.length)
                      }
                    >
                      {route.component}
                    </UserRoute>
                  }
                  path={router_config[route.slug].path}
                  key={router_config[route.slug].path}
                >
                  {route.children?.length && (
                    <>
                      {route.children.map((child) => (
                        <Route
                          element={
                            <ChildRoute slug_key={child.slug}>
                              {child.component}
                            </ChildRoute>
                          }
                          path={router_config[child.slug].path}
                          key={router_config[child.slug].path}
                        />
                      ))}
                    </>
                  )}
                </Route>
              </>
            )}
          </>
        ))}

        <Route
          element={
            <UserRoute
              slug_key={
                checkPermissionAny(MODULES.dashboard) ? 'DASHBOARD' : 'MY_TASKS'
              }
            >
              {checkPermissionAny(MODULES.dashboard) ? (
                <Dashboard />
              ) : (
                <MyTasks />
              )}
            </UserRoute>
          }
          key="*"
          path="*"
        />
      </Routes>
    </>
  )
}
