import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { TextArea } from '../../../common'
import CustomDrawer from '../../../common/drawer'
import { noteSchema, NoteSchema } from './noteSchema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  onSubmit: (data: NoteSchema) => void
  values?: NoteSchema
  isLoading?: boolean
}

export default function CreateNotes({
  isDrawerOpen,
  handleClose,
  onSubmit,
  values,
  isLoading,
}: Props) {
  const {
    handleSubmit,
    setValue,
    formState: { errors },
    control,
    reset,
  } = useForm<NoteSchema>({
    resolver: zodResolver(noteSchema),
    defaultValues: { note: '' },
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  useEffect(() => {
    reset({
      id: values?.id ?? undefined,
      note: values?.note ?? '',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isDrawerOpen])

  const handleClearAndClose = () => {
    reset({ id: undefined, note: '' })
    handleClose()
  }

  const handleNoteChange = (e: any) => {
    const { value } = e?.target
    setValue('note', value, { shouldValidate: true })
  }
  return (
    <CustomDrawer
      className="formDrawer"
      handleSubmit={() => handleSubmit(onSubmit)()}
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      title={values?.id ? 'Edit Note' : 'Create New Note'}
      actionLoader={isLoading}
    >
      <div className="flex flex-col gap-4">
        <Controller
          name={`note`}
          control={control}
          render={({ field: { value } }) => (
            <TextArea
              label={'Note'}
              placeholder={'Add Note'}
              id={'note'}
              name={'note'}
              value={value ?? ''}
              onChange={handleNoteChange}
              required={true}
              errors={errors}
            />
          )}
        />
      </div>
    </CustomDrawer>
  )
}
