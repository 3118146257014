import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { getErrorMessage } from '../../../utilities/parsers'
import { sendInvoice } from '../api'
import { sendInvoiceSchema, SendInvoiceSchema } from './schema'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  subSection?: boolean
  handleCallback?: () => void
  id?: string
}

export default function Create({
  isDrawerOpen,
  handleClose,
  handleCallback,
  id,
}: Props) {
  const { enqueueSnackbar } = useSnackbarManager()
  const [isLoading, setIsLoading] = useState(false)

  const methods = useForm<SendInvoiceSchema>({
    resolver: zodResolver(sendInvoiceSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {},
  })

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    type = 'text'
  ) => ({
    name,
    label,
    id: name,
    type: type,
    placeholder,
    ...(required ? { required: true } : {}),
  })

  const formBuilderProps = [
    textField('email', 'Email', 'Enter Email Id', true, 'text'),
  ]

  const handleSubmission = () => {
    methods.reset()
    handleClose()
    handleCallback && handleCallback()
  }

  const onSubmit = (data: SendInvoiceSchema) => {
    setIsLoading(true)
    const input = {
      email: data?.email ?? '',
    }
    sendInvoice(id ?? '', input)
      .then(() => {
        handleSubmission()
        enqueueSnackbar('Invoice send successfully', {
          variant: 'success',
        })
        setIsLoading(false)
      })
      .catch((error: any) => {
        enqueueSnackbar(getErrorMessage(error?.response.data.error), {
          variant: 'error',
        })
        setIsLoading(false)
      })
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({})
    handleClose()
  }
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      handleClose={() => handleClearAndClose()}
      handleSubmit={handleSubmit((data) => onSubmit(data))}
      actionLoader={isLoading}
      title="Share Invoice"
      actionLabel="Share"
    >
      <div className="flex flex-col gap-4">
        <FormProvider {...methods}>
          <FormBuilder data={formBuilderProps} edit={true} />
        </FormProvider>
      </div>
    </CustomDrawer>
  )
}
