import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons/index'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import { MODULES } from '../../../../configs/scopes.config'
import { getErrorMessage } from '../../../../utilities/parsers'
import AddService from '../../addServices'
import { deleteDealService, useOrderServiceDetails } from '../../api'
import { checkDetailPermission } from '../../permission'
import { getColumns } from './servicesColumns'

const DealServices = () => {
  const {
    setActiveTab,
    edit = false,
    disabled = false,
  } = useOutletContext<{
    setActiveTab: (id: string) => void
    edit?: boolean
    disabled?: boolean
  }>()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const [isAccountOpen, setIsAccountOpen] = useState(edit)
  const [activeRow, setActiveRow] = useState<any>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const [pageParams, setPageParams] = useState({
    page: 1,
    page_size: 30,
    search: '',
    ordering: '',
  })

  const params = useParams()

  const handleAction = () => {
    setIsAccountOpen(true)
  }
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])
  useEffect(() => {
    setIsAccountOpen(edit)
  }, [edit])
  useEffect(
    () => {
      setActiveTab('DEAL_DETAILS_SERVICES')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { page, page_size, search, ordering } = pageParams

  const searchParams = {
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }
  const handleDelete = () => {
    deleteDealService(params.id, activeRow?.id)
      .then(() => {
        enqueueSnackbar('Service deleted successfully', { variant: 'success' })
        handleDeleteClose()
        refetch()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.errors[0]?.message[0] ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const { data, isFetching, refetch } = useOrderServiceDetails(
    params?.id ?? '',
    searchParams
  )
  // const onViewAction = (r: any) => {
  //   return `${router_config.SERVICE_AND_ORDERS_BY_SERVICE.path}/${r.service?.id}/summary`
  // }

  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }
  // const handleOnSlect = (rows: any) => {
  //   setSelectedRows([...rows])
  // }
  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }
  const handleDeleteOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }
  const handleCallback = () => {
    refetch()
  }
  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data?.results ?? []}
          dataRowKey="id"
          toolbar={true}
          // selectedRows={selectedRows}
          // selection={true}
          search={true}
          isLoading={isFetching}
          columns={getColumns() as any}
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                onClick={handleAction}
                label={'Add New'}
                disabled={isDisabled}
                hidden={!checkDetailPermission('add', MODULES.service)}
                className="se"
                outlined
              />
            </div>
          }
          actionProps={[
            ...(isDisabled
              ? []
              : [
                  {
                    icon: <Icons name="delete" />,
                    action: (row: any) => handleDeleteOpen(row),
                    title: 'Delete',
                    toolTip: 'Delete',
                    hidden: !checkDetailPermission('delete', MODULES.service),
                  },
                ]),
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          // onSelect={handleOnSlect}
          columnToggle
        />
      </div>
      <AddService
        isDrawerOpen={isAccountOpen}
        subSection={true}
        handleClose={() => setIsAccountOpen(false)}
        handleCallback={handleCallback}
        id={params.id}
      />
      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleDelete()}
        secondaryAction={() => handleDeleteClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Are you sure you want to delete this service from this deal?'
            }
          />
        }
      />
    </>
  )
}

export default DealServices
