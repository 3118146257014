import { useEffect, useRef, useState } from 'react'

import { getFranchiseeList } from '../apis/common.apis'
import Icons from '../components/common/icons/index'
import { useAuthStore } from '../store/authStore'

export default function FranchiseeMenu() {
  const [franchiseeMenu, setFranchiseeMenu] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)

  const [franchiseeData, setFranchiseeData] = useState<any>()

  const {
    franchisee,
    setFranchisee,
    setUserData,
    setPermissionData,
    setRoleData,
    setIsMember,
    setFranchiseeDefaultGateway,
    setFranchiseesList,
  } = useAuthStore()

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setFranchiseeMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const stringToColor = (string: string) => {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value?.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }

  function stringAvatar(name: string) {
    const splitName = name?.split(' ')

    return {
      bgcolor: stringToColor(name),
      name: splitName
        .filter((n) => n) // Filter out any empty strings
        .map((n) => n[0].toUpperCase()) // Capitalize the first letter
        .slice(0, 2) // Take only the first two elements
        .join(''), // Join them together
    }
  }
  const handleSwitch = () => {
    setFranchiseeMenu(true)
  }
  const getData = async () => {
    const { data } = await getFranchiseeList()
    setFranchiseesList(data)
    setFranchiseeData(data)
  }

  useEffect(() => {
    if (franchiseeMenu || !franchiseeData) {
      getData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [franchiseeMenu])
  const handleSwitchFranchise = (item: any) => {
    const relaod = localStorage.getItem('shouldReload')
    if (item.franchisee.id === item.user_data.franchisee.id) {
      item.franchisee.is_default_franchisee = true
    }
    setFranchisee(item.franchisee)
    setFranchiseeDefaultGateway({
      ...item?.franchisee?.default_payment_gateway,
    })
    sessionStorage.setItem('showSnackbar', 'Franchisee switched successfully!')
    setIsMember(item.is_member)
    localStorage.setItem('shouldReload', relaod === 'true' ? 'false' : 'true')
    setRoleData(item?.role && item?.role !== '' ? item.role : undefined)
    if (item?.role?.permissions) {
      setPermissionData(item?.role?.permissions)
    } else {
      setPermissionData([])
    }
    setUserData({ ...item?.user_data, ...item?.user_data?.user })
    setFranchiseeMenu(false)
    window.open('/', '_self')
  }
  const { impersonating } = useAuthStore()
  return (
    <div
      ref={menuRef}
      className={`${
        impersonating ? 'bg-primary' : 'bg-white'
      } dropdown text-black dark:text-white  dark:bg-[#424242]  `}
    >
      <div className="dropdown-toggle">
        {franchiseeData && franchiseeData?.length > 0 && (
          <a
            href="#/"
            onClick={() => handleSwitch()}
            className="bg-cardWrapperBg h-10  rounded-[36px] p-3  flex items-center justify-center cursor-pointer"
          >
            <span className="flex items-center gap-3 ">
              <span className=" text-xs text-linkBlue">
                {franchisee?.franchisee_display_name ??
                  franchisee?.franchisee_name}
                {/* <span className="text-xs text-[#999696] italic">
                  {franchisee?.is_default_franchisee ? ' - Default' : ''}
                </span> */}
              </span>
              <span>
                <Icons
                  name="btn-drop-arrow"
                  className="iconWidthXs iconBlack"
                />
              </span>
            </span>
          </a>
        )}
      </div>

      {franchiseeMenu && (
        <div
          className={`dropdown-menu origin-top ${'text-black dark:text-white'} p-2 min-w-[320px] text-sm font-normal border-grey-border  tracking-[0.24px]  rounded-[8px]  absolute left-0 right-auto mt-[20px] top-8 w-auto shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-[#424242]  dark:text-white `}
        >
          <div className=" max-h-[220px] overflow-auto flex flex-col w-full gap-1">
            {franchiseeData?.map((item: any) => (
              <a
                href="#/"
                key={item.id}
                onClick={() =>
                  item?.franchisee?.id === franchisee.id
                    ? ''
                    : handleSwitchFranchise(item)
                }
                className={`p-2 leading-7 hover:bg-background flex items-center justify-between  gap-2 `}
              >
                <div
                  className={`${'text-black dark:text-white'}   flex items-center w-full tracking-[0.24px]  leading-4`}
                >
                  <span
                    className={` ${
                      item.is_default && 'border-[#e76458]'
                    } w-10 h-10 p-1 rounded-full text-base flex justify-center border items-center`}
                  >
                    {/* {item.franchisee?.franchisee_name?.charAt(0).toUpperCase()} */}
                    {
                      stringAvatar(
                        (item.franchisee?.franchisee_display_name ||
                          item.franchisee?.franchisee_name) as string
                      )?.name
                    }
                  </span>

                  <span className="pl-2 text-sm font-medium">
                    {item.franchisee?.franchisee_display_name ??
                      item.franchisee?.franchisee_name}
                  </span>
                </div>
                {item.is_default && <Icons name="favourite-colored" />}
                {item?.franchisee?.id === franchisee.id && (
                  <Icons
                    name={'check-mark'}
                    className="w-[30px] flex justify-end"
                  />
                )}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
