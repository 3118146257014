import React from 'react'
import { IconProps } from '../../../common/types'

const ServicesIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 10C1 10.84 1.63 11.81 2.4 12.15L9.19 15.17C9.71 15.4 10.3 15.4 10.81 15.17L17.6 12.15C18.37 11.81 19 10.84 19 10M1 15C1 15.93 1.55 16.77 2.4 17.15L9.19 20.17C9.71 20.4 10.3 20.4 10.81 20.17L17.6 17.15C18.45 16.77 19 15.93 19 15M16.91 4.54007C18.61 5.29007 18.61 6.53007 16.91 7.28007L11.01 9.90007C10.34 10.2001 9.24 10.2001 8.57 9.90007L2.67 7.28007C0.970004 6.53007 0.970004 5.29007 2.67 4.54007L8.57 1.92007C9.24 1.62007 10.34 1.62007 11.01 1.92007L16.91 4.54007Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ServicesIcon
