import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { AdvanceFilter, StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'

export const useAgentStatusReportFilterStore =
  create<AgentStatusReportFilterStoreType>()(
    persist(
      (set) => ({
        pageParams: defaultPageParams,
        advanceFilter: {
          filterParams: [],
          isDetailed: false,
        },
        selectedRows: [],

        setAdvanceFilter: (data) => set(() => ({ advanceFilter: data })),
        clearSelectedRows: () => set({ selectedRows: [] }),
        setPageParams: (data) => set(() => ({ pageParams: data })),
        clearAdvanceFilter: () =>
          set(() => ({
            advanceFilter: {
              filterParams: [],
              isDetailed: false,
            },
          })),
        setSelectedRows: (data) => set(() => ({ selectedRows: data })),
        clearAgentStatusReportFilter: (key) =>
          set((state) => ({
            pageParams: key
              ? { ...defaultPageParams, search: state?.pageParams?.search }
              : defaultPageParams,
          })),
      }),
      { name: 'fit__agent_status_report_store' }
    )
  )

type AgentStatusReportFilterStoreType = {
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]
  advanceFilter: AdvanceFilter
  setSelectedRows: (data: (number | string)[]) => void
  setPageParams: (data: StoreFilterParams) => void
  setAdvanceFilter: (data: AdvanceFilter) => void
  clearAgentStatusReportFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
