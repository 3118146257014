import React from 'react'
import { IconProps } from '../../../common/types'

const DeactivateIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3033 15.3033C18.2322 12.3744 18.2322 7.62563 15.3033 4.6967C12.3744 1.76777 7.62563 1.76777 4.6967 4.6967M15.3033 15.3033C12.3744 18.2322 7.62563 18.2322 4.6967 15.3033C1.76777 12.3744 1.76777 7.62563 4.6967 4.6967M15.3033 15.3033L4.6967 4.6967"
        stroke="#313131"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DeactivateIcon
