// import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
// import { useForm } from 'react-hook-form'

import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { Button } from '../../../../components/common'
import Icons from '../../../../components/common/icons'
import FormFieldView from '../../../../components/common/inputs/FormFieldView'
import TextField from '../../../../components/common/inputs/TextField'
import config from '../../../../config'
import { useUpdateNotificationSettings } from '../../api'
import { adminCommunicationSchema } from './schema'
// import { PaymentSchema, paymentSchema } from '../../create/schema'

type Props = {
  data: any
  id: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
}

const BasicInformation = (props: Props) => {
  const { id, handleRefresh, handleEditButton, editFlag, hasPermission, data } =
    props

  const [isEditing, setIsEditing] = useState(false)
  // const [gateway, setGateway] = useState<any>()
  // const [adminEmails, setAdminEmails] = useState<any>([])

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({ emails: data })
  }
  const handleSubmission = () => {
    // methods.reset({
    //   // ...onInit(),
    // })
    handleRefresh()
    setIsEditing(!isEditing)
  }
  const methods = useForm({
    resolver: zodResolver(adminCommunicationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  })
  useEffect(() => {
    methods.reset({ emails: data })
  }, [data])
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = methods
  // const { append, remove, update } = useFieldArray({
  //   control: control,
  //   name: 'service',
  // })
  const { mutate } = useUpdateNotificationSettings(handleSubmission)

  const onSubmit = (data: any) => {
    mutate({
      input: {
        emails: data?.emails,
      },
      id,
    })
  }

  // const { handleSubmit } = methods

  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])
  //console.log(methods.formState.errors, 'errors')
  const handleReturnError = (fieldIndex: number) => {
    if ('emails' in errors && Array.isArray(errors.emails)) {
      //console.log(errors.emails, fieldIndex)
      const itemError = errors.emails?.[fieldIndex]

      return itemError?.message
    }
    if ('emails' in errors && errors.emails?.message) {
      return errors.emails.message
    }
    return false
  }
  const handleDelete = (index: any) => {
    const emailDatas = [...methods.watch()?.emails]
    emailDatas.splice(index, 1)

    setValue('emails', emailDatas, { shouldValidate: true })
    if (emailDatas.length === 0 || !emailDatas) {
      mutate({
        input: {
          emails: emailDatas,
        },
        id,
      })
    }
  }

  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <>
            <div className="w-full col-span-12 flex flex-col gap-4">
              <FormProvider {...methods}>
                {methods.watch()?.emails.map((item: any, i: any) => (
                  <div key={i} className="flex w-full">
                    <div className="w-full">
                      <Controller
                        name={'emails'}
                        control={control}
                        key={`${i}-emails`}
                        render={({}) => (
                          <TextField
                            id={`${i}-emails`}
                            key={`${i}-emails`}
                            name={'emails'}
                            type="email"
                            value={item || ''}
                            onChange={(e) => handleTextChange(e, i)}
                            placeholder=""
                            errText={handleReturnError(i)}
                            errors={handleReturnError(i)}
                            required={false}
                          />
                        )}
                      />
                    </div>
                    <div
                      onClick={() => handleDelete(i)}
                      className="flex pl-2 items-center justify-center text-primary"
                    >
                      <Icons name="delete" className="text-primary" />
                    </div>
                  </div>
                ))}
              </FormProvider>
            </div>
            <>
              {methods.watch()?.emails?.length ? (
                <></>
              ) : (
                <>
                  <div className="w-full col-span-12 flex items-center flex-col justify-center gap-4 p-5">
                    <img
                      src={config.EMPTY_ILLUSTRATION}
                      alt="bizpole"
                      className="h-auto"
                    />
                    <p className="text-sm font-bold text-primaryText dark:text-white mt-5">
                      No emails available
                    </p>
                    <p className="text-xxs leading-4 font-medium text-secondary dark:text-white mb-5">
                      Oops! No emails to display at the moment.
                    </p>
                    <div className="pb-5">
                      <Button
                        label="Add Email"
                        icon="plus"
                        onClick={() => handleAddEmail()}
                        size="xs"
                        outlined={false}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          </>
        ) : (
          <>
            {methods.watch()?.emails?.length ? (
              <>
                {methods.watch()?.emails?.map((item: any, i: any) => (
                  <div
                    className="w-full col-span-12 flex flex-col gap-4"
                    key={i}
                  >
                    <FormFieldView value={item} required={item} fullwidth />
                  </div>
                ))}
              </>
            ) : (
              <>
                <div className="w-full col-span-12 flex items-center flex-col justify-center gap-4 p-5">
                  <img
                    src={config.EMPTY_ILLUSTRATION}
                    alt="bizpole"
                    className="h-auto"
                  />
                  <p className="text-sm font-bold text-primaryText dark:text-white mt-5">
                    No emails available
                  </p>
                  <p className="text-xxs leading-4 font-medium text-secondary dark:text-white mb-5">
                    Oops! No emails to display at the moment.
                  </p>
                  <div className="pb-5">
                    <Button
                      label="Add Email"
                      icon="plus"
                      onClick={() => handleAddEmail()}
                      size="xs"
                      outlined={false}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    )
  }
  const handleTextChange = (e: any, index: number) => {
    const { value } = e.target
    setValue(`emails.${index}`, value, {
      shouldValidate: true,
    })
  }
  // const handleReturnError = (fieldIndex: number, key?: string) => {
  //   if ('email' in errors && Array.isArray(errors.email)) {
  //     const itemError = errors["email"][fieldIndex]
  //     return itemError && key ? itemError[key] : itemError
  //   }
  //   return false
  // }
  const handleAddEmail = () => {
    setIsEditing(true)
    const emailDatas = [...methods.watch()?.emails]
    emailDatas.push('')
    setValue('emails', emailDatas)
  }
  const checkForErrors = () => {
    const values: any = methods.watch().emails || []
    const errors: any = methods?.formState?.errors

    let hasEmptyValues = false
    let hasErrors = false

    errors?.emails?.forEach((item: any) => {
      if (item) {
        hasErrors = true
      }
    })

    values.forEach((item: any) => {
      if (!item || item === '') {
        hasEmptyValues = true
      }
    })
    return hasEmptyValues || hasErrors
  }
  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="email-pin" />
          <h4 className=" font-bold text-primaryText ">
            <div className="flex gap-2">
              <div>Admin Emails</div>
              {isEditing && methods.watch()?.emails?.length > 0 && (
                <div>
                  <Button
                    label="Add Email"
                    icon="plus"
                    disabled={checkForErrors()}
                    onClick={() => handleAddEmail()}
                    size="xs"
                    outlined={true}
                  />
                </div>
              )}
            </div>
          </h4>
        </div>
        <div className="iconBlack cursor-pointer">
          {hasPermission && (
            <div className="iconBlack cursor-pointer">
              {isEditing ? (
                <div className="flex gap-2">
                  {methods.watch()?.emails?.length > 0 && (
                    <>
                      <div>
                        <Button
                          onClick={handleCancel}
                          size="xs"
                          label="Cancel"
                          outlined={true}
                        />
                      </div>
                      <div>
                        <Button
                          size="xs"
                          label="Save"
                          disabled={checkForErrors()}
                          outlined={false}
                          onClick={handleSubmit((data) => onSubmit(data))}
                        />
                      </div>
                    </>
                  )}
                </div>
              ) : !editFlag && methods?.watch()?.emails?.length ? (
                <Icons onClick={handleEditClick} name="edit" />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">{renderFields()}</div>
    </div>
  )
}

export default BasicInformation
