const defaultColumnProps = {
  sortable: false,
  resizable: true,
  isVisible: true,
}

export const getColumns = ({ onViewAction }: any) => {
  return [
    {
      title: 'Role Name',
      field: 'name',
      ...defaultColumnProps,
      // fixed: true,
      colWidth: window.innerWidth - 350,
      link: true,
      getPath: (row: any) => onViewAction(row, 'role'),
    },
  ]
}
