import { useMutation, useQuery } from '@tanstack/react-query'

import {
  deleteData,
  getData,
  postData,
  updateData,
} from '../../apis/api.helpers'
import apiUrl from '../../apis/api.url'
import { QueryParams } from '../../common/types'
import { useSnackbarManager } from '../../components/common/snackbar'
import { getErrorMessage, parseQueryParams } from '../../utilities/parsers'

interface Params {
  [key: string]: string | number | boolean
}

const buildUrlWithParams = (baseUrl: string, params: any) => {
  return `${baseUrl}${parseQueryParams(params)}`
}

export const createFranchisee = (input: any) => {
  return postData(apiUrl.FRANCHISEE, input)
}

export const useCreateFranchisee = (handleSubmission: (data: any) => void) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createFranchisee, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Franchisee Created successfully', { variant: 'success' })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

const fetchData = async (input: QueryParams) => {
  const url = buildUrlWithParams(apiUrl.FRANCHISEE, {
    ...input,
  })
  const response = await getData(url)
  return response
}

export const useFranchisee = (input: QueryParams) => {
  return useQuery(['employee', input], () => fetchData(input), {
    refetchOnWindowFocus: false,
  })
}

export const getPointOfSupply = (params: Params) =>
  getData(`${apiUrl.POINTOFSUPPLY}${parseQueryParams(params)}`)

export const getFranchiseeDetails = (id: any) => {
  return getData(`${apiUrl.FRANCHISEE}${id}/`)
}

export const editFranchisee = (data: { input: any; id: string }) => {
  return updateData(`${apiUrl.FRANCHISEE}${data?.id}/`, data?.input)
}

export const useEditFranchisee = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()

  return useMutation(editFranchisee, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(getErrorMessage(error.response.data.error), {
        variant: 'error',
      })
    },
  })
}

export const updateFranchiseeStatus = (data: { input: any; id: string }) => {
  return postData(
    `${apiUrl.FRANCHISEE_DETAILS}${data?.id}/change-status`,
    data?.input
  )
}

export const createFranchiseeService = (data: { input: any; id: string }) => {
  return postData(
    `${apiUrl.FRANCHISEE_DETAILS}${data.id}/add-service`,
    data.input
  )
}

export const useCreateFranchiseeService = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createFranchiseeService, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Service Added successfully', { variant: 'success' })
    },
    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

export const createFranchiseeEmployee = (data: { input: any; id: string }) => {
  return postData(
    `${apiUrl.FRANCHISEE_DETAILS}${data.id}/add-employee`,
    data.input
  )
}

export const useCreateFranchiseeEmployee = (
  handleSubmission: (input: any, id: string) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(createFranchiseeEmployee, {
    onSuccess: (res: any) => {
      handleSubmission(res.data, res.id)
      enqueueSnackbar('Employee Added successfully', { variant: 'success' })
    },
    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}

const fetchFranchiseeServices = async (id: string, input: QueryParams) => {
  const url = buildUrlWithParams(
    `${apiUrl.FRANCHISEE_DETAILS}${id}/list-service`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  return response
}

export const useFranchiseeServices = (id: string, input: QueryParams) => {
  return useQuery(
    ['franchise_services', id, input],
    () => fetchFranchiseeServices(id, input),
    {
      // refetchOnWindowFocus: true,
      // staleTime: 0,
    }
  )
}

export const fetchFranchiseeEmployee = async (id: string, input: any) => {
  const url = buildUrlWithParams(
    `${apiUrl.FRANCHISEE_DETAILS}${id}/list-employee`,
    {
      ...input,
    }
  )
  const response = await getData(url)
  return response
}
export const fetchNotificationSettings = async (id: string) => {
  const url = `${apiUrl.FRANCHISEE_DETAILS}${id}/get-notification-settings`

  const response = await getData(url)
  return response
}

export const useFranchiseeEmployee = (id: string, input: QueryParams) => {
  return useQuery(
    ['franchise_employee', id, input],
    () => fetchFranchiseeEmployee(id, input),
    {
      // refetchOnWindowFocus: true,
      // staleTime: 0,
    }
  )
}

export const deleteFranchiseeServices = (id: any) => {
  return deleteData(
    `${apiUrl.FRANCHISEE_DETAILS}${id}/delete-franchisee-service`
  )
}

export const deleteFranchiseeEmployee = (id: any) => {
  return deleteData(
    `${apiUrl.FRANCHISEE_DETAILS}${id}/delete-franchisee-employee`
  )
}

export const changeFranchiseeEmployeeStatus = (data: {
  input: any
  id: string
}) => {
  return postData(
    `${apiUrl.EMPLOYEE_LIST_URL}${data?.id}/change-status`,
    data?.input
  )
}
export const getRoles = (input: any) => {
  return getData(`${apiUrl.ROLE_LIST_URL}${parseQueryParams(input)}`)
}

export const getPaymentGateway = (id: string) => {
  return getData(`${apiUrl.FRANCHISEE}${id}/get_payment_gateway_settings/`)
}

export const getPaymentList = (input: any) => {
  return getData(
    `${apiUrl.PAYMENT_LIST}payment_gateways${parseQueryParams(input)}`
  )
}

export const updatePaymentGateway = (data: { input: any; id: string }) => {
  return updateData(
    `${apiUrl.FRANCHISEE}${data?.id}/payment_gateway_settings/`,
    data?.input
  )
}

export const useUpdatePaymentGateway = (
  handleSubmission: (data: any) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(updatePaymentGateway, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Payment Gateway updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
export const updateNotificationSettings = (data: {
  input: any
  id: string
}) => {
  return postData(
    `${apiUrl.FRANCHISEE_DETAILS}${data?.id}/notification-settings`,
    data?.input
  )
}
export const useUpdateNotificationSettings = (
  handleSubmission: (data: any) => void
) => {
  const { enqueueSnackbar } = useSnackbarManager()
  return useMutation(updateNotificationSettings, {
    onSuccess: (res: any) => {
      handleSubmission(res.data)
      enqueueSnackbar('Admin emails updated successfully', {
        variant: 'success',
      })
    },

    onError: (error: any) => {
      enqueueSnackbar(
        getErrorMessage(
          error.response.data?.errors?.[1]?.message ?? error.response.data.error
        ),
        {
          variant: 'error',
        }
      )
    },
  })
}
