import { AutoComplete } from 'qbs-core'
import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'

import InfoBox from '../../../../components/app/alertBox/infoBox'
import { DialogModal } from '../../../../components/common'
import Button from '../../../../components/common/buttons/Button'
import Icons from '../../../../components/common/icons/index'
import { useSnackbarManager } from '../../../../components/common/snackbar'
import {
  allowActionsByStatusExcept,
  blockActionsbyStatus,
} from '../../../../configs/permissionGate'
import { router_config } from '../../../../configs/route.config'
import { MODULES } from '../../../../configs/scopes.config'
import { downloadFromServer } from '../../../../utilities/commonUtilities'
import { getErrorMessage } from '../../../../utilities/parsers'
import {
  TABlE_COLUMNS,
  useTablePreference,
} from '../../../../utilities/tableprefrences'
import AddService from '../../addServices'
import {
  generateQuoteDocument,
  getQuoteStatus,
  quoteCancel,
  quoteStatusChange,
  useGetQuoteDocumentList,
} from '../../api'
import { checkDetailPermission } from '../../permission'
import ShareInvoice from '../../shareInvoice'
import { getColumns } from './documentColumns'

const QuoteDocumnets = () => {
  const { setActiveTab, handleParentCallBack, detailsData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallBack?: () => void
    detailsData: any
  }>()
  const [isAccountOpen, setIsAccountOpen] = useState(false)
  const [activeRow, setActiveRow] = useState<any>(null)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [generateOpen, setGenerateOpen] = useState(false)
  const [changeStatusOpen, setChangeStatusOpen] = useState(false)
  const [status, setStatus] = useState('')
  const [statusData, setStatusData] = useState<any>()
  const [detailData, setDetailData] = useState<any>()
  const [shareInvoice, setShareInvoice] = useState(false)
  const [rowData, setRowData] = useState<any>()
  const { enqueueSnackbar } = useSnackbarManager()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const params = useParams()

  const handleAction = () => {
    setGenerateOpen(true)
  }

  useEffect(
    () => {
      setActiveTab('QUOTE_DETAILS_DOCUMENT')
      getStatusData()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    setDetailData(detailsData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsData])

  const getStatusData = () => {
    getQuoteStatus()
      .then((res) => {
        setStatusData(res.data)
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.errors[0]?.message[0] ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const handleCancel = () => {
    quoteCancel(activeRow.id)
      .then(() => {
        enqueueSnackbar('Quote Document cancelled successfully', {
          variant: 'success',
        })

        handleDeleteClose()
        refetch()
        setActiveRow(null)
        handleParentCallBack?.()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const generateDocument = () => {
    setIsLoading(true)
    generateQuoteDocument(params.id ?? '')
      .then(() => {
        enqueueSnackbar('Quote Document created successfully', {
          variant: 'success',
        })
        setGenerateOpen(false)
        refetch()
        handleParentCallBack?.()
        setIsLoading(true)
      })
      .catch((error: any) => {
        enqueueSnackbar(getErrorMessage(error?.response?.data?.error.message), {
          variant: 'error',
        })
        setIsLoading(true)
      })
  }

  const handleStatusChange = () => {
    quoteStatusChange(activeRow.id ?? '', { document_status_id: status })
      .then(() => {
        enqueueSnackbar('Quote Document status changed successfully', {
          variant: 'success',
        })
        setChangeStatusOpen(false)
        setActiveRow(null)
        handleParentCallBack?.()
        refetch()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const { data, isFetching, refetch } = useGetQuoteDocumentList(
    params?.id ?? ''
  )

  const onViewAction = (r: any, from: string) => {
    if (from == 'serviceName') {
      navigate(
        `${router_config.SERVICE_AND_ORDERS_BY_SERVICE.path}/${r.service?.id}/summary`
      )
    } else if (from == 'view') {
      if (r?.id) {
        window.open(
          `${router_config.QUOTES.path}/${params?.id}/documents/${r?.id}`,
          '_blank'
        )
      }
    }
  }

  const handleStatusClose = () => {
    setChangeStatusOpen(false)
    setActiveRow(null)
  }
  const handleStatusOpen = (row: any) => {
    setActiveRow(row)
    setChangeStatusOpen(true)
  }

  const handleDeleteClose = () => {
    setDeleteOpen(false)
    setActiveRow(null)
  }
  const handleDeleteOpen = (row: any) => {
    setActiveRow(row)
    setDeleteOpen(true)
  }
  const handleCallback = () => {
    refetch()
  }
  const { getSavedColumns } = useTablePreference()

  const [columns, setColumns] = useState<any>([])
  useEffect(() => {
    setColumns(
      getSavedColumns(
        getColumns({
          onViewAction: onViewAction,
        }),
        TABlE_COLUMNS.QUOTE
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleView = (row: any) => {
    if (row?.document) {
      downloadFromServer(row.document, row.display_file_name)
    }
  }
  const handleShare = (row: any) => {
    setRowData(row)
    setShareInvoice(true)
  }
  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data?.results ?? []}
          dataRowKey="id"
          toolbar={true}
          search={true}
          isLoading={isFetching}
          // columns={getColumns() as any} Changed for quote document id click action
          columns={columns}
          tableHeaderActions={
            <div className="flex gap-2">
              <Button
                onClick={handleAction}
                label={'Generate Quote Document'}
                className="se"
                hidden={!checkDetailPermission('add', MODULES.quotedocument)}
                outlined
                disabled={
                  detailData?.has_active_quote_document ||
                  blockActionsbyStatus(detailData?.order_status?.code) ||
                  !detailData?.is_quote ||
                  !detailData?.order_items ||
                  detailData?.order_items?.length < 1
                }
              />
            </div>
          }
          actionProps={[
            ...(allowActionsByStatusExcept(detailData?.order_status?.code) &&
            detailData?.is_quote
              ? [
                  {
                    icon: <Icons name="invoice" />,
                    action: (row: any) => onViewAction(row, 'view'),
                    title: 'View Quote',
                    hidden: !checkDetailPermission('view', 'quotation'),
                    toolTip: 'View Quote',
                    hide: (row: any) =>
                      blockActionsbyStatus(row?.status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                      ]) ||
                      blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                      ]),
                  },
                  {
                    icon: <Icons name="close" />,
                    action: (row: any) => handleDeleteOpen(row),
                    hidden: !checkDetailPermission(
                      'delete',
                      MODULES.quotedocument
                    ),
                    hide: (row: any) =>
                      blockActionsbyStatus(row?.status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                        'expired',
                        // 'approved',
                      ]) ||
                      blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                      ]),
                    title: 'Cancel',
                    toolTip: 'Cancel',

                    // hide: (row:any) => (row?.status?.code == 'cancelled' ? true : false),
                  },
                  {
                    icon: <Icons name="edit" />,
                    hide: (row: any) =>
                      blockActionsbyStatus(row?.status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                        'expired',
                      ]) ||
                      blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                      ]),
                    action: (row: any) => handleStatusOpen(row),
                    title: 'Change Status',
                    hidden: !checkDetailPermission(
                      'change',
                      MODULES.quotedocument
                    ),
                    toolTip: 'Edit',
                  },
                  {
                    icon: <Icons name="share" />,
                    action: (row: any) => handleShare(row),
                    title: 'Share',
                    toolTip: 'share',
                    hide: (row: any) =>
                      blockActionsbyStatus(row?.status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                        'expired',
                      ]) ||
                      blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                      ]),
                    hidden:
                      !checkDetailPermission('share', 'quotation') ||
                      blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                      ]),
                  },

                  {
                    icon: <Icons name="download" />,
                    action: (row: any) => handleView(row),
                    title: 'Download',
                    toolTip: 'Download',
                    hide: (row: any) =>
                      blockActionsbyStatus(row?.status?.code, [
                        'cancelled',
                        'expired',
                        'order_cancelled',
                        'rejected',
                      ]) ||
                      blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                      ]),
                    hidden:
                      !checkDetailPermission('download', 'quotation') ||
                      blockActionsbyStatus(detailData?.order_status?.code, [
                        'cancelled',
                        'order_cancelled',
                        'rejected',
                      ]),
                  },
                ]
              : []),
          ]}
          columnToggle
        />
      </div>

      <AddService
        isDrawerOpen={isAccountOpen}
        subSection={true}
        handleClose={() => setIsAccountOpen(false)}
        handleCallback={handleCallback}
        id={params.id}
      />

      <DialogModal
        isOpen={deleteOpen}
        onClose={() => handleDeleteClose()}
        title={'Are you sure?'}
        onSubmit={() => handleCancel()}
        secondaryAction={() => handleDeleteClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              'Are you sure you want to cancel this Quote Document? This process is irreversible'
            }
          />
        }
      />

      <DialogModal
        isOpen={generateOpen}
        onClose={() => setGenerateOpen(false)}
        title={'Are you sure?'}
        onSubmit={() => generateDocument()}
        secondaryAction={() => setGenerateOpen(false)}
        actionLoader={isLoading}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, I am"
        body={
          <InfoBox
            content={
              ' At a time only 1 Quote Document can in Active State (Created, Shared, Approved). To create a new quote document the existing quote document status should be either in Rejected or Cancelled state.'
            }
          />
        }
      />
      <ShareInvoice
        isDrawerOpen={shareInvoice}
        handleClose={() => setShareInvoice(false)}
        id={rowData?.id}
      />
      <DialogModal
        isOpen={changeStatusOpen}
        onClose={() => handleStatusClose()}
        title={'Are you sure?'}
        onSubmit={() => handleStatusChange()}
        secondaryAction={() => handleStatusClose()}
        secondaryActionLabel="No, Cancel"
        actionLabel="Yes, Continue"
        body={
          <InfoBox
            content={
              <div>
                <div className="mb-5">
                  Title You can change the status of the quotation document
                  based on the clients response from here. Only 1 Quote document
                  will be active at a time, so incase a second quote document
                  needs to be created other should be either marked as rejected
                  or cancelled.
                </div>
                <AutoComplete
                  placeholder={'Enter Status'}
                  desc={'name'}
                  type={'custom_select'}
                  descId={'id' as string}
                  data={statusData}
                  name={'name'}
                  onChange={(value: any) => setStatus(value.id)}
                />
              </div>
            }
          />
        }
      />
    </>
  )
}

export default QuoteDocumnets
