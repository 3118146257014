import { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import EventComponent from '../../../../components/app/eventlogs/index'
import { checkDetailPermission } from '../../permission'

export default function QuoteEventLogs() {
  const { setActiveTab, disabled } = useOutletContext<{
    setActiveTab: (id: string) => void
    disabled: boolean
  }>()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  useEffect(() => {
    setActiveTab('QUOTE_DETAILS_EVENT_LOGS')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    setIsDisabled(disabled)
  }, [disabled])
  return (
    <EventComponent
      model_name="Quote"
      disabled={isDisabled}
      checkModulePermission={checkDetailPermission}
    />
  )
}
