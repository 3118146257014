import React from 'react'
import { IconProps } from '../../../common/types'

const CheckMarkIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4434 18C14.8616 18 18.4434 14.4183 18.4434 10C18.4434 5.58172 14.8616 2 10.4434 2C6.02508 2 2.44336 5.58172 2.44336 10C2.44336 14.4183 6.02508 18 10.4434 18ZM14.1505 8.70711C14.541 8.31658 14.541 7.68342 14.1505 7.29289C13.7599 6.90237 13.1268 6.90237 12.7363 7.29289L9.44336 10.5858L8.15047 9.29289C7.75994 8.90237 7.12678 8.90237 6.73625 9.29289C6.34573 9.68342 6.34573 10.3166 6.73625 10.7071L8.73625 12.7071C9.12678 13.0976 9.75994 13.0976 10.1505 12.7071L14.1505 8.70711Z"
        fill="#299957"
      />
    </svg>
  )
}

export default CheckMarkIcon
