import { getNestedProperty } from '../../../utilities/parsers'
import Icons from '../../common/icons/index'

const defaultColumnProps1 = {
  sortable: false,
  resizable: true,
  isVisible: true,
}
export const getColumns = () => {
  const createRenderCell =
    (key: string | string[], type?: string) => (row: any) => {
      switch (type) {
        case 'join':
          return {
            cell: `${getNestedProperty(row, key[0]) ?? ''} ${
              getNestedProperty(row, key[1]) ?? ''
            }`,
          }
        case 'custom':
          return {
            cell: (
              <div className="flex gap-1 items-center">
                {getNestedProperty(row, key[0]) && (
                  <Icons
                    name="phone"
                    toolTip={getNestedProperty(row, key[0])}
                  />
                )}
                {getNestedProperty(row, key[1]) && (
                  <Icons
                    name="email"
                    toolTip={getNestedProperty(row, key[1])}
                  />
                )}
              </div>
            ),
          }
        case 'loop':
          return {
            cell: (
              <>
                {row?.assignees?.slice(0, 1).map((item: any, index: number) => (
                  <span key={item.id}>
                    {item.name}
                    {row?.assignees.length - 1 !== index && ', '}
                  </span>
                ))}
                {row?.assignees?.length > 1 && (
                  <span className=" p-1 bg-bgBadgeColor rounded-xl px-2 text-white ml-1">
                    +{row.assignees.length - 1}
                  </span>
                )}
              </>
            ),
          }

        default:
          return {
            cell: getNestedProperty(row, key as string),
            toolTip: getNestedProperty(row, key as string) ?? '',
          }
      }
    }

  const column = [
    {
      title: 'Event Date',
      field: 'event_date',
      type: 'date',
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Event Type',
      field: '',
      renderCell: createRenderCell('event_type.name'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Event time',
      field: 'event_time',
      // customCell: true,
      ...defaultColumnProps1,
    },

    {
      title: 'Event Notes/Remarks',
      field: 'remarks',

      ...defaultColumnProps1,
    },
    {
      title: 'Event Participant(s)',
      field: 'datetime_created',
      renderCell: createRenderCell('assignees', 'loop'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Attachment',
      field: '',
      renderCell: createRenderCell('attachment_name'),
      customCell: true,
      getPath: (row: any) => row?.attachment || '#',
      link: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Created By',
      field: '',
      renderCell: createRenderCell('created_by.name'),
      customCell: true,
      ...defaultColumnProps1,
    },
    {
      title: 'Created On',
      field: 'datetime_created',
      type: 'date',
      customCell: true,
      ...defaultColumnProps1,
    },
  ]
  return column
}
