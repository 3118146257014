import React, { useEffect, useState } from 'react'

import { Button } from '../../../../../../components/common'
import Icons from '../../../../../../components/common/icons'
import { useSnackbarManager } from '../../../../../../components/common/snackbar'
import { handleError } from '../../../../../../utilities/commonUtilities'
import { checkPermission } from '../../../../permission'
import TableTreeRow from './TableTreeNode'

const RoleDetailPermissions: React.FC<any> = ({
  permissions,
  handleRefresh,
  handleUpdate,
}) => {
  const { enqueueSnackbar } = useSnackbarManager()
  const [perm, setPerm] = useState<any>(permissions)
  const [submiting, setSubmiting] = useState(false)

  const [editable, setEditable] = useState(false)

  const hasPermission = () => {
    return checkPermission('change')
  }
  const handleSubmit = () => {
    setSubmiting(true)
    const permionData: any = []
    perm.forEach((item: any) => {
      if (item.checked) {
        permionData.push(item.id)
      }
    })
    handleUpdate({ permissions: permionData })
      .then(() => {
        setSubmiting(false)
        setEditable(false)
        handleRefresh?.()
        enqueueSnackbar('Updated successfully', {
          variant: 'success',
        })
      })
      .catch((err: any) => {
        handleError(err)
        setSubmiting(false)
      })
  }
  const checkParent = (node: any, checked: boolean, data: any) => {
    const updatedData = data || []

    updatedData.forEach((item: any) => {
      if (checked) {
        if (item.id === node.parent) {
          item['checked'] = true
          checkParent(item, checked, updatedData)
        }
      }
    })
    const finalData = setIntermediate(updatedData) || []
    return finalData
  }
  const checkForIntermediate = (node: any, data: any) => {
    const childs = data.filter((itm: any) => itm.parent === node.id)
    const checkedChilds = childs.filter((itm: any) => itm.checked)
    const interMediateChilds = childs.filter((itm: any) => itm.intermediate)
    return (
      childs.length !== checkedChilds.length || interMediateChilds.length > 0
    )
  }
  const setIntermediate = (data: any) => {
    const final = data || []
    final.forEach((ele: any) => {
      ele['intermediate'] = checkForIntermediate(ele, final)
    })
    return final
  }
  const getCheckedData = (node: any, checked: boolean, data: any) => {
    const updatedData = data
    updatedData.forEach((item: any) => {
      if (item.id === node.id) {
        item['checked'] = checked
        if (checked) {
          item['intermediate'] = false
        }
      }
      if (item.parent === node.id) {
        getCheckedData(item, checked, updatedData)
      }
    })
    const finalData = checkParent(node, checked, updatedData)
    return finalData
  }
  const handleNodeCheck = (node: any, e: any) => {
    const checked = e.target.checked

    const updatedData = getCheckedData(node, checked, perm)

    setPerm(updatedData.map((item: any) => item))
  }

  const handleEditClick = () => {
    setEditable(true)
  }
  const handleCancel = () => {
    setEditable(false)
    handleRefresh?.()
  }
  const handleTreeExpand = (nodeIndex: any) => {
    let isParent = false
    let parentId = ''
    perm.forEach((item: any, index: number) => {
      if (index === nodeIndex) {
        if (item.parent === parentId || !item.parent) {
          isParent = true
        } else {
          isParent = true
          parentId = item.parent
        }
      }
    })
    const data = perm?.map((item: any, index: number) => {
      if (index === nodeIndex) {
        return { ...item, isOpen: !item.isOpen }
      } else if (item.parent === parentId && isParent) {
        return { ...item, isOpen: false }
      } else {
        return item
      }
    })
    setPerm(data)
  }
  useEffect(() => {
    if (permissions?.length) {
      setPerm(setIntermediate(permissions))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions])

  const renderTree = (item: any, level = 0): React.ReactNode =>
    perm?.map((node: any, index: number) => (
      <React.Fragment key={index}>
        {item.id === node?.parent && (
          <TableTreeRow
            key={`${index}`}
            label={node.title}
            node={node}
            level={level}
            data={perm}
            disabled={!editable}
            nodeId={index}
            handleNodeCheck={handleNodeCheck}
            handleTreeExpand={handleTreeExpand}
          >
            {node.isOpen && renderTree(node, level + 1)}
          </TableTreeRow>
        )}
      </React.Fragment>
    ))

  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg ">
      <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center gap-2">
            <Icons name="location-pin" />
            <h4 className=" font-bold text-primaryText">Permissions</h4>
          </div>
          <div className="iconBlack cursor-pointer">
            {hasPermission() && (
              <div className="iconBlack cursor-pointer">
                {editable ? (
                  <div className="flex gap-2">
                    <div>
                      <Button
                        onClick={handleCancel}
                        size="xs"
                        disabled={submiting}
                        label="Cancel"
                        outlined={true}
                      />
                    </div>
                    <div>
                      <Button
                        size="xs"
                        label="Save"
                        disabled={submiting}
                        isLoading={submiting}
                        outlined={false}
                        onClick={() => handleSubmit()}
                      />
                    </div>
                  </div>
                ) : !editable ? (
                  <Icons onClick={handleEditClick} name="edit" />
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="p-4 rounded-lg bg-cardWrapperBg ">
            <div className="customTreeView">
              {perm?.map((node: any, index: number) => (
                <React.Fragment key={index}>
                  {node?.parent === '' && (
                    <TableTreeRow
                      key={`${index}`}
                      label={node.title}
                      node={node}
                      level={0}
                      disabled={!editable}
                      nodeId={index}
                      handleNodeCheck={handleNodeCheck}
                      handleTreeExpand={handleTreeExpand}
                      data={perm}
                    >
                      {node.isOpen && renderTree(node, 1)}
                    </TableTreeRow>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoleDetailPermissions
