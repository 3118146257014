import React from 'react'
import { IconProps } from '../../../common/types'

const AssignTeam: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99984 8.08219C6.15889 8.08219 4.6665 6.58981 4.6665 4.74886C4.6665 2.90791 6.15889 1.41553 7.99984 1.41553C9.84079 1.41553 11.3332 2.90791 11.3332 4.74886C11.3332 6.58981 9.84079 8.08219 7.99984 8.08219Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.27344 14.7487C2.27344 12.1687 4.8401 10.082 8.0001 10.082C8.6401 10.082 9.26011 10.1687 9.84011 10.3287"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5868 12.7022C14.5268 12.9689 14.4202 13.2289 14.2802 13.4555C13.8202 14.2289 12.9735 14.7489 12.0002 14.7489C11.3135 14.7489 10.6935 14.4888 10.2268 14.0622C10.0268 13.8888 9.85349 13.6822 9.72015 13.4555C9.47349 13.0555 9.3335 12.5822 9.3335 12.0822C9.3335 11.3622 9.62017 10.7022 10.0868 10.2222C10.5735 9.72221 11.2535 9.41553 12.0002 9.41553C12.7868 9.41553 13.5002 9.75554 13.9802 10.3022C14.4068 10.7755 14.6668 11.4022 14.6668 12.0822C14.6668 12.2955 14.6402 12.5022 14.5868 12.7022Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9935 12.0684H11.0068"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 11.0952V13.0885"
        stroke="#292D32"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AssignTeam
