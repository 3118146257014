import React from 'react'
import { IconProps } from '../../../common/types'

const PlusLargeIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00014 1.44444V9M9.00014 9V16.5556M9.00014 9H16.5557M9.00014 9L1.44458 9"
        stroke="#313131"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlusLargeIcon
