import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import InfoBox from '../../components/app/alertBox/infoBox'
import { DialogModal } from '../../components/common'
import ListingHeader from '../../components/common/ListingTiles/index'
import Icons from '../../components/common/icons'
import { useSnackbarManager } from '../../components/common/snackbar'
import { router_config } from '../../configs/route.config'
import { useVendorFilterStore } from '../../store/filterSore/vendorStore'
import { calcWindowHeight } from '../../utilities/calcHeight'
import { statusClassGen } from '../../utilities/generators'
import { getErrorMessage, getSortedColumnName } from '../../utilities/parsers'
import {
  TABlE_COLUMNS,
  useTablePreference,
} from '../../utilities/tableprefrences/index'
import { updateVendorStatus, useVendor } from './api'
import CreateVendor from './create'
import { checkPermission } from './permission'
import { getColumns } from './vendorColumn'

const VendorsList = () => {
  const { enqueueSnackbar } = useSnackbarManager()
  const { pageParams, setPageParams } = useVendorFilterStore()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [activeRow, setActiveRow] = useState<any>(null)
  const [changeStatus, setChangeStatus] = useState<any>(null)
  const [changeStatusOpen, setStatusOpen] = useState(false)
  const { page, page_size, search, ordering } = pageParams
  const navigate = useNavigate()

  const searchParams = {
    listing: true,
    page: page,
    page_size: page_size,
    search: search,
    ordering: ordering,
  }
  const { data, refetch, isLoading, isFetching } = useVendor({
    ...searchParams,
  })
  const { getSavedColumns, handleSaveColumns } = useTablePreference()

  // useEffect(() => {
  //   refetch()
  // }, [page, page_size, search, ordering, refetch])

  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }

  const onViewAction = (r: any, type: string) => {
    if (type === 'franchiseeName')
      return `${router_config.SYSTEM_SETTINGS_VENDORS.path}/${r.id}/basic-info`
  }

  const handleSeach = (key?: string) => {
    setPageParams({
      ...pageParams,
      search: key as string,
      page: 1,
    })
  }

  const basicData = {
    title: 'Vendors',
    icon: 'user',
  }

  const handleSort = (orderColumn: any, orderDirection: any) => {
    setPageParams({
      ...pageParams,
      sortColumn: orderColumn,
      sortType: orderDirection,
      ordering: getSortedColumnName(orderColumn, orderDirection),
    })
  }

  // const handleOpenDrawer = () => {
  //   setOpenDrawer(true)
  // }

  const handleColumnToggle = (columns: any) => {
    handleSaveColumns(columns, TABlE_COLUMNS.FRANCHISEE_LIST)
  }

  const [columns, setColumns] = useState<any>()

  useEffect(() => {
    setColumns(
      getSavedColumns(
        getColumns({
          onViewAction: onViewAction,
          statusClassGen: statusClassGen,
        }),
        TABlE_COLUMNS.FRANCHISEE_LIST
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResetColums = () => {
    setColumns([
      ...getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
    ])
    handleSaveColumns(
      getColumns({
        onViewAction: onViewAction,
        statusClassGen: statusClassGen,
      }),
      TABlE_COLUMNS.FRANCHISEE_LIST
    )
  }

  const handleStatusChangeOpen = (row: any, status: boolean) => {
    setActiveRow(row)
    setStatusOpen(true)
    setChangeStatus(status)
  }

  const handleStatusChangeClose = () => {
    setStatusOpen(false)
    setActiveRow(null)
    setChangeStatus(null)
  }

  const handleStatusChange = () => {
    updateVendorStatus({
      input: { status: changeStatus },
      id: activeRow.id,
    })
      .then(() => {
        enqueueSnackbar('Status changed successfully', {
          variant: 'success',
        })
        handleStatusChangeClose()
        refetch()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.error?.message
          ),
          {
            variant: 'error',
          }
        )
      })
  }

  const handleCallback = () => {
    refetch()
  }
  return (
    <div className="con">
      <ListingHeader
        data={basicData}
        // onActionClick={handleOpenDrawer}
        checkPermission={checkPermission}
        actionProps={{ hideExport: true }}
      />
      {/* <PageTitle data={data?.total} /> */}

      <div className=" p-4">
        <QbsTable
          columns={columns}
          dataRowKey="id"
          isLoading={isLoading || isFetching}
          handleColumnToggle={handleColumnToggle}
          toolbar
          search
          height={calcWindowHeight(303)}
          data={data?.data?.results ?? []}
          sortType={pageParams.sortType}
          tableBodyHeight="calc(100vh - 500px)"
          sortColumn={pageParams.sortColumn}
          handleColumnSort={handleSort}
          handleResetColumns={() => handleResetColums()}
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams.page_size ?? data?.data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
              ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
              : [10, 20, 30, 50],
          }}
          actionProps={[
            {
              title: 'View',
              action: (rowData) =>
                navigate(`${onViewAction(rowData, 'franchiseeName')}`),
              icon: <Icons name="eye" />,
              hidden: !checkPermission('view'),
              toolTip: 'View',
            },
            {
              title: 'Activate',
              action: (rowData) => handleStatusChangeOpen(rowData, true),
              icon: <Icons name="activate-icon" />,
              toolTip: 'Activate',
              hide: (rowData) => rowData?.status || true,
            },
            {
              title: 'Deactivate',
              action: (rowData) => handleStatusChangeOpen(rowData, false),
              icon: <Icons name="deactivate-icon" />,
              toolTip: 'Deactivate',
              hide: (rowData) => !rowData?.status || true,
            },
          ]}
          searchValue={pageParams?.search}
          onSearch={handleSeach}
          asyncSearch
          handleSearchValue={(key?: string) => handleSeach(key)}
          columnToggle
        />
        <CreateVendor
          isDrawerOpen={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          handleCallback={handleCallback}
        />
        <DialogModal
          isOpen={changeStatusOpen}
          onClose={() => handleStatusChangeClose()}
          title={'Are you sure?'}
          onSubmit={() => handleStatusChange()}
          secondaryAction={() => handleStatusChangeClose()}
          secondaryActionLabel="No, Cancel"
          actionLabel="Yes, I am"
          body={
            <InfoBox
              content={`Are you sure you want to ${
                changeStatus ? 'Activate' : 'Deactivate'
              }?`}
            />
          }
        />
      </div>
    </div>
  )
}

export default VendorsList
