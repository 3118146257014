import { z } from 'zod'

import noLeadingSpaces from '../../../utilities/noLeadingSpaces'

export const leadSchema = z.object({
  name: z
    .string()
    .min(1, { message: 'Name is required.' })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    }),
  business_name: z.string().optional().nullable(),
  probability: z
    .string()
    .refine(
      (val) =>
        val === '' ||
        (!Number.isNaN(parseFloat(val)) &&
          parseFloat(val) >= 0 &&
          parseFloat(val) <= 100),
      {
        message: 'Enter a valid probability %',
      }
    )
    .optional()
    .nullable(),
  phone: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (
          !/^(?:(?:\+|0{0,2})91(\s*[\ -]\s*)?|[0]?)?[789]\d{9}|(\d[ -]?){9}\d$/.test(
            value
          )
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid Mobile Number.',
          })
        }
      }
    })
    .refine(noLeadingSpaces, {
      message: 'Leading spaces are not allowed',
    })
    .optional()
    .nullable(),
  preferred_language: z.string().optional(),
  language_name: z.string().optional(),
  language_id: z.string().optional(),
  preferred_language_id: z.string().optional(),

  sector_id: z.string().optional(),
  sector_name: z.string().optional(),

  industry_id: z.string().optional(),
  industry_name: z.string().optional(),

  service: z
    .object({ id: z.string().optional(), name: z.string().optional() })
    .array()
    .optional()
    .nullable(),

  lead_source_id: z.string().optional().nullable(),
  lead_source_name: z.string().optional().nullable(),

  email: z
    .string()
    .superRefine((value, ctx) => {
      if (value !== '') {
        if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Invalid email format.',
          })
        }
      }
    })
    // .email('Invalid email format.')
    .optional()
    .nullable(),
  city: z.string().optional().nullable(),

  state: z.string().optional().nullable(),
  stateName: z.string().optional().nullable(),
  next_followup_date: z
    .union([
      z.date({
        required_error: 'Next followup date is required',
        invalid_type_error: 'Next followup date is required',
      }),
      z.string({
        required_error: 'Next followup date is required',
        invalid_type_error: 'Next followup date is required',
      }),
    ])
    .refine((date) => date !== undefined && date !== null && date !== '', {
      message: 'Next followup date is required.',
    }),
  target_date: z
    .union([
      z.date({
        required_error: 'Target date is required',
        invalid_type_error: 'Target date is required',
      }),
      z.string({
        required_error: 'Target date is required',
        invalid_type_error: 'Target date is required',
      }),
    ])
    .refine((date) => date !== undefined && date !== null && date !== '', {
      message: 'Target date is required.',
    }),
  ad_id: z.string().optional().nullable(),
  ad_set_id: z.string().optional().nullable(),
  campaign: z.string().optional().nullable(),
})

export type LeadSchema = z.infer<typeof leadSchema>
