import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { StoreFilterParams, AdvanceFilter } from '../../common/types'
import { defaultPageParams } from '../constants'
import { isAllFranchisee } from '../../configs/permissionGate'

const defaultParams = [
  { title: 'Service Names', slug: 'service_name', isChecked: false },
  { title: 'Target Date', slug: 'target_date', isChecked: false },
  { title: 'Next Followup Date', slug: 'followup_date', isChecked: false },
  { title: 'Created Date', slug: 'created_date', isChecked: false },
  { title: 'Created By', slug: 'created_by', isChecked: false },
  { title: 'Team', slug: 'team', isChecked: false },
  ...(isAllFranchisee()
    ? [{ title: 'Franchisee', slug: 'franchisee_id', isChecked: false }]
    : []),
]
export const useProspectFilterStore = create<ProspectFilterstoreType>()(
  persist(
    (set) => ({
      pageParams: defaultPageParams,
      advanceFilter: {
        filterParams: defaultParams,
        isDetailed: true,
      },
      selectedRows: [],

      setAdvanceFilter: (data) => set(() => ({ advanceFilter: data })),
      setPageParams: (data) => set(() => ({ pageParams: data })),
      clearAdvanceFilter: () =>
        set(() => ({
          advanceFilter: {
            filterParams: defaultParams?.map((item: any) => ({
              ...item,
              isChecked: false,
            })),
            isDetailed: true,
          },
        })),
      setSelectedRows: (data) => set(() => ({ selectedRows: data })),
      clearSelectedRows: () => set({ selectedRows: [] }),
      clearProspectFilter: (key) =>
        set((state) => ({
          pageParams: key
            ? { ...defaultPageParams, search: state?.pageParams?.search }
            : defaultPageParams,
        })),
    }),
    { name: 'fit__prospect_store' }
  )
)

type ProspectFilterstoreType = {
  advanceFilter: AdvanceFilter
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]

  setAdvanceFilter: (data: AdvanceFilter) => void
  setSelectedRows: (data: (number | string)[]) => void
  setPageParams: (data: StoreFilterParams) => void
  clearProspectFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
