import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'

import { getCountryState } from '../../../apis/common.apis'
import FormBuilder from '../../../components/app/formBuilder/index'
import CustomDrawer from '../../../components/common/drawer'
import { addSupplier, updateSuppliers } from '../api'
import { formSchema, FranchiseeSchema } from './schema'
import { getErrorMessage } from '../../../utilities/parsers'
import { useSnackbarManager } from '../../../components/common/snackbar'
import { fetchSupplierCategoryData } from '../../supplierCategories/api'
import { useEffect, useState } from 'react'
import FormFieldView from '../../../components/common/inputs/FormFieldView'

type Props = {
  isDrawerOpen: boolean
  handleClose: () => void
  handleRefresh?: () => void
  subSection?: boolean
  data?: any
  edit?: any
  view_mode: boolean
  parentParams?: { id?: string; name?: string }
  handleCallback?: () => void
}

export default function CreateFranchisee({
  isDrawerOpen,
  handleClose,
  data,
  edit,
  view_mode,
  handleCallback,
}: Props) {
  const [categoryPage, setCategoryPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const getPlaceOfSupply = async (value: any) => {
    const resp = await fetchSupplierCategoryData({
      search: value ?? '',
      page: 1,
    })

    setCategoryPage(resp?.next ? categoryPage + 1 : 0)
    return resp?.results?.map((item: any) => ({
      ...item,
    }))
  }

  const getState = async () => {
    const { data } = await getCountryState()
    return data?.state?.map((item: any) => ({
      ...item,
      state_name: item.name,
    }))
  }

  const getCountry = async () => {
    const { data } = await getCountryState()
    return data?.countries?.map((item: any) => ({
      ...item,
      country_name: item.name,
    }))
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false,
    isDuplicateCheck = false,
    value = ''
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
    ...(isDuplicateCheck ? { isDuplicateCheck: true } : {}),
    value,
  })

  const formBuilderProps = [
    {
      ...textField('name', 'Name', 'Enter name', true, false, data?.name),
    },
    {
      name: 'category_name',
      label: 'Category',
      required: true,
      getData: getPlaceOfSupply,
      id: 'category',
      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      async: true,
      paginationEnabled: true,
      nextBlock: categoryPage,
      placeholder: 'Enter Category ',
      value: data?.category_name,
    },
    {
      ...textField(
        'contact_email',
        'Email',
        'Enter email',
        false,
        false,
        data?.contact_email
      ),
      type: 'email',
    },
    textField(
      'contact_phone',
      'Phone',
      'Enter Phone',
      false,
      false,
      data?.contact_phone
    ),
    textField(
      'address_1',
      'Address Line 1',
      'Enter Address Line 1',
      false,
      false,
      data?.address?.address_1
    ),
    textField(
      'address_2',
      'Address Line 2',
      'Enter Address Line 2',
      false,
      false,
      data?.address?.address_2
    ),
    textField('city', 'City', 'Enter City', false, false, data?.address?.city),
    {
      name: 'state_name',
      label: 'State',
      required: false,
      getData: getState,
      async: false,
      id: 'state',
      descId: 'state_name',
      initialLoad: true,
      desc: 'state_name',
      type: 'auto_complete',
      placeholder: 'Enter state',
      value: data?.address?.state,
    },
    textField(
      'pin_code',
      'Pincode',
      'Enter Pincode',
      false,
      false,
      data?.address?.pin_code
    ),
    {
      name: 'country_name',
      label: 'Country',
      required: false,
      getData: getCountry,
      async: false,
      id: 'country',
      descId: 'country_name',
      initialLoad: true,
      desc: 'country_name',
      type: 'auto_complete',
      placeholder: 'Enter country',
      value: data?.address?.country,
    },
    textField('gst_no', 'GST No', 'Enter GST No', false, false, data?.gst_no),
  ]

  const methods = useForm<FranchiseeSchema>({
    resolver: zodResolver(formSchema),
    mode: 'onChange',

    reValidateMode: 'onChange',
  })

  useEffect(() => {
    if (edit && isDrawerOpen) {
      methods.reset({ ...methods.watch(), ...data })
    }
  }, [edit, isDrawerOpen, data])
  const manageApis = (d: any, id?: string) =>
    !id ? addSupplier(d) : updateSuppliers(id as string, d)

  const onSubmit = (d: any) => {
    setIsLoading(true)
    const { id, ...rest } = d
    manageApis(
      {
        ...rest,
      },
      data?.id || id
    )
      .then(() => {
        enqueueSnackbar(
          data?.id
            ? 'Supplier updated successfully '
            : 'Supplier created successfully',
          {
            variant: 'success',
          }
        )

        handleClearAndClose()

        handleCallback?.()
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const { handleSubmit } = methods

  const handleClearAndClose = () => {
    methods.reset({
      name: '',
      address_1: '',
      address_2: '',
      category: '',
      category_name: '',
      city: '',
      contact_email: '',
      contact_phone: '',
      country: '',
      country_name: '',
      gst_no: '',
      pin_code: '',
      state: '',
      state_name: '',
    })
    handleClose()
  }
  return (
    <>
      <CustomDrawer
        className="formDrawer"
        open={isDrawerOpen}
        handleClose={() => handleClearAndClose()}
        handleSubmit={handleSubmit((data) => onSubmit(data))}
        title={
          edit ? 'Edit Supplier' : view_mode ? 'View Supplier' : 'Add Supplier'
        }
        actionLoader={isLoading}
        hideSubmit={view_mode}
      >
        <div className="flex flex-col gap-4">
          {!view_mode ? (
            <FormProvider {...methods}>
              <FormBuilder data={formBuilderProps} edit={true} />
            </FormProvider>
          ) : (
            formBuilderProps.map((data: any) => (
              <FormFieldView
                value={data?.value}
                label={data?.label}
                key={data?.id}
                type={data?.type}
                link={data?.link}
                hidden={data?.hidden}
              />
            ))
          )}
        </div>
      </CustomDrawer>
    </>
  )
}
