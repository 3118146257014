import React from 'react'
import { IconProps } from '../../../common/types'

const MenuListIcon: React.FC<IconProps> = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.35806 5.72076C4.35806 5.72076 3.71484 5.37235 3.71484 4.67554C3.71484 3.97872 4.25086 3.73751 4.33126 3.68391C4.41166 3.63031 8.99458 1.27185 9.12858 1.21825C9.26258 1.16465 9.66459 1.00385 10.013 1.00385C10.3614 1.00385 10.6294 1.08425 10.817 1.16465C11.0046 1.24505 15.5071 3.57671 15.6411 3.65711C15.7751 3.73751 16.3112 3.97872 16.3112 4.72914C16.3112 5.47956 15.7751 5.66716 15.5875 5.77436C15.3999 5.88157 11.4066 7.97202 11.2458 8.05242C11.085 8.13282 10.0398 8.69564 9.04818 8.18643C8.05655 7.67721 4.35806 5.72076 4.35806 5.72076Z"
        stroke="#AAACB0"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M1 8.93046C1 8.93046 1 8.27518 1.34841 7.92677C1.69682 7.57836 2.25963 7.41756 2.76884 7.68557C3.27806 7.95357 7.32898 10.1244 7.32898 10.1244C7.32898 10.1244 8.39698 10.6411 8.39698 11.7496C8.39698 12.8581 8.39698 16.4917 8.39698 16.6582C8.39698 16.8246 8.39698 18.379 6.65494 17.9234L1.99162 15.4042C1.99162 15.4042 1 14.9577 1 13.7203C1 12.4829 1 8.93046 1 8.93046Z"
        stroke="#AAACB0"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M11.5762 11.9469C11.5762 11.9469 11.603 10.7141 12.5678 10.2049C13.5326 9.69567 16.9631 7.81962 17.0971 7.73922C17.2311 7.65881 17.7671 7.39081 18.3031 7.68562C18.8392 7.98042 19 8.59175 19 8.87557C19 9.15965 19 13.5727 19 13.7112C19 13.8498 18.8928 14.8682 18.0887 15.3238C17.2847 15.7794 13.6934 17.7359 13.5058 17.8431C13.3182 17.9503 11.7638 18.504 11.5762 16.7129V11.9469Z"
        stroke="#AAACB0"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default MenuListIcon
