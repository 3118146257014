import { z } from 'zod'
import { useAuthStore } from '../../../store/authStore'
const franchisee = useAuthStore.getState().franchisee

export const orderSchema = z.object({
  account_id: z.string().min(1, 'Account is required.'),
  account_name: z.string().optional().nullable(),
  contact_id: z.string().min(1, 'Contact is required.'),
  contact_name: z.string().optional().nullable(),
  primary_cre_id: z.string().min(1, 'Primary Cre is required.'),
  primary_cre: z.string().optional().nullable(),
  deal_source_id: z.string().optional().nullable(),
  deal_source: z.string().optional().nullable(),
  probability: z
    .union([
      z
        .string()
        .transform((val) =>
          val === '' || isNaN(parseFloat(val)) ? null : parseFloat(val)
        )
        .refine((val) => val !== null && val >= 0 && val <= 100, {
          message: 'Enter a valid probability %',
        }),
      z.number().refine((val) => val >= 0 && val <= 100, {
        message: 'Enter a valid probability %',
      }),
      z.null(),
    ])
    .optional()
    .nullable(),
  target_date: z.date().optional().nullable(),
  next_followup_date: z.date().optional().nullable(),
  remarks: z.string().optional().nullable(),
})

export const quoteCreateSchema = z.object({
  account_name: z.string().refine((val) => val !== '', {
    message: 'Account is required',
  }),
  account_id: z
    .string()
    .refine((val) => val !== '', { message: 'Account is required' }),
  contact_name: z.string().refine((val) => val !== '', {
    message: 'Contact is required',
  }),
  contact_id: z
    .string()
    .refine((val) => val !== '', { message: 'Contact is required' }),

  assigned_to: z
    .string()
    .refine((val) => val !== '', { message: 'Quote CRE is required' }),
  assigned_name: z
    .string()
    .refine((val) => val !== '', { message: 'Quote CRE is required' }),
  order_date: z
    .union([z.date(), z.string(), z.null()])
    .refine((val) => val !== null, { message: 'Quote Date is required' }),
  target_date: z.union([z.date(), z.string()]).optional().nullable(),
  internal_campaign_id: z.string().optional().nullable(),
  spot_incentive: z.number().optional().nullable(),
  order_comment: z.string().optional().nullable(),
  lead_source: z.string().optional().nullable(),
  lead_source_id: z.string().optional().nullable(),
})

export const serviceItemSchema = z.object({
  discount_percent: z
    .number({ invalid_type_error: 'Required' })
    .min(0, 'Discount percent must be non-negative')
    .max(100, 'Discount percent cannot exceed 100'),
  govt_fee: z
    .number({ invalid_type_error: 'Required' })
    .min(0, 'Government fee must be a non-negative number'),
  gst_percent: franchisee?.has_gst
    ? z
        .number({ invalid_type_error: 'Required' })
        .min(0.01, 'GST percent must be greater than 0')
        .max(100, 'GST percent cannot exceed 100')
    : z.number().optional(),
  id: z
    .string()
    .refine((val) => val !== '', { message: 'Service Name is required' }),
  service_name: z
    .string()
    .refine((val) => val !== '', { message: 'Service Name is required' }),
  professional_fee: z
    .number({ invalid_type_error: 'Required' })
    .min(0, 'Professional fee must be a non-negative number'),
  vendor_fee: z
    .number()
    .nonnegative({ message: 'Vendor fee must be a non-negative number.' })
    .optional()
    .or(z.null()),
  rounding: z.string().optional().nullable(),
  service_total: z.string().optional().nullable(),
})
export type OrderSchema = z.infer<typeof orderSchema>
export const itemSchema = serviceItemSchema.omit({
  service_name: true,
})
const arrayOfItemsSchema = z.array(itemSchema)
export const orderItemSchema = arrayOfItemsSchema
export type QuoteItemShema = z.infer<typeof itemSchema>

export const getMergedSchema = (condition?: boolean) => {
  return condition
    ? quoteCreateSchema.extend({
        items: arrayOfItemsSchema,
      })
    : quoteCreateSchema
}
