import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
  createEventLogs,
  deleteEventLogFile,
  getEmployeeList,
  getEventType,
  updateEventLog,
} from '../../../../apis/common.apis'
import { useAuthStore } from '../../../../store/authStore'
import { getErrorMessage } from '../../../../utilities/parsers'
import CustomDrawer from '../../../common/drawer'
import Icons from '../../../common/icons'
import FormFieldView from '../../../common/inputs/FormFieldView'
import { useSnackbarManager } from '../../../common/snackbar'
import FormBuilder from '../../formBuilder'
import {
  ACCEPTED_IMAGE_TYPES,
  attachmentsSchema,
  AttachmentsSchema,
} from './schema'

type Props = {
  isDrawerOpen: boolean
  disabled: boolean
  handleClose: () => void
  paramsId: any
  handleCallback?: () => void
  model_name: string
  rowData?: any
  setViewMode: (value: boolean) => void
  viewMode?: any
  edit?: boolean
  setEdit: (value: boolean) => void
  hasPermission?: boolean
}

export default function CreateFilesAndAttachments({
  isDrawerOpen,
  handleClose,
  paramsId,
  handleCallback,
  model_name,
  rowData,
  setViewMode,
  viewMode,
  edit,
  disabled,
  setEdit,
  hasPermission,
}: Props) {
  const handleClearAndClose = () => {
    setViewMode(false)
    setEdit(false)
    handleReset()
    handleClose()
    handleCallback?.()
  }
  const { userData, isMember } = useAuthStore()
  const [pageCount, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const textField = (
    name: string,
    label: string,
    placeholder: string,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    ...(required ? { required: true } : {}),
  })

  const handleEventType = async () => {
    const { data } = await getEventType({ type: 'dropdown' })

    return data?.results
  }
  const loadEmployeeList = async (value?: string, page?: number) => {
    const data = await getEmployeeList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })

    const pageLimit = data?.next ? pageCount + 1 : 0
    setPage(pageLimit)

    return data?.results?.map((item: any) => ({
      ...item,
      cre_agent_user_name: item.name,
    }))
  }

  const handleDefaultUser = () => {
    if (isMember) {
      return [{ id: userData?.id, name: userData.name }]
    } else return []
  }
  useEffect(() => {
    methods.reset({
      event_date: rowData?.event_date,
      event_time: rowData?.event_time,
      event_type_id: rowData?.event_type?.id,
      event_type_name: rowData?.event_type?.name,
      remarks: rowData?.remarks,
      assignees: rowData?.assignees ?? handleDefaultUser(),
      attachment: undefined,
      attachment_name: rowData?.attachment_name,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData, viewMode, edit])
  const getAssignees = () => {
    if (rowData?.assignees) {
      const result = rowData?.assignees?.map((item: any) => item.name)
      const resultString = result.join(', ')
      return resultString
    }
    return ''
  }
  const methods = useForm<AttachmentsSchema>({
    resolver: zodResolver(attachmentsSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const handleDeleteFile = () => {
    deleteEventLogFile(rowData?.id)
      .then(() => {
        enqueueSnackbar('File removed successfully', { variant: 'success' })
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.error, { variant: 'error' })
      })
  }
  const formBuilderProps = [
    {
      ...textField('event_date', 'Event Date', 'Enter Event Date'),
      type: 'date',
      required: true,
      value: rowData?.event_date
        ? moment(rowData?.event_date).format('DD-MM-YYYY')
        : '',
    },
    {
      ...textField('event_time', 'Event Time', 'Enter Event Time'),
      type: 'time_only',
      required: true,
      value: rowData?.event_time,
      // value: moment(rowData?.event_time, 'HH:mm:ss').format('h:mm A'),
    },
    {
      name: 'event_type_name',
      label: 'Event Type',
      getData: handleEventType,
      async: false,
      id: 'event_type_id',
      descId: 'id',
      initialLoad: true,
      required: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Event Type',
      value: rowData?.event_type?.name,
    },
    {
      ...textField('remarks', 'Event Notes/Remarks', 'Enter remarks'),
      type: 'textarea',
      required: true,
      value: rowData?.remarks,
    },
    {
      name: 'assignees',
      label: 'Event Participant(s)',
      getData: loadEmployeeList,
      async: true,
      id: 'document_type_id',
      descId: 'id',
      nextBlock: pageCount,
      paginationEnabled: true,
      initialLoad: true,
      required: true,
      selectedItems:
        rowData?.assignees ?? methods?.watch().assignees ?? handleDefaultUser(),
      isMultiple: true,
      desc: 'name',
      type: 'multi_select',
      placeholder: 'Event Participant(s)',
      value: getAssignees(),
    },
    {
      name: 'attachment',
      required: true,
      label: 'Attach file',
      id: 'attachment',
      selectedFiles: rowData?.attachment_name
        ? { name: rowData?.attachment_name, link: rowData?.attachment }
        : '',
      descId: 'id',
      handleDeleteFile: handleDeleteFile,
      supportedExtensions: ACCEPTED_IMAGE_TYPES,
      supportedFiles: ACCEPTED_IMAGE_TYPES,
      type: 'file_upload',
      value: (
        <>
          {rowData?.attachment ? (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              {rowData?.attachment_name ?? 'File'}
              <a
                style={{ marginLeft: '10px' }}
                href={rowData?.attachment}
                download={rowData?.attachment}
              >
                <Icons name="download" />
              </a>
            </span>
          ) : (
            <>--</>
          )}
        </>
      ),
      needConfirmation: true,
    },
  ]

  const handleSubmission = () => {
    methods.reset()
    handleClearAndClose()
    handleCallback?.()
  }
  const handleReset = () => {
    methods.reset({
      attachment: undefined,
    })
  }
  const handleChangeMode = () => {
    setViewMode(false)
    setEdit(true)
  }
  useEffect(() => {
    handleReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])
  const { enqueueSnackbar } = useSnackbarManager()
  const onSubmit = (data: any) => {
    setIsLoading(true)
    const formData = new FormData()
    formData.append('model_id', paramsId)
    formData.append('model_name', model_name)

    Object.keys(data)?.forEach((item: string) => {
      if (item !== 'attachment_name') {
        if (item === 'assignees') {
          const assignee = data?.assignees?.map((item: any) => item.id)
          assignee.forEach((id: string) => {
            formData.append('assignees', id)
          })
        } else if (item === 'event_date') {
          formData.append(
            'event_date',
            moment(data.event_date).format('YYYY-MM-DD')
          )
        } else if (item === 'attachment') {
          if (data?.attachment && typeof data?.attachment !== 'string') {
            formData.append('attachment', data?.attachment)
          }
        } else {
          formData.append(item, data[item])
        }
      }
    })
    const apiFun = rowData?.id
      ? updateEventLog(rowData?.id, formData)
      : createEventLogs(formData)
    apiFun
      .then(() => {
        enqueueSnackbar(
          getErrorMessage(
            rowData?.id
              ? 'Event Updated Successfully'
              : 'Event Created Successfully'
          ),
          {
            variant: 'success',
          }
        )
        handleSubmission()
        setIsLoading(false)
      })
      .catch((err) => {
        enqueueSnackbar(getErrorMessage(err.response.data.error), {
          variant: 'error',
        })
        setIsLoading(false)
      })
  }
  const { handleSubmit } = methods
  return (
    <CustomDrawer
      className="formDrawer"
      open={isDrawerOpen}
      actionLabel={viewMode ? 'Edit' : 'Save'}
      handleClose={() => handleClearAndClose()}
      hideSubmit={viewMode ? !hasPermission || disabled : false}
      handleSubmit={
        viewMode ? handleChangeMode : handleSubmit((data) => onSubmit(data))
      }
      actionLoader={isLoading}
      title={
        edit
          ? 'Edit Event Log'
          : viewMode
            ? 'View Event Log'
            : 'Create New Event Log'
      }
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          {!viewMode ? (
            <FormProvider {...methods}>
              <FormBuilder data={formBuilderProps} edit={true} />
            </FormProvider>
          ) : (
            formBuilderProps.map((data) => (
              <FormFieldView
                type={data?.type}
                value={data.value}
                label={data.label}
                key={data?.id}
              />
            ))
          )}
        </div>
      </div>
    </CustomDrawer>
  )
}
