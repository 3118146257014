import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { handleError } from '../../../../../../utilities/commonUtilities'
import { getTeamMembers } from '../api'
import { checkPermission } from '../../../../permission'
import Members from './members'

const VendorTeamMembersList = () => {
  const [data, setData] = useState<any>()
  const params = useParams()
  const [editFlag, setEditFlag] = useState(false)

  const { setActiveTab, handleParentCallback } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleParentCallback: () => void
  }>()

  useEffect(
    () => {
      setActiveTab('SYSTEM_SETTINGS_VENDOR_TEAM_MEMBERS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    if (params.id && params.id2) getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.id2])

  const getData = async () => {
    getTeamMembers(params.id, params.id2)
      .then((res) => {
        setData(res?.data?.results)
      })
      .catch((err) => {
        handleError(err)
      })
  }
  const handleRefresh = () => {
    getData()
    handleParentCallback?.()
  }

  const handleEditButton = (flag: boolean) => {
    setEditFlag(flag)
  }
  const handlePermission = () => {
    return checkPermission('change')
  }

  return (
    <Members
      data={data}
      id={params.id ?? ''}
      id2={params.id2 ?? ''}
      handleRefresh={handleRefresh}
      handleEditButton={handleEditButton}
      editFlag={editFlag}
      hasPermission={handlePermission()}
    />
  )
}

export default VendorTeamMembersList
