import { useEffect, useState } from 'react'

import {
  getEmployeeList,
  getLeadQualityList,
  getLeadStatusList,
  getTeamList,
} from '../../apis/common.apis'
import { useAuthStore } from '../../store/authStore'
import { useLeadFilterStore } from '../../store/filterSore/leadStore'
import { useMasterData } from '../../store/masterDataStore'
import { getEmployeeFranchiseeList } from '../employees/api'
import { getAllServiceList } from '../order/api'
import {
  getCountryState,
  getLeadSource,
  getSubIndusData,
  getbusIndusData,
  getbusinsector,
} from './api'

interface SubStatus {
  id: string
  name: string
  code: string
}
export const GetFilterDetails = () => {
  const userdata = useAuthStore?.getState()?.userData
  const defaultPages: Record<string, number> = {
    industry: 1,
    subIndustry: 1,
    employee: 1,
    sector: 1,

    language: 1,
  }
  const { masterData } = useMasterData()
  const [qualities, setQualities] = useState([])
  const [franchisees, setFranchisees] = useState([])
  const [pages, setPages] = useState(defaultPages)
  const getFranchiseeList = async () => {
    const { data } = await getEmployeeFranchiseeList(userdata?.id, {
      all_franchisee_filter: true,
    })

    const result = data.map((item: any) => ({
      ...item,
      franchisee_display_name:
        item?.franchisee_display_name ?? item?.franchisee_name,
    }))

    setFranchisees(result)
  }
  const { pageParams } = useLeadFilterStore()
  const getBusinessSector = async (value?: string, page?: number) => {
    const { data } = await getbusinsector({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setPages((prev) => ({
      ...prev,
      sector: data?.next ? prev['sector'] + 1 : 0,
    }))
    return data
  }

  const getLead = async (value?: string, page?: number) => {
    const { data } = await getLeadSource({
      search: value ?? '',
      page: page as number,
    })
    return data?.map((item: any) => ({
      ...item,
      lead_source_name: item.name,
    }))
  }

  const getIndustry = async (value?: string, page?: number) => {
    const { data } = await getbusIndusData({
      search: value ?? '',
      page: page as number,
      industry_only: true,
      business_sector_id: pageParams.filters.sector_id,
    })
    setPages((prev) => ({
      ...prev,
      industry: data?.next ? prev['industry'] + 1 : 0,
    }))
    return data?.results
  }
  const getEmployee = async (value?: string, page?: number) => {
    const data = await getEmployeeList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setPages((prev) => ({
      ...prev,
      employee: data?.next ? prev['employee'] + 1 : 0,
    }))

    return data?.results?.map((item: any) => ({
      ...item,
      cre_name: item.name,
    }))
  }
  const getCreatedBy = async (value?: string, page?: number) => {
    const data = await getEmployeeList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setPages((prev) => ({
      ...prev,
      employee: data?.next ? prev['employee'] + 1 : 0,
    }))

    return data?.results?.map((item: any) => ({
      ...item,
      created_by_name: item.name,
    }))
  }
  const getUpdateddBy = async (value?: string, page?: number) => {
    const data = await getEmployeeList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })
    setPages((prev) => ({
      ...prev,
      employee: data?.next ? prev['employee'] + 1 : 0,
    }))

    return data?.results?.map((item: any) => ({
      ...item,
      updated_by_name: item.name,
    }))
  }
  const getSubIndustry = async (value?: string, page?: number) => {
    const { data } = await getSubIndusData({
      search: value ?? '',
      page: page as number,
      sub_industry_only: true,
      industry__id: pageParams.filters.industry__id,
    })
    setPages((prev) => ({
      ...prev,
      subIndustry: data?.next ? prev['subIndustry'] + 1 : 0,
    }))
    return data.results.map((item: any) => ({
      ...item,
      sub_industry_name: item.business_industry_name,
    }))
  }

  const getState = async () => {
    const { data } = await getCountryState()
    return data?.state.map((item: any) => ({
      ...item,
      state_name: item.name,
      id: item.name,
    }))
  }
  const getCountry = async () => {
    const { data } = await getCountryState()
    return data?.countries.map((item: any) => ({
      ...item,
      country_name: item.name,
      id: item.name,
    }))
  }
  const loadServiceList = async (value?: string, page?: number) => {
    const { data } = await getAllServiceList({
      search: value ?? '',
      page: page as number,
    })
    setPages((prev) => ({
      ...prev,
      service: data?.next ? prev['service'] + 1 : 0,
    }))

    return data?.results?.map((item: any) => ({
      ...item,
      service_name: item.service_name,
    }))
  }
  const getLeadStatus = async (value?: string) => {
    const data = await getLeadStatusList('null', {
      type: 'dropdown',
      search: value ?? '',
    })
    const extractedStatuses: SubStatus[] = []
    data.data.results.forEach((result: any) => {
      result.status.forEach((subStatus: any) => {
        const { id, name, code } = subStatus
        extractedStatuses.push({ id, name, code })
      })
    })

    return extractedStatuses?.map((item: any) => ({
      ...item,
      name: item.name,
    }))
  }
  const getLeadQualities = async () => {
    const data = await getLeadQualityList()
    data?.data.forEach((item: any) => {
      item['lead_quality_name'] = item.name
      item['lead_quality_id'] = item.id
    })
    setQualities(data?.data || [])
  }
  const loadTeamLeadList = async (value?: string, page?: number) => {
    const data = await getTeamList({
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })

    return data?.results?.map((item: any) => ({
      ...item,
      team_name: generateLabelWithId(item.name, item.lead?.name),
    }))
  }
  useEffect(() => {
    getLeadQualities()
  }, [])
  useEffect(() => {
    if (userdata?.id) {
      getFranchiseeList()
    }
  }, [userdata?.id])
  const generateLabelWithId = (name?: string, lead_name?: string) => {
    return lead_name ? `${name} (${lead_name})` : name
  }

  return [
    {
      desc: 'franchisee_display_name',
      type: 'custom_search_select',
      descId: 'id',
      async: true,
      isPrimary: false,
      data: franchisees,
      name: 'Franchisee',
      slug: 'franchisee_id',
      objectId: 'franchisee_id',
    },
    {
      type: 'date_picker',
      selectRange: true,
      desc: 'created_from_date',
      descSecondary: 'created_to_date',
      isPrimary: true,
      name: 'Created Date',
    },
    {
      type: 'date_picker',
      selectRange: true,
      desc: 'followup_from_date',
      descSecondary: 'followup_to_date',
      isPrimary: false,
      name: 'Next Followup Date',
      slug: 'followup_date',
    },
    {
      desc: 'cre_name',
      type: 'multi_select',
      descId: 'id',
      async: true,
      paginationEnabled: true,
      nextBlock: pages.employee ?? undefined,
      isPrimary: true,
      getData: getEmployee,
      name: 'Primary CRE',
      slug: 'cre',
      objectId: 'primary_cre_id',
    },
    {
      desc: 'name',
      type: 'multi_select',
      descId: 'id',
      async: true,
      paginationEnabled: true,
      isPrimary: true,
      getData: getLeadStatus,
      name: 'Lead Status',
      slug: 'lead_status',
      objectId: 'status_id',
    },
    {
      desc: 'language_name',
      type: 'custom_search_select',
      descId: 'id',
      async: false,
      paginationEnabled: false,
      name: 'Language Pref',
      data: masterData?.languages,
      isPrimary: false,
      objectId: 'lead_additional_data__preferred_language_id',
      slug: 'language_pref',
    },
    {
      desc: 'lead_source_name',
      type: 'auto_suggestion',
      descId: 'id',
      async: true,
      paginationEnabled: false,
      isPrimary: false,
      getData: getLead,
      name: 'Lead Source',
      objectId: 'lead_source_id',
      slug: 'lead_source',
    },
    {
      desc: 'business_sector_name',
      type: 'auto_suggestion',
      descId: 'id',
      async: true,
      isPrimary: false,
      paginationEnabled: true,
      nextBlock: pages.sector ?? undefined,
      getData: getBusinessSector,
      name: 'Sector',
      slug: 'sector',
      objectId: 'sector_id',
    },
    {
      desc: 'sub_industry_name',
      type: 'auto_suggestion',
      descId: 'id',
      async: true,
      paginationEnabled: true,
      isPrimary: false,
      nextBlock: pages.subIndustry ?? undefined,
      getData: getSubIndustry,
      name: 'Sub sector',
      objectId: 'sub_sector_id',
      slug: 'sub_sector',
    },
    {
      desc: 'business_industry_name',
      type: 'auto_suggestion',
      descId: 'id',
      async: true,
      isPrimary: false,
      paginationEnabled: true,
      nextBlock: pages.industry ?? undefined,
      getData: getIndustry,
      name: 'Industry',
      slug: 'industry',
      objectId: 'industry_id',
    },
    {
      desc: 'lead_quality_name',
      type: 'custom_search_select',
      descId: 'lead_quality_id',
      async: true,
      isPrimary: false,
      data: qualities,
      name: 'Lead Quality',
      slug: 'lead_quality_id',
      objectId: 'lead_quality_id',
    },
    {
      desc: 'state_name',
      type: 'auto_suggestion',
      descId: 'id',
      async: false,
      paginationEnabled: false,
      isPrimary: false,
      initialLoad: true,
      getData: getState,
      name: 'State',
      slug: 'state',
      objectId: 'lead_address__state',
    },
    {
      desc: 'country_name',
      type: 'auto_suggestion',
      descId: 'id',
      async: false,
      paginationEnabled: false,
      isPrimary: false,
      initialLoad: true,
      getData: getCountry,
      name: 'Country',
      slug: 'country',
      objectId: 'lead_address__country',
    },
    {
      desc: 'service_name',
      type: 'multi_select',
      descId: 'id',
      async: true,
      isPrimary: false,
      paginationEnabled: true,
      nextBlock: pages.service ?? undefined,
      getData: loadServiceList,
      name: 'Service Name',
      objectId: 'service_id',
      slug: 'service_name',
    },
    {
      desc: 'created_by_name',
      type: 'multi_select',
      descId: 'id',
      async: true,
      paginationEnabled: true,
      nextBlock: pages.employee ?? undefined,
      isPrimary: false,
      getData: getCreatedBy,
      name: 'Created By',
      slug: 'crb',
      objectId: 'created_by_id',
    },
    {
      desc: 'updated_by_name',
      type: 'auto_suggestion',
      descId: 'id',
      async: true,
      paginationEnabled: true,
      nextBlock: pages.employee ?? undefined,
      isPrimary: false,
      getData: getUpdateddBy,
      name: 'Updated By',
      slug: 'upb',
      objectId: 'updated_by_id',
    },
    {
      desc: 'team_name',
      type: 'auto_suggestion',
      descId: 'id',
      async: true,
      isPrimary: false,
      paginationEnabled: false,
      getData: loadTeamLeadList,
      name: 'Team',
      objectId: 'team_id',
      slug: 'team',
    },
  ]
}
