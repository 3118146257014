import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { AdvanceFilter, StoreFilterParams } from '../../common/types'
import { defaultPageParams } from '../constants'
import { isAllFranchisee } from '../../configs/permissionGate'

const defaultParams = [
  { title: 'Initiated Date', slug: 'initiated_date', isChecked: false },
  { title: 'Payment Status', slug: 'payment_status', isChecked: false },
  { title: 'Payment Type', slug: 'payment_type', isChecked: false },
  { title: 'Order Status', slug: 'order_status', isChecked: false },
  {
    title: 'Verification Status',
    slug: 'verification_status',
    isChecked: false,
  },
  { title: 'Team', slug: 'team', isChecked: false },
  ...(isAllFranchisee()
    ? [{ title: 'Franchisee', slug: 'franchisee_id', isChecked: false }]
    : []),
]
export const usePaymentsFilterStore = create<PaymentsFilterstoreType>()(
  persist(
    (set) => ({
      pageParams: defaultPageParams,
      advanceFilter: {
        filterParams: defaultParams,
        isDetailed: true,
      },
      selectedRows: [],

      setAdvanceFilter: (data) => set(() => ({ advanceFilter: data })),
      clearAdvanceFilter: () =>
        set(() => ({
          advanceFilter: {
            filterParams: defaultParams?.map((item) => ({
              ...item,
              isChecked: false,
            })),
            isDetailed: true,
          },
        })),
      setPageParams: (data) => set(() => ({ pageParams: data })),
      setSelectedRows: (data) => set(() => ({ selectedRows: data })),
      clearSelectedRows: () => set({ selectedRows: [] }),
      clearPaymentFilter: (key) =>
        set((state) => ({
          pageParams: key
            ? { ...defaultPageParams, search: state?.pageParams?.search }
            : defaultPageParams,
        })),
    }),
    { name: 'fit__payment_store' }
  )
)

type PaymentsFilterstoreType = {
  advanceFilter: AdvanceFilter
  pageParams: StoreFilterParams
  selectedRows?: (number | string)[]

  setAdvanceFilter: (data: AdvanceFilter) => void
  setSelectedRows: (data: (number | string)[]) => void
  setPageParams: (data: StoreFilterParams) => void
  clearPaymentFilter: (key?: string) => void
  clearSelectedRows: () => void
  clearAdvanceFilter: () => void
}
