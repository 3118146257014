import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import FormBuilder from '../../../../../../components/app/formBuilder/index'
import { Button } from '../../../../../../components/common'
import Icons from '../../../../../../components/common/icons'
import FormFieldView from '../../../../../../components/common/inputs/FormFieldView'
import { useSnackbarManager } from '../../../../../../components/common/snackbar'
import { getErrorMessage } from '../../../../../../utilities/parsers'
import { fetchVendorEmployee } from '../../../../api'
import { TeamLeadSchema, teamLeadSchema } from '../../create/schema'
import { editTeam } from '../api'

type Props = {
  data: any
  id: string
  id2: string
  handleRefresh: () => void
  handleEditButton: (flag: boolean) => void
  editFlag?: boolean
  hasPermission?: boolean
  parentCallback?: any
}

const BasicInformation = (props: Props) => {
  const {
    data,
    id,
    id2,
    handleRefresh,
    parentCallback,
    handleEditButton,
    editFlag,
    hasPermission,
  } = props

  const [isEditing, setIsEditing] = useState(false)
  const { enqueueSnackbar } = useSnackbarManager()
  const [isLoading, setIsLoading] = useState(false)

  const [leadPage, setLeadPage] = useState(1)

  const onInit = () => {
    return {
      team_name: data?.name ?? '',
      lead_name: data?.lead?.name,
      lead_id: data?.lead?.id,
    }
  }
  const methods = useForm<TeamLeadSchema>({
    resolver: zodResolver(teamLeadSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...onInit(),
    },
  })
  useEffect(() => {
    methods.reset({
      ...onInit(),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const loadEmployeeList = async (value?: string, page?: number) => {
    const data = await fetchVendorEmployee(id as string, {
      search: value ?? '',
      page: page as number,
      type: 'dropdown',
    })

    const pageLimit = data?.data?.next ? leadPage + 1 : 0
    setLeadPage(pageLimit)

    return (
      data?.data?.results?.map((item: any) => ({
        ...item,
      })) || []
    )
  }

  const textField = (
    name: string,
    label: string,
    placeholder: string,
    value: any,
    required = false
  ) => ({
    name,
    label,
    id: name,
    type: 'text',
    placeholder,
    value,
    ...(required ? { required: true } : {}),
  })

  const formBuilderProps = [
    textField('team_name', 'Team Name', 'Enter Team Name', data?.name, true),
    {
      name: 'lead_name',
      label: 'Team Lead',
      required: true,
      getData: loadEmployeeList,
      async: true,
      id: 'lead_id',
      paginationEnabled: true,
      nextBlock: leadPage,
      descId: 'id',
      initialLoad: true,
      desc: 'name',
      type: 'auto_complete',
      placeholder: 'Select Team Lead',
      value: data?.lead?.name,
    },
  ]

  const handleEditClick = () => {
    setIsEditing(!isEditing)
  }
  const handleCancel = () => {
    setIsEditing(!isEditing)
    methods.reset({
      ...onInit(),
    })
  }
  const handleSubmission = () => {
    methods.reset({
      ...onInit(),
    })
    handleRefresh()
    parentCallback?.()
    setIsEditing(!isEditing)
  }

  const onSubmit = (data: any) => {
    setIsLoading(true)
    editTeam({ input: { name: data.team_name, lead: data?.lead_id }, id, id2 })
      .then(() => {
        enqueueSnackbar('Team Updated successfully', {
          variant: 'success',
        })
        handleSubmission()
        handleRefresh?.()
        setIsLoading(false)
      })
      .catch((error: any) => {
        enqueueSnackbar(
          getErrorMessage(
            error?.response?.data?.error?.message ??
              error?.response?.data?.errors
          ),
          {
            variant: 'error',
          }
        )
        setIsLoading(false)
      })
  }

  const { handleSubmit } = methods

  useEffect(() => {
    handleEditButton(isEditing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing])

  const renderFields = () => {
    return (
      <>
        {isEditing ? (
          <FormProvider {...methods}>
            <FormBuilder data={formBuilderProps} edit={isEditing} />
          </FormProvider>
        ) : (
          formBuilderProps.map((addressInformation) => (
            <div key={data?.id} className=" col-span-2 md:col-span-1">
              <FormFieldView
                value={addressInformation.value}
                required={addressInformation?.required}
                label={addressInformation.label}
                type={addressInformation?.type}
              />
            </div>
          ))
        )}
      </>
    )
  }

  return (
    <div className="bg-white rounded-lg shadow-cardShadow p-5 flex flex-col gap-5 col-span-2 lg:col-span-1">
      <div className="flex items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Icons name="location-pin" />
          <h4 className=" font-bold text-primaryText">Basic Info</h4>
        </div>
        <div className="iconBlack cursor-pointer">
          {hasPermission && (
            <div className="iconBlack cursor-pointer">
              {isEditing ? (
                <div className="flex gap-2">
                  <div>
                    <Button
                      onClick={handleCancel}
                      size="xs"
                      label="Cancel"
                      outlined={true}
                    />
                  </div>
                  <div>
                    <Button
                      size="xs"
                      label="Save"
                      outlined={false}
                      isLoading={isLoading}
                      onClick={handleSubmit((data) => onSubmit(data))}
                    />
                  </div>
                </div>
              ) : !editFlag ? (
                <Icons onClick={handleEditClick} name="edit" />
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-1 xl:grid-cols-2 gap-4">
        {renderFields()}
      </div>
    </div>
  )
}

export default BasicInformation
