import React from 'react'
import { IconProps } from '../../../common/types'

const SideMenuLogout: React.FC<IconProps> = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 13.05V2.90002H12.75V13.05H11.25ZM12 21.05C10.7667 21.05 9.60417 20.8125 8.5125 20.3375C7.42083 19.8625 6.46667 19.2167 5.65 18.4C4.83333 17.5834 4.1875 16.6292 3.7125 15.5375C3.2375 14.4459 3 13.2834 3 12.05C3 10.7167 3.28333 9.47086 3.85 8.31252C4.41667 7.15419 5.21667 6.12502 6.25 5.22502L7.3 6.27502C6.41667 6.99169 5.72917 7.84586 5.2375 8.83752C4.74583 9.82919 4.5 10.8998 4.5 12.0495C4.5 14.1332 5.22917 15.9042 6.6875 17.3625C8.14583 18.8209 9.91667 19.55 12 19.55C14.0893 19.55 15.8616 18.8209 17.317 17.3625C18.7723 15.9042 19.5 14.1332 19.5 12.0495C19.5 10.8998 19.2542 9.82086 18.7625 8.81252C18.2708 7.80419 17.6 6.95836 16.75 6.27502L17.825 5.22502C18.825 6.07502 19.6042 7.09169 20.1625 8.27502C20.7208 9.45836 21 10.7167 21 12.05C21 13.2834 20.7625 14.4459 20.2875 15.5375C19.8125 16.6292 19.1708 17.5834 18.3625 18.4C17.5542 19.2167 16.6042 19.8625 15.5125 20.3375C14.4208 20.8125 13.25 21.05 12 21.05Z"
        fill="#AAACB0"
      />
    </svg>
  )
}

export default SideMenuLogout
