import { QbsTable } from 'qbs-react-grid'
import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { router_config } from '../../../../configs/route.config'

import { useOrderVendorDetails } from '../../api'

import { getColumns } from './vendorOrderColumns'

const VendorOrders = () => {
  const { setActiveTab } = useOutletContext<{
    setActiveTab: (id: string) => void
  }>()
  const [pageParams, setPageParams] = useState<any>({ page_size: 30, page: 1 })
  const params = useParams()
  useEffect(
    () => {
      setActiveTab('SERVICE_AND_ORDERS_BY_ORDERS_VENDOR_ORDERS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const { data, isFetching } = useOrderVendorDetails(params?.id ?? '', {
    ...pageParams,
  })
  const onViewAction = (r: any, from?: string) => {
    if (from == 'contactName') {
      return `${router_config.CONTACTS.path}/${r.contact?.id}/summary`
    } else if (from == 'accountName') {
      return `${router_config.ACCOUNTS.path}/${r.account?.id}/summary`
    } else {
      return `${router_config.SERVICE_AND_ORDERS_BY_ORDERS.path}/${r.id}/summary`
    }
  }
  const onChangeRowsPerPage = (count: any) => {
    setPageParams({
      ...pageParams,
      page_size: count,
      page: 1,
    })
  }
  const onChangePage = (row: any) => {
    setPageParams({
      ...pageParams,
      page: row,
    })
  }
  return (
    <>
      <div className=" p-4">
        <QbsTable
          data={data?.results ?? []}
          dataRowKey="id"
          toolbar={true}
          // selection={true}
          search={true}
          isLoading={isFetching}
          columns={
            getColumns({
              onViewAction: onViewAction,
            }) as any
          }
          pagination
          paginationProps={{
            onPagination: onChangePage,
            total: data?.count,
            currentPage: pageParams?.page,
            rowsPerPage: Number(pageParams.page_size ?? data?.page_size),
            onRowsPerPage: onChangeRowsPerPage,
            dropOptions: process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN
              ? JSON.parse(process.env.REACT_APP_DEFAULT_PAGE_DROPDOWN)
              : [10, 20, 30, 50],
          }}
          columnToggle
        />
      </div>
    </>
  )
}

export default VendorOrders
