import React from 'react'
import Icons from '../../../../../../components/common/icons'
import { Checkbox } from '../../../../../../components/common'

type TreeItemProps = {
  label: string
  level?: number
  children?: React.ReactNode
  handleNodeCheck?: (data?: any, e?: any) => void
  node: any
  treeSelect?: boolean
  disabled?: boolean
  nodeIcon?: string
  handleTreeExpand?: (data: any) => void
  treeAction?: string
  handleTreeAction?: (data: any) => void
  nodeSelectedIcon?: string
  handleMenuActions?: (slug: string, row: any, node: any) => void
  nodeId?: any
  data?: any
}

const TableTreeRow: React.FC<TreeItemProps> = ({
  label,
  level = 0,
  children,
  disabled,
  node,
  handleNodeCheck,
  nodeIcon,
  handleTreeExpand,

  nodeId,
  data,
}) => {
  const handleTree = () => {
    handleTreeExpand?.(nodeId)
  }
  const handleParentCheck = () => {
    let child = false
    data?.forEach((item: any) => {
      if (node.id === item.parent) {
        child = true
      }
    })
    return child
  }

  const preparedChildren = node.isOpen ? <>{children}</> : null

  return (
    <div>
      <div className="grid grid-cols-7 gap-3 items-center border-b-[1px] border-[#E7E7E8] h-[42px] hover:bg-table-hover">
        <div className=" col-span-3 relative">
          <div
            style={{ marginLeft: level > 0 ? level * 10 : 0 }}
            className={` flex  items-center p-1 tree-content gap-2  `}
          >
            {handleParentCheck() ? (
              <button
                onClick={() => handleTree()}
                className="focus:outline-none"
              >
                <span className={`font-bold text-l  text-secondary`}>
                  <Icons name={node?.isOpen ? 'tree_expand' : 'tree_colapse'} />
                </span>
              </button>
            ) : (
              <div className="w-6"></div>
            )}

            <div
              className={`flex text-common  ${
                node?.checked || node.isOpen
                  ? 'text-secondary font-bold'
                  : 'text-table-headColor'
              }   ${nodeIcon ? '' : 'ml-1'} `}
            >
              <Checkbox
                key={node.id}
                disabled={disabled}
                intermediate={node.intermediate}
                handleChange={(e) => {
                  handleNodeCheck?.(node, e)
                }}
                checked={node.checked ? true : false}
                id={node.id}
              />
              <span className="pl-2">{label} </span>
            </div>
          </div>
        </div>
      </div>

      {preparedChildren}
    </div>
  )
}

export default TableTreeRow
