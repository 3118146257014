import React from 'react'
import { IconProps } from '../../../common/types'

const MenuUpArrow: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.8769 4.521C7.78382 4.521 7.69849 4.53652 7.62092 4.56754C7.54336 4.59857 7.46579 4.65287 7.38822 4.73044L2.78068 9.33798C2.65657 9.46208 2.5984 9.62886 2.60615 9.83829C2.61391 10.0477 2.67984 10.2145 2.80395 10.3386C2.95909 10.4937 3.12586 10.5597 3.30427 10.5364C3.48267 10.5131 3.64169 10.4394 3.78131 10.3153L7.8769 6.21974L11.9725 10.3153C12.0966 10.4394 12.2634 10.5093 12.4728 10.5248C12.6822 10.5403 12.849 10.4705 12.9731 10.3153C13.1283 10.1912 13.1942 10.0283 13.1709 9.82665C13.1476 9.62498 13.074 9.45433 12.9498 9.31471L8.36558 4.73044C8.28801 4.65287 8.21044 4.59857 8.13287 4.56754C8.05531 4.53652 7.96998 4.521 7.8769 4.521Z"
        fill="white"
      />
    </svg>
  )
}

export default MenuUpArrow
