import React from 'react'
import { IconProps } from '../../../common/types'

const TextFieldDone: React.FC<IconProps> = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.8172 10.1075L0 5.29032L0.924731 4.36559L4.8172 8.25806L13.0753 0L14 0.924731L4.8172 10.1075Z"
        fill="#1F8B24"
      />
    </svg>
  )
}

export default TextFieldDone
