import React from 'react'

import { IconProps } from '../../../common/types'

const Invoice: React.FC<IconProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M13.3333 11.3886C13.3333 12.4019 13.24 13.122 13 13.6353C13 13.642 12.9933 13.6553 12.9867 13.662C12.84 13.8486 12.6467 13.9419 12.42 13.9419C12.0667 13.9419 11.64 13.7086 11.18 13.2153C10.6333 12.6286 9.79331 12.6753 9.31331 13.3153L8.64001 14.2086C8.37335 14.5686 8.02 14.7486 7.66667 14.7486C7.31333 14.7486 6.95999 14.5686 6.69332 14.2086L6.01335 13.3086C5.54001 12.6753 4.70666 12.6286 4.15999 13.2086L4.15332 13.2153C3.39999 14.0219 2.73335 14.142 2.34668 13.662C2.34001 13.6553 2.33333 13.642 2.33333 13.6353C2.09333 13.122 2 12.4019 2 11.3886V4.77528C2 3.76195 2.09333 3.04195 2.33333 2.52862C2.33333 2.52195 2.33335 2.51528 2.34668 2.50862C2.72668 2.02195 3.39999 2.14195 4.15332 2.94862L4.15999 2.95528C4.70666 3.53528 5.54001 3.48862 6.01335 2.85528L6.69332 1.95528C6.95999 1.59528 7.31333 1.41528 7.66667 1.41528C8.02 1.41528 8.37335 1.59528 8.64001 1.95528L9.31331 2.84862C9.79331 3.48862 10.6333 3.53528 11.18 2.94862C11.64 2.45528 12.0667 2.22195 12.42 2.22195C12.6467 2.22195 12.84 2.32195 12.9867 2.50862C13 2.51528 13 2.52195 13 2.52862C13.24 3.04195 13.3333 3.76195 13.3333 4.77528V11.3886Z"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3335 6.91528H10.6668"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3335 9.24878H9.3335"
        stroke="#292D32"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Invoice
