import { generatePath, useNavigate, useParams } from 'react-router-dom'

import { router_config } from '../../../configs/route.config'
import { useAppStore } from '../../../store/appStore'

export default function CustomBreadCrumbs() {
  const { activeRouteSlug } = useAppStore()
  const params = useParams()
  const navigate = useNavigate()
  const data = activeRouteSlug
    ? router_config[activeRouteSlug]['breadcrumb']
    : []

  const generateDynamicPath = (path: string) => {
    if (params) {
      return generatePath(path, {
        ...(params?.id ? { id: params?.id } : {}),
        ...(params?.i2 ? { id: params?.id2 } : {}),
      })
    }
    // return path
  }
  if (!activeRouteSlug) {
    return null
  }

  return (
    <div className={'dark:text-white text-black px-4 '}>
      <div className=" gap-2" key={activeRouteSlug}>
        <ul className="breadcrumb-navigation" aria-label="breadcrumb">
          {data &&
            data.length &&
            data.map((item, i) => {
              return (
                <>
                  {data.length !== i + 1 &&
                  !router_config[item]?.isDetails &&
                  router_config[item]?.path ? (
                    <li key={i}>
                      <>
                        <span
                          key={i}
                          className={
                            'dark:text-white cursor-pointer text-xxs text-secondary font-semibold '
                          }
                          onClick={() => {
                            navigate(
                              router_config[item].breadcrumb_path
                                ? (generateDynamicPath(
                                    router_config[item]
                                      .breadcrumb_path as string
                                  ) as string)
                                : (router_config[item].path as string)
                            )
                          }}
                        >
                          {router_config[item]?.label}
                        </span>
                      </>
                    </li>
                  ) : (
                    <li
                      key={i}
                      className={
                        'dark:text-white  text-xxs text-secondary font-semibold'
                      }
                    >
                      {router_config[item]?.label}
                    </li>
                  )}
                </>
              )
            })}
        </ul>
      </div>
    </div>
  )
}
