const PROJECT_NAME = 'BIZPOLE'
const PROJECT_LOGO = '/logo.png'
const PROJECT_LOGO_LIGHT = '/logo_light.png'
const PROJECT_DESC = ''
const EMPTY_ILLUSTRATION = '/empty-illustration.svg'

const config = {
  PROJECT_DESC,
  PROJECT_LOGO,
  PROJECT_NAME,
  PROJECT_LOGO_LIGHT,
  EMPTY_ILLUSTRATION,
}
export default config
