import React from 'react'
import { IconProps } from '../../../common/types'

const UnLink: React.FC<IconProps> = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2331_51676)">
        <path
          d="M5.04863 7.26216L2.78105 9.52975C1.73965 10.5711 1.73965 12.2596 2.78105 13.301C3.82245 14.3424 5.51089 14.3424 6.55228 13.301L7.28666 12.5666M10.9646 8.88863L13.219 6.63432C14.2604 5.59292 14.2604 3.90448 13.219 2.86308C12.1776 1.82168 10.4891 1.82168 9.44772 2.86308L8.71464 3.59615"
          stroke="#222222"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M0.692871 0.774902L15.307 15.3891"
          stroke="#222222"
          strokeWidth="1.5"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2331_51676">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.0820312)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default UnLink
