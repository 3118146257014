import { useEffect, useState } from 'react'
import { useOutletContext, useParams } from 'react-router-dom'

import { handleError } from '../../../../../../utilities/commonUtilities'
import { checkPermission } from '../../../../permission'
import { getRoleDetails } from '../api'
import BasicInformation from './basicInfo'

const VendorRoleSummary = () => {
  const [data, setData] = useState<any>()
  const params = useParams()

  const [editFlag, setEditFlag] = useState(false)

  const { setActiveTab, handleCallBack, roleData } = useOutletContext<{
    setActiveTab: (id: string) => void
    handleCallBack: () => void
    roleData?: any
  }>()

  useEffect(
    () => {
      setActiveTab('SYSTEM_SETTINGS_VENDOR_ROLE_SUMMARY')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  useEffect(() => {
    setData(roleData)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async () => {
    getRoleDetails(params.id, params.id2)
      .then((res) => {
        setData(res)
      })
      .catch((err) => {
        handleError(err)
      })
  }
  const handleRefresh = () => {
    getData()
    handleCallBack?.()
  }

  const handleEditButton = (flag: boolean) => {
    setEditFlag(flag)
  }

  const handlePermission = () => {
    return checkPermission('change')
  }
  return (
    <div className="p-4 rounded-lg bg-cardWrapperBg gap-4 grid grid-cols-2">
      <BasicInformation
        data={data}
        id={params.id ?? ''}
        id2={params.id2 ?? ''}
        handleRefresh={handleRefresh}
        handleEditButton={handleEditButton}
        editFlag={editFlag}
        hasPermission={handlePermission()}
      />
    </div>
  )
}

export default VendorRoleSummary
