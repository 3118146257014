import { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'

import TaskComponent from '../../../../components/app/task/index'
import { checkDetailPermission } from '../../permission'

const ActivityTasks = () => {
  const { setActiveTab, hasServiceLock, hasOrderLock } = useOutletContext<{
    setActiveTab: (id: string) => void
    hasServiceLock: boolean
    hasOrderLock: boolean
  }>()

  useEffect(
    () => {
      setActiveTab('ACTIVITY_DETAILS_TASKS')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <TaskComponent
      model_name="OrderItemTaskEmployee"
      disabled={hasServiceLock || hasOrderLock}
      checkModulePermission={checkDetailPermission}
    />
  )
}

export default ActivityTasks
