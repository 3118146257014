import { z } from 'zod'

export const orderEditSchemaFull = z.object({
  account_name: z.string().refine((val) => val !== '', {
    message: 'Account is required',
  }),
  account_id: z
    .string()
    .refine((val) => val !== '', { message: 'Account is required' }),
  contact_name: z.string().refine((val) => val !== '', {
    message: 'Contact is required',
  }),
  contact_id: z
    .string()
    .refine((val) => val !== '', { message: 'Contact is required' }),
  order_date: z
    .union([z.date(), z.string()])
    .refine((val) => val !== null, { message: 'Quote Cre is required' }),

  internal_campaign_id: z.string().optional().nullable(),
  spot_incentive: z
    .union([
      z
        .string()
        .transform((val) =>
          val === '' || isNaN(parseFloat(val)) ? null : parseFloat(val)
        )
        .refine((val) => val !== null && val && val >= 0, {
          message: 'Invalid number format',
        }),
      z.number(),
      z.null(),
    ])
    .optional(),

  lead_source_name: z.string().optional().nullable(),
  lead_source_id: z.string().optional().nullable(),
  assigned_to: z
    .string({
      invalid_type_error: 'Quote Cre is required',
      required_error: "Quote Cre is required' ",
    })
    .refine((val) => val !== '', { message: 'Quote Cre is required' }),
  employee_id: z
    .string({
      invalid_type_error: 'Quote Cre is required',
      required_error: 'Quote Cre is required',
    })
    .refine((val) => val !== '', { message: 'Quote Cre is required' }),
  assigned_to_name: z
    .string({
      invalid_type_error: 'Quote Cre is required',
      required_error: 'Quote Cre is required',
    })
    .refine((val) => val !== '', { message: 'Quote Cre is required' }),
  order_comment: z.string().optional().nullable(),
  quote_note: z.string().optional().nullable(),
  external_id: z.string().optional().nullable(),
  service_name: z.string().optional().nullable(),
  service_id: z.string().optional().nullable(),

  // Edit Service
  order_number: z.string().optional().nullable(),
  total_services_count: z.number().optional().nullable(),
  datetime_created: z.union([z.date(), z.string()]).optional().nullable(),
  created_by: z.string().optional().nullable(),
  order_status_name: z
    .string()
    .refine((val) => val !== '', { message: 'Quote Status is required' }),
  order_status_id: z
    .string()
    .refine((val) => val !== '', { message: 'Quote Status is required' }),
})
export const orderEditSchema = orderEditSchemaFull.omit({
  employee_id: true,
})
export type OrderEditSchema = z.infer<typeof orderEditSchema>
